import dayjs from 'dayjs';

import {
  clearImpersonatedSupplier,
  getBluonIframeUrlResponse,
  getClaimingImageUrlResponse,
  loginResponse,
  logoutPreventRedirectResponse,
  openTermsOfService,
  readTermsOfService,
  resetUser,
  sendSMSResponse,
  setShouldAdjustPermissions,
} from './actions';
import {
  AuthStateProps,
  IImpersonatedSupplierResponseAction,
  ISetShouldAdjustPermissionsAction,
  IStorePermissionsAction,
  IclaimingImageResponseProps,
  IframeResponseProps,
  LoginMappedProps,
  LoginResponseProps,
  ReadTermsProps,
  SendSMSResponseProps,
} from './interface';

import {
  IClaimingDataProps,
  IClaimingImageResponseData,
  IframeDataProps,
  IframeResponseData,
} from '@config/api/auth/interface';
import {
  impersonateSupplierResponse,
  removeSupplierImpersonate,
} from '@state/manager/actions';
import { getStorePermissionsResponse } from '@state/storeInfo/actions';
import { getLoginInfo } from '@utils/Auth/AuthUtils';

const _getInitialLoginInfo = (): LoginMappedProps => {
  const storedInfo = getLoginInfo();
  if (!storedInfo?.impersonatedSupplier)
    return { ...storedInfo, permissions: [] };

  return {
    ...storedInfo,
    permissions: [],
    impersonatedSupplier: {
      ...storedInfo?.impersonatedSupplier,
      permissions: [],
    },
  };
};

const initialState: AuthStateProps = {
  loginResponse: _getInitialLoginInfo(),
  changePasswordResponse: '',
  showTerms: false,
  readTerms: false,
  preventRedirectUser: false,
  shouldAdjustPermissions: false,
  lastUpdatedPermissions: '',
  iframeData: {
    id: '',
    label: '',
    type: '',
    value: '',
  },
  sendSMSResponse: {
    phone: '',
    next_request_available_at: '',
    type: '',
  },
  claimingImage: {
    id: '',
    label: '',
    type: '',
    value: {
      id: '',
      url: '',
      conversions: [],
    },
  },
};

const authReducer = (
  state = initialState,
  action:
    | LoginResponseProps
    | ReadTermsProps
    | SendSMSResponseProps
    | IframeResponseProps
    | IclaimingImageResponseProps
    | IStorePermissionsAction
    | ISetShouldAdjustPermissionsAction
    | IImpersonatedSupplierResponseAction,
): AuthStateProps => {
  switch (action.type) {
    case String(loginResponse):
      return {
        ...state,
        preventRedirectUser: false,
        shouldAdjustPermissions: false,
        loginResponse: (action as LoginResponseProps).payload.data,
      };
    case String(openTermsOfService):
      return {
        ...state,
        showTerms: action.payload as boolean,
      };

    case String(readTermsOfService):
      return {
        ...state,
        readTerms: action.payload as boolean,
      };
    case String(getBluonIframeUrlResponse): {
      const { data } = action.payload as IframeDataProps;

      return {
        ...state,
        iframeData: data as IframeResponseData,
      };
    }
    case String(getClaimingImageUrlResponse): {
      const { data } = action.payload as IClaimingDataProps;

      return {
        ...state,
        claimingImage: data as IClaimingImageResponseData,
      };
    }
    case String(sendSMSResponse):
      return {
        ...state,
        sendSMSResponse: (action as SendSMSResponseProps).payload.data,
      };

    case String(logoutPreventRedirectResponse):
      return {
        ...initialState,
        preventRedirectUser: true,
      };

    case String(resetUser):
      return {
        ...initialState,
        preventRedirectUser: state.preventRedirectUser,
        shouldAdjustPermissions: false,
        lastUpdatedPermissions: '',
        loginResponse: {
          id: '',
          initial_password_set_at: '',
          token: '',
          distributor: false,
          impersonatedSupplier: undefined,
          permissions: [],
        },
      };
    case String(getStorePermissionsResponse): {
      const newLoginData = {
        ...(state?.loginResponse || {}),
        permissions: (action as IStorePermissionsAction)?.payload?.data,
      };

      return {
        ...state,
        lastUpdatedPermissions: dayjs(new Date()).toISOString(),
        loginResponse: newLoginData,
      };
    }
    case String(setShouldAdjustPermissions): {
      return {
        ...state,
        shouldAdjustPermissions: (action as ISetShouldAdjustPermissionsAction)
          ?.payload?.shouldAdjust,
      };
    }

    case String(impersonateSupplierResponse): {
      return {
        ...state,
        loginResponse: {
          ...state?.loginResponse,
          impersonatedSupplier: (action as IImpersonatedSupplierResponseAction)
            ?.payload?.data,
        },
        lastUpdatedPermissions: dayjs(new Date()).toISOString(),
      };
    }

    case String(removeSupplierImpersonate): {
      return {
        ...state,
        lastUpdatedPermissions: '',
      };
    }

    case String(clearImpersonatedSupplier): {
      return {
        ...state,
        loginResponse: {
          ...state?.loginResponse,
          impersonatedSupplier: undefined,
        },
        lastUpdatedPermissions: '',
      };
    }
    default:
      return state;
  }
};

export default authReducer;
