import Container from '@mui/material/Container';
import { useEffect } from 'react';

import { CircularLoading } from '../../../../components/CircularLoading';
import { useSnackbar } from '../../../../components/Snackbar/hooks';

import { ConfirmModal } from './components/ConfirmModal';
import './styles.scss';
import { CustomerDashboard } from './components/CustomerDashboard';
import { CustomerTabAndTableProps } from './components/CustomerDashboard/interface';
import { DeleteModal } from './components/DeleteModal';
import { EditModal } from './components/EditModal';
import { MoveToPending } from './components/MoveToPending';
import { RemoveModal } from './components/RemoveModal';
import {
  confirmedColumns,
  removedColumns,
  unconfirmedColumns,
} from './data/columns';

import { useAppDispatch, useAppSelector } from '@hooks/state';
import { getCustomerListRequest } from '@state/customer/actions';
import { getCustomerListData } from '@state/customer/selectors';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import { getIsNationalSupplier } from '@state/task/selectors';

export const Customer = (): JSX.Element => {
  const tableContainerSx = {
    height: '285px',
    overflow: 'auto',
  };

  const { showMessage } = useSnackbar();
  const dispatch = useAppDispatch();

  const {
    data: { unconfirmedUsers, confirmedUsers, removedUsers },
    isLoading,
    errors,
    isNationalSupplier,
  } = useAppSelector((state) => {
    const result = {
      data: {
        ...getCustomerListData(state),
      },
      isLoading: isRequestRunning(state, String(getCustomerListRequest), true),
      errors: getRequestError(state, String(getCustomerListRequest)),
      isNationalSupplier: getIsNationalSupplier(state),
    };

    return result;
  });

  const unconfirmedCustomerDashboardTables: CustomerTabAndTableProps[] = [
    {
      tab: 'Pending',
      customerList: unconfirmedUsers,
      columns: unconfirmedColumns,
      tableContainerSx: tableContainerSx,
      PrimaryActionButton: ConfirmModal,
      SecondaryActionButton: RemoveModal,
      'data-testid': 'unconfirmedCustomerList',
      isNationalSupplier,
    },
    {
      tab: 'Removed',
      customerList: removedUsers,
      columns: removedColumns,
      tableContainerSx: tableContainerSx,
      PrimaryActionButton: MoveToPending,
      'data-testid': 'removedCustomerList',
      isNationalSupplier,
    },
  ];

  const confirmedCustomerDashboardTables: CustomerTabAndTableProps[] = [
    {
      customerList: confirmedUsers,
      columns: confirmedColumns,
      tableContainerSx: tableContainerSx,
      PrimaryActionButton: DeleteModal,
      SecondaryActionButton: EditModal,
      'data-testid': 'confirmedCustomerList',
      isNationalSupplier,
    },
  ];

  useEffect(() => {
    dispatch(getCustomerListRequest());
  }, []);

  useEffect(() => {
    if (
      errors &&
      errors?.response?.status !== 403 &&
      errors?.response?.status !== 401
    )
      return showMessage('There was an error loading customers', {
        severity: 'error',
      });
  }, [errors]);

  const renderCustomerLists = () => {
    return isLoading ? (
      <CircularLoading />
    ) : (
      <>
        <CustomerDashboard
          title='Unconfirmed Customers'
          customerDashboardTables={unconfirmedCustomerDashboardTables}
        />

        <CustomerDashboard
          title='Confirmed Customers'
          customerDashboardTables={confirmedCustomerDashboardTables}
        />
      </>
    );
  };

  return (
    <Container maxWidth='xl' className='bl-customer-container '>
      {renderCustomerLists()}
    </Container>
  );
};
