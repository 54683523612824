import { AbilityBuilder, PureAbility, SubjectRawRule } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { createContext } from 'react';

import { EAbilityActions, EAbilitySubjects } from './types';

// Function to get current abilities by permissions
const getRules = (userPermissions: EAbilitySubjects[] | null) => {
  if (!userPermissions?.length) return [];

  const { can, rules } = new AbilityBuilder<PureAbility>(PureAbility);

  const enumSubjects = Object.values(EAbilitySubjects);
  userPermissions.forEach((permission) => {
    if (enumSubjects.includes(permission))
      can(EAbilityActions.MANAGE, permission);
  });

  return rules;
};

export const ability = new PureAbility(getRules([]));

export const updateAbilityImpersonate = (
  userPermissions: EAbilitySubjects[],
) => {
  if (!ability.rules.length)
    ability.update(
      getRules(userPermissions) as SubjectRawRule<string, string, unknown>[],
    );
};

export const updateAbility = (userPermissions: EAbilitySubjects[]) => {
  if (!ability.rules.length)
    ability.update(
      getRules(userPermissions) as SubjectRawRule<string, string, unknown>[],
    );
};

export const resetAbilities = () => {
  ability.update(getRules([]) as SubjectRawRule<string, string, unknown>[]);
};

export const AbilityContext = createContext<PureAbility>(ability);
export const Can = createContextualCan(AbilityContext.Consumer);
