import React from 'react';

import { OrderDetailTypes } from '../../constants';

import { ConfirmedAccountCustomerBanner } from './components/ConfirmedAccountCustomerBanner';
import { ConfirmedCashCustomerBanner } from './components/ConfirmedCashCustomerBanner';
import DeclinedOrCanceledBanner from './components/DeclinedOrCanceledBanner';
import { UnconfirmedCustomerBanner } from './components/UnconfirmedCustomerBanner';
import { OrderBannerProps } from './interface';

import { EUserStatusType } from '@utils/enums';

export const OrderBanner = ({
  order,
  orderDetailType,
  isNationalSupplier,
}: OrderBannerProps) => {
  if (
    orderDetailType === OrderDetailTypes.CANCELED ||
    orderDetailType === OrderDetailTypes.RETURNED
  )
    return <DeclinedOrCanceledBanner order={order} />;

  if (
    isNationalSupplier &&
    order?.supplier_user?.status !== EUserStatusType.REMOVED
  )
    if (order?.supplier_user?.status === EUserStatusType.UNCONFIRMED)
      return <UnconfirmedCustomerBanner order={order} />;
    else if (order?.supplier_user?.account_number)
      return (
        <ConfirmedAccountCustomerBanner
          accountNumber={order?.supplier_user?.account_number}
        />
      );
    else if (order?.supplier_user?.cash_buyer)
      return <ConfirmedCashCustomerBanner />;

  return null;
};
