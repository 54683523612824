import { RootState } from '..';

import {
  GetOrdersProps,
  Order,
  StatusListFilterResponse,
} from '@config/api/task/interface';

export const getOrdersLogs = (state: RootState): Order[] => {
  return state?.ordersLogsReducer?.logs;
};

export const getHasNextPage = (state: RootState): boolean => {
  return state?.ordersLogsReducer?.hasNextPage;
};

export const getNextPage = (state: RootState): number => {
  return state?.ordersLogsReducer?.nextPage || 1;
};

export const getLogFilter = (state: RootState): GetOrdersProps | null => {
  return state?.ordersLogsReducer?.filter;
};
export const getStatusListFilter = (
  state: RootState,
): StatusListFilterResponse[] => {
  return state?.ordersLogsReducer?.statusListFilter;
};
