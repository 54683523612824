import { Check } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';

import { ICustomSelectProps } from './types';

import './styles.scss';

const CustomSelect = ({
  options,
  addSelectedIcon = false,
  ...selectProps
}: ICustomSelectProps) => {
  const renderValue = (value: unknown) => {
    const selectedOption =
      options?.find((option) => option?.value === value) || options[0];
    if (!selectedOption) return null;

    return <ListItemText>{selectedOption?.label}</ListItemText>;
  };

  return (
    <Select {...selectProps} renderValue={renderValue}>
      {options?.map((option) => (
        <MenuItem
          className='select-menu-item custom-menu-item'
          value={option?.value}
          key={option?.value}
          selected={option?.value === selectProps?.value}
        >
          <ListItemText>{option?.label}</ListItemText>
          {option?.value === Number(selectProps?.value) && addSelectedIcon && (
            <ListItemIcon className='menu-item-icon'>
              <Check />
            </ListItemIcon>
          )}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelect;
