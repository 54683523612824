import * as Yup from 'yup';

import { UnconfirmedCustomerModalFormKeys } from '../interface';

export const UnconfirmedCustomerModalValidationSchema = Yup.object<
  Record<keyof UnconfirmedCustomerModalFormKeys, Yup.AnySchema>
>({
  account_number: Yup.string().when('$hasAccount', {
    is: true,
    then: Yup.string().max(50, 'Max 50 characters').required('Required'),
  }),
  customer_tier: Yup.string().max(25, 'Max 25 characters').notRequired(),
});
