import { all, call, select, takeLatest } from 'redux-saga/effects';

import { getUsersCountReceive, getUsersCountRequest } from './actions';
import { IGetUsersCountAction } from './interface';

import { getUsersCount } from '@config/api/user';
import makeRequest from '@state/requests/make-request';
import { getRequestError } from '@state/requests/selectors';

export function* workerGetUsersCountRequest(
  action: IGetUsersCountAction,
): Generator<unknown> {
  const customCall: any = call;
  const { payload } = action;

  //  Calling API
  yield customCall(makeRequest, {
    endpoint: getUsersCount,
    params: {},
    requestAction: action,
    receiveAction: getUsersCountReceive,
  });

  //  Handling error state
  const error = yield select((state) =>
    getRequestError(state, getUsersCountRequest),
  );
  if (
    error &&
    payload?.errorCallback &&
    payload?.errorCallback instanceof Function
  )
    return payload?.errorCallback();

  //  Handling success state
  if (payload?.successCallback && payload?.successCallback instanceof Function)
    payload?.successCallback();
}

export default function* shareableOrderSagas(): Generator<unknown> {
  yield all([takeLatest(getUsersCountRequest, workerGetUsersCountRequest)]);
}
