import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import {
  errorResponseAction,
  handlePermissionsError,
  handleUserDisabledError,
} from './errorResponseAction';
import { log404ErrorsInTests } from './logTestErrors';

import {
  logout,
  logoutPreventRedirectResponse,
  setShouldAdjustPermissions,
} from '@state/auth/actions';
import store from '@state/index';
import { getLoginInfo } from '@utils/Auth/AuthUtils';
import { emptyFunction } from '@utils/functions';

const environment = process ? process.env : null;

let abortResponseAction = false;

const handleLogout = (preventRedirect = false) => {
  const { dispatch } = store;

  abortResponseAction = true;
  // restore abortResponseAction after 1 second
  setTimeout(() => {
    abortResponseAction = false;
  }, 1000);

  if (preventRedirect) dispatch(logoutPreventRedirectResponse());

  dispatch(logout(() => emptyFunction()));
};

const onPermissionsError = () => {
  const { dispatch } = store;

  abortResponseAction = true;
  // restore abortResponseAction after 1 second
  setTimeout(() => {
    abortResponseAction = false;
  }, 1000);

  dispatch(setShouldAdjustPermissions({ shouldAdjust: true }));
};

//  INTERCEPTORS
const requestInterceptor = (config: AxiosRequestConfig) => {
  const loginData = getLoginInfo();
  const isDistributorEndpoint = config.url?.includes('distributor');

  const token =
    loginData.impersonatedSupplier && !isDistributorEndpoint
      ? loginData.impersonatedSupplier.token
      : loginData.token;

  Object.defineProperty(config, 'headers', {
    value: { Authorization: `Bearer ${token}` },
  });

  return config;
};

const responseSuccessInterceptor = (successRes: any) => {
  return successRes;
};

const responseErrorInterceptor = (error: any) => {
  log404ErrorsInTests(error);
  errorResponseAction(error, handleLogout, abortResponseAction);
  handleUserDisabledError(abortResponseAction, error, handleLogout);
  handlePermissionsError(abortResponseAction, error, onPermissionsError);

  return Promise.reject(error);
};

//  Main client
const client: AxiosInstance = axios.create({
  baseURL: `${environment?.REACT_APP_API_BASE_URL}`,
});
client.interceptors.request.use(requestInterceptor);
client.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

export default client;
