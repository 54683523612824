import client from '..';

const environment = process ? process.env : null;

export const getShareableOrder = async (orderId: string) => {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/unauthenticated/orders/${orderId}`,
  );

  return data;
};

export const getShareableOrderParts = async (orderId: string) => {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/unauthenticated/orders/${orderId}/parts`,
  );

  return data;
};

export const getShareableOrderExtraItems = async ({
  orderId,
  initial_request = 1,
}: {
  orderId: string;
  initial_request: number;
}) => {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/unauthenticated/orders/${orderId}/extra-items`,
    { params: { initial_request } },
  );

  return data;
};

export const getShareableOrderCustomItems = async (orderId: string) => {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/unauthenticated/orders/${orderId}/custom-items`,
  );

  return data;
};

export const cancelShareableOrder = async (params: {
  orderId: string;
  reason: string;
  detail?: string;
}) => {
  const { orderId, reason, detail } = params;
  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/unauthenticated/orders/${orderId}/decline`,
    {
      reason,
      detail,
    },
  );

  return data;
};

export const approveShareableOrder = async (orderId: string) => {
  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/unauthenticated/orders/${orderId}/contractor-approve`,
  );

  return data;
};

export const approveUpdatedShipmentOrder = async (orderId: string) => {
  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/unauthenticated/orders/${orderId}/delivery/shipment/approve`,
  );

  return data;
};
