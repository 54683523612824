import PartAvailability from '../PartAvailability';

import UnlistedPartItemDetail from './components/UnlistedPartItemDetail';
import UnlistedPartItemInfo from './components/UnlistedPartItemInfo';
import { UnlistedPartItemProps } from './interface';

import { partImage } from '@utils/functions';

const UnlistedPartItem = ({
  item,
  isLastItem,
  handleLastItemAction,
  setSelectedId,
  expanded,
}: UnlistedPartItemProps) => (
  <div className='mb-2 flex w-full flex-col items-center justify-center rounded-lg border border-solid border-[var(--bluon--ds--lightgray-4)]'>
    <div
      className='flex w-full cursor-pointer flex-col items-center justify-center bg-[var(--bluon--default--white)] p-2'
      onClick={() => setSelectedId(expanded ? null : item?.item?.id)}
    >
      <div className='flex w-full flex-row items-center'>
        <div className='flex h-10 w-10 flex-col items-center justify-center'>
          <img src={partImage(item?.item?.info, item?.item?.type)} />
        </div>
        <UnlistedPartItemInfo item={item} />
        <div className='ml-auto flex flex-row items-center justify-center'>
          <PartAvailability
            item={item}
            weCanGetItByDate=''
            expanded={expanded}
            selectedReplacement={null}
          />
        </div>
      </div>
    </div>
    {expanded && (
      <UnlistedPartItemDetail
        item={item}
        isLastItem={isLastItem}
        handleLastItemAction={handleLastItemAction}
        closeExpanded={() => setSelectedId(null)}
      />
    )}
  </div>
);

export default UnlistedPartItem;
