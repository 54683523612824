import { LoginMappedProps } from '@state/auth/interface';

const environment = process ? process.env : null;

const emptyLoginInfo: LoginMappedProps = {
  id: '',
  initial_password_set_at: '',
  token: '',
  distributor: false,
  impersonatedSupplier: undefined,
};

export function isLogged(): boolean {
  const loginData = getLoginInfo();

  return !!loginData.token;
}

export function hasToSetInitialPassword(): boolean {
  const loginData = getLoginInfo();

  return !!loginData.initial_password_set_at;
}

export function getLoginInfo(): LoginMappedProps {
  const loginData = window.sessionStorage.getItem(
    `${environment?.REACT_APP_STORAGE_KEY}:user-loginData`,
  );

  if (!loginData) return emptyLoginInfo;

  const parsedLoginData = JSON.parse(loginData);

  return parsedLoginData as LoginMappedProps;
}

export function setAuthToken(loginData: LoginMappedProps): void {
  sessionStorage.setItem(
    `${environment?.REACT_APP_STORAGE_KEY}:user-loginData`,
    JSON.stringify(loginData),
  );
}

export function getManagerAuthToken(): LoginMappedProps {
  const loginData = window.sessionStorage.getItem(
    `${environment?.REACT_APP_STORAGE_KEY}:managerUser-loginData`,
  );

  if (!loginData) return emptyLoginInfo;

  return JSON.parse(loginData) as LoginMappedProps;
}

export const updateLoginInfoPermissions = (permissions: string[]) => {
  const loginInfo = getLoginInfo();
  const newLoginData = {
    ...(loginInfo || {}),
    permissions,
  };

  setAuthToken(newLoginData);
};
