import Grid from '@mui/material/Grid';
import { Controller } from 'react-hook-form';

import { StoreContactInputsProps } from './interface';

import InputComponent from '@components/InputComponent';
import { FormTextInputLengths } from '@utils/FormInputLengths';

const StoreContactInputs = ({
  control,
  isLoading,
}: StoreContactInputsProps): JSX.Element => (
  <Grid
    container
    justifyContent='center'
    className='store-info-inputs-container'
  >
    <Grid item xs={12} md={9}>
      <Controller
        name='contact_phone'
        control={control}
        render={({ field, fieldState, formState }) => (
          <InputComponent
            variant='outlined'
            id='contact_phone'
            label='Store Phone Number (how techs call your branch)'
            data-testid='contactPhoneNumber'
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) event.preventDefault();
            }}
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            inputProps={{
              maxLength: FormTextInputLengths.profile.phoneFullNumber,
            }}
            helperText={formState.errors.contact_phone?.message}
            isLoading={isLoading}
            keepHelperTextSpace
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={9}>
      <Controller
        name='contact_email'
        control={control}
        render={({ field, fieldState, formState }) => (
          <InputComponent
            variant='outlined'
            id='contact_email'
            label='Primary Store Email'
            data-testid='contactPrimaryEmail'
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            inputProps={{
              maxLength: FormTextInputLengths.profile.email,
            }}
            helperText={formState.errors.contact_email?.message}
            isLoading={isLoading}
            keepHelperTextSpace
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={9}>
      <Controller
        name='contact_secondary_email'
        control={control}
        render={({ field, fieldState, formState }) => (
          <InputComponent
            variant='outlined'
            id='contact_secondary_email'
            label={
              <>
                Secondary Email <a>(Optional)</a>
              </>
            }
            data-testid='contactSecondaryEmail'
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            inputProps={{
              maxLength: FormTextInputLengths.profile.email,
            }}
            helperText={formState.errors.contact_secondary_email?.message}
            isLoading={isLoading}
            keepHelperTextSpace
          />
        )}
      />
    </Grid>
  </Grid>
);

export default StoreContactInputs;
