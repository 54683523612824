import { UnlistedOrderItem } from '@config/api/task/interface';

export interface UnlistedPartItemActionsProps {
  disableActions: boolean;
  isLoading: boolean;
  orderId: string;
  formValues: { brand: string; part_number: string };
  item: UnlistedOrderItem;
  isLastItem: boolean;
  closeExpanded: () => void;
  handleLastItemAction: () => void;
  triggerValidations: () => Promise<boolean>;
}

export enum EUnlistedPartStatus {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'not_available',
}

export enum EButtonIds {
  WE_CANT_GET_IT = '1',
  WE_CAN_GET_IT_BY = '2',
  WE_CAN_GET_IT = '3',
}

export interface IUnlistedItemPayload {
  order: string;
  item: string;
  status: EUnlistedPartStatus;
  available_at: string | undefined;
  brand: string;
  part_number: string;
  quantity: number;
  price: number;
  replacement: null;
}
