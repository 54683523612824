import { RootState } from '..';

import { Profile, Supplier } from './interface';

export function getSupplierList(state: RootState): Array<Supplier> | undefined {
  return state.managerReducer.supplierList;
}

export function getManagerProfile(state: RootState): Profile | undefined {
  return state.managerReducer.profile;
}

export function getImpersonatedSupplier(
  state: RootState,
): Supplier | undefined {
  return state.managerReducer.impersonatedSupplier;
}
