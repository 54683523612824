import { Theme } from '@mui/material';

import { bluonColors, solidColors } from './colors';

export const MuiButtonThemeVariants = (theme: Theme) => [
  {
    props: { size: 'large' },
    style: {
      fontFamily: 'Montserrat',
      height: 42,
      minWidth: 106,
      paddingBottom: 10,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 8,
      [theme.breakpoints.down('lg')]: {
        padding: 0,
      },
      // -- button text props --
      fontWeight: '600',
      fontSize: 16,
    },
  },
  {
    props: { size: 'medium' },
    style: {
      fontFamily: 'Montserrat',
      height: 37,
      minWidth: 85,
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 6,
      // -- button text props --
      fontWeight: '600',
      fontSize: 15,
      letterSpacing: -0.24,
    },
  },
  {
    props: { size: 'small' },
    style: {
      fontFamily: 'Montserrat',
      height: 30,
      minWidth: 75,
      paddingBottom: 4,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 2,
      // -- button text props --
      fontWeight: '600',
      fontSize: 14,
    },
  },
  {
    props: { variant: 'contained', color: 'error' },
    style: {
      '&:hover': {
        color: solidColors.white,
      },
    },
  },
  {
    props: { variant: 'contained', color: 'success' },
    style: {
      '&:hover': {
        color: solidColors.white,
      },
    },
  },
  {
    props: { variant: 'outlined', color: 'primary' },
    style: {
      borderColor: bluonColors.blue[500],
      color: bluonColors.blue[500],
      '&:hover': {
        backgroundColor: bluonColors.blue[50],
        borderColor: bluonColors.blue[600],
        color: bluonColors.blue[600],
      },
    },
  },
  {
    props: { variant: 'outlined', color: 'primary2' },
    style: {
      borderColor: bluonColors.blue[500],
      color: bluonColors.blue[500],
      backgroundColor: bluonColors.blue[50],
      '&:hover': {
        backgroundColor: bluonColors.blue[50],
        borderColor: bluonColors.blue[600],
        color: bluonColors.blue[600],
      },
    },
  },
  {
    props: { variant: 'outlined', color: 'buy' },
    style: {
      borderColor: bluonColors.yellow[700],
      color: bluonColors.yellow[700],
      '&:hover': {
        backgroundColor: bluonColors.yellow[50],
        borderColor: bluonColors.yellow[800],
        color: bluonColors.yellow[800],
      },
    },
  },
  {
    props: { variant: 'outlined', color: 'buy2' },
    style: {
      borderColor: bluonColors.yellow[500],
      color: bluonColors.yellow[500],
      '&:hover': {
        backgroundColor: bluonColors.yellow[50],
        borderColor: bluonColors.yellow[800],
        color: bluonColors.yellow[800],
      },
    },
  },
  {
    props: { variant: 'outlined', color: 'secondary' },
    style: {
      borderColor: bluonColors.navy[500],
      color: bluonColors.navy[500],
      '&:hover': {
        backgroundColor: bluonColors.navy[50],
        borderColor: bluonColors.navy[600],
        color: bluonColors.navy[600],
      },
    },
  },
  {
    props: { variant: 'outlined', color: 'error' },
    style: {
      borderColor: bluonColors.tomato[500],
      color: bluonColors.tomato[500],
      '&:hover': {
        backgroundColor: bluonColors.tomato[100],
        borderColor: bluonColors.tomato[600],
        color: bluonColors.tomato[600],
      },
    },
  },
  {
    props: { variant: 'outlined', color: 'success' },
    style: {
      borderColor: bluonColors.green[500],
      color: bluonColors.green[500],
      '&:hover': {
        backgroundColor: bluonColors.green[50],
        borderColor: bluonColors.green[600],
        color: bluonColors.green[600],
      },
    },
  },
  {
    props: { variant: 'text', color: 'primary' },
    style: {
      '&:hover': {
        backgroundColor: bluonColors.blue[50],
      },
    },
  },
  {
    props: { variant: 'text', color: 'buy' },
    style: {
      color: bluonColors.yellow[700],
      '&:hover': {
        backgroundColor: bluonColors.yellow[50],
        color: bluonColors.yellow[800],
      },
    },
  },
  {
    props: { variant: 'text', color: 'secondary' },
    style: {
      color: bluonColors.navy[500],
      '&:hover': {
        backgroundColor: bluonColors.navy[50],
        color: bluonColors.navy[600],
      },
    },
  },
  {
    props: { variant: 'text', color: 'error' },
    style: {
      color: bluonColors.tomato[500],
      '&:hover': {
        backgroundColor: bluonColors.tomato[100],
        color: bluonColors.tomato[600],
      },
    },
  },
  {
    props: { variant: 'text', color: 'success' },
    style: {
      color: bluonColors.green[500],
      '&:hover': {
        backgroundColor: bluonColors.green[50],
        color: bluonColors.green[600],
      },
    },
  },
];

export const MuiButtonTheme = (theme: Theme) => ({
  styleOverrides: {
    root: {
      boxShadow: 'none',
      borderRadius: 1000,
      textTransform: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: bluonColors.grey[300],
        color: bluonColors.grey[600],
      },
      '&.MuiButton-outlined.Mui-disabled': {
        backgroundColor: 'transparent',
        borderColor: bluonColors.grey[300],
        color: bluonColors.grey[600],
      },
      '&.MuiButton-text.Mui-disabled': {
        backgroundColor: 'transparent',
        color: bluonColors.grey[600],
      },
      '.MuiButton-startIcon': {
        marginRight: 4,
      },
      '.MuiButton-endIcon': {
        marginLeft: 4,
      },
      '.MuiButton-iconSizeLarge': {
        '.MuiSvgIcon-root': {
          fontSize: 24,
        },
      },
      '.MuiButton-iconSizeMedium': {
        '.MuiSvgIcon-root': {
          fontSize: 24,
        },
      },
      '.MuiButton-iconSizeSmall': {
        '.MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
  },
  variants: MuiButtonThemeVariants(theme),
});
