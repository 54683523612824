import luxon, { DateTime } from 'luxon';

import { SelectedHoursDataModel } from '../../config/api/storeInfo/interface';
import './styles.scss';
import { convertTime12to24 } from '../Numbers';
import { capitalizeFirstLetter } from '../Strings/capitalize';

import { IWeekdayNumber, weekdayNumberISO } from './constants';

export const getHoursMinutesSecondsDiff = (
  dateTo: number,
  dateFrom: number,
): {
  hours: number;
  minutes: number;
  seconds: number;
} => {
  const hours = Math.abs(dateTo - dateFrom) / (1000 * 60 * 60);
  const minutes = (Math.abs(dateTo - dateFrom) / (1000 * 60)) % 60;
  const seconds = (Math.abs(dateTo - dateFrom) / 1000) % 60;

  return {
    hours,
    minutes,
    seconds,
  };
};

export const formatETATime = (time: string) => {
  const index = time.indexOf('M');

  const formatTime = [
    time.slice(0, index - 1),
    ':00',
    time.slice(index - 1, time.length),
  ].join('');

  return DateTime.fromFormat(formatTime.trim(), 'h:mma').toFormat('HH:mm');
};

export const parseETATime = (start_time: string, end_time: string) => {
  if (!start_time || !end_time) return '';

  const start = DateTime.fromFormat(start_time, 'HH:mm')
    .toFormat('h:a')
    .replace(':', '');

  const end = DateTime.fromFormat(end_time, 'HH:mm')
    .toFormat('h:a')
    .replace(':', '');

  return `${start} - ${end}`;
};

const getNextOpenDay = (hours: SelectedHoursDataModel[], today: number) => {
  let nextOpenDay = today;

  for (let i = 0; i < hours.length; i++) {
    if (hours[nextOpenDay]?.active) return hours[nextOpenDay];

    if (nextOpenDay + 1 >= hours.length) nextOpenDay = 0;
    else nextOpenDay++;
  }

  return hours[nextOpenDay];
};

export const formatSelectedHoursToUTCDate = (
  selectedHours: SelectedHoursDataModel,
  from: boolean,
) => {
  const [hour, minutes] = convertTime12to24(
    from ? selectedHours.from : selectedHours.to,
    'array',
  );

  const currentTime = DateTime.now();
  const today = currentTime.weekday;
  const selectedToday =
    weekdayNumberISO[selectedHours.day as keyof IWeekdayNumber];

  const diffInDay =
    selectedToday !== today ? selectedToday + ((7 - today) % 7) : 0;

  const selectedTime = currentTime.plus({ days: diffInDay }).set({
    hour: Number(hour),
    minute: Number(minutes),
    millisecond: 0,
    second: 0,
  });

  return selectedTime.toUTC();
};

export const getMinutesDiff = (
  dateTo: luxon.DateTime,
  dateFrom: luxon.DateTime,
) => {
  return dateTo.diff(dateFrom, 'minutes').minutes;
};

export const getOpenHours = (
  branchHours?: SelectedHoursDataModel[],
): JSX.Element => {
  if (!branchHours) return <></>;

  const calculateOpenHour = (): JSX.Element => {
    if (branchHours.length === 0) return <></>;
    const currentTime = DateTime.utc();
    const today = currentTime.toLocal().weekday;
    const branchHourToday = branchHours[today - 1];
    const closeDate = formatSelectedHoursToUTCDate(
      branchHours[today - 1],
      false,
    );

    const isAfterHours = getMinutesDiff(currentTime, closeDate) > 0;
    const isOpenToday = branchHourToday?.active && !isAfterHours;

    const supplierIsClosed = (): JSX.Element => {
      const nextOpenDay = getNextOpenDay(branchHours, today);
      const nextOpenDayIndex =
        weekdayNumberISO[nextOpenDay.day as keyof IWeekdayNumber];
      const openTomorrow = today + 1 == nextOpenDayIndex;
      const openHours = branchHours[nextOpenDayIndex - 1].from;
      const openDay = capitalizeFirstLetter(
        branchHours[nextOpenDayIndex - 1].day,
      );

      return openTomorrow ? (
        <>Open Tomorrow: {openHours}</>
      ) : (
        <>
          Open On {openDay}: {openHours}
        </>
      );
    };

    if (!isOpenToday) return supplierIsClosed();

    const openDate = formatSelectedHoursToUTCDate(branchHours[today - 1], true);
    const diffInMinutesToOpen = getMinutesDiff(openDate, currentTime);
    const diffInMinutesToClose = getMinutesDiff(closeDate, currentTime);

    if (diffInMinutesToOpen > 0)
      return <>Open Soon: {branchHours[today - 1].from} </>;

    if (diffInMinutesToClose < 60)
      return (
        <span className='close-soon-text'>
          Closes Soon: {branchHours[today - 1].to}
        </span>
      );

    if (diffInMinutesToClose >= 0 && diffInMinutesToOpen <= 60)
      return <>Open Until: {branchHours[today - 1].to}</>;

    if (diffInMinutesToClose < 0) return supplierIsClosed();

    return <></>;
  };

  return calculateOpenHour();
};

export const diffDateAndLabels = (date: Date, today: Date) => {
  const diffTime = Math.abs(today.valueOf() - date.valueOf());
  let days = diffTime / (24 * 60 * 60 * 1000);
  let hours = (days % 1) * 24;
  let minutes = (hours % 1) * 60;

  [days, hours, minutes] = [
    Math.floor(days),
    Math.floor(hours),
    Math.floor(minutes),
  ];

  let status = 'danger';

  if (minutes >= 0 && minutes < 5) status = 'green';

  if (minutes > 4 && minutes < 10) status = 'warning';

  if (days > 0 || hours > 0) status = 'danger';

  return {
    formattedLabel: `${days > 0 ? days + 'd ' : ''}${
      hours > 0 ? hours + 'hr ' : ''
    }${minutes >= 0 ? minutes + 'm ' : ''}`,
    status,
  };
};

export const transformStringDateTo12HoursFormat = (
  time: string | undefined,
) => {
  if (time) {
    const formattedTimeUTC = DateTime.fromFormat(time, 'HH:mm', {
      zone: 'utc',
    });

    return formattedTimeUTC.toLocal().toFormat('hh:mm a');
  }
};

export const checkIfIsTodayOrTomorrow = (date: string) => {
  if (date === DateTime.now().toFormat('yyyy-MM-dd').toLocaleString())
    return 'Today';
  if (
    date ===
    DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd').toLocaleString()
  )
    return 'Tomorrow';

  return date;
};

export const hoursAndMinutesByMinutes = (minutes: number): string => {
  if (minutes <= 59) return `${minutes % 60} minutes`;

  const hour = Math.floor(minutes / 60);
  const finalMinute = minutes % 60;

  return `${hour} ${hour > 1 ? 'hours' : 'hour'} ${
    finalMinute === 0
      ? ''
      : `${finalMinute}${finalMinute > 1 ? ' minutes' : ' minute'}`
  }`;
};

export const hasPassedLessThan15Minutes = (date: string) => {
  const providedDate = DateTime.fromISO(date, { zone: 'utc' });

  return (
    providedDate.isValid &&
    DateTime.utc().diff(providedDate, 'minutes').minutes <= 15
  );
};
