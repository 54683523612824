import { Company } from '@config/api/task/interface';

export interface UnconfirmedCustomerModalProps {
  open: boolean;
  accountUser?: AccountUser;
  closeModal?: () => void;
}
export interface AccountUser {
  name: string;
  id: string;
  email: string;
  phone: string;
  company: Company;
}

export interface UnconfirmedCustomerModalFormKeys {
  account_number: string;
  customer_tier: string;
}

export interface ConfirmUserPayload {
  customer_tier: string;
  have_account_number: boolean | undefined;
  user: number | undefined;
  onSuccessCallback: () => void;
  onErrorCallback: () => void;
  account_number?: string;
}

export enum ECustomerAccount {
  NO_SELECTION,
  HAS_ACCOUNT,
  CASH_BUYER,
}
