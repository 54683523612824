import { CircularProgress, Modal } from '@mui/material';

import { FunctionalPartsModalSection } from './components/FunctionalPartsModalSection';
import { TruckStockModalSection } from './components/TruckStockModalSection';

import CloseModalIcon from '@assets/icons/close.svg';
import { Button } from '@components/ButtonV2';
import { useAppSelector } from '@hooks/state';
import useIsAllUnavailableItemsCheck from '@hooks/useIsAllUnavailableItemsCheck';
import { isRequestRunning } from '@state/requests/selectors';
import { sendForApprovalRequest } from '@state/task/actions';
import {
  getOrderCustomItems,
  getOrderInvoice,
  getOrderItems,
  getOrderSupplyItems,
} from '@state/task/selectors';
import { emptyFunction } from '@utils/functions';

export const ReviewAndSendModal = ({
  open,
  onClose,
  totalValue,
  onSendAvailability,
}: {
  open: boolean;
  onClose: () => void;
  totalValue: string | undefined;
  onSendAvailability: () => void;
}) => {
  const {
    isAllUnavailable,
    allFunctionalParts,
    allTruckStockUnavailable,
    isAllUnavailableButCustomItems,
  } = useIsAllUnavailableItemsCheck();

  const { items, truckStockItems, orderCustomItems, isLoading, orderInvoice } =
    useAppSelector((state) => ({
      orderInvoice: getOrderInvoice(state),
      items: getOrderItems(state),
      truckStockItems: getOrderSupplyItems(state),
      orderCustomItems: getOrderCustomItems(state),
      isLoading: isRequestRunning(state, String(sendForApprovalRequest)),
    }));

  const allUnavailableButAdditionalItems =
    isAllUnavailableButCustomItems &&
    orderCustomItems &&
    !!orderCustomItems.length;

  const showFunctionalPartsTitle =
    items &&
    !!items.length &&
    (isAllUnavailable ||
      !allFunctionalParts ||
      allUnavailableButAdditionalItems);

  const showTruckStockTitle =
    truckStockItems &&
    !!truckStockItems.length &&
    (isAllUnavailable ||
      !allTruckStockUnavailable ||
      allUnavailableButAdditionalItems);

  return (
    <Modal
      onBackdropClick={() => null}
      open={open}
      onClose={emptyFunction}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='flex items-center justify-center'
    >
      <div className='flex w-full max-w-[800px] flex-col rounded-lg bg-white px-4 py-4'>
        <div className='mb-1 flex w-full items-end justify-end'>
          <div
            onClick={onClose}
            className='mb-1 flex h-[1.75rem] w-[1.75rem] cursor-pointer items-center justify-center'
          >
            <img src={CloseModalIcon} alt='close' className='h-4 w-4' />
          </div>
        </div>
        <div className='mx-2 flex flex-col'>
          <div className='mb-2 mr-8 w-full'>
            <div className='font-[Roboto] text-[1.5rem] font-normal leading-[2rem]'>
              Review & Send
            </div>
          </div>
          <div className='flex w-full flex-row items-center justify-between bg-[var(--bluon--light--gray-21)] px-4 py-1.5'>
            <div className='font-[Roboto] text-[0.875rem] font-medium leading-[1.5rem]'>
              Items
            </div>
            <div className='font-[Roboto] text-[0.875rem] font-medium leading-[1.5rem]'>
              Qty
            </div>
          </div>
          <div className='max-h-[27.75rem] overflow-auto'>
            {showFunctionalPartsTitle && (
              <div className='px-4'>
                <div className='font-[Roboto] text-[0.875rem] font-medium leading-[3rem] text-[var(--bluon--default--blue-3)]'>
                  Functional Parts
                </div>
              </div>
            )}
            {items.map((item, idx) => (
              <FunctionalPartsModalSection
                key={item.id}
                items={items}
                item={item}
                idx={idx}
                isAllUnavailable={isAllUnavailable}
                hasAdditionalItem={allUnavailableButAdditionalItems}
              />
            ))}

            {orderCustomItems && !!orderCustomItems.length && (
              <div className='px-4'>
                <div className='font-[Roboto] text-[0.875rem] font-medium leading-[3rem] text-[var(--bluon--default--blue-3)]'>
                  Additional Items You Added
                </div>
              </div>
            )}
            {orderCustomItems.map((customItem) => (
              <div
                key={customItem.id}
                className='flex items-center justify-between p-4'
              >
                <div className='font-[Roboto] text-[0.875rem] font-bold leading-[1.25rem]'>
                  {customItem.item.info.name}
                </div>
                <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem]'>
                  {customItem.quantity}
                </div>
              </div>
            ))}

            {showTruckStockTitle && (
              <div className='px-4'>
                <div className='font-[Roboto] text-[0.875rem] font-medium leading-[3rem] text-[var(--bluon--default--blue-3)]'>
                  Truck Stock
                </div>
              </div>
            )}
            <div className='mb-[1.5rem]'>
              {truckStockItems &&
                !!truckStockItems.length &&
                truckStockItems.map((truckStockItem) => (
                  <TruckStockModalSection
                    key={`truck-stock-item-${truckStockItem.id}`}
                    truckStockItem={truckStockItem}
                    isAllUnavailable={isAllUnavailable}
                    hasAdditionalItem={allUnavailableButAdditionalItems}
                  />
                ))}
            </div>
          </div>
          <div className='mb-2 flex w-full items-center justify-center py-4'>
            {!isAllUnavailable ? (
              <div className='text-[1.25rem] font-medium leading-[2rem] tracking-[0.009rem]'>
                Quote Total: ${totalValue?.replace(/\s/g, '')}
              </div>
            ) : (
              <div className='text-[1.25rem] font-medium leading-[2rem] tracking-[0.009rem] text-[var(--bluon--dark--red-2)]'>
                Requested Items Unavailable
              </div>
            )}
          </div>
          <div className='mb-4 flex w-full items-center justify-center'>
            <Button onClick={onSendAvailability} variant='contained'>
              <>
                Send Availability
                {isLoading && (
                  <CircularProgress
                    size='small'
                    className='ml-1.5 w-[1.5rem] !text-white'
                  />
                )}
              </>
            </Button>
          </div>
          {!isAllUnavailable && !orderInvoice && (
            <div className='mb-4 flex w-full items-center justify-center'>
              <div className='font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] text-[var(--bluon--default--blue-3)]'>
                * You’re sending a quote without a quote / invoice attached.
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
