import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { filter } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { BrandsList } from '../../../../../../components/BrandsList';
import { useChipList } from '../../../../../../components/ChipList/hooks/useChipList';

import { BrandsModalEmptySearch } from './components/BrandsEmptySearch';
import { BrandsListSkeleton } from './components/BrandsListSkeleton';
import { BrandsRequestError } from './components/BrandsRequestError';
import { BRANDS_MODAL_ERRORS, BRANDS_MODAL_HEADERS } from './constants';
import {
  Brand,
  BrandModalProps,
  BrandsLabel,
  IModelBrandsItem,
} from './interface';

import { ChipKey } from '@components/ChipList/interface';
import './styles.scss';
import { SearchInput } from '@components/SearchInput';
import { VirtualizedInfiniteList } from '@components/VirtualizedInfiniteList';

const MAX_NUMBER_BRANDS = 20;

export const BrandModal = (props: BrandModalProps): JSX.Element => {
  const {
    initialBrands,
    isOpen,
    ModalActions,
    brandsList,
    title,
    internalSearch,
    hasNextPage,
    isLoading,
    isLoadingMore,
    showListHeaders,
    requestError,
    tooltipTitle,
    maxSelectedBrands,
    canSendEmpty,
    onClose,
    onSearch,
    onLoadMore,
    tryAgainAction,
  } = props;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [search, setSearch] = useState<string>('');
  const [brandsToUpdate, setBrandsToUpdate] =
    useState<BrandsLabel[]>(initialBrands);
  const [filteredBrands, setFilteredBrands] = useState<Brand[]>(brandsList);

  //  Watching initial brands changes
  useEffect(() => {
    setBrandsToUpdate(initialBrands);
  }, [initialBrands]);

  const canAddNewItem =
    brandsToUpdate?.length < (maxSelectedBrands ?? MAX_NUMBER_BRANDS);

  const canDelete = canSendEmpty || brandsToUpdate?.length > 1;

  const headers = useMemo(() => {
    if (!showListHeaders) return undefined;

    const listHeaders: Record<number, string> = {
      0: BRANDS_MODAL_HEADERS.TOP_BRAND,
    };

    const lastTopBrand = brandsList?.findLastIndex((brand) => brand.top_brand);

    listHeaders[lastTopBrand + 1] = BRANDS_MODAL_HEADERS.ALL_BRAND;

    return lastTopBrand ? listHeaders : undefined;
  }, [showListHeaders, brandsList]);

  const checkedBrandsId = useMemo(
    () => brandsToUpdate?.map((item) => item.id) ?? [],
    [brandsToUpdate],
  );

  const onHandleClose = () => {
    if (isDirty && errorMessage !== BRANDS_MODAL_ERRORS.DIRTY_MODAL)
      return setErrorMessage(BRANDS_MODAL_ERRORS.DIRTY_MODAL);

    setBrandsToUpdate(initialBrands);
    resetInitialState(initialBrands);
    setSearch('');
    setIsDirty(false);
    onClose();
  };

  const onChipDeleteClicked = (chipKey: ChipKey) => {
    setIsDirty(true);
    if (!canAddNewItem) setErrorMessage('');
    const newChecked = brandsToUpdate.filter((c) => c.id !== chipKey);
    setBrandsToUpdate(newChecked);
  };

  const { ChipListElement, addNewItem, deleteItem, resetInitialState } =
    useChipList({
      initialData: brandsToUpdate,
      onChipDeleteClicked,
      canDelete,
    });

  const handleClickBrand = (brand: Brand) => {
    const currentIndex = brandsToUpdate.findIndex(
      (item) => item.id === brand.id,
    );
    const newBrandsToUpdate = [...brandsToUpdate];
    const isAddingNewBrand = currentIndex === -1;

    if (isAddingNewBrand) {
      if (!canAddNewItem)
        return setErrorMessage(BRANDS_MODAL_ERRORS.MAX_AMOUNT);

      const newBrand = {
        id: brand.id,
        label: brand.name,
      };

      newBrandsToUpdate.push(newBrand);
      addNewItem(newBrand);
    } else if (canDelete) {
      newBrandsToUpdate.splice(currentIndex, 1);
      deleteItem(brand.id);
    }

    setIsDirty(true);
    setErrorMessage('');
    setBrandsToUpdate(newBrandsToUpdate);
  };

  const handleFilterSearchChange = (value: string) => {
    setSearch(value);

    if (internalSearch)
      setFilteredBrands(
        filter(
          brandsList,
          (item) => item.name.toLowerCase().indexOf(value) > -1,
        ),
      );
    else if (onSearch) onSearch(value);
  };

  const onCloseDialog = (event: unknown, reason: string) => {
    if (reason !== 'backdropClick') onClose();
  };

  const loadMoreRows = () => {
    if (hasNextPage && !isLoading && onLoadMore) return onLoadMore(search);
  };

  return (
    <Dialog open={isOpen} onClose={onCloseDialog} className='bl-brands-modal'>
      <Box
        className={`${
          errorMessage === BRANDS_MODAL_ERRORS.DIRTY_MODAL && 'disabled'
        }`}
      >
        <Box className='modal-header-text'>
          <Typography variant='h6' color='initial' className='title'>
            {title}

            {tooltipTitle && (
              <Tooltip
                title={tooltipTitle}
                placement='top'
                arrow
                PopperProps={{ style: { zIndex: 10001 } }}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            )}
          </Typography>
          <IconButton onClick={onHandleClose} data-testid='closeIconButton'>
            <CloseIcon />
          </IconButton>
        </Box>

        <SearchInput type='TextField' onChange={handleFilterSearchChange} />

        {brandsToUpdate.length > 0 && (
          <Box className='chips-container' data-testid='chipsContainer'>
            <ChipListElement data-testid='brandModal' />
          </Box>
        )}
      </Box>

      <DialogContent className={`modal-content ${errorMessage && 'disabled'}`}>
        {isLoading ? (
          <BrandsListSkeleton listHeaders={!search && showListHeaders} />
        ) : (
          !requestError && (
            <VirtualizedInfiniteList
              hasNextPage={hasNextPage}
              isLoading={!!isLoadingMore}
              list={internalSearch ? filteredBrands : brandsList}
              onLoadMore={loadMoreRows}
              rowHeight={42}
              threshold={1}
            >
              {({ index, list }: IModelBrandsItem) => {
                const item = list[index];

                const itemChecked = checkedBrandsId.some(
                  (id) => id === item.id,
                );

                return (
                  <BrandsList
                    data={item}
                    checked={itemChecked}
                    onClickItem={handleClickBrand}
                    headerText={
                      showListHeaders && headers && !search
                        ? headers[index]
                        : ''
                    }
                  />
                );
              }}
            </VirtualizedInfiniteList>
          )
        )}

        {!brandsList.length && search && (
          <BrandsModalEmptySearch search={search} />
        )}

        {requestError && <BrandsRequestError onTryAgain={tryAgainAction} />}
      </DialogContent>

      <ModalActions
        brandsChecked={checkedBrandsId}
        closeModal={onClose}
        onHandleClose={onHandleClose}
        errorMessage={errorMessage}
        isDirty={isDirty}
      />
    </Dialog>
  );
};
