import { Box, Typography } from '@mui/material';
import React from 'react';

import { BrandsRequestErrorProps } from './interface';

import './styles.scss';
import { Button } from '@components/ButtonV2';

export const BrandsRequestError = ({ onTryAgain }: BrandsRequestErrorProps) => {
  return (
    <Box className='bl-brands-request-error'>
      <Typography variant='h5'>Error Loading</Typography>

      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={onTryAgain}
      >
        Try Again
      </Button>
    </Box>
  );
};
