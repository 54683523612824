import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, CircularProgress } from '@mui/material';
import {
  AutoSizer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
  ListRowProps,
} from 'react-virtualized';

import { VirtualizedRow } from '../VirtualizedRow';

import { VirtualizedInfiniteListProps } from './interface';

import { Button } from '@components/ButtonV2';
import { emptyFunction } from '@utils/functions';

import './styles.scss';

export const VirtualizedInfiniteList = ({
  hasNextPage,
  isLoading,
  list,
  onClickItem,
  onLoadPrevious,
  isLoadingPreviousParts,
  isLoadPreviousButtomVisible,
  onLoadMore,
  selectedItem,
  rowHeight,
  threshold,
  children,
  listClassName,
}: VirtualizedInfiniteListProps): JSX.Element => {
  const cache = new CellMeasurerCache({
    defaultHeight: rowHeight,
    fixedWidth: true,
  });

  const isItemLoaded = ({ index }: { index: number }) =>
    !hasNextPage || !!list[index];

  const loadMoreRows = async () => {
    if (!isLoading && hasNextPage) return onLoadMore();

    return emptyFunction();
  };

  return (
    <>
      {isLoadPreviousButtomVisible && (
        <Box className='previous-button-container'>
          <Button
            variant='outlined'
            startIcon={!isLoadingPreviousParts ? <ExpandLessIcon /> : null}
            onClick={onLoadPrevious}
            className='previous-button'
          >
            {isLoadingPreviousParts ? (
              <CircularProgress
                size='small'
                className='previous-button-loader'
              />
            ) : (
              <Box>Load Previous Items</Box>
            )}
          </Button>
        </Box>
      )}
      <InfiniteLoader
        isRowLoaded={isItemLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={hasNextPage ? list.length + 1 : list.length}
        threshold={threshold ? threshold : 5}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  scrollToIndex={selectedItem?.index}
                  height={height}
                  width={width}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  rowCount={list.length}
                  overscanRowCount={5}
                  rowHeight={cache.rowHeight}
                  deferredMeasurementCache={cache}
                  className={listClassName}
                  rowRenderer={(props: ListRowProps) => (
                    <VirtualizedRow
                      cache={cache}
                      {...props}
                      isLoading={isLoading}
                      checkIfItemIsLoaded={isItemLoaded}
                      hasNextPage={hasNextPage}
                      list={list}
                      listKey={props.key}
                      onClickItem={onClickItem}
                      selectedItem={selectedItem}
                    >
                      {children}
                    </VirtualizedRow>
                  )}
                />
              );
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </>
  );
};
