import { Navigate } from 'react-router-dom';

import { ICanWrapperProps } from './interface';

import useCustomAbility from '@hooks/useCustomAbility';
import useNavigateToHome from '@hooks/useNavigateToHome';

const CanWrapper = ({
  children,
  action,
  subject,
  skipCanValidation = false,
}: ICanWrapperProps) => {
  const ability = useCustomAbility();
  const { homeRoute } = useNavigateToHome();

  if (
    !skipCanValidation &&
    ability?.rules?.length > 0 &&
    ability?.cannot(action, subject) &&
    homeRoute
  )
    return <Navigate to={homeRoute} replace />;

  return children;
};

export default CanWrapper;
