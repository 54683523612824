export enum MessageTypes {
  Curri = 'curri',
  InstantQuote = 'instant_quote',
  Text = 'text',
  NewOrder = 'new_order',
  OrderCanceled = 'order_canceled',
  OrderDeclined = 'order_declined',
  AutoMessage = 'auto_message',
  ApprovedOrder = 'order_approved',
}

export enum EOrderStatus {
  PendingApproval = 'pending_approval',
  Pending = 'pending',
  Approved = 'approved',
  Completed = 'completed',
  Canceled = 'canceled',
}

export enum EAvailabiltyValues {
  ASAP = 'ASAP',
  EndDayToday = 'EODT',
  TomorrowMorning = 'TM',
  TomorrowAfternoon = 'TA',
  EndDayTomorrow = 'EODTW',
}

export enum EItemStatus {
  Pending = 'pending',
  Available = 'available',
  NotAvailable = 'not_available',
  SeeBelowItem = 'see_below_item',
  Removed = 'removed',
  Replacement = 'replacement',
}

export enum EReplacementTypes {
  Single = 'single',
  Grouped = 'grouped',
}

// Deprecated
export enum ERequestedAvailabiltyValues {
  no_rush = 'NO RUSH',
  tomorrow = 'TOMORROW',
  asap = 'ASAP',
}

export enum EOrderMainStatus {
  PENDING = 'pending',
  PENDING_APPROVAL = 'pending-approval',
  APPROVED = 'approved',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  RETURNED = 'returned',
  THIRD_PARTY_HANDLED = 'third-party-handled',
}

export enum EOrderSubstatus {
  // PENDING
  PENDING_REQUESTED = 'pending-requested',
  PENDING_ASSIGNED = 'pending-assigned',
  // PENDING_APPROVAL 200,210,220
  PENDING_APPROVAL_FULFILLED = 'pending-approval-fulfilled',
  PENDING_APPROVAL_QUOTE_NEEDED = 'pending-approval-quote-needed',
  PENDING_APPROVAL_QUOTE_UPDATED = 'pending-approval-quote-updated',
  // APPROVED_AWAITING 300,301,302,303
  APPROVED_AWAITING_DELIVERY = 'approved-awaiting-delivery',
  APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED_WS = 'approved-awaiting-delivery-quote-needed-ws',
  APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED = 'approved-awaiting-delivery-quote-needed',
  APPROVED_AWAITING_DELIVERY_QUOTE_UPDATED = 'approved-awaiting-delivery-quote-updated',

  // APPROVED_READY 310,311,312,313
  APPROVED_READY_DELIVERY = 'approved-ready-for-delivery',
  APPROVED_READY_DELIVERY_QUOTE_NEEDED_WS = 'approved-ready-for-delivery-quote-needed-ws',
  APPROVED_READY_DELIVERY_QUOTE_NEEDED = 'approved-ready-for-delivery-quote-needed',
  APPROVED_READY_DELIVERY_QUOTE_UPDATED = 'approved-ready-for-delivery-quote-updated',
  // APPROVED_DELIVERED 320,321,322,323
  APPROVED_DELIVERED = 'approved-delivered',
  APPROVED_DELIVERED_QUOTE_NEEDED_WS = 'approved-delivered-quote-needed-ws',
  APPROVED_DELIVERED_QUOTE_NEEDED = 'approved-delivered-quote-needed',
  APPROVED_DELIVERED_QUOTE_UPDATED = 'approved-delivered-quote-updated',

  // THIRD PARTY
  THIRD_PARTY_HANDLED = 'third-party-handled-third-party-handled',

  // COMPLETED
  RETURNED_RETURNED = 'returned-returned',
  COMPLETED_DONE = 'completed-done',
  // CANCELED
  CANCELED_ABORTED = 'canceled-aborted',
  CANCELED_REJECTED = 'canceled-rejected',
  CANCELED_BLOCKED_USER = 'canceled-blocked-user',
  CANCELED_DELETED_USER = 'canceled-deleted-user',
  CANCELED_CANCELED = 'canceled-canceled',
  CANCELED_DECLINED = 'canceled-declined',
  CANCELED_BY_CONTRACTOR = 'canceled-rejected-by-contractor',
}

export enum EDeliveryType {
  CURRI_DELIVERY = 'curri_delivery',
  PICKUP = 'pickup',
  SHIPMENT_DELIVERY = 'shipment_delivery',
}
export enum EUserStatusType {
  CONFIRMED = 'confirmed',
  REMOVED = 'removed',
  UNCONFIRMED = 'unconfirmed',
}
