import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { TermsCheckboxProps } from './interface';

import { useAppSelector } from '@hooks/state';
import { openTermsOfService } from '@state/auth/actions';
import { getReadTermsInfoData } from '@state/auth/selectors';

import './styles.scss';

export const AcceptTermsCheckbox = ({
  control,
}: TermsCheckboxProps): JSX.Element => {
  const dispatch = useDispatch();

  const { didUserReadTerms } = useAppSelector((state) => ({
    didUserReadTerms: getReadTermsInfoData(state),
  }));

  const showTerms = () => {
    dispatch(openTermsOfService(true));
  };

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 470,
    },
  });

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs>
          <Controller
            name='acceptTerms'
            control={control}
            render={({ field, fieldState }) => (
              <CustomTooltip
                title={
                  didUserReadTerms === false ? (
                    <Typography variant='caption' textAlign='center'>
                      Please click on the “Terms of Service and Privacy
                      Policies” link to read. You must get to the bottom of the
                      document, then you can ‘check the box’ to continue
                    </Typography>
                  ) : (
                    ''
                  )
                }
                placement='top'
                arrow
              >
                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    data-testid='acceptTermsCheckbox'
                    label={
                      <span className='checkbox-label'>
                        I agree with the{' '}
                        <a
                          data-testid='termsOfServicesLink'
                          onClick={showTerms}
                        >
                          Terms of Services and Privacy Policies.
                        </a>
                      </span>
                    }
                    checked={field.value}
                    disabled={!didUserReadTerms}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value}
                  />
                  <FormHelperText>
                    {(fieldState.error &&
                      'The terms and conditions must be accepted.') ||
                      ' '}
                  </FormHelperText>
                </div>
              </CustomTooltip>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
