import {
  FormControl,
  FormHelperText,
  InputLabel,
  Skeleton,
} from '@mui/material';
import {
  Controller,
  ControllerFieldState,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';

import { ICustomControlledSelectProps } from './types';

import './styles.scss';

import CustomSelect from '@components/CustomSelect';
import { appendSupMarkupToString } from '@utils/Strings/strongMarkup';

function CustomControlledSelect<T extends FieldValues>({
  selectLabel,
  name,
  control,
  selectProps,
  hideHelperText = false,
  isLoading,
  ...props
}: ICustomControlledSelectProps<T>) {
  const labelId = `${name}-label`;

  const renderHelperText = (
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<T>,
  ) => {
    if (!hideHelperText)
      return (
        <FormHelperText error={!!fieldState?.error}>
          {formState.errors[name]?.message?.toString()}
        </FormHelperText>
      );

    return fieldState?.error ? (
      <FormHelperText error={!!fieldState?.error}>
        {formState.errors[name]?.message?.toString()}
      </FormHelperText>
    ) : null;
  };

  return isLoading ? (
    <Skeleton
      role='progressbar'
      className='dropdown-input-skeleton'
      animation='wave'
    />
  ) : (
    <>
      <FormControl {...props}>
        {selectLabel && (
          <InputLabel id={labelId}>
            {appendSupMarkupToString(selectLabel, 'mandatory-field')}
          </InputLabel>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState, formState }) => (
            <>
              <CustomSelect
                id={name}
                labelId={labelId}
                value={field?.value}
                defaultValue={field?.value}
                onChange={field?.onChange}
                onBlur={field?.onBlur}
                {...selectProps}
              />
              {renderHelperText(fieldState, formState)}
            </>
          )}
        />
      </FormControl>
    </>
  );
}

export default CustomControlledSelect;
