import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useRealTimeRolePrivilegesUpdates from './fireBase/useRealTimeRolePrivilegesUpdates';
import { useAppDispatch, useAppSelector } from './state';
import useCustomAbility from './useCustomAbility';

import { useSnackbar } from '@components/Snackbar/hooks';
import { resetAbilities } from '@config/canAbility';
import { homeRoutes } from '@config/routing/Routes';
import { logout, setShouldAdjustPermissions } from '@state/auth/actions';
import {
  getIsImpersonatedSupplier,
  getIsManager,
  getLastUpdatedPermissions,
  getLoginInfoData,
  getShouldAdjustPermissions,
} from '@state/auth/selectors';
import { impersonateSupplierRequest } from '@state/manager/actions';
import { getStorePermissionsRequest } from '@state/storeInfo/actions';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import { emptyFunction } from '@utils/functions';
import { getFirstAvailableHomeRoute } from '@utils/navigation';

const usePermissionsValidations = () => {
  //  State
  const ability = useCustomAbility();
  const navigate = useNavigate();
  const [isLoadingPermissions, setIsLoadingPermissions] =
    useState<boolean>(false);

  //  Redux selectors
  const {
    isImpersonatedSupplier,
    isManager,
    loginData,
    shouldAdjustPermissions,
    supplierLoggedId,
    storeInfo,
    internalLastUpdatedPermissions,
  } = useAppSelector((state) => ({
    isManager: getIsManager(state),
    loginData: getLoginInfoData(state),
    supplierLoggedId: getStoreInfoData(state)?.id,
    shouldAdjustPermissions: getShouldAdjustPermissions(state),
    isImpersonatedSupplier: getIsImpersonatedSupplier(),
    storeInfo: getStoreInfoData(state),
    internalLastUpdatedPermissions: getLastUpdatedPermissions(state),
  }));

  //  Hooks
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const { lastPermissionUpdate } = useRealTimeRolePrivilegesUpdates(
    supplierLoggedId || storeInfo?.id || '',
  );

  const onPermissionsError = useCallback(
    (errorMessage: string) => {
      setIsLoadingPermissions(false);
      showMessage(errorMessage, { severity: 'error' });
    },
    [showMessage],
  );

  const onPermissionsReloadSuccess = useCallback(() => {
    setIsLoadingPermissions(false);
    showMessage('Your account has been updated', { severity: 'info' });
    if (!ability?.rules?.length) return;

    const homeRoute = getFirstAvailableHomeRoute(homeRoutes, ability);
    if (homeRoute) navigate(homeRoute);
  }, [showMessage, ability, ability?.rules, navigate]);

  const reloadPermissions = useCallback(() => {
    //  Handling case for regular users
    if (loginData?.id && !isImpersonatedSupplier && !isManager) {
      dispatch(
        getStorePermissionsRequest({
          errorCallback: () => {
            dispatch(logout(() => emptyFunction()));
            onPermissionsError('Error getting permissions');
          },
          successCallback: onPermissionsReloadSuccess,
        }),
      );

      return;
    }

    //  Handling case for impersonated users
    if (isImpersonatedSupplier && supplierLoggedId) {
      dispatch(
        impersonateSupplierRequest({
          supplierId: supplierLoggedId,
          errorCallback: () => {
            dispatch(logout(() => emptyFunction()));
            onPermissionsError('Error getting permissions');
          },
          successCallback: onPermissionsReloadSuccess,
        }),
      );

      return;
    }

    setIsLoadingPermissions(false);
  }, [
    loginData?.id,
    isImpersonatedSupplier,
    isManager,
    supplierLoggedId,
    dispatch,
    onPermissionsError,
    onPermissionsReloadSuccess,
  ]);

  //  Watching changes to call permissions endpoint
  useEffect(() => {
    if (!loginData?.id || isImpersonatedSupplier || isManager) return;
    dispatch(
      getStorePermissionsRequest({
        errorCallback: () => {
          dispatch(logout(() => emptyFunction()));
          onPermissionsError('Error getting permissions');
        },
        successCallback: () => setIsLoadingPermissions(false),
      }),
    );
  }, [
    loginData?.id,
    isImpersonatedSupplier,
    isManager,
    onPermissionsError,
    dispatch,
  ]);

  //  Watching impersonated changes to call impersonate endpoint if necessary
  useEffect(() => {
    if (
      !isImpersonatedSupplier ||
      !supplierLoggedId ||
      loginData?.impersonatedSupplier?.permissions?.length
    )
      return;

    dispatch(
      impersonateSupplierRequest({
        supplierId: supplierLoggedId,
        errorCallback: () => {
          dispatch(logout(() => emptyFunction()));
          onPermissionsError('Error getting permissions');
        },
        successCallback: () => setIsLoadingPermissions(false),
      }),
    );
  }, [
    supplierLoggedId,
    loginData?.impersonatedSupplier?.permissions,
    isImpersonatedSupplier,
    dispatch,
    onPermissionsError,
  ]);

  //  Watching flag changes to adjust permissions if needed (403 error)
  useEffect(() => {
    if (!shouldAdjustPermissions) return;
    //  Resetting abilities to allow rules updates on ability
    resetAbilities();
    dispatch(setShouldAdjustPermissions({ shouldAdjust: false }));

    reloadPermissions();
  }, [shouldAdjustPermissions, reloadPermissions, dispatch]);

  //  Watching last permission updates to refresh if needed
  useEffect(() => {
    if (isLoadingPermissions || !lastPermissionUpdate) return;
    /*
       If internal timestamp is greater than real time database timestamp,
       everything is up to date
    */
    if (
      internalLastUpdatedPermissions &&
      dayjs(internalLastUpdatedPermissions).isAfter(dayjs(lastPermissionUpdate))
    )
      return;

    setIsLoadingPermissions(true);
    resetAbilities();
    reloadPermissions();
  }, [
    lastPermissionUpdate,
    internalLastUpdatedPermissions,
    isLoadingPermissions,
    reloadPermissions,
  ]);
};

export default usePermissionsValidations;
