import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const environment = process ? process.env : null;

const firebaseConfig = {
  apiKey: environment?.REACT_APP_FIREBASE_API_KEY,
  authDomain: environment?.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: environment?.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: environment?.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: environment?.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: environment?.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: environment?.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

export const database = getDatabase(app);

export const databaseNode = environment?.REACT_APP_FIREBASE_DATABASE_NODE;
export const databaseNodeOrders =
  environment?.REACT_APP_FIREBASE_DATABASE_NODE_ORDERS;
export const databaseNodeOrdersComplete =
  environment?.REACT_APP_FIREBASE_DATABASE_NODE_ORDERS_COMPLETE;
export const databaseNodeDelivery =
  environment?.REACT_APP_FIREBASE_DATABASE_NODE_DELIVERY;
export const databaseNodeNotificationSound =
  environment?.REACT_APP_FIREBASE_DATABASE_NODE_NOTIFICATION_SOUND;
export const databaseNodeTaskUpdated =
  environment?.REACT_APP_FIREBASE_DATABASE_NODE_TASK_UPDATED;
export const databaseNodeResponseTime =
  environment?.REACT_APP_FIREBASE_DATABASE_NODE_RESPONSE_TIME;
export const storeRatingNode =
  environment?.REACT_APP_FIREBASE_DATABASE_NODE_STORE_RATING;
export const distributorNodeUpdate =
  environment?.REACT_APP_FIREBASE_DATABASE_NODE_DISTRIBUTOR_UPDATE;
export const rolePermissionsUpdatesNode =
  environment?.REACT_APP_FIREBASE_DATABASE_NODE_ROLE_PRIVILEGES_UPDATE;
