export const LABELS = {
  available: {
    color: 'blue',
    text: 'We have it',
  },
  not_available: {
    color: 'red',
    text: "We can't get it",
  },
  replacement: {
    color: 'green',
    text: 'We have a replacement',
  },
};
