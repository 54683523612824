import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

import CallForHelp from '../CallForHelp';
import ReturnCompleteOrderModal from '../ReturnCompleteOrderModal';

import { CompletedFooterProps } from './interface';

import { Button } from '@components/ButtonV2';

const CompletedFooter = ({ order }: CompletedFooterProps) => {
  const [showReturnModal, setShowReturnModal] = useState<boolean>(false);

  const renderCancelModal = () => {
    if (!showReturnModal) return null;

    return (
      <ReturnCompleteOrderModal
        orderId={String(order?.id)}
        onClose={() => setShowReturnModal(false)}
        isOpen={showReturnModal}
      />
    );
  };

  return (
    <>
      {renderCancelModal()}
      <div className='mt-6 flex w-full flex-col items-center justify-center'>
        <div className='mt-4 '>
          <Button
            className='h-7'
            variant='outlined'
            color='error'
            startIcon={<CloseIcon />}
            onClick={() => setShowReturnModal(true)}
          >
            Order Returned
          </Button>
        </div>
        <CallForHelp />
      </div>
    </>
  );
};

export default CompletedFooter;
