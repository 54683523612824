import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ThumbsUpImage from '../../assets/images/thumbs-up.png';

import { List } from './components/List';
import './styles.scss';
import { ResponseTimeBadge } from './components/ResponseTimeBadge';
import { UnconfirmedCustomersModal } from './components/UnconfirmedCustomerModal';
import { RealTimeResponseTime } from './interfaces';

import { Button } from '@components/ButtonV2';
import { ClaimingModal } from '@components/ClaimingModal';
import { useSnackbar } from '@components/Snackbar/hooks';
import { ORDER_TYPE } from '@config/api/task/interface';
import { EAbilityActions, EAbilitySubjects } from '@config/canAbility/types';
import useRealTimeOrderComplete from '@hooks/fireBase/useRealTimeOrderComplete';
import useRealTimeOrderUpdated from '@hooks/fireBase/useRealTimeOrderUpdated';
import useRealTimeResponseTime from '@hooks/fireBase/useRealTimeResponseTime';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import useCustomAbility from '@hooks/useCustomAbility';
import useGoogleTagManager from '@hooks/useGoogleTagManager';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';
import { isRequestRunning } from '@state/requests/selectors';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import {
  clearOrders,
  getApprovedOrders as getApprovedOrdersAction,
  getAvailabilityOrders as getAvailabilityOrdersAction,
  getMoreApprovedOrders,
  getMoreAvailabilityOrders,
  realTimeRefreshApprovedOrders,
  realTimeRefreshAvailabilityOrders,
  setSelectedClaimingModal,
} from '@state/task/actions';
import {
  getApprovedOrders,
  getApprovedOrdersHasNextPage,
  getApprovedOrdersIsDirty,
  getApprovedOrdersNextPage,
  getAvailabilityOrders,
  getAvailabilityOrdersHasNextPage,
  getAvailabilityOrdersIsDirty,
  getAvailabilityOrdersNextPage,
  getIsNationalSupplier,
  getSelectedClaimingModal,
  getShowUnconfirmedCustomerModal,
} from '@state/task/selectors';
import { assembleGTMEvent } from '@utils/GoogleTagManager';
import {
  GOOGLE_TAG_MANAGER_CATEGORY_PAGES,
  GOOGLE_TAG_MANAGER_EVENT_MESSAGES,
} from '@utils/GoogleTagManager/interface';

export const Tasks = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const { setGTMEvent } = useGoogleTagManager();
  const { changeSelectedOrder } = useSetSelectedOrder();

  const [minutes, setMinutes] = useState<number | undefined>(undefined);
  const ability = useCustomAbility();
  const [isFlagedAsRapidResponder, setIsFlagedAsRapidResponder] =
    useState(false);
  const [hasMoreThanTenOrders, setHasMoreThanTenOrders] = useState(false);
  const [orderCompleteFlag, setOrderCompleteFlag] = useState(false);
  const stateRef = useRef<boolean>();
  stateRef.current = orderCompleteFlag;

  const {
    approvedOrders,
    approvedOrdersNextPage,
    hasMoreApprovedOrders,
    approvedOrdersIsLoading,
    availabilityOrders,
    availabilityOrdersNextPage,
    hasMoreAvailabilityOrders,
    availabilityOrdersIsLoading,
    availabilityOrdersInitialFetchCompleted,
    approvedOrdersInitialFetchCompleted,
    approvedOrdersLoadingAll,
    availabilityOrdersLoadingAll,
    storeInfo,
    claimingModal,
    isNationalSupplier,
    showUnconfirmedCustomerModal,
  } = useAppSelector((state) => ({
    // Approved Orders
    approvedOrders: getApprovedOrders(state),
    approvedOrdersNextPage: getApprovedOrdersNextPage(state),
    hasMoreApprovedOrders: getApprovedOrdersHasNextPage(state),
    approvedOrdersLoadingAll: isRequestRunning(
      state,
      String(getApprovedOrdersAction),
    ),
    approvedOrdersIsLoading:
      isRequestRunning(state, String(getApprovedOrdersAction)) ||
      isRequestRunning(state, String(getMoreApprovedOrders)),
    // Availability Orders
    availabilityOrders: getAvailabilityOrders(state),
    availabilityOrdersNextPage: getAvailabilityOrdersNextPage(state),
    hasMoreAvailabilityOrders: getAvailabilityOrdersHasNextPage(state),
    availabilityOrdersLoadingAll: isRequestRunning(
      state,
      String(getAvailabilityOrdersAction),
    ),
    availabilityOrdersIsLoading:
      isRequestRunning(state, String(getAvailabilityOrdersAction)) ||
      isRequestRunning(state, String(getMoreAvailabilityOrders)),
    availabilityOrdersInitialFetchCompleted:
      getAvailabilityOrdersIsDirty(state),
    approvedOrdersInitialFetchCompleted: getApprovedOrdersIsDirty(state),
    storeInfo: getStoreInfoData(state),
    claimingModal: getSelectedClaimingModal(state as any),
    isNationalSupplier: getIsNationalSupplier(state),
    showUnconfirmedCustomerModal: getShowUnconfirmedCustomerModal(state),
  }));

  const fetchMoreApprovedOrders = useCallback(() => {
    dispatch(
      getMoreApprovedOrders({
        type: ORDER_TYPE.APPROVED,
        page: approvedOrdersNextPage,
      }),
    );
  }, [approvedOrdersNextPage]);

  const fetchMoreAvailabilityOrders = useCallback(() => {
    dispatch(
      getMoreAvailabilityOrders({
        type: ORDER_TYPE.AVAILABILITY_REQUESTS,
        page: availabilityOrdersNextPage,
      }),
    );
  }, [availabilityOrdersNextPage]);

  const refreshRealTimeData = (refreshApprovedOrders: boolean) => {
    if (refreshApprovedOrders)
      dispatch(
        realTimeRefreshApprovedOrders({
          type: ORDER_TYPE.APPROVED,
          page: 1,
        }),
      );
    else
      dispatch(
        realTimeRefreshAvailabilityOrders({
          type: ORDER_TYPE.AVAILABILITY_REQUESTS,
          page: 1,
        }),
      );
  };

  const responseTimeReceived = (value: RealTimeResponseTime | null) => {
    setMinutes(value ? value?.average_time_response : undefined);
    setIsFlagedAsRapidResponder(value?.forced_rapid_responder || false);
    setHasMoreThanTenOrders(value?.has_over_ten_responses || false);
  };

  const responseOrderComplete = () => {
    if (stateRef.current)
      return showMessage('Completed Order', {
        severity: 'success',
        autoHide: false,
      });
    setOrderCompleteFlag(true);
  };

  useRealTimeOrderUpdated(storeInfo, refreshRealTimeData);
  useRealTimeResponseTime(storeInfo, responseTimeReceived);
  useRealTimeOrderComplete(storeInfo, responseOrderComplete);

  const fetchOrdersData = () => {
    dispatch(getApprovedOrdersAction({ type: ORDER_TYPE.APPROVED, page: 1 }));
    dispatch(
      getAvailabilityOrdersAction({
        type: ORDER_TYPE.AVAILABILITY_REQUESTS,
        page: 1,
      }),
    );
  };

  useEffect(() => {
    fetchOrdersData();

    setGTMEvent(
      assembleGTMEvent(
        GOOGLE_TAG_MANAGER_CATEGORY_PAGES.TASKS_PAGE,
        GOOGLE_TAG_MANAGER_EVENT_MESSAGES.TASK_PAGE_VIEW,
      ),
    );

    //  Clearing orders state on unrender to avoid UI issues
    return () => {
      dispatch(clearOrders());
    };
  }, []);

  const renderThumbsUp = useCallback(() => {
    if (
      availabilityOrdersInitialFetchCompleted &&
      approvedOrdersInitialFetchCompleted &&
      approvedOrders.length === 0 &&
      availabilityOrders.length === 0
    )
      return (
        <Box className='flex items-center justify-center p-20'>
          <img
            className='h-20 w-20 sm:h-36 sm:w-36'
            src={ThumbsUpImage}
            alt='Thumbs Up Image'
          />
        </Box>
      );

    return null;
  }, [
    availabilityOrdersInitialFetchCompleted,
    approvedOrdersInitialFetchCompleted,
    approvedOrders,
    availabilityOrders,
  ]);

  const handleClick = () => {
    navigate('/help', { replace: true });
  };

  const handleOrderLogClicked = useCallback(() => {
    navigate('/tasks/logs');
  }, []);

  return (
    <Container
      maxWidth={false}
      className='bl-dashboard-container flex bg-[var(--bluon--ds--lightgray)]'
    >
      {claimingModal?.companyName && claimingModal?.order?.id && (
        <ClaimingModal
          visible
          orderId={claimingModal.order.id}
          onClose={() => dispatch(setSelectedClaimingModal(null))}
          contractorName={claimingModal.companyName}
          techName={claimingModal.techName}
          onSuccessClaiming={() => {
            changeSelectedOrder(claimingModal.order.id, claimingModal.order);
            dispatch(setSelectedClaimingModal(null));
          }}
        />
      )}

      {isNationalSupplier && showUnconfirmedCustomerModal && (
        <UnconfirmedCustomersModal open={showUnconfirmedCustomerModal} />
      )}

      <Box className='align-center mb-6 mt-4 flex justify-start'>
        <Box className='ml-[47%] text-center font-normal'>
          <Typography className='title'>Tasks</Typography>
        </Box>
        <Box className='ml-auto flex items-end'>
          {Number.isInteger(minutes) && (
            <ResponseTimeBadge
              minutes={minutes || 0}
              isFlagedAsRapidResponder={isFlagedAsRapidResponder}
              hasMoreThanTenOrders={hasMoreThanTenOrders}
            />
          )}
          <Button
            endIcon={<AccessTimeIcon />}
            size='small'
            variant='contained'
            color='secondary'
            onClick={handleOrderLogClicked}
          >
            Order Log
          </Button>
        </Box>
      </Box>
      <Grid
        container
        spacing={2}
        justifyContent='center'
        alignItems='flex-start'
      >
        <Grid item xs={12} md={6}>
          <List
            title='Price & Availability Requests'
            emptyMessage='No new requests!'
            message='If a customer needs help it will show here.'
            items={availabilityOrders}
            isRequestLoading={availabilityOrdersIsLoading}
            loadingAllOrders={availabilityOrdersLoadingAll}
            initialFetchCompleted={availabilityOrdersInitialFetchCompleted}
            canFetchMore={hasMoreAvailabilityOrders}
            fetchMore={fetchMoreAvailabilityOrders}
            isNationalSupplier={isNationalSupplier}
            showTimer
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <List
            title='Will Call / Approved Orders'
            emptyMessage='You’re all caught up!'
            message='Approved Orders that need your attention will show here.'
            items={approvedOrders}
            isRequestLoading={approvedOrdersIsLoading}
            loadingAllOrders={approvedOrdersLoadingAll}
            initialFetchCompleted={approvedOrdersInitialFetchCompleted}
            canFetchMore={hasMoreApprovedOrders}
            fetchMore={fetchMoreApprovedOrders}
            isNationalSupplier={isNationalSupplier}
          />
        </Grid>
      </Grid>
      {renderThumbsUp()}
      {ability.can(
        EAbilityActions.MANAGE,
        EAbilitySubjects.HELP_AND_FEEDBACK,
      ) && (
        <div className='absolute bottom-5'>
          <Button
            variant='contained'
            size='small'
            color='secondary'
            endIcon={<InfoIcon />}
            onClick={handleClick}
          >
            Help / Feedback
          </Button>
        </div>
      )}
    </Container>
  );
};
