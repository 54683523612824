import { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from './state';

import { EAddedItemStatus, ExtraItem } from '@config/api/task/interface';
import { getOrderAddedItems } from '@state/task/selectors';
import { areAddedItemsDifferent } from '@utils/Orders';

const useAddedItemsValidations = () => {
  const { addedItems } = useAppSelector((state) => ({
    addedItems: getOrderAddedItems(state),
  }));

  const [initialAddedItems, setInitialAddedItems] =
    useState<ExtraItem[]>(addedItems);

  useEffect(() => {
    //  Updating initial added items on first request
    if (initialAddedItems?.length === 0 && addedItems?.length > 0)
      setInitialAddedItems(addedItems);
  }, [addedItems, initialAddedItems]);

  //  Items validations
  const haveChangedItems = useMemo<boolean>(
    () => areAddedItemsDifferent(initialAddedItems, addedItems),
    [initialAddedItems, addedItems],
  );
  const areAllItemsConfirmed = useMemo<boolean>(() => {
    return addedItems?.every(
      (item) =>
        item?.status === EAddedItemStatus.AVAILABLE ||
        item?.status === EAddedItemStatus.NOT_AVAILABLE,
    );
  }, [addedItems]);

  const areAllItemsUnavailable = useMemo<boolean>(() => {
    return addedItems?.every(
      (item) => item?.status === EAddedItemStatus.NOT_AVAILABLE,
    );
  }, [addedItems]);

  //  Exporting assets
  return {
    haveChangedItems,
    areAllItemsConfirmed,
    areAllItemsUnavailable,
  };
};

export default useAddedItemsValidations;
