import { Box, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { OrderDetailTypes } from '../../constants';

import DeliveryBar from './components/DeliveryBar';
import { TotalsForm } from './components/form';
import { UploadInvoice } from './components/UploadInvoice';
import { TotalsProps } from './interface';

import { MUIAccordion } from '@components/MUIAccordion';
import { ORDER_STATUS } from '@config/api/task/interface';
import { useAppSelector } from '@hooks/state';
import useIsAllUnavailableItemsCheck from '@hooks/useIsAllUnavailableItemsCheck';
import { getCustomOrderStatus } from '@pages/Tasks/utils';
import { setTotalIsExpanded } from '@state/task/actions';
import { getIsTotalExpanded, getOrderCustomItems } from '@state/task/selectors';
import { EDeliveryType, EOrderSubstatus } from '@utils/enums';
import { roundNumber } from '@utils/Numbers';
import { isCurriApprovedDeliveredQuoteUpdated } from '@utils/Orders';

import './styles.scss';

export const Totals = ({
  isBidNumberRequired,
  initiallyExpanded = false,
  order,
  orderDetailType,
  handleValues,
  quoteFileRequired,
  quoteFileChanged,
  isNationalSupplier,
}: TotalsProps): JSX.Element => {
  const dispatch = useDispatch();
  const { isAllUnavailable } = useIsAllUnavailableItemsCheck();

  const shouldDisableTotals = isAllUnavailable;

  const { isTotalExpanded, orderCustomItems } = useAppSelector((state) => ({
    isTotalExpanded: getIsTotalExpanded(state),
    orderCustomItems: getOrderCustomItems(state),
  }));

  useEffect(() => {
    if (orderCustomItems.length || initiallyExpanded)
      dispatch(setTotalIsExpanded(true));
    if (isAllUnavailable && !orderCustomItems.length && !initiallyExpanded)
      dispatch(setTotalIsExpanded(false));
  }, [isAllUnavailable, orderCustomItems, initiallyExpanded]);

  const isCompletedOrCanceledOrReturned =
    orderDetailType === OrderDetailTypes.COMPLETED ||
    orderDetailType === OrderDetailTypes.RETURNED ||
    orderDetailType === OrderDetailTypes.CANCELED ||
    orderDetailType === OrderDetailTypes.THIRD_PARTY_HANDLED;
  const isOrderCurriApprovedDeliveredQuoteUpdated =
    !!order && isCurriApprovedDeliveredQuoteUpdated(order);

  const disabledSxState = isCompletedOrCanceledOrReturned
    ? { pointerEvents: 'none' }
    : {};
  const isCurriDelivery =
    order?.delivery?.type === EDeliveryType.CURRI_DELIVERY;

  const showDeliveryNotes = useMemo(() => {
    const customOrderStatus = order ? getCustomOrderStatus(order) : '';

    const isNotePresent = !!order?.delivery?.note;
    const isShipmentDelivery =
      order?.delivery?.type === EDeliveryType.SHIPMENT_DELIVERY;
    const isOneOfTheCorrectStatuses =
      customOrderStatus ===
        ORDER_STATUS['approved-awaiting-delivery-shipment'] ||
      customOrderStatus === ORDER_STATUS['pending-approval-quote-updated'] ||
      customOrderStatus ===
        ORDER_STATUS['pending-approval-quote-updated-shipment'] ||
      customOrderStatus ===
        ORDER_STATUS['pending-approval-quote-needed-shipment'] ||
      isCompletedOrCanceledOrReturned;

    return isNotePresent && isShipmentDelivery && isOneOfTheCorrectStatuses;
  }, [order?.delivery, order?.current_status]);

  const renderShipmentAndPickupDeliveryBar = useCallback(() => {
    if (
      order?.current_status?.substatus ===
        EOrderSubstatus.PENDING_APPROVAL_FULFILLED ||
      orderDetailType === OrderDetailTypes.PENDING ||
      !order?.delivery ||
      isCurriDelivery
    )
      return null;

    return (
      <DeliveryBar
        delivery={order.delivery}
        extraClassName='mt-6 w-full mr-[1.5em] ml-[1em]'
        showDeliveryNotes={showDeliveryNotes}
      />
    );
  }, [isCurriDelivery]);

  const trackedStatuses: EOrderSubstatus[] = [
    EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED_WS,
    EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED,
    EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_NEEDED_WS,
    EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_NEEDED,
    EOrderSubstatus.APPROVED_DELIVERED_QUOTE_NEEDED_WS,
    EOrderSubstatus.APPROVED_DELIVERED_QUOTE_NEEDED,
  ];

  const trackedWithDifferentMessageStatuses: EOrderSubstatus[] = [
    EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_UPDATED, // 313
    EOrderSubstatus.APPROVED_DELIVERED_QUOTE_UPDATED, // 323
    EOrderSubstatus.COMPLETED_DONE, // 400
  ];

  const renderCurriDeliveryBar = () => {
    if (!order || !isCurriDelivery) return;
    const orderStatus = order?.current_status?.substatus;
    const showDeliveryBar = trackedStatuses.includes(
      orderStatus as EOrderSubstatus,
    );
    const showDifferentMessage = trackedWithDifferentMessageStatuses.includes(
      orderStatus as EOrderSubstatus,
    );

    if (showDeliveryBar || showDifferentMessage)
      return (
        <DeliveryBar
          customDeliveryBarMessage={
            showDifferentMessage
              ? 'Click Track Delivery button for Curri updates'
              : ''
          }
          delivery={order.delivery}
          extraClassName='mt-6 w-full mr-[1.5em] ml-[1em] text-[1rem]'
          showDeliveryNotes={showDeliveryNotes}
        />
      );
  };

  return (
    <MUIAccordion
      className={`${!isTotalExpanded ? 'accordion-styles' : ''} ${
        shouldDisableTotals ? 'disabled' : ''
      }`}
      expanded={isTotalExpanded}
      onChange={(e, expanded) => {
        if (shouldDisableTotals) return;

        return dispatch(setTotalIsExpanded(expanded));
      }}
      headerTitle={
        isTotalExpanded ? (
          ''
        ) : (
          <div
            className={`text-sm ${
              shouldDisableTotals
                ? 'text-[var(--bluon--ds--lightblack-primary)]'
                : 'font-bold'
            }`}
          >
            Enter Total
          </div>
        )
      }
      defaultExpanded={initiallyExpanded}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='flex-start'
        className='bl-totals-container'
      >
        {order?.name && (
          <Typography variant='h5' className={!isCurriDelivery ? '!mb-6' : ''}>
            Customer PO: {order?.name}
          </Typography>
        )}
        {renderCurriDeliveryBar()}
        <Grid container className='bl-totals-container-inputs-upload'>
          <Grid item xs={12} sm={6} className='container-total-inputs'>
            <Box className='mb-8 ml-4'>
              <Typography variant='h5' color='initial'>
                Enter Total and Notes
              </Typography>
              <Typography
                className='text-black text-opacity-60 opacity-60'
                variant='caption'
                color='initial'
              >
                {quoteFileRequired
                  ? 'Total and File required'
                  : 'Total is required'}
              </Typography>
            </Box>
            <TotalsForm
              isTotalExpanded={isTotalExpanded}
              isBidNumberRequired={isBidNumberRequired}
              disableForm={
                isCompletedOrCanceledOrReturned ||
                isOrderCurriApprovedDeliveredQuoteUpdated
              }
              initialValues={{
                bidNumber: order?.bid_number || '',
                total: order?.total ? `${roundNumber(order?.total, 2)}` : '',
                notes: order?.note || '',
                orderLink: order?.third_party_url || '',
              }}
              handleValues={handleValues}
              isNationalSupplier={isNationalSupplier}
            />
          </Grid>

          <Grid item xs={12} sm={6} className='container-upload-section'>
            <Box className='mb-14'>
              <Typography variant='h5' color='initial'>
                Upload a Quote / Invoice
              </Typography>
            </Box>
            <UploadInvoice
              disabledSxState={disabledSxState}
              disableForm={
                isCompletedOrCanceledOrReturned ||
                isOrderCurriApprovedDeliveredQuoteUpdated
              }
              onUploadFileEvent={quoteFileChanged}
            />
          </Grid>
          {renderShipmentAndPickupDeliveryBar()}
        </Grid>
      </Grid>
    </MUIAccordion>
  );
};
