import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import dayjs from 'dayjs';

import { getLabelAvailableAtPart } from '../../utils';

import { LABELS } from './constants';
import { PartAvailabilityProps } from './interface';

import { Label } from '@components/Label';
import { EItemStatus } from '@utils/enums';
import { orderItemAvailableAtFormat } from 'src/constants';

const PartAvailability = ({
  item,
  expanded,
  weCanGetItByDate,
  selectedReplacement,
}: PartAvailabilityProps) => {
  const handleLabel = () => {
    if (weCanGetItByDate)
      return getLabelAvailableAtPart({
        message: selectedReplacement ? '1' : '0',
        format: orderItemAvailableAtFormat,
        available_at: dayjs(weCanGetItByDate).local().format('MM-DD-YYYY'),
      });

    if (item?.available_at)
      return getLabelAvailableAtPart({
        message: selectedReplacement ? '1' : '0',
        format: orderItemAvailableAtFormat,
        available_at: item?.available_at,
      });

    return '';
  };

  return (
    <div className='flex items-center gap-2'>
      {item.status !== EItemStatus.Pending &&
        (weCanGetItByDate || item?.available_at) && (
          <div>
            <Label label={handleLabel()} color='brown' />
          </div>
        )}
      {item.status !== EItemStatus.Pending &&
        !weCanGetItByDate &&
        !item?.available_at && (
          <div>
            <Label
              label={LABELS[item?.status as keyof typeof LABELS]?.text}
              color={LABELS[item?.status as keyof typeof LABELS]?.color}
            />
          </div>
        )}
      <div className='text-xs'>Qty: {item.quantity}</div>
      {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </div>
  );
};

export default PartAvailability;
