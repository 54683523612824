export type SnackbarSeverity = 'error' | 'success' | 'info' | 'order_cancelled';

export enum ESnackbarSeverity {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  ORDER_CANCELLED = 'order_cancelled',
}
export interface SnackbarOptions {
  severity: SnackbarSeverity;
  position?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
  closeTextButton?: string;
  xCloseButton?: boolean;
  autoHide?: boolean;
  subMessage?: string;
}

export interface SnackbarProps {
  message: string | JSX.Element;
  subMessage?: string;
  open: boolean;
  onClose: () => void;
  severity: SnackbarSeverity;
  position: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
  closeTextButton: string;
  xCloseButton?: boolean;
}

export type SnackbarIconsMapType = {
  [key in SnackbarSeverity]: {
    icon: React.ReactNode;
    color: string;
    backgroundColor: string;
    secondaryColor?: string;
  };
};
