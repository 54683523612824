import { CheckCircle } from '@mui/icons-material';
import { Modal } from '@mui/material';
import { useCallback } from 'react';

import { InvoiceSentApprovalModalProps } from './interface';

import CloseModalIcon from '@assets/icons/close.svg';
import { Button } from '@components/ButtonV2';
import { useSnackbar } from '@components/Snackbar/hooks';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import { approveUpdatedShipmentOrderRequest } from '@state/shareableOrder/actions';

const InvoiceSentApprovalModal = ({
  orderId,
  onClose,
  onDenyClicked,
}: InvoiceSentApprovalModalProps) => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const { isApproveLoading } = useAppSelector((state) => ({
    isApproveLoading: isRequestRunning(
      state,
      String(approveUpdatedShipmentOrderRequest),
    ),
  }));

  //  Callbacks
  const onConfirmError = useCallback(() => {
    showMessage('Error approving order.', {
      severity: 'error',
      autoHide: true,
    });
  }, []);

  const onConfirmSuccess = useCallback(() => {
    showMessage('Order approved successfully.', {
      severity: 'success',
      autoHide: true,
    });
    onClose();
  }, []);

  //  Button handlers
  const onConfirmClicked = useCallback(() => {
    dispatch(
      approveUpdatedShipmentOrderRequest({
        orderId,
        onError: onConfirmError,
        onSuccess: onConfirmSuccess,
      }),
    );
  }, [orderId, onConfirmError, onConfirmSuccess]);

  //  Render logic
  const renderButtons = () => {
    return (
      <div className='mt-6 flex w-full flex-row items-center justify-center'>
        <div className='mr-4'>
          <Button
            color='error'
            variant='outlined'
            onClick={onDenyClicked}
            disabled={isApproveLoading}
          >
            Deny & Cancel
          </Button>
        </div>
        <div>
          <Button
            color='success'
            variant='contained'
            onClick={onConfirmClicked}
            disabled={isApproveLoading}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      onClose={onClose}
      onBackdropClick={onClose}
      open
      className='flex w-screen flex-col items-center justify-end md:justify-center '
    >
      <div className='flex w-full flex-col rounded-lg bg-white px-4 py-4 md:w-[27%]'>
        <div className='align-center flex w-full flex-row justify-end'>
          <div onClick={onClose} className='cursor-pointer'>
            <img src={CloseModalIcon} className='h-4 w-4' />
          </div>
        </div>
        <div className='mt-2 flex w-full flex-col items-center justify-center'>
          <CheckCircle sx={{ width: 40, height: 40 }} color='success' />
          <div className='mt-3 w-full text-center text-2xl font-normal text-black'>
            You are agreeing to pay for this!
          </div>
          <div className='w-full text-center text-base font-normal leading-7 text-black text-opacity-[60%]'>
            Upon confirmation, parts will be on the way!
          </div>
        </div>
        {renderButtons()}
      </div>
    </Modal>
  );
};

export default InvoiceSentApprovalModal;
