import { Typography } from '@mui/material';

import { AuthFormHeaderProps } from './interface';
import './styles.scss';

export const AuthFormHeader = ({
  title,
  subTitle,
}: AuthFormHeaderProps): JSX.Element => (
  <>
    <Typography
      variant='h4'
      fontWeight='bold'
      className='auth-form-header-title'
    >
      {title}
    </Typography>
    <Typography className='subtitle' variant='body1'>
      {subTitle}
    </Typography>
  </>
);
