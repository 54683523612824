import { Components } from '@mui/material';

import { opacityColors } from './colors';

export const MuiFormControlLabelTheme: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    label: {
      fontFamily: 'Roboto',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '125%',
      letterSpacing: '0.00625rem',
      color: opacityColors.black['87'],
    },
  },
};
