export interface IUsersCountResponse {
  data: {
    users_count: string;
  };
}

export enum EUserTypes {
  REGULAR_USER = 'REGULAR_USER',
  SEARCH_USER = 'SEARCH_USER',
}
