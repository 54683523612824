import {
  clearSupplierList,
  getManagerProfileResponse,
  getSupplierListResponse,
} from './actions';
import { ManagerState, Supplier, TaskActionProps } from './interface';

const initialState: ManagerState = {
  supplierList: undefined,
  impersonatedSupplier: undefined,
  profile: undefined,
};

const managerReducer = (
  state = initialState,
  action: TaskActionProps,
): ManagerState => {
  switch (action.type) {
    case String(getSupplierListResponse): {
      return {
        ...state,
        supplierList: action.payload.data.map((supplier: Supplier) => ({
          ...supplier,
          stateName: supplier.state?.name,
        })),
      };
    }

    case String(clearSupplierList): {
      return {
        ...state,
        supplierList: [],
      };
    }

    case String(getManagerProfileResponse): {
      return {
        ...state,
        profile: action.payload.data,
      };
    }

    default:
      return state;
  }
};

export default managerReducer;
