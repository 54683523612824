import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { bluonColors, solidColors } from './colors';
import { MuiAccordionSummaryTheme } from './MuiAccordionSummaryTheme';
import { MuiAccordionTheme } from './MuiAccordionTheme';
import { MuiAvatarTheme } from './MuiAvatarTheme';
import { MuiButtonTheme } from './MuiButtonTheme';
import { MuiCardActionAreaTheme } from './MuiCardActionAreaTheme';
import { MuiCardTheme } from './MuiCardTheme';
import { MuiFormControlLabelTheme } from './MuiFormControlLabel';
import { MuiFormHelperTextTheme } from './MuiFormHelperTextTheme';
import { MuiFormLabelTheme } from './MuiFormLabelTheme';
import { MuiIconButtonTheme } from './MuiIconButtonTheme';
import { MuiInputBaseTheme } from './MuiInputBaseTheme';
import { MuiMenuItemTheme } from './MuiMenuItemTheme';
import { MuiPopperTheme } from './MuiPopperTheme';
import { MuiSelectTheme } from './MuiSelectTheme';
import { MuiSwitchTheme } from './MuiSwitchTheme';
import { MuiTableCellTheme } from './MuiTableCellTheme';
import { MuiTableContainerTheme } from './MuiTableContainerTheme';
import { MuiTabTheme } from './MuiTabTheme';
import { MuiTypographyTheme } from './MuiTyphographyTheme';

const themePalette = createTheme({
  palette: {
    // key: {
    //   light: '',
    //   main: '',
    //   dark: '',
    //   contrastText: '',
    // },
    primary: {
      // light: '',
      main: bluonColors.blue[500],
      dark: bluonColors.blue[700],
      contrastText: solidColors.white,
    },
    primary2: {
      light: '',
      main: bluonColors.blue[500],
      dark: bluonColors.blue[700],
      contrastText: solidColors.white,
    },
    secondary: {
      main: bluonColors.blue[50],
      dark: bluonColors.blue[100],
      contrastText: bluonColors.blue[500],
    },
    success: {
      main: bluonColors.green[50],
      dark: bluonColors.green[600],
      contrastText: bluonColors.green[600],
    },
    warning: {
      main: '#FF8765',
    },
    background: {
      paper: solidColors.white,
      default: solidColors.white,
    },
    error: {
      main: bluonColors.tomato[100],
      dark: bluonColors.tomato[600],
      contrastText: bluonColors.tomato[600],
    },
    buy: {
      main: bluonColors.yellow[500],
      dark: bluonColors.yellow[600],
      contrastText: bluonColors.yellow[950],
    },
    buy2: {
      main: bluonColors.yellow[500],
      dark: bluonColors.yellow[600],
      contrastText: bluonColors.yellow[950],
    },
    default: {
      main: solidColors.white,
      contrastText: bluonColors.grey[700],
    },
    iconMirrorPrimary: {
      main: solidColors.white,
    },
  },
});

const theme = createTheme(themePalette, {
  components: {
    MuiTab: MuiTabTheme,
    MuiCard: MuiCardTheme,
    MuiButton: MuiButtonTheme(themePalette),
    MuiIconButton: MuiIconButtonTheme,
    MuiFormLabel: MuiFormLabelTheme,
    MuiInputBase: MuiInputBaseTheme,
    MuiPopper: MuiPopperTheme,
    MuiFormHelperText: MuiFormHelperTextTheme,
    MuiCardActionArea: MuiCardActionAreaTheme,
    MuiTypography: MuiTypographyTheme,
    MuiMenuItem: MuiMenuItemTheme,
    MuiAccordion: MuiAccordionTheme,
    MuiAccordionSummary: MuiAccordionSummaryTheme,
    MuiTableContainer: MuiTableContainerTheme,
    MuiTableCell: MuiTableCellTheme,
    MuiFormControlLabel: MuiFormControlLabelTheme,
    MuiSwitch: MuiSwitchTheme,
    MuiSelect: MuiSelectTheme,
    MuiAvatar: MuiAvatarTheme,
  },
});

export default responsiveFontSizes(theme);

declare module '@mui/material/styles' {
  interface Palette {
    buy: Palette['primary'];
    buy2: Palette['primary'];
    primary2: Palette['primary'];
    default: Palette['primary'];
    iconMirrorPrimary: Palette['primary'];
  }

  interface PaletteOptions {
    buy?: PaletteOptions['primary'];
    buy2?: PaletteOptions['primary'];
    primary2?: PaletteOptions['primary'];
    default?: PaletteOptions['primary'];
    iconMirrorPrimary?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    buy: true;
    buy2: true;
    primary2: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    default: true;
    iconMirrorPrimary: true;
  }
}
