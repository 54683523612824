import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { TabPanel } from '../../components/TabPanel';

import { accountTabs } from './constants';

import { useAppSelector } from '@hooks/state';
import useCustomAbility from '@hooks/useCustomAbility';
import { getStoreInfoData } from '@state/storeInfo/selectors';

import './styles.scss';

const setProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const Account = (): JSX.Element => {
  const ability = useCustomAbility();
  const [value, setValue] = useState(0);

  const filteredTabs = useMemo(() => {
    if (!ability?.rules?.length) return [];

    return accountTabs.filter((tab) => {
      const { abilityConfig } = tab;

      return (
        !abilityConfig ||
        ability?.can(abilityConfig.action, abilityConfig.subject)
      );
    });
  }, [ability, ability?.rules]);

  useEffect(() => {
    if (!ability?.rules?.length) return;
    setValue(0);
  }, [ability?.rules]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data } = useAppSelector((state) => ({
    data: getStoreInfoData(state),
  }));

  useEffect(() => {
    if (data) if (!data.verified_at) setValue(0);
  }, [data]);

  const renderTabs = () => {
    return filteredTabs.map((tab) => {
      const { id, label, dataTestId } = tab;

      return (
        <Tab
          className='menu-tab'
          data-testid={dataTestId}
          label={label}
          {...setProps(id)}
          key={id}
        />
      );
    });
  };

  const renderTabPanels = () => {
    return filteredTabs.map((tab, index) => {
      const { component, id } = tab;

      return (
        <TabPanel value={value} index={index} key={id}>
          {component}
        </TabPanel>
      );
    });
  };

  return (
    <div className='bl-account-container '>
      <Box
        className='bl-account-container-tab-and-logout'
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs value={value} onChange={handleChange}>
          {renderTabs()}
        </Tabs>
      </Box>
      <Box>{renderTabPanels()}</Box>
    </div>
  );
};
