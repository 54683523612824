import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import { ReadOnlyItemProps } from './interface';

import { Label } from '@components/Label';

//  COMPONENT
const ReadOnlyItem = ({
  name,
  quantity,
  isLastItem,
  noItemsFlow,
}: ReadOnlyItemProps) => {
  return (
    <div
      className={classNames(
        'flex w-full flex-row items-center justify-start px-4 py-2',
        {
          'border-b border-solid border-[var(--bluon--ds--lightgray-3)]':
            !isLastItem,
        },
      )}
    >
      <div className='text-sm font-normal text-[var(--bluon--ds--black)]'>
        {name}
      </div>
      {noItemsFlow && (
        <div className='ml-3'>
          <Label label='We can’t get it' color='light-red' />
        </div>
      )}
      <div
        className={`ml-auto text-sm font-normal ${
          noItemsFlow
            ? 'text-[var(--bluon--light--red-4)]'
            : 'text-[var(--bluon--ds--black)]'
        }`}
      >
        {noItemsFlow ? 0 : quantity || 0}
      </div>
    </div>
  );
};

//  LOADING STATE
const ANIMATION_DURATION = { animationDuration: '0.1s' };
const LoadingSkeleton = () => {
  return (
    <div className='flex w-full flex-row items-center justify-start border-b border-solid border-[var(--bluon--ds--lightgray-3)] px-4 py-2'>
      <Skeleton
        animation='wave'
        sx={ANIMATION_DURATION}
        width={75}
        height={12}
      />
      <Skeleton
        className='ml-auto'
        animation='wave'
        sx={ANIMATION_DURATION}
        width={20}
        height={12}
      />
    </div>
  );
};

ReadOnlyItem.Skeleton = LoadingSkeleton;
export default ReadOnlyItem;
