import { useEffect, useMemo, useState } from 'react';

import { AddedItems } from './components/AddedItems';
import CallForHelp from './components/CallForHelp';
import CompletedFooter from './components/CompleteFooter';
import { CurriInformativePopUp } from './components/CurriInformativePopUp';
import { CustomItemsComponent } from './components/CustomItemsComponent';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { ItemList } from './components/ItemList';
import { MarkAsCompleteFooter } from './components/MarkAsComplete';
import { OrderBanner } from './components/OrderBanner';
import PendingApprovalOrApprovedFooter from './components/PendingApprovalOrApprovedFooter';
import ReadOnlyOrderDetail from './components/ReadOnlyOrderDetail';
import { ReviewAndSendModal } from './components/ReviewAndSendModal';
import { Totals } from './components/Totals';
import { TruckStock } from './components/TruckStock';
import { OrderDetailTypes } from './constants';
import { SendOrderDetailPayload } from './interface';
import { getOrderDetailType } from './utils';

import { useSnackbar } from '@components/Snackbar/hooks';
import { ORDER_STATUS, ORDER_TYPE, Order } from '@config/api/task/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import useAddedItemsValidations from '@hooks/useAddedItemsValidations';
import useGoogleTagManager from '@hooks/useGoogleTagManager';
import useIsAllUnavailableItemsCheck from '@hooks/useIsAllUnavailableItemsCheck';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';
import { getCustomOrderStatus, isQuoteFileRequired } from '@pages/Tasks/utils';
import { setChatOpenedAction } from '@state/chat/actions';
import { ordersLogsRequest } from '@state/ordersLogs/actions';
import { getLogFilter } from '@state/ordersLogs/selectors';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import { getStoreInfoBidNumberOption } from '@state/storeInfo/selectors';
import {
  getAddedItemsRequest,
  getCustomItemsOrderRequest,
  getMoreOrderItems,
  getOrderItems as getOrderItemsAction,
  getOrderItemsSupply,
  getSupplierStaffRequest,
  patchAddedItemsRequest,
  patchOrderRequest,
  realTimeRefreshApprovedOrders,
  sendForApprovalRequest,
  setOrderCompleteRequest,
  setOrderDetailsRequest,
} from '@state/task/actions';
import {
  getCurrentSelectedOrder,
  getCurrentUploadedInvoice,
  getIsNationalSupplier,
  getOrderAddedItems,
  getOrderCustomItems,
  getOrderItems,
  getOrderSupplyItems,
  getShowUnconfirmedCustomerModal,
} from '@state/task/selectors';
import { EDeliveryType, EOrderMainStatus, EOrderSubstatus } from '@utils/enums';
import { assembleOrderDetailGTMEvent } from '@utils/GoogleTagManager';
import { GOOGLE_TAG_MANAGER_EVENT_MESSAGES } from '@utils/GoogleTagManager/interface';
import { isOrderCanceled } from '@utils/Orders';

export const OrderDetail = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const { clearSelectedOrder } = useSetSelectedOrder();
  const { setGTMEvent } = useGoogleTagManager();
  const { isAllUnavailable } = useIsAllUnavailableItemsCheck();
  const { haveChangedItems, areAllItemsConfirmed } = useAddedItemsValidations();

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const [modalReviewOpen, setModalReviewOpen] = useState<boolean>(false);
  const [totalValue, setTotalValue] = useState<string>('');
  const [orderLink, setOrderLink] = useState<string>('');
  const [bidNumberOption, setBidNumberOption] = useState<string>('');
  const [notesValue, setNotesValue] = useState<string>('');
  const [someInputHasChangedValue, setSomeInputHasChangedValue] =
    useState<boolean>(false);
  const [addItemModalOpen, setAddItemModalOpen] = useState<boolean>(false);

  const {
    order,
    parts,
    isLoadingItems,
    truckStockItems,
    addedItems,
    bidNumberMandatory,
    isLoadingTruckStockItems,
    uploadedInvoice,
    isUpdateLoading,
    customItems,
    areCustomItemsLoading,
    isAddedItemsLoading,
    isCompleteLoading,
    isLoadingDetail,
    filter,
    isNationalSupplier,
  } = useAppSelector((state) => ({
    order: getCurrentSelectedOrder(state),
    parts: getOrderItems(state),
    filter: getLogFilter(state),
    truckStockItems: getOrderSupplyItems(state),
    addedItems: getOrderAddedItems(state),
    isNationalSupplier: getIsNationalSupplier(state),
    showUnconfirmedCustomerModal: getShowUnconfirmedCustomerModal(state),
    isLoadingItems: isRequestRunning(state, String(getOrderItemsAction)),
    bidNumberMandatory: getStoreInfoBidNumberOption(state),
    isUpdateLoading: isRequestRunning(state, String(patchOrderRequest)),
    itemsHasNextPage: getMoreOrderItems(state),
    isLoadingNextPageItems: isRequestRunning(state, String(getMoreOrderItems)),
    itemsRequestError: getRequestError(state, String(getOrderItemsAction)),
    isCompleteLoading: isRequestRunning(state, String(setOrderCompleteRequest)),
    isLoadingTruckStockItems: isRequestRunning(
      state,
      String(getOrderItemsSupply),
    ),
    uploadedInvoice: getCurrentUploadedInvoice(state),
    customItems: getOrderCustomItems(state),
    areCustomItemsLoading: isRequestRunning(
      state,
      String(getCustomItemsOrderRequest),
    ),
    isAddedItemsLoading: isRequestRunning(
      state,
      String(patchAddedItemsRequest),
    ),
    isLoadingDetail: isRequestRunning(state, String(setOrderDetailsRequest)),
  }));

  const quoteFileRequired = isQuoteFileRequired(order);

  useEffect(() => {
    if (!order?.id) return;
    dispatch(getOrderItemsAction({ order: order.id, page: 1 }));
    dispatch(getOrderItemsSupply({ order: order.id, page: 1 }));
    dispatch(getAddedItemsRequest(order.id));
    dispatch(getCustomItemsOrderRequest({ order: order?.id }));
    setGTMEvent(
      assembleOrderDetailGTMEvent(
        GOOGLE_TAG_MANAGER_EVENT_MESSAGES.ORDER_DETAIL_VIEW,
        {
          orderDetail: {
            status: order.current_status.status,
            subStatus: order.current_status.substatus,
            orderId: order.id,
          },
        },
      ),
    );
  }, [order?.id]);

  useEffect(() => {
    dispatch(getSupplierStaffRequest({ type: 'counter' }));
  }, []);

  const orderDetailType = useMemo(
    () => getOrderDetailType(order?.current_status?.status as EOrderMainStatus),
    [order?.current_status?.status],
  );
  const isButtonDisabled = () => {
    const BUTTON_ENABLED = false;
    const BUTTON_DISABLED = true;

    const requiredFileButNoFile = quoteFileRequired && !uploadedInvoice;
    const bidNumberRequiredButNoBid = bidNumberMandatory && !bidNumberOption;
    const isPickUp = order?.delivery?.type === EDeliveryType.PICKUP;
    const totalValueNumber = totalValue
      ? Number(totalValue.replaceAll(',', '').replace('$ ', ''))
      : -1;
    const totalChanged = totalValueNumber !== order?.total;

    if (
      totalValueNumber <= 0 ||
      !areAllItemsConfirmed ||
      bidNumberRequiredButNoBid ||
      !someInputHasChangedValue ||
      (areAllItemsConfirmed && haveChangedItems && !totalChanged)
    )
      return BUTTON_DISABLED;

    if (!isPickUp && requiredFileButNoFile) return BUTTON_DISABLED;

    return BUTTON_ENABLED;
  };

  const getUpdateButtonTooltipMessage = () => {
    const requiredFileButNoFile = quoteFileRequired && !uploadedInvoice;
    const bidNumberRequiredButNoBid = bidNumberMandatory && !bidNumberOption;
    const totalValueNumber = totalValue
      ? Number(totalValue.replaceAll(',', '').replace('$ ', ''))
      : -1;
    const totalChanged = totalValueNumber !== order?.total;

    if (!areAllItemsConfirmed) return 'You must confirm added items';
    else if (areAllItemsConfirmed && haveChangedItems && !totalChanged)
      return bidNumberRequiredButNoBid
        ? 'You must update total and enter a bid number to continue'
        : 'You must update total to continue';

    if (requiredFileButNoFile)
      return 'You must have a quote/invoice uploaded to continue';

    if (totalValueNumber <= 0)
      return bidNumberRequiredButNoBid
        ? 'You must enter a total and bid number to continue'
        : 'You must enter a total to continue';

    return '';
  };

  const onOpenChat = () => {
    setTimeout(() => {
      dispatch(
        setChatOpenedAction({
          opened: true,
          channel: order?.channel,
        }),
      );
    }, 50);
  };

  const renderItemsAndTruckStockInfo = () => {
    //  Pending state
    if (orderDetailType === OrderDetailTypes.PENDING)
      return (
        <>
          <ItemList isLoading={isLoadingDetail || isLoadingItems} />
          <CustomItemsComponent
            onCloseModalAdd={() => setAddItemModalOpen(false)}
            addItemModalOpen={addItemModalOpen}
          />
          {truckStockItems && !!truckStockItems.length && (
            <TruckStock
              items={truckStockItems}
              order={order?.id}
              isBidRequired={bidNumberMandatory}
            />
          )}
        </>
      );

    //  Approved state
    if (
      orderDetailType === OrderDetailTypes.PENDING_APPROVAL_OR_APPROVED ||
      orderDetailType === OrderDetailTypes.CANCELED ||
      orderDetailType === OrderDetailTypes.RETURNED ||
      orderDetailType === OrderDetailTypes.COMPLETED ||
      orderDetailType === OrderDetailTypes.THIRD_PARTY_HANDLED
    )
      return (
        <>
          <ReadOnlyOrderDetail
            partsLoading={isLoadingDetail || isLoadingItems}
            truckStockLoading={isLoadingDetail || isLoadingTruckStockItems}
            truckStockItems={truckStockItems}
            showAddedItems={
              orderDetailType !== OrderDetailTypes.PENDING_APPROVAL_OR_APPROVED
            }
            items={parts}
            customItems={customItems}
            addedItems={addedItems}
            areCustomItemsLoading={isLoadingDetail || areCustomItemsLoading}
            isAddedItemsLoading={isLoadingDetail || isAddedItemsLoading}
            isCanceledOrder={isOrderCanceled(
              order?.current_status?.substatus as EOrderSubstatus,
            )}
          />

          {orderDetailType === OrderDetailTypes.PENDING_APPROVAL_OR_APPROVED &&
            addedItems &&
            !!addedItems.length && (
              <AddedItems
                items={addedItems}
                order={order?.id}
                isBidRequired={bidNumberMandatory}
              />
            )}
        </>
      );

    //  Displaying loading state
    if (isLoadingDetail) return <ItemList isLoading={true} />;

    return null;
  };

  const isPendingApprovalCurriDelivery = useMemo(() => {
    const customOrderStatus = order ? getCustomOrderStatus(order) : '';

    return (
      customOrderStatus === ORDER_STATUS['approved-awaiting-delivery-curri'] ||
      customOrderStatus === ORDER_STATUS['approved-ready-for-delivery-curri']
    );
  }, [order]);

  const updateOrderButton = () => {
    if (isButtonDisabled()) return null;
    const substatus = order?.current_status?.substatus;
    if (
      order?.delivery?.type === EDeliveryType.CURRI_DELIVERY &&
      !uploadedInvoice &&
      [
        'approved-awaiting-delivery',
        'approved-awaiting-delivery-quote-needed-ws',
        'approved-awaiting-delivery-quote-needed',
        'approved-ready-for-delivery',
        'approved-ready-for-delivery-quote-needed-ws',
        'approved-ready-for-delivery-quote-needed',
        'approved-ready-for-delivery-quote-updated',
        'approved-delivered-quote-needed-ws',
        'approved-delivered-quote-needed',
        'approved-awaiting-delivery-quote-updated',
        'approved-delivered-quote-updated',
      ].indexOf(substatus as EOrderSubstatus) > -1
    )
      showMessage('Please upload an attachment to update the Quote', {
        severity: 'error',
      });
    else
      dispatch(
        patchOrderRequest({
          order: order?.id,
          total: Number(totalValue?.replace(/[^0-9.-]+/g, '')),
          bid_number: bidNumberOption,
          note: notesValue,
          successCallback: () => {
            setSomeInputHasChangedValue(false);
            clearSelectedOrder(false);
            if (isPendingApprovalCurriDelivery)
              dispatch(
                realTimeRefreshApprovedOrders({
                  type: ORDER_TYPE.APPROVED,
                  page: 1,
                }),
              );
          },
          errorCallback: () => {
            showMessage('There was an error updating the order', {
              severity: 'error',
            });
          },
        }),
      );
  };

  const completeOrderButton = () => {
    if (!order) return;
    dispatch(
      setOrderCompleteRequest({
        order: order.id,
        total: Number(totalValue?.replace(/[^0-9.-]+/g, '')),
        bid_number: bidNumberOption,
        note: notesValue,
        successCallback: () => {
          dispatch(
            realTimeRefreshApprovedOrders({
              type: ORDER_TYPE.APPROVED,
              page: 1,
            }),
          );

          setSomeInputHasChangedValue(false);
          clearSelectedOrder(false);

          if (window.location.href.includes('logs'))
            dispatch(ordersLogsRequest(filter));
        },
        errorCallback: () => {
          showMessage('There was an error completing the order request.', {
            severity: 'error',
            autoHide: true,
          });
        },
      }),
    );
  };

  const isCompleteButtonDisabled = () => {
    const hasTotalValue =
      totalValue &&
      Number(totalValue.replaceAll(',', '').replace('$ ', '')) > 0;

    const isButtonEnabled = bidNumberMandatory
      ? hasTotalValue && bidNumberOption
      : hasTotalValue;

    return !isButtonEnabled;
  };
  const renderFooter = () => {
    if (orderDetailType === OrderDetailTypes.PENDING)
      return (
        <Footer
          disabledButtonSend={
            !isAllUnavailable &&
            (!totalValue ||
              (!bidNumberOption && bidNumberMandatory) ||
              (totalValue &&
                Number(totalValue.replaceAll(',', '').replace('$ ', '')) < 0) ||
              hasPendingParts ||
              hasPendingTruckStockItems ||
              (isNationalSupplier === true && !orderLink))
          }
          setModalReviewOpen={() => setModalReviewOpen(true)}
          sendButtonTooltip={
            hasPendingParts || hasPendingTruckStockItems
              ? ''
              : getUpdateButtonTooltipMessage()
          }
        />
      );

    if (orderDetailType === OrderDetailTypes.PENDING_APPROVAL_OR_APPROVED) {
      const orderStatus = order?.current_status?.substatus;
      const isCurri = order?.delivery?.type === EDeliveryType.CURRI_DELIVERY;

      const showMarkAsCompleteFooter =
        [
          EOrderSubstatus.APPROVED_AWAITING_DELIVERY,
          EOrderSubstatus.APPROVED_READY_DELIVERY,
          EOrderSubstatus.APPROVED_DELIVERED,
        ].indexOf(orderStatus as EOrderSubstatus) > -1;

      return !isCurri && showMarkAsCompleteFooter ? (
        <MarkAsCompleteFooter
          order={order as Order}
          isUpdateLoading={isUpdateLoading}
          updateButtonDisabled={isButtonDisabled()}
          completeDisabled={isCompleteButtonDisabled()}
          updateButtonCallback={updateOrderButton}
          completeButtonCallback={completeOrderButton}
          updateButtonTooltip={getUpdateButtonTooltipMessage()}
          isCompleteLoading={isCompleteLoading}
        />
      ) : (
        <PendingApprovalOrApprovedFooter
          order={order as Order}
          updateButtonCallback={updateOrderButton}
          updateButtonDisabled={isButtonDisabled()}
          updateButtonTooltip={getUpdateButtonTooltipMessage()}
          isUpdateLoading={isUpdateLoading}
        />
      );
    }

    if (orderDetailType === OrderDetailTypes.COMPLETED)
      return <CompletedFooter order={order as Order} />;

    if (
      orderDetailType === OrderDetailTypes.CANCELED ||
      orderDetailType === OrderDetailTypes.RETURNED ||
      orderDetailType === OrderDetailTypes.THIRD_PARTY_HANDLED
    )
      return (
        <div className='flex w-full flex-col items-center justify-center'>
          <CallForHelp />
        </div>
      );

    return null;
  };

  const handleFormValues = (
    totalValue: string,
    bidNumberValue: string,
    notesValue: string,
    inputHasChangedSomeValue: boolean,
    orderLinkValue: string,
  ) => {
    setSomeInputHasChangedValue(inputHasChangedSomeValue);
    setTotalValue(totalValue);
    setBidNumberOption(bidNumberValue);
    setNotesValue(notesValue);
    setOrderLink(orderLinkValue);
  };

  const hasPendingParts = useMemo(
    () => (parts ? parts.some((part) => part.status === 'pending') : true),
    [parts],
  );

  const hasPendingTruckStockItems = useMemo(
    () =>
      truckStockItems
        ? truckStockItems.some((item) => item.status === 'pending')
        : false,
    [truckStockItems],
  );
  const handleSendAvailability = () => {
    const payload: SendOrderDetailPayload = {
      order: order?.id,
      totalValue: isAllUnavailable ? '' : totalValue,
      bidNumberOption,
      notesValue,

      errorCallback: () => {
        showMessage('There was an error sending availability', {
          severity: 'error',
          autoHide: true,
        });
      },
      successCallback: () => {
        // Leaving here the logic commented on a possible future rollback.
        // if (hasPassedLessThan15Minutes(order?.created_at ?? ''))
        //   showMessage('You got $5 from Bluon! - Fast Quote', {
        //     subMessage: 'Thanks for getting that quote out in 15 min.',
        //     severity: 'success',
        //     autoHide: false,
        //   });

        setModalReviewOpen(false);
        clearSelectedOrder(false);
      },
    };

    if (!isAllUnavailable && isNationalSupplier)
      payload.third_party_url = orderLink;

    dispatch(sendForApprovalRequest(payload));
  };

  const initiallyExpanded =
    orderDetailType === OrderDetailTypes.PENDING_APPROVAL_OR_APPROVED ||
    orderDetailType === OrderDetailTypes.COMPLETED ||
    orderDetailType === OrderDetailTypes.RETURNED ||
    orderDetailType === OrderDetailTypes.CANCELED ||
    orderDetailType === OrderDetailTypes.THIRD_PARTY_HANDLED;

  return (
    <div className='flex w-[90vw] flex-col gap-2'>
      <Header
        isLoading={isLoadingDetail || isLoadingItems}
        orderDetailType={orderDetailType}
        onAddItem={() => setAddItemModalOpen(true)}
        onOpenChat={onOpenChat}
      />

      {!isLoadingDetail && (
        <OrderBanner
          order={order}
          orderDetailType={orderDetailType}
          isNationalSupplier={isNationalSupplier}
        />
      )}

      {renderItemsAndTruckStockInfo()}
      {order?.note !== undefined && (
        <Totals
          isBidNumberRequired={bidNumberMandatory}
          initiallyExpanded={initiallyExpanded}
          orderDetailType={orderDetailType}
          order={order}
          handleValues={handleFormValues}
          quoteFileRequired={quoteFileRequired}
          quoteFileChanged={() => setSomeInputHasChangedValue(true)}
          isNationalSupplier={!!isNationalSupplier}
        />
      )}
      {renderFooter()}
      <CurriInformativePopUp order={order} />
      <ReviewAndSendModal
        open={modalReviewOpen}
        onClose={() => setModalReviewOpen(false)}
        totalValue={totalValue || formatter.format(order?.total ?? 0)}
        onSendAvailability={handleSendAvailability}
      />
    </div>
  );
};
