import { SubjectType } from '@casl/ability';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid, ListItemIcon, ListItemText, Tab } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NavDropDownProps } from './interface';

import SupplierRating from '@components/SupplierRating';
import { Can } from '@config/canAbility';
import { EAbilityActions, EAbilitySubjects } from '@config/canAbility/types';
import { NavRoute } from '@config/routing/interface';
import { DropdownRoutes, LogOutDropdownRoute } from '@config/routing/Routes';
import useRealTimeStoreRating from '@hooks/fireBase/useRealTimeStoreRating';
import { useAppSelector } from '@hooks/state';
import useCustomAbility from '@hooks/useCustomAbility';
import { logout } from '@state/auth/actions';
import { getIsImpersonatedSupplier, getIsManager } from '@state/auth/selectors';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import './styles.scss';

export const NavDropDown = ({
  storeInfoBoolean,
  isActive,
  value,
}: NavDropDownProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customAbility = useCustomAbility();
  const [dropdownMenu, setDropDownMenu] = useState<null | HTMLElement>(null);

  const { storeInfo, isManager, isImpersonatedSupplier } = useAppSelector(
    (state) => ({
      storeInfo: getStoreInfoData(state),
      isManager: getIsManager(state),
      isImpersonatedSupplier: getIsImpersonatedSupplier(),
    }),
  );

  const { rating: storeRating } = useRealTimeStoreRating(storeInfo?.id || '');

  const filteredRoutes: NavRoute[] = useMemo(() => {
    if (isManager && !isImpersonatedSupplier) return LogOutDropdownRoute;

    return DropdownRoutes;
  }, [isImpersonatedSupplier, isManager]);

  const handleCloseUserMenu = () => {
    setDropDownMenu(null);
  };

  const handleExternalRedirect = (url: string, target: string) => {
    window.open(url, target);
    handleCloseUserMenu();
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setDropDownMenu(event.currentTarget);
  };

  const enableMenu = storeInfoBoolean || isManager;

  const handleCanManageAccess = (subject: string | string[]) => {
    const ruleFound = customAbility.rules.find(
      (rule) =>
        rule.subject === subject || subject.includes(rule.subject as string),
    );

    return ruleFound?.subject || subject;
  };

  const handleNavigation = (tabPath: string) => {
    if (!tabPath) return;

    if (tabPath === 'Log Out') dispatch(logout(() => navigate('/login')));

    navigate(tabPath);
    handleCloseUserMenu();
  };

  return (
    <Grid item xs className='container-dropdown'>
      {storeRating && (
        <Can
          I={EAbilityActions.MANAGE}
          a={EAbilitySubjects.TASKS_AND_CUSTOMERS}
        >
          {() => <SupplierRating rating={storeRating} />}
        </Can>
      )}

      <Tab
        value={value}
        icon={<MenuIcon />}
        className={`custom-tab ${isActive ? 'Mui-selected' : ''}`}
        sx={{ opacity: 1 }}
        onClick={handleOpenUserMenu}
        disabled={!enableMenu}
      />
      <Menu
        className='bl-menu-list-container'
        PaperProps={{ sx: { width: '16.25rem' } }}
        MenuListProps={{ sx: { paddingTop: 0, paddingBottom: 0 } }}
        anchorEl={dropdownMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={Boolean(dropdownMenu)}
        onClose={handleCloseUserMenu}
      >
        {filteredRoutes.map((route: NavRoute, i: number) => {
          const { type, path, target, icon, title, ability } = route;

          const Tab =
            type === 'external' ? (
              <MenuItem
                key={'menu-' + i}
                onClick={() =>
                  handleExternalRedirect(String(path), target ?? '_self')
                }
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{title}</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                key={title}
                onClick={() => handleNavigation(path.toString() || title)}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{title}</ListItemText>
              </MenuItem>
            );

          return ability ? (
            <Can
              I={ability?.action}
              a={handleCanManageAccess(ability?.subject) as SubjectType}
              key={title}
            >
              {Tab}
            </Can>
          ) : (
            Tab
          );
        })}
      </Menu>
    </Grid>
  );
};
