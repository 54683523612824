import client from '..';

import {
  ISearchList,
  ModelPartsDataProps,
  ModelSpecificationsDataProps,
  ModelsDataProps,
  PartDetailsDataProps,
  PartReplacementsDataProps,
  PartsDataProps,
  SearchModelsDataProps,
  SeriesDataProps,
} from './interface';

const environment = process ? process.env : null;

export async function getSeries(params: {
  brandId: ISearchList['id'];
  search: string;
  page: number;
}): Promise<SeriesDataProps> {
  const search_string = params.search ? encodeURI(params.search) : '';

  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/brands/${params.brandId}/series`,
    {
      params: { page: params.page, search_string },
    },
  );

  return data;
}

export async function getModels(params: {
  brandId: ISearchList['id'];
  serieId: ISearchList['id'];
  page: number;
  search?: string;
}): Promise<ModelsDataProps> {
  const search_string = params.search ? encodeURI(params.search) : '';

  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/brands/${params.brandId}/series/${params.serieId}/oems`,
    {
      params: { page: params.page, search_string },
    },
  );

  return data;
}

export async function getModelSpecifications(params: {
  modelId: ISearchList['id'];
  oem_search_counter_id?: string;
}): Promise<ModelSpecificationsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/oems/${params.modelId}`,
    {
      params: { oem_search_counter: params.oem_search_counter_id },
    },
  );

  return data;
}

export async function getModelParts(params: {
  modelId: ISearchList['id'];
  page: number;
}): Promise<ModelPartsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/oems/${params.modelId}/parts`,
    {
      params: { page: params.page },
    },
  );

  return data;
}

export async function getParts(params: {
  number: string;
  page: number;
}): Promise<PartsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/parts`,
    {
      params: { page: params.page, number: encodeURI(params.number) },
    },
  );

  return data;
}

export async function getPartDetail(params: {
  partId: string;
  part_search_counter_id?: string;
}): Promise<PartDetailsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/parts/${params.partId}`,
    {
      params: {
        part_search_counter: params.part_search_counter_id,
      },
    },
  );

  return data;
}

export async function getPartReplacements(params: {
  partId: string;
  page: number;
}): Promise<PartReplacementsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/parts/${params.partId}/replacements`,
    {
      params: { page: params.page },
    },
  );

  return data;
}

export async function getSearchModels(params: {
  model: string;
  page: number;
}): Promise<SearchModelsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/oems`,
    {
      params: { model: encodeURI(params.model), page: params.page },
    },
  );

  return data;
}

export async function recommendPartReplacements(params: {
  partId: string;
  brand: string;
  partNumber: string;
  note?: string;
}): Promise<SearchModelsDataProps> {
  const { partId, brand, partNumber, note } = params;

  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/parts/${partId}/recommended-replacements`,
    {
      part: partId,
      brand,
      part_number: partNumber,
      note: note || null,
    },
  );

  return data;
}
