import { Box, Typography } from '@mui/material';
import React from 'react';

import './styles.scss';
import { UnconfirmedCustomerInfoCardProps } from './interface';

import { formatPhoneNumber } from '@utils/Strings/formatPhoneNumber';
export const UnconfirmedCustomerInfoCard = ({
  name,
  title,
  address,
  email,
  phone,
}: UnconfirmedCustomerInfoCardProps) => (
  <Box className='bl-unconfirmed-customer-card'>
    <Typography variant='subtitle1' fontWeight={700}>
      {title}
    </Typography>

    <Typography variant='subtitle1' className='highlight-name'>
      {name}
    </Typography>

    {address && <Typography variant='subtitle2'>{address}</Typography>}
    {email && <Typography variant='subtitle2'>{email}</Typography>}
    {phone && (
      <Typography variant='subtitle2'>{formatPhoneNumber(phone)}</Typography>
    )}
  </Box>
);
