import { ITruckStockModalSectionProps } from './interface';

import { Label } from '@components/Label';

import '../../styles.scss';

export const TruckStockModalSection = ({
  truckStockItem,
  isAllUnavailable,
  hasAdditionalItem,
}: ITruckStockModalSectionProps) => {
  if (isAllUnavailable || hasAdditionalItem)
    return (
      <div className='flex items-center justify-between border-b border-[var(--bluon--light--gray-21)] px-4 py-1.5'>
        <div className='flex items-center'>
          <span className='ellipsis-text max-w-[70%] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem] text-[var(--bluon--ds--lightblack-primary)]'>
            {truckStockItem.item.info.name}
          </span>
          <div className='ml-2'>
            <Label label='We can’t get it' color='light-red' />
          </div>
        </div>
        <div>
          <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem] text-[var(--bluon--light--red-4)]'>
            {truckStockItem.quantity}
          </div>
        </div>
      </div>
    );

  if (truckStockItem.quantity === 0) return null;

  return (
    <div className='flex items-center justify-between border-b border-[var(--bluon--light--gray-21)] px-4 py-1.5'>
      <div className='ellipsis-text max-w-[70%] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem] text-[var(--bluon--ds--lightblack-primary)]'>
        {truckStockItem.item.info.name}
      </div>
      <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem]'>
        {truckStockItem.quantity}
      </div>
    </div>
  );
};
