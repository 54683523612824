import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FromModelButtonProps } from './interface';

import { Button } from '@components/ButtonV2';
import useGoogleTagManager from '@hooks/useGoogleTagManager';
import { assembleOrderDetailGTMEvent } from '@utils/GoogleTagManager';
import { GOOGLE_TAG_MANAGER_EVENT_MESSAGES } from '@utils/GoogleTagManager/interface';

import './styles.scss';

const FromModelButton = ({
  brand,
  onClick,
  model,
  modelId,
  buttonAdditionalStyle = 'transparent',
  labelAdditionalStyle = '',
}: FromModelButtonProps) => {
  const navigate = useNavigate();
  const { setGTMEvent } = useGoogleTagManager();

  const handleButtonClick = () => {
    onClick && onClick();
    setGTMEvent(
      assembleOrderDetailGTMEvent(
        GOOGLE_TAG_MANAGER_EVENT_MESSAGES.CLICKED_ON_MODEL_BUTTON,
        { model },
      ),
    );
    navigate(
      `/bluon-search?tab=searchModel&search=${model}&modelId=${modelId}&modelPage=1`,
    );
  };

  return (
    <div className='from-model-button-wrapper mr-4 flex flex-row items-center justify-center'>
      <div
        className={`mr-2 flex items-center text-[var(--bluon--ds--button--lightgray--border)] ${labelAdditionalStyle}`}
      >
        From Model
      </div>
      <div className={`border-0 ${buttonAdditionalStyle}`}>
        <Button
          className='from-model-button h-full w-full'
          variant='outlined'
          onClick={handleButtonClick}
        >
          {brand} {model}
          <ArrowForwardIosIcon fontSize='small' />
        </Button>
      </div>
    </div>
  );
};

export default FromModelButton;
