import { off, onValue, ref } from 'firebase/database';
import { useEffect } from 'react';

import { database, distributorNodeUpdate } from '@config/firebase';

const useRealTimeDistributorUpdate = (
  distributorId: string | undefined,
  onValueReceived: () => void,
) => {
  useEffect(() => {
    if (distributorId && distributorNodeUpdate) {
      const distributorUpdateRef = ref(
        database,
        distributorNodeUpdate + distributorId + '',
      );
      onValue(distributorUpdateRef, onValueReceived);

      return () => {
        off(distributorUpdateRef);
      };
    }
  }, [distributorId]);
};

export default useRealTimeDistributorUpdate;
