import { onValue, ref } from 'firebase/database';
import { useEffect } from 'react';

import { StoreInfoDataModel } from '@config/api/storeInfo/interface';
import { database, databaseNodeOrdersComplete } from '@config/firebase';

const useRealTimeOrderComplete = (
  storeInfo: StoreInfoDataModel | undefined,
  onValueReceived: () => void,
) => {
  useEffect(() => {
    if (storeInfo)
      if (databaseNodeOrdersComplete) {
        const countersRef = ref(
          database,
          databaseNodeOrdersComplete + storeInfo.id,
        );
        onValue(countersRef, (snapshot) => {
          if (snapshot.val()) onValueReceived();
        });
      }
  }, [storeInfo]);
};

export default useRealTimeOrderComplete;
