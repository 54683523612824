import ClearIcon from '@mui/icons-material/Clear';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DoneIcon from '@mui/icons-material/Done';
import dayjs from 'dayjs';
import { useState } from 'react';

import { WeCanGetItByPicker } from '../../../WeCanGetItByPicker';

import {
  EButtonIds,
  EUnlistedPartStatus,
  IUnlistedItemPayload,
  UnlistedPartItemActionsProps,
} from './interface';

import { Button } from '@components/ButtonV2';
import { useSnackbar } from '@components/Snackbar/hooks';
import { useAppDispatch } from '@hooks/state';
import { patchCurrentItem } from '@state/task/actions';

const UnlistedPartItemActions = ({
  orderId,
  formValues,
  disableActions,
  isLoading,
  item,
  isLastItem,
  handleLastItemAction,
  closeExpanded,
  triggerValidations,
}: UnlistedPartItemActionsProps) => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [loadingButton, setLoadingButton] = useState<EButtonIds | null>(null);

  const getAvailableAt = () => {
    return item?.available_at
      ? dayjs(
          new Date(
            new Date(item?.available_at).setDate(
              new Date(item?.available_at).getDate() + 1,
            ),
          ).toISOString(),
        )
      : null;
  };

  const onUpdateSuccess = () => {
    setLoadingButton(null);
    closeExpanded();
    if (isLastItem && handleLastItemAction) handleLastItemAction();
    showMessage('Order updated successfully', {
      severity: 'success',
      autoHide: true,
    });
  };

  const onUpdateError = () => {
    setLoadingButton(null);
    showMessage('Error updating order', {
      severity: 'error',
      autoHide: true,
    });
  };

  const updateUnlistedItem = async (
    status: EUnlistedPartStatus,
    available_at: string | undefined,
  ) => {
    //  Validating form (is items not available, we do not need validations)
    const canSubmit =
      status === EUnlistedPartStatus.NOT_AVAILABLE
        ? true
        : await triggerValidations();
    if (!canSubmit) {
      setLoadingButton(null);

      return;
    }

    //  Patching item
    const itemPayload: IUnlistedItemPayload = {
      order: orderId,
      item: item?.id,
      status,
      replacement: null,
      available_at: available_at
        ? dayjs(available_at).local().format('YYYY-MM-DD')
        : undefined,
      brand: formValues?.brand || '',
      part_number: formValues?.part_number || '',
      quantity: item?.quantity,
      price: item?.price,
    };
    dispatch(
      patchCurrentItem({
        ...itemPayload,
        errorCallback: onUpdateError,
        successCallback: onUpdateSuccess,
      }),
    );
  };

  const onAcceptDate = (ISOString: string | undefined) => {
    setLoadingButton(EButtonIds.WE_CAN_GET_IT_BY);
    updateUnlistedItem(EUnlistedPartStatus.AVAILABLE, ISOString);
    setShowDatePicker(false);
  };

  const onButtonClick = (status: EUnlistedPartStatus, buttonId: EButtonIds) => {
    setLoadingButton(buttonId);
    updateUnlistedItem(status, undefined);
  };

  //  Rendering
  return (
    <div className='flex w-full flex-row items-center justify-between px-4 py-2'>
      <Button
        id={EButtonIds.WE_CANT_GET_IT}
        className='h-[3.625rem] w-[16%]'
        variant='outlined'
        color='error'
        startIcon={<ClearIcon />}
        onClick={() =>
          onButtonClick(
            EUnlistedPartStatus.NOT_AVAILABLE,
            EButtonIds.WE_CANT_GET_IT,
          )
        }
        disabled={isLoading}
        loading={loadingButton === EButtonIds.WE_CANT_GET_IT}
      >
        {"Can't Get It"}
      </Button>
      <Button
        id={EButtonIds.WE_CAN_GET_IT_BY}
        className='h-[3.625rem] w-[41%]'
        variant='outlined'
        startIcon={<DateRangeIcon />}
        disabled={disableActions || isLoading}
        loading={loadingButton === EButtonIds.WE_CAN_GET_IT_BY}
        onClick={() => setShowDatePicker(true)}
      >
        We Can Get It By...
      </Button>
      {showDatePicker && (
        <WeCanGetItByPicker
          open={showDatePicker}
          onClose={() => setShowDatePicker(false)}
          onAccept={onAcceptDate}
          available_at={getAvailableAt()}
        />
      )}
      <Button
        id={EButtonIds.WE_CAN_GET_IT}
        className='h-[3.625rem] w-[41%]'
        variant='contained'
        startIcon={<DoneIcon />}
        onClick={() =>
          onButtonClick(EUnlistedPartStatus.AVAILABLE, EButtonIds.WE_CAN_GET_IT)
        }
        disabled={disableActions || isLoading}
        loading={loadingButton === EButtonIds.WE_CAN_GET_IT}
      >
        We Have It
      </Button>
    </div>
  );
};

export default UnlistedPartItemActions;
