import AddIcon from '@mui/icons-material/Add';
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import HoursModal from '../BranchHoursModal';
import { BrandModal } from '../BrandModal';
import { AccountBrandModalActions } from '../BrandModal/components/AccountBrandModalActions';

import { StoreInfoInputsProps } from './interface';

import { BranchHours } from '@components/BranchHours';
import { ChipList } from '@components/ChipList';
import { ChipData } from '@components/ChipList/interface';
import CustomControlledSelect from '@components/CustomControlledSelect';
import FileUploaderArea from '@components/FileUploaderArea';
import InputComponent from '@components/InputComponent';
import { SpecialInputSelector } from '@components/SpecialInputSelector';
import { SelectedHoursDataModel } from '@config/api/storeInfo/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import {
  getCountriesRequest,
  getStatesRequest,
} from '@state/storeInfo/actions';
import {
  getBrandsData,
  getCountriesData,
  getStatesData,
} from '@state/storeInfo/selectors';
import { FormTextInputLengths } from '@utils/FormInputLengths';
import { timeZones } from 'src/constants';

const StoreInfoInputs = ({
  setValue,
  control,
  open_hours,
  clearErrors,
  watch,
  formState,
  image,
  logo,
  isLoading,
  selectedBrands = [],
  errorMessageBrandModal,
}: StoreInfoInputsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [isOpenBrandModal, setIsOpenBrandModal] = useState(false);
  const watchCountry = watch('country');
  const watchImage = watch('image');
  const watchLogo = watch('logo');

  const { countriesData, statesData, brands, stateLoading } = useAppSelector(
    (state) => ({
      countriesData: getCountriesData(state) ?? [],
      statesData: getStatesData(state) ?? [],
      brands: getBrandsData(state) ?? [],
      stateLoading: isRequestRunning(state, String(getStatesRequest), false),
    }),
  );

  useEffect(() => {
    if (!countriesData.length) dispatch(getCountriesRequest());
  }, []);

  useEffect(() => {
    if (watchCountry && countriesData.length) {
      dispatch(getStatesRequest(watchCountry));
      if (watchCountry !== 'US') {
        setValue('zip_code', '');
        clearErrors('zip_code');
      }
    }
  }, [watchCountry, countriesData, clearErrors, dispatch, setValue]);

  const stateOptions = useMemo(
    () => statesData.map(({ code, name }) => ({ label: name, value: code })),
    [statesData],
  );

  const countryOptions = useMemo(
    () => countriesData.map(({ code, name }) => ({ label: name, value: code })),
    [countriesData],
  );

  const initialChips = useMemo(() => {
    const initial = selectedBrands.map((c) => ({ id: c.id, label: c.name }));

    return initial;
  }, [selectedBrands]);

  const handleOnPress = () => {
    if (isLoading) return;
    setVisible((prevState) => !prevState);
  };

  const handleHourChange = useCallback(
    (branchHours: SelectedHoursDataModel[]) => {
      setValue('branch_hours', branchHours);
    },
    [setValue],
  );

  const handleClickBrandModalAction = () => {
    if (isLoading) return;
    setIsOpenBrandModal(!isOpenBrandModal);
  };

  const handleFileChange = useCallback(
    (file: File) => {
      clearErrors('image');
      setValue('image', file);
    },
    [clearErrors, setValue],
  );

  const handleLogoChange = useCallback(
    (file: File) => {
      clearErrors('logo');
      setValue('logo', file);
    },
    [clearErrors, setValue],
  );

  return (
    <Grid
      container
      justifyContent='center'
      className='store-info-inputs-container'
    >
      <Grid item xs={12} md={9}>
        <Controller
          name='name'
          control={control}
          render={({ field, fieldState, formState }) => (
            <InputComponent
              variant='outlined'
              id='name'
              label='Store name'
              data-testid='storeName'
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              helperText={formState.errors.name?.message}
              keepHelperTextSpace
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid
          container
          item
          display='flex'
          gap={{ xs: '2rem', sm: '2rem', md: '2rem', lg: '0' }}
          columnSpacing={{ xs: 0, md: 2 }}
        >
          <Grid item xs={12} md={12} lg={6}>
            <FormLabel className='typography-label-styles'>
              Company/Store Logo <a>(optional)</a>
            </FormLabel>
            <FileUploaderArea
              data-testid='storeCompanyLogo'
              file={watchLogo}
              setFileValue={handleLogoChange}
              error={formState.errors.logo}
              image={logo}
              fieldName='logo'
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <FormLabel className='typography-label-styles'>
              Store Image <a>(optional)</a>
            </FormLabel>
            <FileUploaderArea
              data-testid='storeCompanyImage'
              file={watchImage}
              setFileValue={handleFileChange}
              error={formState.errors.image}
              image={image}
              fieldName='image'
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9}>
        <Controller
          name='branch'
          control={control}
          render={({ field, fieldState, formState }) => (
            <InputComponent
              id='branch'
              data-testid='branchIdentifier'
              label={
                <>
                  Unique Branch Identifier <a>(Optional)</a>
                </>
              }
              name={field.name}
              onBlur={field.onBlur}
              inputProps={{
                maxLength: FormTextInputLengths.branch,
              }}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              helperText={formState.errors.branch?.message}
              variant='outlined'
              keepHelperTextSpace
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Controller
          name='address'
          control={control}
          render={({ field, fieldState, formState }) => (
            <InputComponent
              variant='outlined'
              id='address'
              label='Store Address'
              data-testid='storeAddress'
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              helperText={formState.errors.address?.message}
              keepHelperTextSpace
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Controller
          name='address_2'
          control={control}
          render={({ field, fieldState, formState }) => (
            <InputComponent
              id='address_2'
              label={
                <>
                  Address line 2 <a>(Optional)</a>
                </>
              }
              data-testid='storeAddressLine2'
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              helperText={formState.errors.address_2?.message}
              variant='outlined'
              isLoading={isLoading}
              keepHelperTextSpace
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid
          container
          display='flex'
          gap={{ xs: '2rem', sm: '2rem', md: '2rem', lg: '0' }}
          item
          columnSpacing={{ xs: 0, md: 2 }}
        >
          <Grid item xs={12} md={6}>
            <CustomControlledSelect
              id='country'
              name='country'
              control={control as any}
              selectLabel='Country'
              data-testid='storeCountry'
              selectProps={{
                addSelectedIcon: true,
                placeholder: 'Select one please',
                options: countryOptions,
              }}
              isLoading={isLoading}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomControlledSelect
              name='state'
              id='state'
              selectLabel='State'
              control={control as any}
              data-testid='storeState'
              selectProps={{
                addSelectedIcon: true,
                placeholder: 'Select one please',
                options: stateOptions,
              }}
              isLoading={stateLoading || isLoading}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid
          container
          display='flex'
          gap={{ xs: '2rem', sm: '2rem', md: '2rem', lg: '0' }}
          item
          columnSpacing={{ xs: 0, md: 2 }}
        >
          <Grid item xs={12} md={6}>
            <Controller
              name='city'
              control={control}
              render={({ field, fieldState, formState }) => (
                <InputComponent
                  id='city'
                  label='City'
                  data-testid='storeCity'
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  error={!!fieldState.error}
                  helperText={formState.errors.city?.message}
                  variant='outlined'
                  keepHelperTextSpace
                  isLoading={isLoading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='zip_code'
              control={control}
              render={({ field, fieldState, formState }) => (
                <InputComponent
                  variant='outlined'
                  id='zip_code'
                  label='Zip code'
                  data-testid='storeZipCode'
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  error={!!fieldState.error}
                  inputProps={{
                    maxLength: FormTextInputLengths.address.zipCode,
                  }}
                  helperText={formState.errors.zip_code?.message}
                  disabled={watchCountry !== 'US'}
                  keepHelperTextSpace
                  isLoading={isLoading}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9}>
        <Controller
          name='email'
          control={control}
          render={({ field, fieldState, formState }) => (
            <InputComponent
              variant='outlined'
              id='email'
              label='Email for Login'
              data-testid='storeEmail'
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              inputProps={{
                maxLength: FormTextInputLengths.profile.email,
              }}
              helperText={formState.errors.email?.message}
              keepHelperTextSpace
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid
          container
          display='flex'
          gap={{ xs: '2rem', sm: '2rem', md: '2rem', lg: '0' }}
          item
          columnSpacing={{ xs: 0, md: 2 }}
        >
          <Grid item xs={12} md={6}>
            <Controller
              name='phone'
              control={control}
              render={({ field, fieldState, formState }) => (
                <InputComponent
                  variant='outlined'
                  id='phone'
                  label='Phone number'
                  data-testid='storePhoneNumber'
                  name={field.name}
                  onBlur={field.onBlur}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }}
                  onChange={field.onChange}
                  value={field.value}
                  error={!!fieldState.error}
                  inputProps={{
                    maxLength: FormTextInputLengths.profile.phoneFullNumber,
                  }}
                  helperText={formState.errors.phone?.message}
                  keepHelperTextSpace
                  isLoading={isLoading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='prokeep_phone'
              control={control}
              render={({ field, fieldState, formState }) => (
                <InputComponent
                  variant='outlined'
                  id='prokeep_phone'
                  label={
                    <>
                      Prokeep Phone Number <a>(Optional)</a>
                    </>
                  }
                  data-testid='storeProkeepPhoneNumber'
                  name={field.name}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  error={!!fieldState.error}
                  inputProps={{
                    maxLength: FormTextInputLengths.profile.phoneFullNumber,
                  }}
                  helperText={formState.errors.prokeep_phone?.message}
                  keepHelperTextSpace
                  isLoading={isLoading}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid
          container
          display='flex'
          gap={{ xs: '2rem', sm: '2rem', md: '2rem', lg: '0' }}
          item
          columnSpacing={{ xs: 0, md: 2 }}
        >
          <Grid item xs={12} md={6}>
            <SpecialInputSelector
              label={'Branch Hours'}
              buttonText={'See/Edit Hours'}
              buttonIcon={<AddIcon />}
              onClick={handleOnPress}
              data={open_hours ?? []}
              data-testid='storeBranchHours'
            >
              <BranchHours
                data-testid='storeBranchHours'
                dataHours={open_hours ?? []}
              />
            </SpecialInputSelector>
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomControlledSelect
              name='timezone'
              id='timezone'
              selectLabel='Branch Time Zone'
              control={control as any}
              data-testid='storeState'
              selectProps={{
                addSelectedIcon: true,
                placeholder: 'Select one please',
                options: timeZones,
              }}
              isLoading={isLoading}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      {visible && (
        <HoursModal
          data={open_hours}
          visible={visible}
          onAccept={handleOnPress}
          onCancel={handleOnPress}
          setFieldValue={handleHourChange}
        />
      )}
      <Grid item xs={12} md={9}>
        <SpecialInputSelector
          label='OEM Brands Carried at Store'
          buttonText='See/Edit Brands'
          buttonIcon={<AddIcon />}
          onClick={handleClickBrandModalAction}
          data={initialChips as ChipData[]}
          data-testid='storeBrands'
          errorMessage={errorMessageBrandModal}
        >
          <ChipList
            data={initialChips as ChipData[]}
            data-testid='storeBrands'
            capitalizeLabel={false}
          />
        </SpecialInputSelector>
      </Grid>
      {brands && isOpenBrandModal && (
        <BrandModal
          ModalActions={AccountBrandModalActions}
          isOpen={isOpenBrandModal}
          brandsList={brands}
          initialBrands={initialChips}
          onClose={handleClickBrandModalAction}
          title='Choose the brands you carry'
          hasNextPage={false}
          isLoading={false}
          internalSearch
        />
      )}
      <Grid item xs={12} md={9}>
        <Controller
          name='offers_delivery'
          control={control}
          render={({ field }) => (
            <>
              <FormLabel className='typography-label-styles'>
                Do you offer Job Site Delivery?
              </FormLabel>
              <RadioGroup
                row
                aria-label='Do you offer Job Site Delivery?'
                defaultValue={0}
                value={field.value}
                name='offers_delivery'
              >
                <FormControlLabel
                  id='offers_delivery'
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={1}
                  control={<Radio />}
                  label='Yes'
                />
                <FormControlLabel
                  id='offers_delivery'
                  value={0}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  control={<Radio />}
                  label='No'
                />
              </RadioGroup>
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default StoreInfoInputs;
