import { Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

import { BaseCustomerBanner } from '../BaseBanner';

import { UnconfirmedCustomerBannerProps } from './interface';

import { Button } from '@components/ButtonV2';
import { setCurrentOrderUnconfirmedUserInfo } from '@state/task/actions';

import './styles.scss';

export const UnconfirmedCustomerBanner = ({
  order,
}: UnconfirmedCustomerBannerProps) => {
  const dispatch = useDispatch();

  return (
    <BaseCustomerBanner className='bl-unconfirmed-customer-banner'>
      <Typography variant='subtitle1'>Account Status Unconfirmed</Typography>

      <Button
        variant='outlined'
        color='primary'
        onClick={() => dispatch(setCurrentOrderUnconfirmedUserInfo(order))}
      >
        Confirm Status
      </Button>
    </BaseCustomerBanner>
  );
};
