import CloseIcon from '@mui/icons-material/Close';
import { Box, Tooltip } from '@mui/material';
import React, { useState } from 'react';

import CallForHelp from '../CallForHelp';
import DeclineOrCancelModal from '../DeclineOrCancelModal';

import { PendingApprovalOrApprovedFooterProps } from './interface';

import { Button } from '@components/ButtonV2';
import useGoogleTagManager from '@hooks/useGoogleTagManager';
import { EOrderSubstatus } from '@utils/enums';
import { assembleOrderDetailGTMEvent } from '@utils/GoogleTagManager';
import { GOOGLE_TAG_MANAGER_EVENT_MESSAGES } from '@utils/GoogleTagManager/interface';
import { isCurriApprovedDeliveredQuoteUpdated } from '@utils/Orders';

const PendingApprovalOrApprovedFooter = ({
  order,
  updateButtonDisabled,
  updateButtonCallback,
  isUpdateLoading,
  updateButtonTooltip,
}: PendingApprovalOrApprovedFooterProps) => {
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const { setGTMEvent } = useGoogleTagManager();

  const renderUpdateButton = () => {
    const handleUpdateButtonClick = () => {
      updateButtonCallback();
      setGTMEvent(
        assembleOrderDetailGTMEvent(
          GOOGLE_TAG_MANAGER_EVENT_MESSAGES.CLICKED_ON_UPDATE_BUTTON,
          {
            orderDetail: {
              orderId: order.id,
            },
          },
        ),
      );
    };

    if (
      // Not displaying for pending-approval-fulfilled (200) or curri 323
      order?.current_status?.substatus ===
        EOrderSubstatus.PENDING_APPROVAL_FULFILLED ||
      isCurriApprovedDeliveredQuoteUpdated(order)
    )
      return null;

    return (
      <>
        <Tooltip
          title={updateButtonDisabled ? updateButtonTooltip : ''}
          placement='top'
        >
          <Box>
            <Button
              variant='contained'
              color='primary'
              disabled={updateButtonDisabled || isUpdateLoading}
              onClick={handleUpdateButtonClick}
              loading={isUpdateLoading}
              className='h-14 w-36'
            >
              Update
            </Button>
          </Box>
        </Tooltip>

        <div className='mt-1 text-xs font-normal leading-5 text-[var(--bluon--ds--lightgray-1)]'>
          All items must be completed and totals entered
        </div>
      </>
    );
  };

  const handleCancelRequestButtonClick = () => {
    setGTMEvent(
      assembleOrderDetailGTMEvent(
        GOOGLE_TAG_MANAGER_EVENT_MESSAGES.CLICKED_ON_CANCEL_REQUEST,
        {
          orderDetail: {
            status: order.current_status.status,
            subStatus: order.current_status.substatus,
            orderId: order.id,
          },
        },
      ),
    );
    setShowCancelModal(true);
  };

  const renderCancelRequest = () => {
    //  Just allowing cancel from the following states
    const substatus = order?.current_status?.substatus;
    if (
      substatus === EOrderSubstatus.PENDING_APPROVAL_QUOTE_NEEDED ||
      substatus === EOrderSubstatus.APPROVED_AWAITING_DELIVERY ||
      substatus === EOrderSubstatus.PENDING_APPROVAL_QUOTE_UPDATED ||
      substatus ===
        EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED_WS ||
      substatus === EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED ||
      substatus === EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_UPDATED
    )
      return (
        <div className='mt-4 '>
          <Button
            className='h-7'
            variant='outlined'
            color='error'
            startIcon={<CloseIcon />}
            onClick={handleCancelRequestButtonClick}
          >
            Cancel Request
          </Button>
        </div>
      );

    return null;
  };

  const renderCancelModal = () => {
    if (!showCancelModal) return null;

    return (
      <DeclineOrCancelModal
        orderId={String(order?.id)}
        onClose={() => setShowCancelModal(false)}
        isOpen={showCancelModal}
        isCancel
      />
    );
  };

  return (
    <>
      {renderCancelModal()}
      <div className='mt-6 flex w-full flex-col items-center justify-center'>
        {renderUpdateButton()}
        {renderCancelRequest()}
        <CallForHelp />
      </div>
    </>
  );
};

export default PendingApprovalOrApprovedFooter;
