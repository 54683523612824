import { Typography } from '@mui/material';

import BannerSkeleton from './Skeleton';

import { useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import { storeInfoGetRequest } from '@state/storeInfo/actions';
import { getStoreInfoData } from '@state/storeInfo/selectors';

import './styles.scss';

const ManagerBanner = () => {
  const { storeInfo, isStoreLoading } = useAppSelector((state) => ({
    storeInfo: getStoreInfoData(state),
    isStoreLoading: isRequestRunning(state, String(storeInfoGetRequest)),
  }));

  const isAddressPresent =
    !!storeInfo?.address ||
    !!storeInfo?.city ||
    !!storeInfo?.state ||
    !!storeInfo?.state?.code ||
    !!storeInfo?.zip_code;

  if (isStoreLoading) return <BannerSkeleton />;

  return (
    <div className='banner z-[1399] flex justify-center bg-[var(--bluon--ds--darkblue)] py-1'>
      {storeInfo && (
        <Typography
          variant='body1'
          className='!mr-2 !text-[var(--bluon--ds--white)]'
        >
          {storeInfo?.name}{' '}
          {storeInfo?.branch ? `(ID# ${storeInfo?.branch}) ` : ' '}
        </Typography>
      )}
      {storeInfo && isAddressPresent && (
        <Typography
          variant='body1'
          className='max-w-[12.5rem] truncate !text-[var(--bluon--ds--disabled)] md:max-w-[28.25rem]'
        >
          <span className='mr-2'>·</span>
          {storeInfo?.address} {storeInfo?.city ? `, ${storeInfo?.city}` : ''}
          {storeInfo?.state ? `, ${storeInfo?.state?.code}` : ''}{' '}
          {storeInfo?.zip_code}
        </Typography>
      )}
    </div>
  );
};

export default ManagerBanner;
