import client from '..';

import {
  BranchHoursResponse,
  BrandsDataProps,
  IStorePermissionsResponse,
  LimitedStoreInfoDataProps,
  PostBranchHours,
  ReasonsResponse,
  SelectedPartsBrandsDataProps,
  StoreInfoDataProps,
  StoreInfoStatesCountries,
} from './interface';
import { mapPatchPayload, prepareFormData } from './mappers';

import { StoreInfoFormKeys } from '@pages/Account/subpages/StoreInfo/interfaces';
import { PostStoreInfoParamsProps } from '@state/storeInfo/interface';

const environment = process ? process.env : null;

export async function getLimitedStoreInfo(): Promise<LimitedStoreInfoDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/limited-supplier`,
  );

  return data;
}

export async function getStoreInfo(): Promise<StoreInfoDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/supplier`,
  );

  return data;
}

export async function getBrands(params: {
  page: number;
  search: string;
}): Promise<BrandsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/brands`,
    {
      params: { page: params.page, search_string: params.search || '' },
    },
  );

  return data;
}

export async function getPartBrands(params: {
  page: number;
  search: string;
}): Promise<BrandsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/part-brands`,
    {
      params: { page: params.page, search_string: params.search },
    },
  );

  return data;
}

export async function getSelectedPartBrands(): Promise<SelectedPartsBrandsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/supplier/part-brands`,
  );

  return data;
}

export async function patchStoreInfo(
  payload: StoreInfoFormKeys,
): Promise<StoreInfoDataProps> {
  const formData = new FormData();
  const mappedPayload = mapPatchPayload(payload);

  prepareFormData(formData, mappedPayload);

  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/supplier`,
    formData,
  );

  return data;
}

export async function postStoreInfoBrands(
  payload: PostStoreInfoParamsProps,
): Promise<BrandsDataProps> {
  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/supplier/bulk-brand`,
    payload,
  );

  return data;
}

export async function postStoreInfoPartBrands(
  payload: PostStoreInfoParamsProps,
): Promise<BrandsDataProps> {
  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/supplier/part-brands`,
    payload,
  );

  return data;
}

export async function getCountriesApi(): Promise<StoreInfoStatesCountries> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/address/countries`,
  );

  return data;
}

export async function getStatesApi(
  countryCode: string,
): Promise<StoreInfoStatesCountries> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/address/countries/${countryCode}/states`,
  );

  return data;
}

export async function postBranchHoursApi(
  payload: PostBranchHours,
): Promise<BranchHoursResponse> {
  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/supplier/bulk-hour`,
    payload,
  );

  return data;
}

export async function getReasonsByStatus(
  status: string,
): Promise<ReasonsResponse> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/substatuses/${status}/reasons`,
  );

  return data;
}

export const getStorePermissions =
  async (): Promise<IStorePermissionsResponse> => {
    const response = await client.get<IStorePermissionsResponse>(
      `/${environment?.REACT_APP_API_VERSION}/supplier/permissions`,
    );

    return response?.data;
  };
