import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useCustomAbility from './useCustomAbility';

import { IHomeRoute } from '@config/routing/interface';
import { homeRoutes } from '@config/routing/Routes';
import { getFirstAvailableHomeRoute } from '@utils/navigation';

const useNavigateToHome = () => {
  const ability = useCustomAbility();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [homeRoute, setHomeRoute] = useState<IHomeRoute['path'] | null>(null);

  useEffect(() => {
    const newHomeRoute = getFirstAvailableHomeRoute(homeRoutes, ability);
    setHomeRoute(newHomeRoute);
  }, [ability, ability?.rules]);

  const navigateToHome = useCallback(() => {
    if (!homeRoute) return;
    if (homeRoute === pathname) return;

    navigate(homeRoute);
  }, [homeRoute, pathname, navigate]);

  return { homeRoute, navigateToHome };
};

export default useNavigateToHome;
