import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { UnconfirmedCustomerActions } from './components/UnconfirmedCustomerActions';
import { UnconfirmedCustomerForm } from './components/UnconfirmedCustomerForm';
import { UnconfirmedCustomerInfoCard } from './components/UnconfirmedCustomerInfoCard';
import {
  ConfirmUserPayload,
  ECustomerAccount,
  UnconfirmedCustomerModalFormKeys,
  UnconfirmedCustomerModalProps,
} from './interface';
import { UnconfirmedCustomerModalValidationSchema } from './validations/validations';

import './styles.scss';
import { useSnackbar } from '@components/Snackbar/hooks';
import {
  ESnackbarSeverity,
  SnackbarSeverity,
} from '@components/Snackbar/interface';
import { useAppSelector } from '@hooks/state';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';
import { isRequestRunning } from '@state/requests/selectors';
import {
  postConfirmCustomer,
  setSelectedClaimingModal,
  setShowUnconfirmedCustomerModal,
} from '@state/task/actions';
import {
  getCurrentSelectedOrderId,
  getSelectedOrderUnconfirmedCustomerInfo,
} from '@state/task/selectors';
import { EOrderSubstatus } from '@utils/enums';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';
import { getParsedAddress } from '@utils/Strings/getAddress';

export const UnconfirmedCustomersModal = ({
  open,
  accountUser,
  closeModal,
}: UnconfirmedCustomerModalProps) => {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const { changeSelectedOrder } = useSetSelectedOrder();
  const [customerAccount, setCustomerAccount] = useState<ECustomerAccount>(
    ECustomerAccount.NO_SELECTION,
  );

  const { customerOrder, isConfirmCustomerLoading, currentOrderSelectedId } =
    useAppSelector((state) => ({
      customerOrder: getSelectedOrderUnconfirmedCustomerInfo(state),
      currentOrderSelectedId: getCurrentSelectedOrderId(state),
      isConfirmCustomerLoading: isRequestRunning(
        state,
        String(postConfirmCustomer),
      ),
    }));

  const { control, watch, formState, reset, trigger } =
    useForm<UnconfirmedCustomerModalFormKeys>({
      resolver: yupResolver(UnconfirmedCustomerModalValidationSchema),
      mode: 'onChange',
      context: {
        hasAccount: customerAccount === ECustomerAccount.HAS_ACCOUNT,
      },
    });

  useEffect(() => {
    if (customerAccount === ECustomerAccount.NO_SELECTION) return;
    reset({
      account_number: '',
      customer_tier: '',
    });
  }, [customerAccount, reset]);

  const accountNumber = watch('account_number');
  const customerTier = watch('customer_tier');

  const handleSendAction = () => {
    const payload: ConfirmUserPayload = {
      customer_tier: customerTier,
      have_account_number: ECustomerAccount.HAS_ACCOUNT === customerAccount,
      user: accountUser ? +accountUser.id : customerOrder?.user?.id,
      onSuccessCallback: handleCallback(
        ESnackbarSeverity.SUCCESS,
        'The customer has been confirmed successfully',
      ),
      onErrorCallback: handleCallback(
        ESnackbarSeverity.ERROR,
        'There was an error confirming the customer',
      ),
    };

    if (customerAccount === ECustomerAccount.HAS_ACCOUNT)
      payload.account_number = accountNumber;

    dispatch(postConfirmCustomer(payload));
  };

  const handleCallback = (severity: SnackbarSeverity, message: string) => {
    return () => {
      showMessage(message, { severity });
      handleClose();
    };
  };

  const getParsedUserInfo = () => {
    const user = customerOrder?.user;
    const company = customerOrder?.company;

    const companyName = company
      ? capitalizeFirstLetter(user?.company?.name || company?.name || '')
      : "For Technician's Personal Job";

    const techName = `${capitalizeFirstLetter(
      user?.first_name || '',
    )} ${capitalizeFirstLetter(user?.last_name || '')}`;

    return { companyName, techName };
  };

  const closeCustomerModal = () => {
    dispatch(setShowUnconfirmedCustomerModal(false));
    if (closeModal) closeModal();
  };

  const handleClose = () => {
    if (accountUser) return closeCustomerModal();

    if (!customerOrder) return;

    const isPendingNotAssignedOrder =
      !customerOrder.working_on_it &&
      customerOrder.current_status.substatus ===
        EOrderSubstatus.PENDING_REQUESTED;
    if (isPendingNotAssignedOrder) {
      const { companyName, techName } = getParsedUserInfo();
      dispatch(
        setSelectedClaimingModal({
          order: customerOrder,
          techName,
          companyName,
        }),
      );
    } else if (
      !currentOrderSelectedId ||
      (currentOrderSelectedId && currentOrderSelectedId !== customerOrder?.id)
    ) {
      changeSelectedOrder(customerOrder?.id, customerOrder);
    }

    closeCustomerModal();
  };

  const getCompanyAddress = () => {
    const company = accountUser ? accountUser.company : customerOrder?.company;

    if (!company) return '';

    return getParsedAddress(
      {
        address_1: company.address ?? null,
        address_2: null,
        city: company.city ?? null,
        country: company.country?.name ?? null,
        state: company.state?.name ?? null,
        zip_code: company.zip_code ?? null,
      },
      false,
    );

    return `${company?.address}`;
  };

  return (
    <Dialog
      className='bl-unconfirmed-customers-modal'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') closeCustomerModal();
      }}
    >
      <IconButton onClick={closeCustomerModal}>
        <CloseIcon />
      </IconButton>

      <DialogTitle>Unconfirmed Customer - Confirm Status</DialogTitle>
      <DialogContent>
        <UnconfirmedCustomerInfoCard
          title='Customer Info'
          name={
            accountUser
              ? accountUser.name
              : `${customerOrder?.user?.first_name} ${customerOrder?.user?.last_name}`
          }
          email={accountUser ? accountUser.email : customerOrder?.user?.email}
          phone={accountUser ? accountUser.phone : customerOrder?.user?.phone}
        />
        <UnconfirmedCustomerInfoCard
          title='Company Info'
          name={
            accountUser
              ? accountUser.company?.name ?? ''
              : customerOrder?.company?.name ?? ''
          }
          address={getCompanyAddress()}
        />
      </DialogContent>

      <UnconfirmedCustomerForm
        control={control}
        customerAccount={customerAccount}
        setCustomerAccount={setCustomerAccount}
        trigger={trigger}
      />

      <UnconfirmedCustomerActions
        leaveForNowAction={handleClose}
        handleSendAction={handleSendAction}
        isSendButtonDisabled={
          !formState.isValid ||
          customerAccount === ECustomerAccount.NO_SELECTION ||
          (customerAccount === ECustomerAccount.HAS_ACCOUNT && !accountNumber)
        }
        isSendButtonLoading={isConfirmCustomerLoading}
      />
    </Dialog>
  );
};
