import { ReactNode } from 'react';

export const appendStrongMarkupToString = (
  paragraph: string,
  customClass = '',
): ReactNode => {
  const startIndex = paragraph.indexOf('<strong>');
  if (startIndex && startIndex > -1) {
    const endIndex = paragraph.indexOf('</strong>');

    const strongString = paragraph.substring(startIndex + 8, endIndex);
    const prefix = paragraph.substring(0, startIndex - 1);
    const suffix = paragraph.substring(endIndex + 9);

    return (
      <span>
        {prefix}
        <strong className={customClass}> {strongString} </strong>
        {suffix}
      </span>
    );
  } else {
    return <span>{paragraph}</span>;
  }
};

export const appendSupMarkupToString = (
  paragraph: string,
  customClass = '',
): ReactNode => {
  const startIndex = paragraph.indexOf('<sup>');
  if (startIndex && startIndex > -1) {
    const endIndex = paragraph.indexOf('</sup>');

    const strongString = paragraph.substring(startIndex + 5, endIndex);
    const prefix = paragraph.substring(0, startIndex - 1);
    const suffix = paragraph.substring(endIndex + 6);

    return (
      <span>
        {prefix}
        <sup className={customClass}> {strongString} </sup>
        {suffix}
      </span>
    );
  } else {
    return <span>{paragraph}</span>;
  }
};
