import { Supplier } from '@state/manager/interface';

export const generateSkeletonRows = (): Supplier[] => {
  return [...Array(4)].map((_, index) => ({
    id: String(index),
    name: '',
    address: '',
    address_2: '',
    city: '',
    country: '',
    phone: '',
    branch: '',
    pending_tasks: index,
    stateName: '',
    zip_code: '',
  }));
};
