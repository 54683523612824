import { getUsersCountReceive } from './actions';
import { IUserState, IUserStateAction } from './interface';

const initialState: IUserState = {
  users_count: '',
};

const userReducer = (
  state: IUserState = initialState,
  action: IUserStateAction,
): IUserState => {
  switch (action?.type) {
    case String(getUsersCountReceive): {
      return {
        ...state,
        users_count: action?.payload?.data?.users_count || '',
      };
    }
    default:
      return state;
  }
};

export default userReducer;
