import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { PasswordInputProps } from './interfaces';

import InputComponent from '@components/InputComponent';

const PasswordInput = ({ control, name, ...props }: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) => (
        <InputComponent
          label='Password'
          id='password'
          data-testid='password'
          placeholder='Enter password'
          aria-label={name}
          {...props}
          type={showPassword ? 'text' : 'password'}
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
          defaultValue={field.value}
          error={!!fieldState.error}
          helperText={
            formState.errors[name]?.message?.toString() ??
            'Must be at least 8 characters and contain 1 number, 1 uppercase and one lowercase character.'
          }
          keepHelperTextSpace
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default PasswordInput;
