export const FormTextInputLengths = {
  profile: {
    email: 40,
    phoneFullNumber: 15,
  },
  address: {
    address2: 25,
    zipCode: 10,
  },
  branch: 16,
};
