import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useState } from 'react';

import { partsSpecificationsString } from '../../../../../../data/parts';

import { ItemSpecsProps } from './interface';

import { Button } from '@components/ButtonV2';
import { handleGetString } from '@utils/functions';

import './styles.scss';

export const ItemSpecsComponent = (props: ItemSpecsProps): JSX.Element => {
  const {
    itemSpecs,
    specsCount,
    type,
    isSpecsLoading,
    itemSpecsHasValueItems,
  } = props;
  const [showMore, setShowMore] = useState(false);

  const getRows = () => {
    if (!itemSpecs) return;
    const rows = [];

    const array = showMore
      ? Object.entries(itemSpecs)
      : Object.entries(itemSpecs).slice(0, 4);

    for (const [key, value] of array)
      rows.push(row({ type: type, value: key }, value));

    return rows;
  };

  const row = (data: { type: string | null; value: string }, value: string) => {
    if (!value) return null;

    return (
      <Box key={data.value} className='specs-item-container'>
        <Typography variant='caption' className='title'>
          {handleGetString(data, data.value, partsSpecificationsString)}
        </Typography>
        <Typography variant='caption' className='value'>
          {value}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box className='specs-container'>
        {isSpecsLoading ? (
          <Box display='flex' justifyContent='center' paddingY={1}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          getRows()
        )}
      </Box>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        padding={0.5}
      >
        {specsCount > 4 && itemSpecsHasValueItems && (
          <Button
            variant='text'
            color='primary'
            onClick={() => setShowMore(!showMore)}
            disabled={isSpecsLoading}
          >
            {showMore ? '- Show less' : '+ Show all specs'}
          </Button>
        )}
      </Grid>
    </>
  );
};
