import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { IPrivateRouteProps } from './types';

import { isLogged } from '@utils/Auth/AuthUtils';

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children }) => {
  const userLoggedIn = isLogged();
  const location = useLocation();

  if (userLoggedIn) return children;

  return (
    <Navigate
      to={`/login?redirect=${location?.pathname}${
        location?.search ? encodeURIComponent(location?.search) : ''
      }`}
    />
  );
};

export default PrivateRoute;
