import { Typography } from '@mui/material';
import React from 'react';

import { BaseCustomerBanner } from '../BaseBanner';

import './styles.scss';

export const ConfirmedCashCustomerBanner = () => {
  return (
    <BaseCustomerBanner className='bl-confirmed-cash-customer-banner'>
      <Typography variant='subtitle1'>No Account</Typography>
    </BaseCustomerBanner>
  );
};
