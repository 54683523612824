import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WindowIcon from '@mui/icons-material/Window';
import { getLiveRoutes } from 'bluon-search';

import { ConfigRoute, IHomeRoute, NavRoute } from './interface';

import { EAbilityActions, EAbilitySubjects } from '@config/canAbility/types';
import { Account } from '@pages/Account';
import { Customer } from '@pages/Account/subpages/Customer';
import ShareableOrderDetail from '@pages/Contractor/ShareableOrderDetail';
import { Help } from '@pages/Help';
import { ContractorLogin } from '@pages/Login/ContractorLogin';
import { LoginComponent } from '@pages/Login/DefaultLogin';
import { ForgotPasswordPage } from '@pages/Login/ForgotPassword';
import { ResetPassword } from '@pages/Login/ResetPassword';
import { SetNewPassword } from '@pages/Login/SetNewPassword';
import { StoreOverview } from '@pages/Manager/StoreOverview';
import { MM_ROUTE } from '@pages/MasterMechanic';
import OrdersLogs from '@pages/OrdersLogs';
import { Tasks } from '@pages/Tasks';

export const MasterMechanicRoute: NavRoute = {
  path: MM_ROUTE,
  title: 'Master Mechanic',
  icon: <SmartToyOutlinedIcon />,
  customClassName: 'min-w-[6.875rem]',
  ability: {
    action: EAbilityActions.MANAGE,
    subject: EAbilitySubjects.SEARCH,
    skipCanValidation: true,
  },
};

export const BluonSearchRoute: NavRoute = {
  path: '/search',
  title: 'Search',
  icon: <SearchIcon />,
  ability: {
    action: EAbilityActions.MANAGE,
    subject: EAbilitySubjects.SEARCH,
    skipCanValidation: false,
  },
};

export const ManagerNavRoutes: NavRoute[] = [
  {
    path: '/store-overview',
    title: 'Stores',
    icon: <WarehouseIcon />,
  },
];

export const SupplierNavRoutes: NavRoute[] = [
  BluonSearchRoute,
  {
    path: '/tasks',
    title: 'Tasks',
    icon: <WindowIcon />,
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.TASKS_AND_CUSTOMERS,
      skipCanValidation: false,
    },
  },
  {
    path: '/customers',
    title: 'Customers',
    icon: <SupervisorAccountIcon />,
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.TASKS_AND_CUSTOMERS,
      skipCanValidation: false,
    },
  },
  MasterMechanicRoute,
];

export const ImpersonatedSupplierNavRoutes: NavRoute[] = [
  ...ManagerNavRoutes,
  ...SupplierNavRoutes,
];

export const LogOutDropdownRoute: NavRoute[] = [
  {
    path: '',
    title: 'Log Out',
    icon: <LogoutIcon />,
  },
];

export const DropdownRoutes: NavRoute[] = [
  {
    path: '/account',
    title: 'Account',
    icon: <PersonIcon />,
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.ACCOUNT,
      skipCanValidation: false,
    },
  },
  {
    path: 'https://www.bluon.com/BluonLiveFeedback.php',
    target: '_blank',
    type: 'external',
    title: 'Feedback',
    icon: <ChatBubbleOutlineOutlinedIcon />,
    ability: {
      action: EAbilityActions.MANAGE,
      subject: [
        EAbilitySubjects.HELP_AND_FEEDBACK,
        EAbilitySubjects.FEEDBACK_ONLY,
      ] as any,
      skipCanValidation: false,
    },
  },
  ...LogOutDropdownRoute,
];

export const ManagerAuthenticatedRoutes: ConfigRoute[] = [
  {
    path: '/store-overview',
    component: StoreOverview,
    loginRequired: true,
    includeGlobalComponents: false,
  },
];

export const AuthenticatedRoutes: ConfigRoute[] = [
  {
    path: '/customers',
    component: Customer,
    loginRequired: true,
    includeGlobalComponents: true,
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.TASKS_AND_CUSTOMERS,
      skipCanValidation: false,
    },
  },
  {
    path: '/tasks',
    component: Tasks,
    loginRequired: true,
    includeGlobalComponents: true,
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.TASKS_AND_CUSTOMERS,
      skipCanValidation: false,
    },
  },
  ...getLiveRoutes(),
  {
    path: '/account',
    component: Account,
    loginRequired: true,
    includeGlobalComponents: true,
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.ACCOUNT,
      skipCanValidation: false,
    },
  },
  {
    path: '/feedback',
    component: Help,
    loginRequired: true,
    includeGlobalComponents: true,
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.HELP_AND_FEEDBACK,
      skipCanValidation: false,
    },
  },
  {
    path: '/help',
    component: Help,
    loginRequired: true,
    includeGlobalComponents: true,
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.HELP_AND_FEEDBACK,
      skipCanValidation: false,
    },
  },
  {
    path: '/tasks/logs',
    component: OrdersLogs,
    loginRequired: true,
    includeGlobalComponents: true,
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.TASKS_AND_CUSTOMERS,
      skipCanValidation: false,
    },
  },
  {
    path: MM_ROUTE,
    component: () => <div></div>,
    loginRequired: true,
    includeGlobalComponents: true,
  },
  ...ManagerAuthenticatedRoutes,
];

export const ContractorUnauthenticatedRoutes: ConfigRoute[] = [
  {
    path: '/contractor/login',
    component: ContractorLogin,
    loginRequired: false,
    includeGlobalComponents: false,
  },
  {
    path: '/order-summary',
    component: ShareableOrderDetail,
    loginRequired: false,
    includeGlobalComponents: false,
  },
];

export const UnauthenticatedRoutes: ConfigRoute[] = [
  {
    path: '/login',
    component: LoginComponent,
    loginRequired: false,
    includeGlobalComponents: false,
  },
  {
    path: '/login/set-new-password',
    component: SetNewPassword,
    loginRequired: true,
    includeGlobalComponents: false,
  },
  {
    path: '/login/forgot-password',
    component: ForgotPasswordPage,
    loginRequired: false,
    includeGlobalComponents: false,
  },
  {
    path: '/password-reset/:token',
    component: ResetPassword,
    loginRequired: false,
    includeGlobalComponents: false,
  },
  ...ContractorUnauthenticatedRoutes,
];

export const ConfigRoutes: ConfigRoute[] = [
  ...UnauthenticatedRoutes,
  ...AuthenticatedRoutes,
];

export const homeRoutes: IHomeRoute[] = [
  {
    path: '/search',
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.SEARCH,
      skipCanValidation: true,
    },
  },
  {
    path: '/tasks',
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.TASKS_AND_CUSTOMERS,
      skipCanValidation: false,
    },
  },
  {
    path: '/account',
    ability: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.ACCOUNT,
      skipCanValidation: false,
    },
  },
];
