import { AxiosError } from 'axios';

const messageType = {
  TOKEN_SIGNATURE_COULD_NOT_BE_VERIFIED:
    'Token Signature could not be verified.',
  USER_DISABLED: 'User is disabled',
  USER_WITHOUT_PERMISSIONS: 'User does not have the right permissions.',
};
export function errorResponseAction(
  error: AxiosError,
  callBackAction: () => void,
  abortResponseAction: boolean,
): void {
  if (
    error?.response?.status === 401 &&
    messageType.TOKEN_SIGNATURE_COULD_NOT_BE_VERIFIED &&
    !abortResponseAction
  )
    callBackAction();
}

export const handleUserDisabledError = (
  abortAction: boolean,
  error: AxiosError,
  handleLogOut: (param: boolean) => void,
) => {
  if (
    error?.response?.status === 403 &&
    error?.response?.data?.message === messageType.USER_DISABLED &&
    !abortAction
  )
    handleLogOut(true);
};

export const handlePermissionsError = (
  abortAction: boolean,
  error: AxiosError,
  handleError: () => void,
) => {
  if (
    error?.response?.status === 403 &&
    error?.response?.data?.message === messageType.USER_WITHOUT_PERMISSIONS &&
    !abortAction
  )
    handleError();
};
