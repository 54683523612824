import client from '..';

import { ValidationSettingsDataProps } from './interface';

const environment = process ? process.env : null;

export async function getValidationSettings(): Promise<ValidationSettingsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/settings`,
    {
      params: {
        group: 'validation',
      },
    },
  );

  return data;
}
