export const BRANDS_MODAL_ERRORS = {
  DIRTY_MODAL: 'Are you sure? All progress will be lost.',
  MAX_AMOUNT: 'You’ve reached the max amount allowed',
};

export const BRANDS_MODAL_HEADERS = {
  TOP_BRAND: 'Top Brands',
  ALL_BRAND: 'All Brands',
};

export const BRANDS_MODAL_SKELETON_HEADERS: Record<number, string> = {
  0: BRANDS_MODAL_HEADERS.TOP_BRAND,
  5: BRANDS_MODAL_HEADERS.ALL_BRAND,
};
