import { Dialog, DialogActions, DialogContent } from '@mui/material';

import { AcceptModalProps } from './interface';

import { Button } from '@components/ButtonV2';

export const AcceptModal = ({
  acceptCallback,
  denyCallback,
  open,
  content,
}: AcceptModalProps) => {
  return (
    <Dialog open={open} maxWidth='xs'>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant='text' onClick={denyCallback} color='secondary'>
          No
        </Button>
        <Button variant='text' onClick={acceptCallback}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
