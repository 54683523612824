import { MetaModel } from '@state/interface';
import { Reason } from '@state/storeInfo/interface';

export interface SelectedHoursDataModel {
  day: string;
  from: string;
  to: string;
  active?: boolean;
}
export type CounterStaffDataModel = {
  id?: string;
  name: string;
  email: string | null;
  phone: string | null;
  sms_notification: boolean;
  email_notification: boolean;
  created_at?: string;
};

export interface CounterStaffInfo {
  id: string;
  name: string | null;
  email: string | null;
  phone: string | null;
}

export interface StateCountryDataModel {
  code: string;
  name: string;
}
export interface PostBranchHours {
  hours: SelectedHoursDataModel[];
}

export interface IStoreRating {
  rates: Record<number, number>;
  reviews: number;
  score: number;
}

export interface StoreInfoDataModel {
  id: string | null;
  name: string;
  branch: string | null;
  email: string | null;
  phone: string | null;
  address: string | null;
  address_2: string | null;
  zip_code: string | null;
  city: string | null;
  state: StateCountryDataModel | null;
  country: StateCountryDataModel | null;
  contact_phone: string | null;
  contact_email: string | null;
  contact_secondary_email: string | null;
  open_hours: Array<SelectedHoursDataModel>;
  verified_at: string | null;
  manager_email: string | null;
  manager_name: string | null;
  manager_phone: string | null;
  accountant_name: string | null;
  accountant_email: string | null;
  accountant_phone: string | null;
  counter_staff: CounterStaffDataModel[];
  brands: BrandsDataProps;
  welcome_displayed_at: string | null;
  offers_delivery: boolean | null;
  prokeep_phone: string | null;
  timezone: string;
  image: ImageFile | null;
  logo: ImageFile | null;
  take_rate: string;
  take_rate_until: string | null;
  can_use_curri_delivery?: boolean | false;
  bid_number_required: boolean;
  national?: boolean;
}

export interface ImageFile {
  conversions: Conversions;
  id: string;
  url: string;
}

interface Conversions {
  thumb: string;
}
export interface StoreInfoPayloadModel {
  name: string;
  branch: string;
  email: string;
  phone: string;
  address: string;
  address_2?: string | null;
  image: File | undefined;
  logo: File | undefined;
  zip_code: string;
  city: string;
  state: string;
  country: string;
  contact_phone: string;
  contact_email: string;
  contact_secondary_email?: string | null;
  manager_email: string;
  manager_name: string;
  manager_phone: string;
  accountant_name: string;
  accountant_email: string;
  accountant_phone: string;
  counter_staff: CounterStaffDataModel[];
  offers_delivery: number;
  prokeep_phone?: string | null;
}

export interface LimitedStoreInfoDataModel {
  id: string;
  take_rate: string;
  take_rate_until: string | null;
}

export interface LimitedStoreInfoDataProps {
  data: LimitedStoreInfoDataModel;
}

export interface StoreInfoDataProps {
  data: StoreInfoDataModel;
}
export interface BrandImageDataModel {
  id: string;
  url: string;
}

export interface BrandsDataModel {
  id: string;
  name: string;
  image: BrandImageDataModel;
}

export interface SelectedPartBrandsDataModel {
  id: string;
  name: string;
}

export interface PartBrandsDataModel {
  id: string;
  name: string;
  slug: string;
  top_brand: boolean;
  preferred: boolean;
}

export interface PartBrands {
  data: PartBrandsDataModel[];
  hasNextPage: boolean;
  nextPage: number | null;
}

export interface SelectedPartBrands {
  data: SelectedPartBrandsDataModel[];
  hasNextPage: boolean;
  nextPage: number | null;
}
export interface PartBrandsDataProps {
  data: PartBrandsDataModel[];
  meta: MetaModel;
}

export interface BrandsDataProps {
  data: BrandsDataModel[];
  meta: MetaModel;
}
export interface SelectedPartsBrandsDataProps {
  data: SelectedPartBrandsDataModel[];
  meta: MetaModel;
}

export interface StoreInfoStatesCountries {
  data: StateCountryDataModel[];
}

export interface BranchHoursResponse {
  data: SelectedHoursDataModel[];
}

export interface ReasonsResponse {
  data: Reason[];
  meta: MetaModel;
}

export enum ReasonsSubStatus {
  RETURNED = 'returned-returned',
  DECLINED = 'canceled-declined',
  CANCELED = 'canceled-canceled',
}

export interface IStorePermissionsResponse {
  data: string[];
}
