import { yupResolver } from '@hookform/resolvers/yup';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CancelIcon from '@mui/icons-material/Cancel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
  Box,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import dayjs from 'dayjs';
import { SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { LogsFilterFormData, LogsFilterProps } from './interface';
import LogsDatePickerPopover from './LogsDatePickPopover'; // Asegúrate de importarlo desde la ruta correcta
const LogsFilter = ({
  statusListFilter,
  onSubmitLogFilter,
}: LogsFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const schema = yup.object().shape({
    status: yup.string().required(),
    fromDate: open
      ? yup.date().required('This field is required')
      : yup.date().nullable(),
    toDate: open
      ? yup
          .date()
          .required('This field is required')
          .when(
            'fromDate',
            (fromDate, yup) =>
              fromDate &&
              yup.min(fromDate, "To date can't be before from date"),
          )
      : yup.date().nullable(),
  });

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<LogsFilterFormData>({
    defaultValues: {
      status: 'all',
      fromDate: undefined,
      toDate: undefined,
      calendarApplied: false,
    },
    resolver: yupResolver(schema),
  });

  const handleCalendarClick = (event: {
    currentTarget: SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    if (watch('calendarApplied') || watch('status'))
      handleSubmit(onSubmitLogFilter)();
  }, [watch('calendarApplied'), watch('status')]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSubmitDatePicker = () => {
    trigger(['fromDate', 'toDate']).then((isValid) => {
      if (isValid) {
        handleClose();
        setValue('calendarApplied', true);
      }
    });
  };

  const onCancelCalendar = () => {
    setValue('fromDate', null);
    setValue('toDate', null);
    setValue('calendarApplied', false);
  };

  return (
    <>
      <form>
        <Grid
          container
          alignItems='center'
          spacing={1}
          alignContent={'flex-end'}
          justifyContent={'flex-end'}
        >
          <Grid item xs={2}>
            <Box display='flex' justifyContent='flex-end'>
              <h3>Filter By Status:</h3>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box display='flex' justifyContent='flex-end'>
              <Controller
                name='status'
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id='status-label'>Filter</InputLabel>
                    <Select
                      className='w-full'
                      labelId='status-label'
                      id='status'
                      placeholder=''
                      label='Filter'
                      {...field}
                    >
                      <MenuItem value={'all'}>All</MenuItem>
                      {statusListFilter?.map((status) => (
                        <MenuItem key={'m-' + status.slug} value={status.slug}>
                          {status.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={watch('calendarApplied') ? 4 : 1}>
            <Box display='flex' justifyContent='flex-end'>
              {watch('calendarApplied') ? (
                <Chip
                  className='w-full !rounded-3xl !border-0 !bg-[var(--bluon--light-blue--background)] !pb-7 !pl-2 !pr-2 !pt-7'
                  size='medium'
                  color='primary'
                  icon={<CalendarTodayIcon className='!mr-1' />}
                  label={
                    <div className='!text-sm font-normal'>
                      {dayjs(getValues('fromDate')).format('MM/DD/YYYY')} -{' '}
                      {dayjs(getValues('toDate')).format('MM/DD/YYYY')}
                    </div>
                  }
                  onDelete={onCancelCalendar}
                  variant='outlined'
                  deleteIcon={<CancelIcon />}
                />
              ) : (
                <IconButton onClick={handleCalendarClick}>
                  <DateRangeIcon className='text-blue-500' />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
      <LogsDatePickerPopover
        errors={errors}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        control={control}
        watch={watch}
        onSubmit={onSubmitDatePicker}
      />
    </>
  );
};

export default LogsFilter;
