import { FormHelperText, Skeleton, TextField } from '@mui/material';

import { IInputComponentProps } from './types';

import { appendStrongMarkupToString } from '@utils/Strings/strongMarkup';

import './styles.scss';

const InputComponent = ({
  helperText,
  keepHelperTextSpace = false,
  isLoading,
  ...props
}: IInputComponentProps) => {
  const { error } = props;

  return (
    <>
      {isLoading ? (
        <Skeleton
          role='progressbar'
          className='input-skeleton'
          animation='wave'
        />
      ) : (
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          {...props}
          helperText=''
        />
      )}

      {keepHelperTextSpace && (
        <FormHelperText error={error}>
          {appendStrongMarkupToString(helperText || '')}
        </FormHelperText>
      )}
    </>
  );
};

export default InputComponent;
