import { Badge, Tab } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import './styles.scss';
import { ITabTemplate } from './interface';

const NavMenuItem = ({
  icon,
  label,
  tabPath,
  badgeValue = 0,
  onClick,
  isActive,
  ...rest
}: ITabTemplate) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) onClick(e);
    navigate(tabPath);
  };

  return (
    <Tab
      onClick={handleClick}
      icon={
        <Badge badgeContent={badgeValue} className='bluon-badge'>
          {icon}
        </Badge>
      }
      label={label}
      className={`custom-tab ${isActive ? 'Mui-selected' : ''}`}
      {...rest}
    />
  );
};

export default NavMenuItem;
