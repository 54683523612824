import dayjs from 'dayjs';
import { off, onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';

import { database, rolePermissionsUpdatesNode } from '@config/firebase';

const useRealTimeRolePrivilegesUpdates = (storeId: string) => {
  const [lastPermissionUpdate, setLastPermissionUpdate] = useState<string>('');

  useEffect(() => {
    if (!storeId) return setLastPermissionUpdate('');
    const url = `${rolePermissionsUpdatesNode}/${storeId}`;
    const permissionsRef = ref(database, url);

    onValue(permissionsRef, (snapshot) => {
      if (snapshot.val())
        setLastPermissionUpdate(dayjs(snapshot?.val()).toISOString());
    });

    return () => {
      setLastPermissionUpdate('');
      off(permissionsRef);
    };
  }, [storeId]);

  return { lastPermissionUpdate };
};

export default useRealTimeRolePrivilegesUpdates;
