import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

import { BRANDS_MODAL_SKELETON_HEADERS } from '../../constants';

import { BrandsListSkeletonProps, SkeletonItemProps } from './interface';
import './styles.scss';

export const BrandsListSkeleton = ({
  listHeaders,
}: BrandsListSkeletonProps) => {
  const items = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  return (
    <Box className='bl-brands-skeleton-list'>
      {items.map((i) => (
        <SkeletonItem key={i} index={i} listHeaders={listHeaders} />
      ))}
    </Box>
  );
};

const SkeletonItem = ({ index, listHeaders }: SkeletonItemProps) => {
  const header = listHeaders ? BRANDS_MODAL_SKELETON_HEADERS[index] : '';

  return (
    <>
      {header && (
        <Typography variant='subtitle2' className='list-header'>
          {header}
        </Typography>
      )}
      <Box className='row-skeleton'>
        <Skeleton width={120} height={29} />
        <Skeleton width={18} height={29} />
      </Box>
    </>
  );
};
