import { CustomerTableColumn } from '../components/CustomerTable/interface';

export const columnIDs = {
  agent: 'agent',
  name: 'name',
  company: 'company',
  zip: 'zip',
  customer_tier_component: 'customer_tier_component',
  cash_buyer_display_value: 'cash_buyer_display_value',
  date: 'date',
  action: 'action',
};

export const unconfirmedColumns: CustomerTableColumn[] = [
  {
    id: 'agent',
    label: 'Agent',
    align: 'left',
    sx: {},
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    sx: {},
  },
  {
    id: 'company_display_value',
    label: 'Company Name',
    align: 'left',
    sx: {},
  },
  {
    id: 'zip',
    label: 'Zip Code',
    align: 'left',
    sx: {},
  },
  {
    id: 'customer_tier_component',
    label: 'Customer Tag',
    align: 'left',
    sx: { visibility: 'hidden' },
  },
  {
    id: 'cash_buyer_display_value',
    label: 'Cash Buyer',
    align: 'left',
    sx: { visibility: 'hidden' },
  },
  {
    id: 'date',
    label: 'Date Added',
    align: 'left',
    sx: {},
  },
  {
    id: 'action',
    label: 'Action',
    align: 'left',
    sx: {},
  },
];

export const confirmedColumns: CustomerTableColumn[] = [
  {
    id: 'agent',
    label: 'Agent',
    align: 'left',
    sx: {},
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    sx: {},
  },
  {
    id: 'company_display_value',
    label: 'Company Name',
    align: 'left',
    sx: {},
  },
  {
    id: 'zip',
    label: 'Zip Code',
    align: 'left',
    sx: {},
  },
  {
    id: 'cash_buyer_display_value',
    label: 'Type of Customer',
    align: 'left',
    sx: {},
  },
  {
    id: 'customer_tier_component',
    label: 'Custom Tag',
    align: 'left',
    sx: {},
  },

  {
    id: 'date',
    label: 'Date Added',
    align: 'left',
    sx: {},
  },
  {
    id: 'action',
    label: 'Action',
    align: 'left',
    sx: {},
  },
];

export const removedColumns: CustomerTableColumn[] = [
  {
    id: 'agent',
    label: 'Agent',
    align: 'left',
    sx: {},
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    sx: {},
  },
  {
    id: 'company',
    label: 'Company Name',
    align: 'left',
    sx: {},
  },
  {
    id: 'zip',
    label: 'Zip Code',
    align: 'left',
    sx: {},
  },
  {
    id: 'customer_tier_component',
    label: 'Customer Tag',
    align: 'left',
    sx: { visibility: 'hidden' },
  },
  {
    id: 'cash_buyer_display_value',
    label: 'Cash Buyer',
    align: 'left',
    sx: { visibility: 'hidden' },
  },
  {
    id: 'date',
    label: 'Date Added',
    align: 'left',
    sx: {},
  },
  {
    id: 'action',
    label: 'Action',
    align: 'left',
    sx: {},
  },
];
