// import mobileClient from '../mobileClient';

// import { IUsersCountResponse } from './interface';

export const getUsersCount = async () => {
  /* const response = await mobileClient.get<IUsersCountResponse>(
    '/v3/users/count',
  );*/
  const response = {
    data: {
      data: {
        users_count: '175k',
      },
    },
  };

  return response?.data;
};
