import useUserPermissions from './useUserPermissions';

import { EUserTypes } from '@config/api/user/interface';

const useUserType = () => {
  const { isSupplier } = useUserPermissions();

  return {
    user_type: !isSupplier ? EUserTypes.SEARCH_USER : EUserTypes.REGULAR_USER,
  };
};

export default useUserType;
