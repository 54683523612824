import { CircularProgress } from '@mui/material';

import { Button } from '@components/ButtonV2';
import { ShellModal } from '@components/ShellModal';
import { CustomItem } from '@config/api/task/interface';

export const DeleteItemModal = ({
  item,
  open,
  onClose,
  handleDeleteItem,
  isLoading,
}: {
  item: CustomItem;
  open: boolean;
  onClose: () => void;
  handleDeleteItem: () => void;
  isLoading: boolean;
}) => (
  <ShellModal
    title='Remove Custom Item?'
    subtitle={`Are you sure you want to delete ${
      item.item.info.name || ''
    } from this
      request?`}
    open={open}
    onClose={onClose}
    bottomButtons={[
      <Button key={1} onClick={onClose} variant='outlined'>
        Keep Item
      </Button>,
      <Button
        key={2}
        onClick={handleDeleteItem}
        variant='contained'
        disabled={isLoading}
        sx={{
          backgroundColor: '#D32F2F',
          ':hover': {
            bgcolor: '#D32F2F',
            color: 'white',
          },
        }}
      >
        <>
          Delete Custom Item
          {isLoading && (
            <CircularProgress
              size='small'
              className='ml-1.5 w-[1.5rem] !text-white'
            />
          )}
        </>
      </Button>,
    ]}
  />
);
