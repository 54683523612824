import client from '..';

import { ApproveResponse, ItemsResponse, OrderResponse } from './interface';

const environment = process ? process.env : null;

export async function getOrder(params: {
  orderId: string;
}): Promise<OrderResponse> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/unauthenticated/orders/${params.orderId}`,
  );

  return data;
}

export async function getItems(params: {
  orderId: string;
  page: number;
}): Promise<ItemsResponse> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/unauthenticated/orders/${params.orderId}/items`,
    {
      params: { page: params.page },
    },
  );

  return data;
}

export async function confirmSupplierUser(params: {
  user: string;
  account_number: string;
  customer_tier: string;
  have_account_number: boolean;
}): Promise<ItemsResponse> {
  const { account_number, customer_tier, user, have_account_number } = params;

  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/national/confirmed-users/${user}`,
    {
      customer_tier,
      account_number,
      have_account_number,
    },
  );

  return { ...data, id: user };
}

export async function updateSupplierUser(params: {
  user: string;
  account_number: string;
  customer_tier: string;
  have_account_number: boolean;
}): Promise<ItemsResponse> {
  const { account_number, customer_tier, user, have_account_number } = params;

  const { data } = await client.patch(
    `/${environment?.REACT_APP_API_VERSION}/national/confirmed-users/${user}`,
    {
      customer_tier,
      account_number,
      have_account_number,
    },
  );

  return { ...data, id: user };
}

export async function approveOrderSummary(params: {
  orderId: string;
  name: string;
}): Promise<ApproveResponse> {
  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/unauthenticated/orders/${params.orderId}/approve`,
    {
      name: params.name,
    },
  );

  return data;
}
