import { Components } from '@mui/material';

export const MuiAvatarTheme: Components['MuiAvatar'] = {
  styleOverrides: {
    root: {
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '1.125rem',
    },
  },
};
