import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import './styles.scss';

import EnhancedTable from './components/SupplierTable';
import { generateSkeletonRows } from './components/SupplierTable/utils/skeleton';

import { useSnackbar } from '@components/Snackbar/hooks';
import useRealTimeDistributorUpdate from '@hooks/fireBase/useRealTimeDistributorUpdate';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { clearImpersonatedSupplier } from '@state/auth/actions';
import { getLoginInfoData } from '@state/auth/selectors';
import { clearChatState } from '@state/chat/actions';
import {
  clearSupplierList,
  getSupplierList as getSupplierListAction,
} from '@state/manager/actions';
import { Supplier } from '@state/manager/interface';
import { getSupplierList } from '@state/manager/selectors';
import { isRequestRunning } from '@state/requests/selectors';
import { getLoginInfo, setAuthToken } from '@utils/Auth/AuthUtils';

export const StoreOverview = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const [searchCriteria, setSearchCriteria] = useState<string>('');
  const searchCriteriaRef = useRef<string>();
  searchCriteriaRef.current = searchCriteria;

  const { suppliers, suppliersAreLoading, distributorInfo } = useAppSelector(
    (state) => ({
      suppliers: getSupplierList(state),
      suppliersAreLoading: isRequestRunning(
        state,
        String(getSupplierListAction),
      ),
      distributorInfo: getLoginInfoData(state),
    }),
  );

  useEffect(() => {
    const currentAuthData = getLoginInfo();
    if (currentAuthData.impersonatedSupplier)
      setAuthToken({ ...currentAuthData, impersonatedSupplier: undefined });
  }, []);

  useEffect(() => {
    dispatch(clearImpersonatedSupplier());
    dispatch(clearChatState());
  }, [dispatch]);

  const errorCallback = () => {
    showMessage('Oops, something wrong happened!', {
      severity: 'error',
      autoHide: true,
      subMessage: 'Please try again.',
    });
  };

  const filteredRowsBySearchCriteria = useMemo(() => {
    if (searchCriteria.length < 2) return suppliers;

    return suppliers?.filter((sup: Supplier) => {
      const { name, branch, address, address_2 } = sup;
      const searchText = searchCriteria.toLowerCase();

      return (
        name.toLowerCase().includes(searchText) ||
        String(branch).toLowerCase().includes(searchText) ||
        address?.toLowerCase().includes(searchText) ||
        address_2?.toLowerCase().includes(searchText)
      );
    });
  }, [searchCriteria, suppliers]);

  const fetchSuppliers = () => {
    dispatch(
      getSupplierListAction({
        searchCriteria: searchCriteriaRef.current,
        errorCallback,
      }),
    );
  };

  useRealTimeDistributorUpdate(distributorInfo?.id, fetchSuppliers);

  useEffect(() => {
    //  Clearing table state on unrender to avoid UI issues
    return () => {
      dispatch(clearSupplierList());
    };
  }, []);

  const skeletonRows = useMemo(
    () => generateSkeletonRows(),
    [generateSkeletonRows, searchCriteria],
  );

  const data = suppliersAreLoading
    ? skeletonRows
    : searchCriteria.length
    ? filteredRowsBySearchCriteria
    : suppliers;

  return (
    <Container
      maxWidth={false}
      className='manager-dashboard-container flex bg-[var(--bluon--ds--lightgray)]'
    >
      <Box className='align-center mb-6 mt-8 flex justify-center'>
        <Box className='text-center font-normal'>
          <Typography className='title'>Store Overview Manager</Typography>
        </Box>
      </Box>
      <EnhancedTable
        data={data}
        isLoading={suppliersAreLoading}
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
      />
    </Container>
  );
};
