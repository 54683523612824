import { Badge } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { usePubNub } from 'pubnub-react';
import { useDispatch } from 'react-redux';

import { CustomChannelProps } from './interface';

import { saveLastReadTimeStamp } from '@components/NewChat/pubnub';
import useGoogleTagManager from '@hooks/useGoogleTagManager';
import { setCurrentChatSelected } from '@state/chat/actions';
import { assembleChatGTMEvent } from '@utils/GoogleTagManager';
import { GOOGLE_TAG_MANAGER_EVENT_MESSAGES } from '@utils/GoogleTagManager/interface';

dayjs.extend(utc);
dayjs.extend(tz);

const CustomChatItem = ({
  channel,
  currentChatSelected,
  unreadChatMessages,
}: CustomChannelProps): JSX.Element => {
  const dispatch = useDispatch();
  const pubnub = usePubNub();
  const { setGTMEvent } = useGoogleTagManager();
  const channelId = channel.chat.channel;
  const isChatActive = currentChatSelected?.chat?.channel === channelId;

  const isContainUnreadMessage = unreadChatMessages[channelId];
  const lastMessageAt = channel.chat.last_message_at
    ? dayjs.utc(channel.chat.last_message_at).local().format('MM/DD/YY hh:mmA')
    : null;

  const onChatItemClick = () => {
    if (currentChatSelected) saveLastReadTimeStamp(pubnub, currentChatSelected);
    saveLastReadTimeStamp(pubnub, channel);
    dispatch(setCurrentChatSelected(channel));
    setGTMEvent(
      assembleChatGTMEvent(
        `${GOOGLE_TAG_MANAGER_EVENT_MESSAGES.CLICKED_ON_CHAT_CHANNEL}${channel.name}`,
      ),
    );
  };

  return (
    <div
      key={channel.id}
      className={clsx(
        {
          ['bg-primary hover:bg-blue-500']: isChatActive,
          ['bg-white hover:bg-gray-100']: !isChatActive,
        },
        `flex h-20 cursor-pointer  items-center justify-start 
         border border-l-0 border-t-0 border-gray-200`,
      )}
      onClick={onChatItemClick}
    >
      <div className='flex basis-7 flex-col items-center justify-center gap-y-3'>
        {isContainUnreadMessage > 0 && (
          <Badge
            sx={{
              '& .MuiBadge-badge': {
                color: 'lightgreen',
                backgroundColor: 'var(--bluon--brand--blue)',
              },
            }}
            variant='dot'
          ></Badge>
        )}
      </div>

      <div className='flex-1 text-black'>
        <div
          className={clsx(
            {
              ['text-white']: isChatActive,
            },
            'mb-1 flex flex-row text-sm leading-4',
          )}
        >
          <span className='basis-1/2 text-left'>{channel.name}</span>
          <span className='mr-3.5 basis-1/2 whitespace-nowrap text-right text-tiny'>
            {lastMessageAt}
          </span>
        </div>
        <div
          className={clsx(
            {
              ['text-white']: isChatActive,
              ['text-bluon_navy']: !isChatActive,
            },
            'mb-1 flex text-xs font-normal leading-4 ',
          )}
        >
          {channel?.company}
        </div>
      </div>
    </div>
  );
};

export default CustomChatItem;
