import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from '@components/Modal';
import ChatComponent from '@components/NewChat';
import { useSnackbar } from '@components/Snackbar/hooks';
import { Can } from '@config/canAbility';
import { EAbilityActions, EAbilitySubjects } from '@config/canAbility/types';
import { useAppSelector } from '@hooks/state';
import { useQueryParams } from '@hooks/useQueryParams';
import { OrderDetail } from '@pages/Tasks/components/OrderDetail';
import {
  getIsImpersonatedSupplier,
  getIsManager,
  safelyGetInitialPass,
} from '@state/auth/selectors';
import { setOrderDetailsRequest, setSelectedOrder } from '@state/task/actions';
import { getCurrentSelectedOrderId } from '@state/task/selectors';

export const GlobalComponents = () => {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const [queryParams, , clearQueryParams] = useQueryParams<{
    orderId: string | undefined;
  }>();

  const { selectedOrder, isManager, isImpersonatedSupplier, hasSetPassword } =
    useAppSelector((state) => ({
      selectedOrder: getCurrentSelectedOrderId(state),
      isManager: getIsManager(state),
      isImpersonatedSupplier: getIsImpersonatedSupplier(),
      hasSetPassword: safelyGetInitialPass(state),
    }));

  const handleClose = () => {
    clearQueryParams(false);
  };

  const onOrderDetailError = () => {
    clearQueryParams(true);
    showMessage('Error getting order', { severity: 'error' });
  };

  //  Watching query params changes
  useEffect(() => {
    if (!queryParams?.orderId) {
      dispatch(setSelectedOrder(undefined));

      return;
    }

    dispatch(
      setOrderDetailsRequest({
        id: queryParams?.orderId,
        errorCallback: onOrderDetailError,
      }),
    );
  }, [queryParams?.orderId]);

  return (
    <>
      <Modal
        open={!!queryParams?.orderId && !!selectedOrder}
        handleClose={handleClose}
        position='right'
        isImpersonatedSupplier={isImpersonatedSupplier}
      >
        <OrderDetail />
      </Modal>
      {(!isManager || isImpersonatedSupplier) && !!hasSetPassword && (
        <Can
          I={EAbilityActions.MANAGE}
          a={EAbilitySubjects.TASKS_AND_CUSTOMERS}
        >
          {() => <ChatComponent />}
        </Can>
      )}
    </>
  );
};
