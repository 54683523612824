import { Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReplacementSettingsButtonProps } from './interface';

import { Button } from '@components/ButtonV2';
import { useAppSelector } from '@hooks/state';
import { BrandModal } from '@pages/Account/subpages/StoreInfo/components/BrandModal';
import { PartBrandsModalActions } from '@pages/Account/subpages/StoreInfo/components/BrandModal/components/PartBrandsModalActions';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import {
  getMorePartBrandsRequest,
  getPartBrandsRequest,
  getSelectedPartBrandsRequest,
} from '@state/storeInfo/actions';
import {
  getSelectedStatePartBrands,
  getStoreInfoPartBrands,
  getStoreInfoPartBrandsNextPage,
} from '@state/storeInfo/selectors';

export const ReplacementSettingsButton = (
  props: ReplacementSettingsButtonProps,
) => {
  const dispatch = useDispatch();
  const { onSuccessCallback } = props;
  const [showPartBrandsModal, setShowPartBrandsModal] =
    useState<boolean>(false);

  const {
    nextPage,
    partBrands,
    requestFailed,
    isLoadingGetPartBrands,
    isLoadingGetMorePartBrands,
    initialSelectedBrands,
  } = useAppSelector((state) => ({
    partBrands: getStoreInfoPartBrands(state),
    nextPage: getStoreInfoPartBrandsNextPage(state),
    requestFailed: getRequestError(state, String(getPartBrandsRequest)),
    initialSelectedBrands: getSelectedStatePartBrands(state),
    isLoadingGetMorePartBrands: isRequestRunning(
      state,
      String(getMorePartBrandsRequest),
    ),
    isLoadingGetPartBrands: isRequestRunning(
      state,
      String(getPartBrandsRequest),
    ),
  }));

  useEffect(() => {
    if (!showPartBrandsModal) return;

    dispatch(getSelectedPartBrandsRequest());
    dispatch(getPartBrandsRequest());
  }, [showPartBrandsModal, dispatch]);

  const onLoadMore = (search: string) => {
    if (nextPage)
      dispatch(
        getMorePartBrandsRequest({
          search,
          page: nextPage,
        }),
      );
  };

  const initialChips = useMemo(
    () =>
      initialSelectedBrands?.map((item) => ({
        id: item.id,
        label: item.name,
      })) ?? [],
    [initialSelectedBrands],
  );

  const onSearch = (search: string) => {
    dispatch(getPartBrandsRequest({ search }));
  };

  const onCloseModal = (runCallback?: boolean) => {
    if (runCallback && onSuccessCallback) onSuccessCallback();
    setShowPartBrandsModal(false);
  };

  const onTryAgainAction = () => {
    dispatch(getPartBrandsRequest());
  };

  return (
    <>
      {showPartBrandsModal && partBrands && (
        <BrandModal
          title='Choose brands to prioritize'
          brandsList={partBrands}
          initialBrands={initialChips}
          internalSearch={false}
          isOpen={showPartBrandsModal}
          hasNextPage={!!nextPage}
          isLoading={isLoadingGetPartBrands}
          isLoadingMore={isLoadingGetMorePartBrands}
          maxSelectedBrands={50}
          onClose={onCloseModal}
          onSearch={onSearch}
          onLoadMore={onLoadMore}
          ModalActions={PartBrandsModalActions}
          requestError={requestFailed}
          tryAgainAction={onTryAgainAction}
          tooltipTitle='Select which part brands you carry.  These will be sorted to the top of the Known Replacement lists'
          showListHeaders
          canSendEmpty
        />
      )}
      <Tooltip
        title='Configure the replacement brands you carry'
        placement='top'
        arrow
      >
        <span>
          <Button
            variant='text'
            onClick={() => setShowPartBrandsModal(true)}
            {...props}
          />
        </span>
      </Tooltip>
    </>
  );
};
