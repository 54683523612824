import { StoreInfoFormKeys } from './interfaces';

import { StoreInfoDataModel } from '@config/api/storeInfo/interface';

export const getInitialCounterStaffValues = (
  data: StoreInfoDataModel | undefined,
) => {
  //  Case in which a counter staff exists
  if (data?.counter_staff?.length)
    return data.counter_staff.map((cs) => ({
      uuid: cs.id || undefined,
      name: cs.name || '',
      email: cs.email || '',
      phone: cs.phone || '',
      sms_notification: cs.sms_notification || false,
      email_notification: cs.email_notification || false,
    }));

  return [
    {
      name: '',
      email: '',
      phone: '',
      sms_notification: false,
      email_notification: false,
    },
  ];
};

export const getCounterStaffToSubmit = (values: StoreInfoFormKeys) => {
  return {
    ...values,
    counter_staff: values.counter_staff.map((item) => ({
      ...item,
      sms_notification: item.sms_notification ? '1' : '0',
      email_notification: item.email_notification ? '1' : '0',
    })),
  };
};
