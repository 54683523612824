import { Box } from '@mui/material';
import React from 'react';

import { UnconfirmedCustomerActionsProps } from './interface';

import './styles.scss';
import { Button } from '@components/ButtonV2';

export const UnconfirmedCustomerActions = ({
  leaveForNowAction,
  handleSendAction,
  isSendButtonDisabled,
  isSendButtonLoading,
}: UnconfirmedCustomerActionsProps) => {
  return (
    <Box className='bl-unconfirmed-customer-actions'>
      <Button variant='outlined' color='primary' onClick={leaveForNowAction}>
        Leave for now
      </Button>
      <Button
        variant='contained'
        color='primary'
        disabled={isSendButtonDisabled || isSendButtonLoading}
        onClick={handleSendAction}
        loading={isSendButtonLoading}
      >
        Save & Confirm
      </Button>
    </Box>
  );
};
