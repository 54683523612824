import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { forwardRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';

import '../../styles.scss';

import EmptyState from './EmptyState';
import { Order, SupplierTableProps } from './interface';
import { EnhancedTableHead } from './TableHead';
import SupplierTableRow from './TableRow';
import { EnhancedTableToolbar } from './Toolbar';
import { getComparator, stableSort } from './utils/sort';

import { useSnackbar } from '@components/Snackbar/hooks';
import { impersonateSupplierRequest } from '@state/manager/actions';
import { SortableSupplier, Supplier } from '@state/manager/interface';

const CustomScroller = forwardRef<HTMLDivElement>((props, ref) => (
  <TableContainer component={Paper} {...props} ref={ref} />
));
CustomScroller.displayName = 'CustomScroller';

const VirtuosoTableComponents: TableComponents<Supplier> = {
  Scroller: CustomScroller,
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
    />
  ),
  TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow: (props) => <TableRow hover {...props} />,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const SupplierTable = ({
  data,
  isLoading,
  searchCriteria,
  setSearchCriteria,
}: SupplierTableProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof SortableSupplier>('pending_tasks');
  const showEmptyState =
    !isLoading && data?.length === 0 && searchCriteria.length > 1;

  const visibleRows = useMemo(
    () =>
      stableSort<Supplier>(
        data || [],
        getComparator<keyof SortableSupplier>(order, orderBy),
      ),
    [data, order, orderBy],
  );

  const errorCallback = () => {
    showMessage('Oops! something went wrong.', {
      severity: 'error',
      autoHide: true,
      subMessage: 'Please try again.',
    });
  };

  const handleImpersonateNavigation = (route: string) => {
    navigate(route);
  };

  const handleClick = (row: Supplier) => {
    const { id } = row;
    dispatch(
      impersonateSupplierRequest({
        supplierId: id,
        errorCallback,
        successCallback: handleImpersonateNavigation,
      }),
    );
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortableSupplier,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fixedHeaderContent = () => {
    return (
      <EnhancedTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
    );
  };

  const rowContent = (_index: number, row: Supplier) => {
    return (
      <SupplierTableRow
        row={row}
        handleRowClick={handleClick}
        isLoading={isLoading}
      />
    );
  };

  return (
    <Paper className='supplier-table-container flex flex-col'>
      <EnhancedTableToolbar
        searchCriteria={searchCriteria}
        handleSearchChange={setSearchCriteria}
      />
      {!showEmptyState && (
        <TableVirtuoso
          data={visibleRows}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      )}
      {showEmptyState && <EmptyState searchCriteria={searchCriteria} />}
    </Paper>
  );
};

export default SupplierTable;
