import { useMemo } from 'react';

import { useAppSelector } from './state';

import { ExtraItem, OrderItem } from '@config/api/task/interface';
import {
  getShareableOrderAddedItems,
  getShareableOrderCustomItems,
  getShareableOrderExtraItems,
  getShareableOrderParts,
} from '@state/shareableOrder/selectors';
import {
  getOrderCustomItems,
  getOrderItems,
  getOrderSupplyItems,
} from '@state/task/selectors';
import { EItemStatus } from '@utils/enums';

const useIsAllUnavailableItemsCheck = (shareableOrder?: boolean) => {
  const {
    customItems,
    functionalPartsItems,
    truckStockItems,
    // Shareable Order keys
    shareableOrderExtraItems,
    shareableOrderParts,
    shareableOrderAddedItems,
    shareableOrderCustomItems,
  } = useAppSelector((state) => ({
    customItems: getOrderCustomItems(state),
    functionalPartsItems: getOrderItems(state),
    truckStockItems: getOrderSupplyItems(state),
    // Shareable Order Selectors
    shareableOrderExtraItems: getShareableOrderExtraItems(state),
    shareableOrderParts: getShareableOrderParts(state),
    shareableOrderAddedItems: getShareableOrderAddedItems(state),
    shareableOrderCustomItems: getShareableOrderCustomItems(state),
  }));

  const shareableOrderUnavailableItems =
    shareableOrderExtraItems.every(
      (item) => item.status === EItemStatus.NotAvailable,
    ) &&
    shareableOrderParts.every(
      (item) => item.status === EItemStatus.NotAvailable,
    ) &&
    shareableOrderAddedItems.every(
      (item) => item.status === EItemStatus.NotAvailable,
    ) &&
    !shareableOrderCustomItems.length;

  const isAllUnavailable =
    truckStockItems.every((item) => item.status === EItemStatus.NotAvailable) &&
    functionalPartsItems.every(
      (item) => item.status === EItemStatus.NotAvailable,
    ) &&
    !customItems.length;

  const allTruckStockUnavailable = useMemo(
    () =>
      truckStockItems.every((item) => item.status === EItemStatus.NotAvailable),
    [truckStockItems],
  );

  const allFunctionalParts = useMemo(
    () =>
      functionalPartsItems.every(
        (item) => item.status === EItemStatus.NotAvailable,
      ),
    [functionalPartsItems],
  );

  const isAllUnavailableButCustomItems =
    truckStockItems.every((item) => item.status === EItemStatus.NotAvailable) &&
    functionalPartsItems.every(
      (item) => item.status === EItemStatus.NotAvailable,
    );

  return {
    isAllUnavailable: shareableOrder
      ? shareableOrderUnavailableItems
      : isAllUnavailable,
    isAllUnavailableButCustomItems,
    allTruckStockUnavailable,
    allFunctionalParts,
  };
};

export default useIsAllUnavailableItemsCheck;

export const checkArraysItemsUnavailable = (
  truckStockItems: ExtraItem[],
  functionalPartItems: OrderItem[],
  orderCustomItemsLength: number,
) =>
  truckStockItems.every((item) => item.status === EItemStatus.NotAvailable) &&
  functionalPartItems.every(
    (item) => item.status === EItemStatus.NotAvailable,
  ) &&
  !orderCustomItemsLength;
