import client from '..';

import {
  ChannelUsers,
  OrderDetailRequest,
  PubnubSendMessageAPI,
  User,
} from './interface';

const environment = process ? process.env : null;

export async function getUsersRequest(requestData?: {
  page: number;
}): Promise<ChannelUsers> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/supplier/users`,
    {
      params: { page: requestData ? requestData.page : '' },
    },
  );

  return data;
}

export async function getChannelInfo(channel: string): Promise<User> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/supplier/users/${channel}`,
  );

  return data;
}

export async function getUserOrdersRequest(
  user: string,
): Promise<OrderDetailRequest> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/users/${user}/orders`,
    {},
  );

  return data;
}

export async function sendMessageRequest(
  payload: PubnubSendMessageAPI,
): Promise<any> {
  const { userId, message } = payload;

  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/users/${userId}/new-message`,
    {
      message,
    },
  );

  return data;
}
