import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import './styles.scss';
import { PageMessage } from '../../../../../../components/PageMessage';
import { formatDate } from '../../../../../../constants';
import { ActionButtonProps, CustomerTableDataModel } from '../../interface';

import { CustomerTableProps, Order } from './interface';

import CustomerIconDark from '@assets/icons/InboundIconDark.svg';

export const CustomerTable = ({
  'data-testid': dataTestId,
  customerList,
  columns,
  tableContainerSx,
  PrimaryActionButton,
  SecondaryActionButton,
  isNationalSupplier,
}: CustomerTableProps): JSX.Element => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [isEmptyList, setIsEmptyList] = useState(false);
  const [customers, setCustomers] = useState<CustomerTableDataModel[]>(
    customerList as CustomerTableDataModel[],
  );

  const rowsPerPage = 10;
  useEffect(() => {
    !customerList || customerList.length <= 0
      ? setIsEmptyList(true)
      : setIsEmptyList(false);

    modifyCustomersToFitTable(rowsPerPage);
  }, [customerList]);

  const tempCustomerList = _.cloneDeep(customerList);
  const modifyCustomersToFitTable = (end: number) => {
    setCustomers(
      tempCustomerList
        .map((customer, index): CustomerTableDataModel => {
          const {
            id,
            photo,
            cash_buyer,
            created_at,
            customer_tier,
            company,
            account_number,
          } = customer;
          const userId = id.toString();
          const url = photo?.url || '';
          const modifiedCustomer: ActionButtonProps = {
            ...customer,
            userId,
            imgSrc: url,
            isNationalSupplier,
          };

          const primaryActionButton = (
            <div key={index} className='bl-customer-table-primary-action-modal'>
              <PrimaryActionButton {...modifiedCustomer} name={customer.name} />
            </div>
          );
          const secondaryActionButton = SecondaryActionButton && (
            <div key={id} className='bl-customer-table-secondary-action-modal'>
              <SecondaryActionButton
                {...modifiedCustomer}
                customer={customer}
                isNationalSupplier={isNationalSupplier}
              />
            </div>
          );

          customer.action = [primaryActionButton, secondaryActionButton];
          customer.agent = <Avatar src={url} />;
          customer.cash_buyer_display_value = cash_buyer
            ? 'Cash Buyer'
            : isNationalSupplier && account_number
            ? `Account #${account_number}`
            : 'Account';
          customer.company_display_value = company?.name;
          customer.zip = company?.zip_code;
          customer.customer_tier_component = customer_tier && (
            <Typography
              variant='subtitle2'
              className='bl-customer-table-customer-tier'
            >
              {customer_tier.toUpperCase()}
            </Typography>
          );
          customer.date = formatDate(created_at, true);

          return customer as CustomerTableDataModel;
        })
        .slice(0, end),
    );
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getValue = (
    item: CustomerTableDataModel[keyof CustomerTableDataModel],
  ) => {
    return orderBy === 'date' ? new Date(item).getTime() : item;
  };

  const descendingCompare = (
    a: CustomerTableDataModel,
    b: CustomerTableDataModel,
    orderBy: string,
  ): number => {
    const first = getValue(a[orderBy as keyof CustomerTableDataModel]);
    const second = getValue(b[orderBy as keyof CustomerTableDataModel]);

    return first < second ? -1 : first > second ? 1 : 0;
  };

  const getCompareFunction = (order: Order, orderBy: string) => {
    return order === 'asc'
      ? (a: CustomerTableDataModel, b: CustomerTableDataModel) =>
          descendingCompare(a, b, orderBy)
      : (a: CustomerTableDataModel, b: CustomerTableDataModel) =>
          -descendingCompare(a, b, orderBy);
  };

  const renderTableHeaders = () => {
    return (
      <TableRow>
        {columns.map(({ id, label, align, sx }) => {
          return (
            <TableCell
              key={id}
              align={align}
              sortDirection={orderBy === id ? order : false}
            >
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : 'asc'}
                onClick={() => {
                  handleRequestSort(id);
                }}
                sx={sx}
              >
                <Typography
                  className='bl-customer-table-column-label'
                  variant='body2'
                  style={sx}
                >
                  {label}
                </Typography>
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const renderTableRows = () =>
    customers
      .sort(getCompareFunction(order, orderBy))
      .map((customer, index) => {
        return (
          <TableRow key={index}>
            {columns.map(({ align, sx, id: columnId }, index) => {
              const value = customer[columnId as keyof CustomerTableDataModel];

              return (
                <TableCell key={index} align={align}>
                  <div
                    className='bl-customer-table-cell'
                    key={index}
                    style={sx}
                  >
                    {value}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        );
      });

  const renderWaypoint = () => {
    const handleOnEnter = () => {
      const size = customers.length + rowsPerPage;

      modifyCustomersToFitTable(size);
    };

    return (
      <TableRow className='bl-customer-table-waypoint'>
        <TableCell padding='none'>
          <Waypoint onEnter={handleOnEnter} />
        </TableCell>
      </TableRow>
    );
  };

  const renderCustomerColumnList = () => {
    if (!isEmptyList)
      return (
        <Paper elevation={0} className='bl-customer-table-container'>
          <TableContainer
            data-testid={`${dataTestId}-container`}
            sx={tableContainerSx}
          >
            <Table stickyHeader>
              <TableHead>{renderTableHeaders()}</TableHead>
              <TableBody>
                {renderTableRows()}
                {renderWaypoint()}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      );
    else
      return (
        <Paper
          elevation={0}
          className='bl-customer-table-paper-container'
          data-testid={`${dataTestId}-paper`}
        >
          <PageMessage
            icon={<img alt='Inbound' src={CustomerIconDark} />}
            title='No customers yet'
            description='Here you will find a list of the customers that had selected your store as a place to buy.'
          />
        </Paper>
      );
  };

  return <>{renderCustomerColumnList()}</>;
};
