import { Checkbox, ListItemIcon, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { InputHTMLAttributes } from 'react';

import { BrandsListProps } from './interface';

import './styles.scss';

export const BrandsList = (props: BrandsListProps): JSX.Element => {
  const { data, checked, onClickItem, headerText } = props;

  return (
    <>
      {headerText && (
        <Typography variant='subtitle2' className='list-header'>
          {headerText}
        </Typography>
      )}
      <ListItem
        key={data.id}
        className='bl-list-item'
        data-testid='brandsListItem'
        disablePadding
        onClick={() => onClickItem(data)}
        secondaryAction={
          <Checkbox
            edge='end'
            checked={checked}
            inputProps={
              {
                'data-testid': 'brandsListItemCheckbox',
              } as InputHTMLAttributes<HTMLInputElement> | any
            }
          />
        }
      >
        <ListItemButton>
          {data.image && (
            <ListItemIcon>
              <img
                alt='logo'
                className='bl-branch-logo'
                src={data?.image?.url}
              />
            </ListItemIcon>
          )}
          <ListItemText primary={data?.name} />
        </ListItemButton>
      </ListItem>
    </>
  );
};
