export enum EAbilityActions {
  MANAGE = 'manage',
}

export enum EAbilitySubjects {
  SEARCH = 'search',
  TASKS_AND_CUSTOMERS = 'tasks-and-customers',
  ACCOUNT = 'account',
  NOTIFICATIONS = 'notifications',
  HELP_AND_FEEDBACK = 'help-and-feedback',
  FEEDBACK_ONLY = 'feedback-only',
}

export interface IAbility {
  action: EAbilityActions;
  subject: EAbilitySubjects;
  skipCanValidation: boolean;
}
