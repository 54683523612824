import { ECustomerAccount } from '../../interface';

export const RADIO_ACTIONS = {
  HAS_ACCOUNT: {
    value: ECustomerAccount.HAS_ACCOUNT,
    label: 'Yes',
  },
  CASH_BUYER: {
    value: ECustomerAccount.CASH_BUYER,
    label: 'No',
  },
};
