import {
  Interpolation,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
} from '@mui/material';
import { MUIStyledCommonProps } from '@mui/system';

import { tooltipTestId } from './constants';

export const CustomTooltip = ({
  children,
  styles,
  text,
  placement,
  arrow,
  forceOpen,
}: {
  children: JSX.Element;
  styles: React.CSSProperties;
  text: JSX.Element | string;
  placement: TooltipProps['placement'];
  arrow: TooltipProps['arrow'];
  forceOpen?: boolean;
}) => {
  const TooltipContainer = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({ ...styles } as Interpolation<TooltipProps & MUIStyledCommonProps>);

  return (
    <TooltipContainer
      title={
        <Typography
          className='tooltip-text'
          variant='caption'
          textAlign='center'
          data-testid={tooltipTestId}
        >
          {text}
        </Typography>
      }
      placement={placement}
      arrow={arrow}
      open={forceOpen}
    >
      {children}
    </TooltipContainer>
  );
};
