import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import OtherTextArea from '../OtherTextArea';
import RadioGroupReasons from '../RadioGroupReasons';

import { DeclineModalForm, DeclineModalProps } from './interface';
import {
  DeclineModalValidationSchema,
  OTHER_TEXT_MAX_LENGTH_RCM,
  OTHER_TEXT_MIN_LENGTH_RCM,
} from './validations';
import './styles.scss';

import { Button } from '@components/ButtonV2';
import { ClaimingModalContainer } from '@components/ClaimingModal';
import { ClaimingModalAddStaff } from '@components/ClaimingModal/Steps/AddStaff';
import { ShellModal } from '@components/ShellModal';
import { useSnackbar } from '@components/Snackbar/hooks';
import { ReasonsSubStatus } from '@config/api/storeInfo/interface';
import { useAppSelector } from '@hooks/state';
import { TermsOfService } from '@pages/Login/components/TermsOfService';
import { openTermsOfService } from '@state/auth/actions';
import { getShowTermsInfoData } from '@state/auth/selectors';
import {
  ordersLogsRequest,
  returnOrderRequest,
} from '@state/ordersLogs/actions';
import { getLogFilter } from '@state/ordersLogs/selectors';
import { isRequestRunning } from '@state/requests/selectors';
import {
  reasonsRequest,
  returnReasonsResponse,
} from '@state/storeInfo/actions';
import {
  getReturnReasons,
  getStoreInfoCounterStaff,
} from '@state/storeInfo/selectors';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

const ReturnCompleteOrderModal = ({
  isOpen,
  onClose,
  orderId,
}: DeclineModalProps) => {
  const initialValues: DeclineModalForm = {
    selectedOption: '',
    otherText: '',
    acceptTerms: false,
  };

  const dispatch = useDispatch();
  const [isStaffOpen, setIsStaffOpen] = useState<boolean>(false);
  const [selectedStaffId, setSelectedStaffId] = useState<string>('');
  const [addNewCounterStaffMode, setAddNewCounterStaffMode] = useState(false);
  const [lastItemCreated, setLastItemCreated] = useState<string | undefined>(
    undefined,
  );

  const handleChange = (event: SelectChangeEvent<typeof selectedStaffId>) => {
    const newStaff = storeStaff.find((item) => item.id === event.target.value);

    setSelectedStaffId(newStaff?.id as string);
  };

  const { showMessage } = useSnackbar();
  const {
    isApiSubmitRunning,
    storeStaff,
    showTermsAndConditions,
    areReasonsLoading,
    returnReasons,
    filter,
  } = useAppSelector((state) => ({
    filter: getLogFilter(state),
    storeStaff: getStoreInfoCounterStaff(state) || [],
    isApiSubmitRunning: isRequestRunning(state, String(returnOrderRequest)),
    showTermsAndConditions: getShowTermsInfoData(state),
    areReasonsLoading: isRequestRunning(state, String(reasonsRequest)),
    returnReasons: getReturnReasons(state),
  }));

  const {
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<DeclineModalForm>({
    resolver: yupResolver(DeclineModalValidationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const selectedOptionValue = watch('selectedOption');
  const otherOptionTextValue = watch('otherText');
  const acceptTermsValue = watch('acceptTerms');

  const hideCountNumber =
    Number(otherOptionTextValue?.length) > 0 &&
    Number(otherOptionTextValue?.length) < OTHER_TEXT_MIN_LENGTH_RCM;
  const showErrorColor =
    Number(otherOptionTextValue?.length) === OTHER_TEXT_MAX_LENGTH_RCM ||
    hideCountNumber;
  const maxLengthReached =
    Number(otherOptionTextValue?.length) === OTHER_TEXT_MAX_LENGTH_RCM;

  const resetFormValues = useCallback(() => {
    reset(initialValues);
  }, []);

  const handleFormClose = useCallback(() => {
    resetFormValues();
    onClose();
  }, []);

  const orderSuccessCallback = useCallback(() => {
    showMessage('Quote has been returned', {
      severity: 'info',
    });
    handleFormClose();
    dispatch(ordersLogsRequest(filter));
  }, []);

  const orderErrorCallback = useCallback(() => {
    showMessage('Oops! Something happened, please try again later', {
      severity: 'error',
    });
    handleFormClose();
  }, []);
  const handleClose = () => setIsStaffOpen(false);
  const handleOpen = () => setIsStaffOpen(true);

  const selectedOption = useMemo(
    () => returnReasons?.find((reason) => reason.id === selectedOptionValue),
    [selectedOptionValue],
  );

  const isOtherOptionSelected =
    selectedOption?.special && selectedOption?.name === 'Other';

  const handleOrderDecline = useCallback(() => {
    const payload = {
      order_id: orderId,
      staff_id: selectedStaffId,
      reason_id: selectedOptionValue,
      status_detail: capitalizeFirstLetter(
        otherOptionTextValue && otherOptionTextValue.length > 0
          ? otherOptionTextValue.toLowerCase()
          : selectedOptionValue.toLowerCase(),
      ),
      errorCallback: orderErrorCallback,
      successCallback: orderSuccessCallback,
    };
    dispatch(returnOrderRequest(payload));
  }, [selectedOptionValue, selectedStaffId, otherOptionTextValue]);

  const isButtonDisabled =
    selectedOptionValue === '' ||
    selectedStaffId === '' ||
    isApiSubmitRunning ||
    (isOtherOptionSelected && (!!errors.otherText || !otherOptionTextValue)) ||
    !acceptTermsValue;

  const showTerms = () => {
    dispatch(openTermsOfService(true));
  };

  const hideTerms = () => {
    dispatch(openTermsOfService(false));
  };

  const handleCloseOfAddStaffModal = (name?: string | undefined) => {
    setAddNewCounterStaffMode(false);

    if (name) setLastItemCreated(name);
  };

  useEffect(() => {
    if (!storeStaff || !lastItemCreated) return;

    const newStaff = storeStaff.find((item) => item.name === lastItemCreated);

    if (newStaff?.id) {
      setSelectedStaffId(newStaff?.id as string);
      setLastItemCreated(undefined);
      handleClose();
    }
  }, [storeStaff, lastItemCreated]);

  const reasonsErrorCallback = useCallback(() => {
    showMessage('Oops! Something happened, please try again later', {
      severity: 'error',
    });
  }, []);

  useEffect(() => {
    dispatch(
      reasonsRequest({
        status: ReasonsSubStatus.RETURNED,
        errorCallback: reasonsErrorCallback,
        receiveAction: returnReasonsResponse,
      }),
    );
  }, []);

  return (
    <>
      <ClaimingModalContainer
        visible={addNewCounterStaffMode}
        onClose={() => {
          handleCloseOfAddStaffModal();
          handleFormClose();
        }}
      >
        <ClaimingModalAddStaff onCancel={handleCloseOfAddStaffModal} />
      </ClaimingModalContainer>
      <TermsOfService open={showTermsAndConditions} handleClose={hideTerms} />
      <ShellModal
        open={isOpen}
        onClose={handleFormClose}
        title='Fill out form to mark as returned'
        subtitle='This information is shared with your manager as well as the tech.'
        buttonsPosition={'md:justify-center'}
        bottomButtons={[
          <Button key={1} onClick={handleFormClose} variant='outlined'>
            Keep Order
          </Button>,
          <Button
            key={2}
            onClick={handleOrderDecline}
            disabled={isButtonDisabled}
            variant='contained'
          >
            Mark As Returned
          </Button>,
        ]}
      >
        <>
          <div className='mb-5'>
            <FormControl className='staff-wrapper w-full'>
              <InputLabel id='demo-controlled-open-select-label'>
                Who is canceling? (Required)
              </InputLabel>
              <Select
                open={isStaffOpen}
                onClose={handleClose}
                onOpen={handleOpen}
                label='Who is canceling? (Required)'
                value={selectedStaffId}
                onChange={handleChange}
                MenuProps={{
                  sx: {
                    '.MuiList-root': {
                      maxHeight: 238,
                      paddingBottom: 0,
                    },
                  },
                }}
              >
                {storeStaff?.map((item, index) => {
                  return item.name ? (
                    <MenuItem key={`${item?.id} ${index}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ) : null;
                })}
                {storeStaff.length < 20 && (
                  <Box className='sticky bottom-0 flex w-full items-center bg-[var(--bluon--ds--white)] '>
                    <MenuItem
                      className='add-new-counter-button'
                      onClick={() => setAddNewCounterStaffMode(true)}
                    >
                      <AddIcon /> Add New Counter Staff
                    </MenuItem>
                  </Box>
                )}
              </Select>
            </FormControl>
          </div>
          <Typography
            variant='body1'
            className='text-left font-normal text-[var(--bluon--ds--lightblack)]'
          >
            Reason for canceling (Required)
          </Typography>
          <div className='mb-6 flex w-[11rem] pl-4 md:w-[39.5rem]'>
            <RadioGroupReasons
              isLoading={areReasonsLoading}
              reasons={returnReasons}
              formControl={control}
            />
          </div>
          {isOtherOptionSelected && (
            <div className='reason-wrapper mb-6'>
              <OtherTextArea
                formControl={control}
                maxLength={OTHER_TEXT_MAX_LENGTH_RCM}
                maxLengthReached={maxLengthReached}
                showErrorColor={showErrorColor}
                errors={errors}
                hideCountNumber={hideCountNumber}
              />
            </div>
          )}
          <div className='mb-9 w-[11rem] pl-3 md:w-[39.5rem]'>
            <Controller
              name='acceptTerms'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox />}
                  data-testid='acceptWarning'
                  label={
                    <span>
                      WARNING: Canceling an order that happened is a violation
                      of Bluon&apos;s{' '}
                      <Link
                        className='text-[var(--bluon--ds--button--highlight--text)]'
                        color='primary'
                        underline='hover'
                        onClick={showTerms}
                      >
                        Terms of Service
                      </Link>
                      , unless the order was not fulfilled or was returned.
                    </span>
                  }
                  checked={field.value}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
            <Typography
              variant='body1'
              className='px-6 pt-6 text-left font-normal text-[var(--bluon--ds--lightblack-primary)]'
            >
              By checking the box, I understand the customer will lose their
              Bluon Points within the mobile application by marking this order
              as returned.
            </Typography>
          </div>
        </>
      </ShellModal>
    </>
  );
};

export default ReturnCompleteOrderModal;
