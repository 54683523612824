import {
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from '@mui/material';
import { useState } from 'react';

import './styles.scss';
import { useSnackbar } from '../../../../../../components/Snackbar/hooks';
import { ActionButtonProps } from '../../interface';

import { Button } from '@components/ButtonV2';
import { useAppDispatch } from '@hooks/state';
import { unconfirmCustomerRequest } from '@state/customer/actions';

export const DeleteModal = ({
  name,
  userId,
  imgSrc,
}: ActionButtonProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { showMessage } = useSnackbar();
  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnClick = async () => {
    dispatch(
      unconfirmCustomerRequest({
        userId,
        errorCallback: () => {
          showMessage('There was an error deleting the customer', {
            severity: 'error',
          });
        },
        successCallback: () => {
          showMessage('The customer has been deleted successfully', {
            severity: 'success',
          });
        },
      }),
    );

    handleClose();
  };

  return (
    <>
      <Button
        variant='outlined'
        className='bl-delete-modal-button'
        onClick={handleClickOpen}
        size='small'
      >
        Delete
      </Button>
      <Dialog
        className='bl-delete-modal-dialog-container'
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <div className='bl-delete-modal-avatar-container'>
            <Avatar src={imgSrc}></Avatar>
          </div>

          <DialogContentText className='bl-delete-modal-dialog-text'>
            Are you sure you want to delete{' '}
            <span className='bl-delete-modal-customer-name'>{name}</span> as a
            customer?
          </DialogContentText>
          <Grid container className='bl-delete-modal-buttons-container'>
            <Grid
              item
              xs={5}
              className='bl-delete-modal-cancel-button-container'
            >
              <Button
                variant='contained'
                color='secondary'
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button variant='contained' onClick={handleOnClick}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
