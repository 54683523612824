import { all, call, select, takeLatest } from 'redux-saga/effects';

import {
  moreOrdersLogsRequest,
  moreOrdersLogsResponse,
  ordersLogsRequest,
  ordersLogsResponse,
  returnOrderRequest,
  statusListFilterRequest,
  statusListFilterResponse,
} from './actions';
import { OrdersLogsRequestAction } from './interface';
import { getNextPage } from './selectors';

import { getOrders, getStatusListFilter, returnOrder } from '@config/api/task';
import makeRequest from '@state/requests/make-request';
import { getRequestError } from '@state/requests/selectors';
import { returnOrderResponse } from '@state/task/actions';
import { DeclineOrderActionProps } from '@state/task/interface';

export function* workerOrdersLogsRequest(
  action: OrdersLogsRequestAction,
): Generator<unknown> {
  const {
    startDate = null,
    endDate = null,
    status = null,
  } = action.payload || {};
  const customCall: any = call;
  const page = 1;
  yield customCall(makeRequest, {
    endpoint: getOrders,
    params: { page, startDate, endDate, status },
    requestAction: action,
    receiveAction: ordersLogsResponse,
  });
}

export function* workerMoreOrdersLogsRequest(
  action: OrdersLogsRequestAction,
): Generator<unknown> {
  const customCall: any = call;
  const page = yield select((state) => getNextPage(state));
  const {
    startDate = null,
    endDate = null,
    status = null,
  } = action.payload || {};
  yield customCall(makeRequest, {
    endpoint: getOrders,
    params: { page, startDate, endDate, status },
    requestAction: action,
    receiveAction: moreOrdersLogsResponse,
  });
}

export function* workerReturnOrder(
  action: DeclineOrderActionProps,
): Generator<any> {
  const {
    order_id,
    staff_id,
    status_detail,
    reason_id,
    errorCallback,
    successCallback,
  } = action?.payload || {};
  const customCall: any = call;

  yield customCall(makeRequest, {
    endpoint: returnOrder,
    params: { order_id, staff_id, status_detail, reason_id },
    requestAction: action,
    receiveAction: returnOrderResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, returnOrderRequest),
  );

  if (error && errorCallback) return errorCallback();

  return !!successCallback && action?.payload?.successCallback();
}

export function* workerStatusListFilterRequest(
  action: OrdersLogsRequestAction,
): Generator<unknown> {
  const customCall: any = call;

  yield customCall(makeRequest, {
    endpoint: getStatusListFilter,
    requestAction: action,
    receiveAction: statusListFilterResponse,
  });
}
export default function* orderLogsSagas(): Generator<unknown> {
  yield all([
    takeLatest(ordersLogsRequest, workerOrdersLogsRequest),
    takeLatest(moreOrdersLogsRequest, workerMoreOrdersLogsRequest),
    takeLatest(returnOrderRequest, workerReturnOrder),
    takeLatest(statusListFilterRequest, workerStatusListFilterRequest),
  ]);
}
