import { useEffect, useState } from 'react';

import { useAppSelector } from './state';
import useCustomAbility from './useCustomAbility';

import { EUserTypes } from '@config/api/user/interface';
import { EAbilitySubjects } from '@config/canAbility/types';
import { getImpersonatedSupplier } from '@state/auth/selectors';

const useImpersonatedUser = () => {
  const ability = useCustomAbility();
  const [impersonatedUserType, setImpersonatedUserType] = useState<EUserTypes>(
    EUserTypes.REGULAR_USER,
  );
  const { impersonatedSupplier } = useAppSelector(() => ({
    impersonatedSupplier: getImpersonatedSupplier(),
  }));

  useEffect(() => {
    if (impersonatedSupplier) {
      //  If there are no rules, default user should be regular
      if (!ability?.rules?.length)
        return setImpersonatedUserType(EUserTypes.REGULAR_USER);

      //  Validating if the current impersonated user is a search user
      const hasTasksAndCustomersPermissions = ability.rules?.some(
        (rule) => rule.subject === EAbilitySubjects.TASKS_AND_CUSTOMERS,
      );
      const hasSearchPermissions = ability?.rules?.some(
        (rule) => rule.subject === EAbilitySubjects.SEARCH,
      );

      if (!hasTasksAndCustomersPermissions && hasSearchPermissions)
        return setImpersonatedUserType(EUserTypes.SEARCH_USER);
    }

    //  Default user should be regulat
    setImpersonatedUserType(EUserTypes.REGULAR_USER);
  }, [ability.rules, impersonatedSupplier]);

  return {
    impersonatedUserType,
  };
};

export default useImpersonatedUser;
