/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import UnlistedItemIcon from '@assets/icons/unlisted-item-gear.svg';
import coming_soon from '@assets/images/coming_soon.png';
import custom_item from '@assets/images/custom_item.svg';
import { partsImages } from '@assets/images/parts';
import Notification from '@assets/sounds/notification.wav';
import NotificationNewOrder from '@assets/sounds/notification_new_order.wav';
import { EOrderItemTypes } from '@config/api/task/interface';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const handleGetString = (data, key, dictionary) => {
  if (!data?.specifications && data?.subcategory) return data?.subcategory;
  if (!data?.type || !key) return '';
  if (data?.type === 'other') return data?.description ?? '';
  const stringCategory = dictionary[data?.type];
  if (!stringCategory) return '';
  if (stringCategory && stringCategory[key]) return stringCategory[key];

  return '';
};

export const partImage = (part, type) => {
  if (type === EOrderItemTypes.UNLISTED_ITEM) return UnlistedItemIcon;
  if (part?.image?.url && part?.image?.url !== '') return part.image.url;
  if (type && type === 'custom_item') return custom_item;

  return partsImages[part?.type] ? partsImages[part?.type] : coming_soon;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunction = () => {};

export const handleRoundDecimals = (num, decimals) => {
  const preDecimalDigitsQty = Math.floor(num).toFixed(0).length;

  if (decimals === 0)
    return num.toFixed(decimals + 1).slice(0, preDecimalDigitsQty + decimals);

  return num.toFixed(decimals + 1).slice(0, preDecimalDigitsQty + decimals + 1);
};

export const handleThousandsValue = (value) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 *
 * @param {React.UIEvent<HTMLElement>} e
 * @returns boolean
 */
export const scrollHitBottom = ({ currentTarget }) =>
  Math.abs(
    currentTarget.scrollHeight -
      currentTarget.scrollTop -
      currentTarget.clientHeight,
  ) <= 3.0;

export const removeSpaceDollarAndComma = (value) =>
  value.replace(/\s/g, '').replace('$', '').replace(/,/g, '');

export const getMonthName = (month) => {
  return months[month];
};

export const playNotificationSound = (newOrder) => {
  const audioElement = new Audio();
  newOrder
    ? (audioElement.src = NotificationNewOrder)
    : (audioElement.src = Notification);

  const playPromise = audioElement.play();

  if (playPromise !== undefined)
    playPromise.then().catch(() => {
      return;
    });
};

export const handleCustomReplacement = (item) => {
  return {
    id: item.id,
    note: item.note ? item.note : '',
    type: item.type ? item.type : 'generic',
    details: {
      brand: item.brand,
      description: `${item.brand} - ${item.part_number}`,
      id: item.id,
      number: item.part_number,
      subcategory: '',
      subtype: '',
      type: item.type ? item.type : 'generic',
    },
  };
};
