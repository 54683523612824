import ClearIcon from '@mui/icons-material/Clear';
import { capitalize } from 'lodash';

import { ChipListProps } from './interface';

import { Button } from '@components/ButtonV2';
import './styles.scss';

export const ChipList = ({
  data,
  onDeleteItem,
  'data-testid': dataTestId,
  capitalizeLabel = true,
}: ChipListProps): JSX.Element => {
  return (
    <ul
      className='clip-list-container'
      data-testid={dataTestId && `${dataTestId}ClipListContainer`}
    >
      {data.map((chip) => {
        return (
          <li className='clip-list-item' key={chip.id}>
            <Button
              data-testid={dataTestId && `${dataTestId}ChipListItem`}
              endIcon={onDeleteItem && <ClearIcon className='button-close' />}
              color={'secondary'}
              variant='contained'
              className={!onDeleteItem ? 'cant-delete-button' : ''}
              onClick={onDeleteItem && (() => onDeleteItem(chip.id))}
              size='small'
              disableRipple={onDeleteItem ? false : true}
            >
              {capitalizeLabel ? capitalize(chip?.label) : chip?.label}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};
