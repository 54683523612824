import Grid from '@mui/material/Grid';
import { Controller } from 'react-hook-form';

import { BranchManagerInputsProps } from './interface';

import InputComponent from '@components/InputComponent';
import { FormTextInputLengths } from '@utils/FormInputLengths';

const BranchManagerInputs = ({
  control,
  isLoading,
}: BranchManagerInputsProps): JSX.Element => (
  <Grid
    container
    justifyContent='center'
    className='store-info-inputs-container'
  >
    <Grid item xs={12} md={9}>
      <Controller
        name='manager_name'
        control={control}
        render={({ field, fieldState, formState }) => (
          <InputComponent
            variant='outlined'
            id='manager_name'
            data-testid='managerName'
            label='Name'
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            helperText={formState.errors.manager_name?.message}
            isLoading={isLoading}
            keepHelperTextSpace
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={9}>
      <Controller
        name='manager_phone'
        control={control}
        render={({ field, fieldState, formState }) => (
          <InputComponent
            variant='outlined'
            id='manager_phone'
            data-testid='managerPhoneNumber'
            label='Phone Number'
            name={field.name}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) event.preventDefault();
            }}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            inputProps={{
              maxLength: FormTextInputLengths.profile.phoneFullNumber,
            }}
            helperText={formState.errors.manager_phone?.message}
            isLoading={isLoading}
            keepHelperTextSpace
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={9}>
      <Controller
        name='manager_email'
        control={control}
        render={({ field, fieldState, formState }) => (
          <InputComponent
            variant='outlined'
            id='manager_email'
            label='Manager Email'
            data-testid='managerEmail'
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            inputProps={{
              maxLength: FormTextInputLengths.profile.email,
            }}
            helperText={formState.errors.manager_email?.message}
            keepHelperTextSpace
            isLoading={isLoading}
          />
        )}
      />
    </Grid>
  </Grid>
);

export default BranchManagerInputs;
