import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import OtherTextArea from '../OtherTextArea';
import RadioGroupReasons from '../RadioGroupReasons';

import { DeclineModalForm, DeclineModalProps } from './interface';
import {
  DeclineModalValidationSchema,
  OTHER_TEXT_MAX_LENGTH_DOC,
  OTHER_TEXT_MIN_LENGTH_DOC,
} from './validations';
import './styles.scss';

import { Button } from '@components/ButtonV2';
import { ClaimingModalContainer } from '@components/ClaimingModal';
import { ClaimingModalAddStaff } from '@components/ClaimingModal/Steps/AddStaff';
import { ShellModal } from '@components/ShellModal';
import { useSnackbar } from '@components/Snackbar/hooks';
import { ReasonsSubStatus } from '@config/api/storeInfo/interface';
import { useAppSelector } from '@hooks/state';
import useGoogleTagManager from '@hooks/useGoogleTagManager';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';
import { TermsOfService } from '@pages/Login/components/TermsOfService';
import { openTermsOfService } from '@state/auth/actions';
import { getShowTermsInfoData } from '@state/auth/selectors';
import { isRequestRunning } from '@state/requests/selectors';
import {
  cancelReasonsResponse,
  declineReasonsResponse,
  reasonsRequest,
} from '@state/storeInfo/actions';
import {
  getCancelReasons,
  getDeclineReasons,
  getStoreInfoCounterStaff,
} from '@state/storeInfo/selectors';
import { cancelOrderRequest, declineOrderRequest } from '@state/task/actions';
import { assembleGTMEvent } from '@utils/GoogleTagManager';
import {
  GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS,
  GOOGLE_TAG_MANAGER_CATEGORY_PAGES,
  GOOGLE_TAG_MANAGER_EVENT_MESSAGES,
} from '@utils/GoogleTagManager/interface';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

const DeclineOrCancelModal = ({
  isOpen,
  onClose,
  orderId,
  isCancel = false,
}: DeclineModalProps) => {
  const initialValues: DeclineModalForm = {
    selectedOption: '',
    otherText: '',
    acceptTerms: false,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setGTMEvent } = useGoogleTagManager();
  const { clearSelectedOrder } = useSetSelectedOrder();
  const [isStaffOpen, setIsStaffOpen] = useState<boolean>(false);
  const [selectedStaffId, setSelectedStaffId] = useState<string>('');
  const [addNewCounterStaff, setAddNewCounterStaff] = useState<boolean>(false);
  const [modalCreatedCounterStaffName, setModalCreatedCounterStaffName] =
    useState('');

  const handleChange = (event: SelectChangeEvent<typeof selectedStaffId>) => {
    const newStaff = storeStaff.find((item) => item.id === event.target.value);

    setSelectedStaffId(newStaff?.id as string);
  };

  const { showMessage } = useSnackbar();
  const {
    isApiSubmitRunning,
    storeStaff,
    cancelReasons,
    declineReasons,
    areReasonsLoading,
    showTermsAndConditions,
  } = useAppSelector((state) => ({
    storeStaff: getStoreInfoCounterStaff(state) || [],
    cancelReasons: getCancelReasons(state) || [],
    declineReasons: getDeclineReasons(state) || [],
    areReasonsLoading: isRequestRunning(state, String(reasonsRequest)),
    isApiSubmitRunning:
      isRequestRunning(state, String(cancelOrderRequest)) ||
      isRequestRunning(state, String(declineOrderRequest)),
    showTermsAndConditions: getShowTermsInfoData(state),
  }));

  const {
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<DeclineModalForm>({
    resolver: yupResolver(DeclineModalValidationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const selectedOptionValue = watch('selectedOption');
  const otherOptionTextValue = watch('otherText');
  const acceptTermsValue = watch('acceptTerms');

  const hideCountNumber =
    Number(otherOptionTextValue?.length) > 0 &&
    Number(otherOptionTextValue?.length) < OTHER_TEXT_MIN_LENGTH_DOC;
  const showErrorColor =
    Number(otherOptionTextValue?.length) === OTHER_TEXT_MAX_LENGTH_DOC ||
    hideCountNumber;
  const maxLengthReached =
    Number(otherOptionTextValue?.length) === OTHER_TEXT_MAX_LENGTH_DOC;

  const selectedOption = useMemo(
    () =>
      (isCancel ? cancelReasons : declineReasons).find(
        (reason) => reason.id === selectedOptionValue,
      ),
    [selectedOptionValue],
  );

  const resetFormValues = useCallback(() => {
    reset(initialValues);
  }, []);

  const handleFormClose = useCallback(() => {
    resetFormValues();
    onClose();
  }, []);

  useEffect(() => {
    if (!storeStaff || !modalCreatedCounterStaffName) return;

    const newStaff = storeStaff.find(
      (staff) => staff.name === modalCreatedCounterStaffName,
    );
    if (newStaff) {
      setSelectedStaffId(newStaff.id ?? '');
      setModalCreatedCounterStaffName('');
      handleClose();
    }
  }, [storeStaff, modalCreatedCounterStaffName]);

  const orderSuccessCallback = useCallback(() => {
    showMessage(`Quote has been ${isCancel ? 'canceled' : 'declined'}`, {
      severity: 'info',
    });
    handleFormClose();
    closeOrderDetail();
    navigate('/tasks');
  }, []);

  const orderErrorCallback = useCallback(() => {
    showMessage('Oops! Something happened, please try again later', {
      severity: 'error',
    });
    handleFormClose();
    closeOrderDetail();
  }, []);

  const reasonsErrorCallback = useCallback(() => {
    showMessage('Oops! Something happened, please try again later', {
      severity: 'error',
    });
    handleFormClose();
  }, []);

  const closeOrderDetail = () => clearSelectedOrder(false);

  const handleClose = () => setIsStaffOpen(false);
  const handleOpen = () => setIsStaffOpen(true);

  useEffect(() => {
    dispatch(
      reasonsRequest({
        status: isCancel
          ? ReasonsSubStatus.CANCELED
          : ReasonsSubStatus.DECLINED,
        errorCallback: reasonsErrorCallback,
        receiveAction: isCancel
          ? cancelReasonsResponse
          : declineReasonsResponse,
      }),
    );
  }, []);

  const handleOrderDecline = useCallback(() => {
    const payload = {
      order_id: orderId,
      staff_id: selectedStaffId,
      reason_id: selectedOptionValue,
      status_detail: capitalizeFirstLetter(
        otherOptionTextValue && otherOptionTextValue.length > 0
          ? otherOptionTextValue.toLowerCase()
          : selectedOptionValue.toLowerCase(),
      ),
      errorCallback: orderErrorCallback,
      successCallback: orderSuccessCallback,
    };
    if (isCancel) {
      dispatch(cancelOrderRequest(payload));
      setGTMEvent(
        assembleGTMEvent(
          GOOGLE_TAG_MANAGER_CATEGORY_PAGES.TASKS,
          GOOGLE_TAG_MANAGER_EVENT_MESSAGES.CANCEL_REQUEST_SUCCESS,
          undefined,
          GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS.CANCEL_REQUEST_MODAL,
          {
            orderDetail: {
              orderId: orderId,
            },
            selectedOption: {
              id: selectedOption?.id,
              name: selectedOption?.name,
              ...(otherOptionTextValue && {
                optionalText: otherOptionTextValue,
              }),
            },
          },
        ),
      );
    } else {
      dispatch(declineOrderRequest(payload));
    }
  }, [isCancel, selectedOptionValue, selectedStaffId, otherOptionTextValue]);

  const isOtherOptionSelected =
    selectedOption?.special && selectedOption?.name === 'Other';

  const isButtonDisabled =
    selectedOptionValue === '' ||
    selectedStaffId === '' ||
    isApiSubmitRunning ||
    (isOtherOptionSelected && (!!errors.otherText || !otherOptionTextValue)) ||
    !acceptTermsValue;

  const showTerms = () => {
    dispatch(openTermsOfService(true));
  };

  const hideTerms = () => {
    dispatch(openTermsOfService(!showTerms));
  };

  const closeAddCounterStaffModal = (name?: string) => {
    setAddNewCounterStaff(false);
    if (name) setModalCreatedCounterStaffName(name);
  };

  return (
    <>
      <TermsOfService open={showTermsAndConditions} handleClose={hideTerms} />
      <ClaimingModalContainer
        visible={addNewCounterStaff}
        onClose={closeAddCounterStaffModal}
      >
        <ClaimingModalAddStaff onCancel={closeAddCounterStaffModal} />
      </ClaimingModalContainer>
      <ShellModal
        open={isOpen}
        onClose={handleFormClose}
        title='Fill out form to cancel'
        subtitle='This information is shared with your manager as well as the tech.'
        buttonsPosition={'md:justify-center'}
        bottomButtons={[
          <Button key={1} onClick={handleFormClose} variant='outlined'>
            Keep Order
          </Button>,
          <Button
            key={2}
            onClick={handleOrderDecline}
            disabled={isButtonDisabled}
            variant='contained'
          >
            Cancel Order
          </Button>,
        ]}
      >
        <>
          <div className='mb-5'>
            <FormControl className='staff-wrapper w-full'>
              <InputLabel id='demo-controlled-open-select-label'>
                Who is canceling? (Required)
              </InputLabel>
              <Select
                open={isStaffOpen}
                onClose={handleClose}
                onOpen={handleOpen}
                label={'Who is canceling? (Required)'}
                value={selectedStaffId}
                onChange={handleChange}
                MenuProps={{
                  sx: {
                    '.MuiList-root': {
                      maxHeight: 238,
                      paddingBottom: 0,
                    },
                  },
                }}
              >
                {storeStaff?.map((item, index) => {
                  return item.name ? (
                    <MenuItem key={`${item?.id} ${index}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ) : null;
                })}
                {storeStaff.length < 20 && (
                  <Box className='sticky bottom-0 flex w-full items-center bg-[var(--bluon--ds--white)] '>
                    <MenuItem
                      className='add-new-counter-button'
                      onClick={() => setAddNewCounterStaff(true)}
                    >
                      <AddIcon /> Add New Counter Staff
                    </MenuItem>
                  </Box>
                )}
              </Select>
            </FormControl>
          </div>
          <Typography
            variant='body1'
            className='text-left font-normal text-[var(--bluon--ds--lightblack)]'
          >
            Reason for canceling (Required)
          </Typography>
          <div className='mb-6 flex w-[11rem] pl-4 md:w-[39.5rem]'>
            <RadioGroupReasons
              isLoading={areReasonsLoading}
              reasons={isCancel ? cancelReasons : declineReasons}
              formControl={control}
            />
          </div>
          {isOtherOptionSelected && (
            <div className='reason-wrapper mb-6'>
              <OtherTextArea
                formControl={control}
                maxLength={OTHER_TEXT_MAX_LENGTH_DOC}
                maxLengthReached={maxLengthReached}
                showErrorColor={showErrorColor}
                errors={errors}
                hideCountNumber={hideCountNumber}
              />
            </div>
          )}
          <div className='mb-9 w-[11rem] pl-4 md:w-[39.5rem]'>
            <Controller
              name='acceptTerms'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox />}
                  data-testid='acceptWarning'
                  label={
                    <span>
                      WARNING: Canceling an order that happened is a violation
                      of Bluon&apos;s{' '}
                      <Link
                        className='text-[var(--bluon--ds--button--highlight--text)]'
                        color='primary'
                        underline='hover'
                        onClick={showTerms}
                      >
                        Terms of Service
                      </Link>
                      , unless the order was not fulfilled or was returned.
                    </span>
                  }
                  checked={field.value}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
        </>
      </ShellModal>
    </>
  );
};

export default DeclineOrCancelModal;
