import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ExtraItemsList } from '../TruckStock/components/list';
import { ExtraItemsProps } from '../TruckStock/interface';

import { MUIAccordion } from '@components/MUIAccordion';
import { useSnackbar } from '@components/Snackbar/hooks';
import { ExtraItem } from '@config/api/task/interface';
import { useAppSelector } from '@hooks/state';
import useAddedItemsValidations from '@hooks/useAddedItemsValidations';
import { isRequestRunning } from '@state/requests/selectors';
import {
  patchAddedItemsRequest,
  resetAddedItemsResponse,
  setTotalIsExpanded,
} from '@state/task/actions';

export const AddedItems = ({
  items,
  order,
  isBidRequired,
}: ExtraItemsProps) => {
  //  Component hooks
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();

  //  Component variables
  const { areAllItemsConfirmed, areAllItemsUnavailable } =
    useAddedItemsValidations();

  //  Component state
  const [selectedItems, setSelectedItems] = useState<ExtraItem[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(
    !areAllItemsUnavailable,
  );
  const [isListDirty, setIsListDirty] = useState<boolean>(false);

  const { isSaving } = useAppSelector((state) => ({
    isSaving: isRequestRunning(state, String(patchAddedItemsRequest)),
  }));

  const successCallback = () => {
    setIsListDirty(false);
    setIsExpanded(false);
    dispatch(setTotalIsExpanded(true));
    showMessage('Items Saved', {
      severity: 'success',
      xCloseButton: true,
      subMessage: `Please update the Total ${
        isBidRequired ? 'and Bid Number' : ''
      } to continue.`,
    });
  };

  const onSave = () => {
    setIsListDirty(false);

    const payload = items
      .reduce(
        (items, item) => {
          const notExist =
            selectedItems.findIndex((selected) => selected.id === item.id) ===
            -1;

          if (notExist) items.push({ ...item, quantity: 0 });

          return items;
        },
        [...selectedItems],
      )
      .map((item) => ({ uuid: item.id, quantity: item.quantity }));

    dispatch(
      patchAddedItemsRequest({ order, items: payload, successCallback }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetAddedItemsResponse());
    };
  }, [dispatch]);

  const renderHeaderTitle = useCallback(() => {
    return (
      <div className='added-items-title container flex min-w-full items-center justify-between'>
        <Typography variant='h6' className='title-container'>
          Added Items
        </Typography>
        {areAllItemsConfirmed && !areAllItemsUnavailable && (
          <CheckIcon className='check-icon mr-4' />
        )}
        {areAllItemsUnavailable && <CloseIcon className='closed-icon mr-4' />}
      </div>
    );
  }, [areAllItemsUnavailable, areAllItemsConfirmed]);

  return (
    <MUIAccordion
      className='bl-truck-stock added-items-container'
      expanded={isExpanded}
      onChange={(e, expanded) => {
        setIsExpanded(expanded);

        if (!expanded && isListDirty) onSave();
      }}
      headerTitle={renderHeaderTitle()}
    >
      <Box className='bl-added-items-container'>
        <ExtraItemsList
          items={items}
          onSave={onSave}
          isSaving={isSaving}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setListDirty={setIsListDirty}
        />
      </Box>
    </MUIAccordion>
  );
};
