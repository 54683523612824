import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';

import CurriPopUpHeader from '../../../../../../assets/images/curri-informative-header.png';

import { CurriInformativePopUpProps } from './interface';

import { Button } from '@components/ButtonV2';
import { useSnackbar } from '@components/Snackbar/hooks';
import { useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import { postCurriInformativePopUpRequest } from '@state/task/actions';
import { EDeliveryType, EOrderSubstatus } from '@utils/enums';
import { handleRoundDecimals } from '@utils/functions';

import './styles.scss';

export const CurriInformativePopUp = ({
  order,
}: CurriInformativePopUpProps) => {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { isLoadingRequest } = useAppSelector((state) => ({
    isLoadingRequest: isRequestRunning(
      state,
      String(postCurriInformativePopUpRequest),
    ),
  }));

  useEffect(() => {
    if (!order || order?.curri_charge_acknowledged) {
      return setIsOpen(false);
    } else if (order.curri_charge_acknowledged === false) {
      const isCurri = order?.delivery?.type === EDeliveryType.CURRI_DELIVERY;
      if (isCurri) {
        const orderStatus = order?.current_status?.substatus;
        const showCurriInformativePopUp =
          [
            EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED_WS,
            EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED,
            EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_NEEDED_WS,
            EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_NEEDED,
            EOrderSubstatus.APPROVED_DELIVERED_QUOTE_NEEDED_WS,
            EOrderSubstatus.APPROVED_DELIVERED_QUOTE_NEEDED,
          ].indexOf(orderStatus as EOrderSubstatus) > -1;

        setIsOpen(!!showCurriInformativePopUp);
      }
    }
  }, [order]);

  const renderCopyToClipboardButton = () => {
    const numberToCopy = order?.delivery?.fee || 0;

    return (
      <CopyToClipboard
        text={handleRoundDecimals(numberToCopy, 2)}
        onCopy={() =>
          showMessage('Copied', {
            severity: 'success',
            autoHide: true,
          })
        }
      >
        <div className='ml-4 bg-[var(--bluon--default--white)]'>
          <Button
            className='max-h-8 text-xs font-medium leading-3'
            variant='outlined'
            startIcon={<ContentCopyIcon sx={{ width: 14, height: 16 }} />}
          >
            Copy Prices
          </Button>
        </div>
      </CopyToClipboard>
    );
  };

  return (
    <Dialog open={isOpen} maxWidth={'lg'}>
      <Box className='bl-curri-informative-pop-up'>
        <img src={CurriPopUpHeader} alt='' srcSet='' />
        <DialogContent>
          <Box className='informative-price'>
            <Typography variant='h6' fontWeight={400}>
              Add delivery charge to the customer’s invoice!
            </Typography>

            <Box display='flex'>
              <Typography variant='h5' fontWeight={700}>
                ${order?.delivery?.fee}
              </Typography>
              {renderCopyToClipboardButton()}
            </Box>
          </Box>

          <Box className='informative-stepper'>
            <Typography variant='h5' textAlign='center'>
              How it works
            </Typography>
            <Stepper nonLinear>
              <Step key={1}>
                <StepLabel>Tech orders delivery</StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel>You include delivery charge on invoice</StepLabel>
              </Step>
              <Step key={3}>
                <StepLabel>Curri driver picks up order</StepLabel>
              </Step>
            </Stepper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            loading={isLoadingRequest}
            disabled={isLoadingRequest}
            startIcon={isLoadingRequest ? undefined : <CheckIcon />}
            onClick={() =>
              dispatch(
                postCurriInformativePopUpRequest({
                  order: order?.id,
                  errorCallback: () => {
                    showMessage(
                      'Oops, something happened, please try again later!',
                      {
                        severity: 'error',
                        autoHide: true,
                      },
                    );
                  },
                }),
              )
            }
          >
            I Got It Thanks
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
