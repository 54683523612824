import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ExtraItemsList } from './components/list';
import { ExtraItemsProps } from './interface';

import { MUIAccordion } from '@components/MUIAccordion';
import { useSnackbar } from '@components/Snackbar/hooks';
import { ExtraItem } from '@config/api/task/interface';
import { useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import {
  getOrderItems as getOrderItemsAction,
  patchSupplyItemsRequest,
  resetOrderItemsSupplyResponse,
  setTotalIsExpanded,
  setTruckStockIsExpanded,
} from '@state/task/actions';
import './styles.scss';
import { getIsTruckStockExpanded, getOrderItems } from '@state/task/selectors';
import { EItemStatus } from '@utils/enums';

export const TruckStock = ({
  items,
  order,
  isBidRequired,
}: ExtraItemsProps): JSX.Element => {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const [listDirty, setListDirty] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<ExtraItem[]>([]);

  const { isTruckStockExpanded, parts, isLoadingParts, isSendingTruckStock } =
    useAppSelector((state) => ({
      isTruckStockExpanded: getIsTruckStockExpanded(state),
      parts: getOrderItems(state),
      isLoadingParts: isRequestRunning(state, String(getOrderItemsAction)),
      isSendingTruckStock: isRequestRunning(
        state,
        String(patchSupplyItemsRequest),
      ),
    }));

  const onSave = () => {
    const payload = items
      .reduce(
        (items, item) => {
          const notExist =
            selectedItems.findIndex((selected) => selected.id === item.id) ===
            -1;

          if (notExist) items.push({ ...item, quantity: 0 });

          return items;
        },
        [...selectedItems],
      )
      .map((item) => ({ uuid: item.id, quantity: item.quantity }));

    const allZeroQuantity = payload.every((item) => item.quantity === 0);

    dispatch(
      patchSupplyItemsRequest({
        order,
        items: payload,
        successCallback: () => {
          showMessage('Items Saved', {
            severity: 'success',
            xCloseButton: true,
            subMessage: allZeroQuantity
              ? ''
              : `Please update the Total ${
                  isBidRequired ? 'and Bid Number' : ''
                } to continue.`,
          });
          dispatch(setTruckStockIsExpanded(!isTruckStockExpanded));
          dispatch(setTotalIsExpanded(true));
        },
      }),
    );
  };

  const showGreenCheck = useMemo(
    () =>
      items.every(
        (item) =>
          item.status === EItemStatus.Available ||
          item.status === EItemStatus.NotAvailable,
      ),
    [items],
  );
  const showRedIcon = useMemo(
    () => items.every((item) => item.status === EItemStatus.NotAvailable),
    [items],
  );

  const hasSomePartPending = parts.some((part) => part.status === 'pending');

  useEffect(() => {
    const partsAreOk = !isLoadingParts && !hasSomePartPending;
    const truckStockAreOk = !isSendingTruckStock && showGreenCheck;

    const partsAreOkAndPendingTruckStock =
      partsAreOk && !showGreenCheck && !isSendingTruckStock;

    const noPartsAndPendingTruckStock =
      !isLoadingParts &&
      !isTruckStockExpanded &&
      !showGreenCheck &&
      !isSendingTruckStock &&
      parts.length === 0;

    if (partsAreOkAndPendingTruckStock || noPartsAndPendingTruckStock)
      dispatch(setTruckStockIsExpanded(true));

    if (partsAreOk && truckStockAreOk) dispatch(setTotalIsExpanded(true));
  }, [
    isTruckStockExpanded,
    showGreenCheck,
    parts,
    isLoadingParts,
    hasSomePartPending,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setTruckStockIsExpanded(false));
      dispatch(resetOrderItemsSupplyResponse());
    };
  }, []);

  return (
    <MUIAccordion
      className='bl-truck-stock'
      expanded={isTruckStockExpanded}
      onChange={(event, expanded) => {
        dispatch(setTruckStockIsExpanded(expanded));

        if (!expanded && !listDirty) onSave();
      }}
      headerTitle={
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
        >
          <Typography className='truck-stock-title' variant='subtitle1'>
            Truck Stock
          </Typography>

          {showGreenCheck && !showRedIcon && (
            <CheckIcon className='check-icon' />
          )}
          {showRedIcon && <CloseIcon className='closed-icon' />}
        </Box>
      }
    >
      <ExtraItemsList
        items={items}
        onSave={onSave}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setListDirty={setListDirty}
        isSaving={isSendingTruckStock}
      />
    </MUIAccordion>
  );
};
