import { Box } from '@mui/material';
import React from 'react';

import './styles.scss';
import { BaseCustomerBannerProps } from './interface';

export const BaseCustomerBanner = ({
  children,
  className,
}: BaseCustomerBannerProps) => {
  return (
    <Box className={`bl-base-customer-banner ${className}`}>{children}</Box>
  );
};
