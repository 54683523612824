import { Box, Dialog } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ClaimingModalContainerProps, ClaimingModalProps } from './interface';
import { ClaimingModalAddStaff } from './Steps/AddStaff';
import { ClaimingModalSelectStaff } from './Steps/SelectStaff';

import CloseIcon from '@assets/icons/close-icon-modal.svg';
import { Button } from '@components/ButtonV2';
import { useSnackbar } from '@components/Snackbar/hooks';
import { CounterStaffInfo } from '@config/api/storeInfo/interface';
import { getIsImpersonatedSupplier } from '@state/auth/selectors';
import { setCounterStaffRequest } from '@state/task/actions';
import { emptyFunction } from '@utils/functions';
import './styles.scss';

export const ClaimingModal = ({
  contractorName,
  onClose,
  onSuccessClaiming,
  orderId,
  techName,
  visible,
}: ClaimingModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const [addNewCounterStaffMode, setAddNewCounterStaffMode] = useState(false);
  const [preloadUpdateStaff, setPreloadUpdateStaff] =
    useState<CounterStaffInfo>();

  const addCounterStaffCallback = (selectedStaff: CounterStaffInfo) => {
    if (!selectedStaff) return;
    setPreloadUpdateStaff(selectedStaff);

    const isValidStaff =
      selectedStaff.email && selectedStaff.name && selectedStaff.phone;

    if (isValidStaff) selectCounterStaff(selectedStaff);
    else setAddNewCounterStaffMode(true);
  };

  const selectCounterStaff = (selectedItem?: CounterStaffInfo) => {
    dispatch(
      setCounterStaffRequest({
        order: orderId,
        staff: selectedItem?.id ?? preloadUpdateStaff?.id,
        errorCallback: () => {
          showMessage('Oops something went wrong!', {
            severity: 'error',
            autoHide: true,
            subMessage: 'Please try again.',
          });
        },
        successCallback: () => {
          onSuccessClaiming();
        },
      }),
    );
  };

  return (
    <ClaimingModalContainer visible={visible} onClose={onClose}>
      {!addNewCounterStaffMode ? (
        <ClaimingModalSelectStaff
          techName={techName}
          contractorName={contractorName}
          onAddNewCounterStaff={() => setAddNewCounterStaffMode(true)}
          addCounterStaffCallback={addCounterStaffCallback}
        />
      ) : (
        <ClaimingModalAddStaff
          onCancel={() => {
            setPreloadUpdateStaff(undefined);
            setAddNewCounterStaffMode(false);
          }}
          editMode={!!preloadUpdateStaff}
          preloadInfo={preloadUpdateStaff}
          afterUpdateCallback={() => selectCounterStaff()}
        />
      )}
    </ClaimingModalContainer>
  );
};

export const ClaimingModalContainer = ({
  visible,
  children,
  onClose,
}: ClaimingModalContainerProps) => {
  const isImpersonatedSupplier = getIsImpersonatedSupplier();

  return (
    <Dialog
      onClose={emptyFunction}
      open={visible}
      maxWidth='xs'
      className='claiming-modal-dialog'
    >
      <Box className='claiming-modal-container-content'>
        <Box
          className={`cm-container-close ${isImpersonatedSupplier && 'pt-6'}`}
        >
          <Button variant='contained' onClick={onClose} className='close-btn'>
            <img src={CloseIcon} alt='close' className='close-icon' />
          </Button>
        </Box>
        {children}
      </Box>
    </Dialog>
  );
};
