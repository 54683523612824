import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Paper, Stack } from '@mui/material';
import Container from '@mui/material/Container';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AccountingContactInputs } from './components/AccountingContactInputs';
import BranchManagerInputs from './components/BranchManagerInputs';
import CounterStaffInputs from './components/CounterStaffInputs';
import FormCardContainer from './components/FormCardContainer';
import StoreContactInputs from './components/StoreContactInputs';
import StoreInfoInputs from './components/StoreInfoInputs';
import { StoreInfoFormKeys } from './interfaces';
import { getCounterStaffToSubmit, getInitialCounterStaffValues } from './utils';
import { StoreInfoValidationSchema } from './validations';

import { Button } from '@components/ButtonV2';
import { CancelFormModal } from '@components/CancelModal';
import { ChangeLoginCredentialModal } from '@components/ChangeLoginCredentialModal';
import { useSnackbar } from '@components/Snackbar/hooks';
import TakeRate from '@components/TakeRate';
import { WelcomeModal } from '@components/WelcomeModal';
import { ImageFile, StoreInfoDataModel } from '@config/api/storeInfo/interface';
import { EAbilityActions, EAbilitySubjects } from '@config/canAbility/types';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import useCustomAbility from '@hooks/useCustomAbility';
import useNavigateToHome from '@hooks/useNavigateToHome';
import { dashboardSetShowThankYouModal } from '@state/dashboard/actions';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import {
  brandsRequest,
  setWelcomeDisplayedAt,
  storeInfoGetRequest,
  storeInfoPatchRequest,
} from '@state/storeInfo/actions';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import { convertTime24to12 } from '@utils/Numbers';
import { stripNonNumeric } from '@utils/Strings/stripNonNumeric';

import './styles.scss';

export const StoreInfo = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const ability = useCustomAbility();
  const { navigateToHome } = useNavigateToHome();

  const { showMessage } = useSnackbar();

  const [openWelcomeModal, setOpenWelcomeModal] = useState<boolean>(false);
  const [openCancelFormModal, setOpenCancelFormModal] =
    useState<boolean>(false);
  const [openChangeLoginCredentialModal, setChangeLoginCredentialModal] =
    useState<boolean>(false);
  const [errorMessageBrandModal, setErrorMessageBrandModal] =
    useState<string>('');

  const { data, loadingGet, loadingPatch, patchStoreInfoErrors } =
    useAppSelector((state) => ({
      data: getStoreInfoData(state),
      loadingGet: isRequestRunning(state, String(storeInfoGetRequest), true),
      loadingPatch: isRequestRunning(state, String(storeInfoPatchRequest)),
      patchStoreInfoErrors: getRequestError(
        state,
        String(storeInfoPatchRequest),
      ),
    }));

  const getInitialValues = (
    data: StoreInfoDataModel | undefined,
  ): StoreInfoFormKeys => {
    return {
      name: data?.name || '',
      address: data?.address || '',
      address_2: data?.address_2 || '',
      country: data?.country?.code || '',
      state: data?.state?.code || '',
      city: data?.city || '',
      zip_code: data?.zip_code || '',
      email: data?.email || '',
      phone: stripNonNumeric(data?.phone),
      offers_delivery: data?.offers_delivery ? 1 : 0,
      take_rate: data?.take_rate || '',
      take_rate_until: data?.take_rate_until || '',
      prokeep_phone: data?.prokeep_phone || '',
      timezone: data?.timezone || '',
      branch: data?.branch || '',
      contact_email: data?.contact_email || '',
      contact_phone: stripNonNumeric(data?.contact_phone),
      contact_secondary_email: data?.contact_secondary_email || '',
      manager_name: data?.manager_name || '',
      manager_email: data?.manager_email || '',
      manager_phone: stripNonNumeric(data?.manager_phone),
      accountant_email: data?.accountant_email || '',
      accountant_name: data?.accountant_name || '',
      accountant_phone: stripNonNumeric(data?.accountant_phone),
      counter_staff: getInitialCounterStaffValues(data),
      branch_hours:
        data?.open_hours.map((item) => {
          return {
            ...item,
            active: true,
            from: convertTime24to12(item.from),
            to: convertTime24to12(item.to),
          };
        }) || [],
    };
  };

  const formProps = useForm({
    resolver: yupResolver(StoreInfoValidationSchema),
    defaultValues: useMemo(() => getInitialValues(data), [data]),
  });

  useEffect(() => {
    if (data) {
      const formValues = formProps.getValues();
      formProps.reset(
        formValues.name
          ? { ...getInitialValues(data), ...formValues }
          : getInitialValues(data),
      );
      if (!data.welcome_displayed_at) {
        setOpenWelcomeModal(true);
        dispatch(setWelcomeDisplayedAt());
      }
    }
  }, [data, formProps, dispatch]);

  useEffect(() => {
    if (!patchStoreInfoErrors) return;
    for (const [key] of Object.entries(patchStoreInfoErrors))
      if (patchStoreInfoErrors[key][0])
        formProps.setError(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          key as any,
          {
            type: 'invalid',
            message: patchStoreInfoErrors[key][0],
          },
        );
  }, [patchStoreInfoErrors, formProps]);

  const onCancelFormModal = () => {
    setOpenCancelFormModal(!openCancelFormModal);
  };

  const onCancelAcceptFormModal = () => {
    formProps.reset();
    setOpenCancelFormModal(!openCancelFormModal);
    navigateToHome();
  };

  useEffect(() => {
    dispatch(storeInfoGetRequest());
    dispatch(brandsRequest());
  }, [dispatch]);

  const handleSubmitVerification = (values: StoreInfoFormKeys) => {
    if (!data?.brands?.data.length)
      return setErrorMessageBrandModal('You must add at least 1 brand');
    if (data && data.email === values.email) onSubmitForm(values);
    else setChangeLoginCredentialModal(true);
  };

  const onSubmitForm = (values: StoreInfoFormKeys) => {
    setChangeLoginCredentialModal(false);
    const valuesWithCounterStaff = getCounterStaffToSubmit(values);
    dispatch(
      storeInfoPatchRequest({
        data: {
          ...valuesWithCounterStaff,
          // When the country is not US the zip code is disabled and we send 11111 as expected by the BE
          zip_code: values.zip_code ? values.zip_code : '11111',
        },
        callback: () => {
          if (data && !data.verified_at)
            dispatch(dashboardSetShowThankYouModal(true));

          navigateToHome();
        },
        error: showMessage,
      }),
    );
  };

  const handleCloseWelcomeModal = () => {
    setOpenWelcomeModal(false);
  };

  return (
    <Container maxWidth='xl' className='bl-store-info-container'>
      <CancelFormModal
        visible={openCancelFormModal}
        onCancel={onCancelFormModal}
        onAccept={onCancelAcceptFormModal}
      />
      <ChangeLoginCredentialModal
        onAccept={() => onSubmitForm(formProps.getValues())}
        onCancel={() => setChangeLoginCredentialModal(false)}
        visible={openChangeLoginCredentialModal}
      />
      <WelcomeModal
        open={openWelcomeModal}
        handleClose={handleCloseWelcomeModal}
      />
      <form
        onSubmit={formProps.handleSubmit(handleSubmitVerification)}
        noValidate
      >
        <FormCardContainer
          title='Store Info'
          subTitle='All your store information so techs can easily find you in the Bluon App.'
          // previewComponent={
          //   <PhonePreview
          //     formProps={formProps}
          //     image={data?.image as ImageFile}
          //     logo={data?.logo as ImageFile}
          //   />
          // }
          form={
            <StoreInfoInputs
              {...formProps}
              selectedBrands={data?.brands?.data}
              open_hours={formProps.getValues('branch_hours')}
              image={data?.image as ImageFile}
              logo={data?.logo as ImageFile}
              isLoading={loadingGet}
              errorMessageBrandModal={errorMessageBrandModal}
            />
          }
        />

        <FormCardContainer
          title='Store Contact Info'
          subTitle='How techs connect with your branch.'
          form={<StoreContactInputs {...formProps} isLoading={loadingGet} />}
        />
        <FormCardContainer
          title='Branch Manager Info'
          subTitle='Primary Business Contact for Bluon. For Bluon use only - will not be visible publicly.'
          form={<BranchManagerInputs {...formProps} isLoading={loadingGet} />}
        />

        <FormCardContainer
          title='Accounting Contact Info '
          subTitle='Individual who handles invoices. For Bluon use only - will not be visible publicly.'
          form={
            <AccountingContactInputs {...formProps} isLoading={loadingGet} />
          }
        />
        <FormCardContainer
          title='Counter Staff'
          form={<CounterStaffInputs {...formProps} isLoading={loadingGet} />}
        />

        <Paper elevation={0}>
          <Stack
            spacing={2}
            className='bl-confirmation-container'
            direction='row'
            justifyContent='flex-end'
          >
            {ability.can(
              EAbilityActions.MANAGE,
              EAbilitySubjects.TASKS_AND_CUSTOMERS,
            ) ? (
              <TakeRate
                className='bl-take-rate-container'
                take_rate={data?.take_rate}
                take_rate_until={data?.take_rate_until}
              />
            ) : (
              <Box className='bl-take-rate-container' />
            )}
            <Button
              variant='contained'
              color='secondary'
              data-testid='cancelButton'
              onClick={() => setOpenCancelFormModal(!openCancelFormModal)}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              loading={loadingPatch}
              variant='contained'
              color='primary'
              data-testid='confirmButton'
              startIcon={<CheckIcon />}
              disabled={
                !_.isEmpty(formProps.formState.errors) ||
                data?.open_hours.length === 0
              }
            >
              Confirm
            </Button>
          </Stack>
        </Paper>
      </form>
    </Container>
  );
};
