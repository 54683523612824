import { Route } from 'react-router-dom';

import CanWrapper from '@components/CanWrapper';
import PrivateRoute from '@components/PrivateRoute';
import { ConfigRoute } from '@config/routing/interface';
import MasterMechanic from '@pages/MasterMechanic';
import { GlobalComponents } from 'src/Global';

export const renderRoutes = (routes: ConfigRoute[], search: string) => {
  return routes.map((route: ConfigRoute) => {
    const { ability, loginRequired, path, includeGlobalComponents, children } =
      route;

    const RouteComponent = ability ? (
      <CanWrapper
        action={ability?.action}
        subject={ability?.subject}
        skipCanValidation={ability?.skipCanValidation}
      >
        <route.component />
      </CanWrapper>
    ) : (
      <route.component />
    );

    //  Returning route without children
    if (!children?.length)
      return (
        <Route
          key={path}
          path={path}
          element={
            loginRequired ? (
              <PrivateRoute>
                {RouteComponent}
                <MasterMechanic />
                {!!includeGlobalComponents && <GlobalComponents />}
              </PrivateRoute>
            ) : (
              RouteComponent
            )
          }
        />
      );

    //    Returning routes with children
    return (
      <Route
        key={path}
        path={path}
        element={
          loginRequired ? (
            <PrivateRoute>
              {RouteComponent}
              <MasterMechanic />
              {!!includeGlobalComponents && <GlobalComponents />}
            </PrivateRoute>
          ) : (
            RouteComponent
          )
        }
      >
        {renderRoutes(children, search)}
      </Route>
    );
  });
};
