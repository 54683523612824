import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { AddStaffModalFormKeys, ClaimingModalAddStaffProps } from './interface';
import {
  AddStaffModalValidationSchema,
  INVALID_EMAIL_ERROR,
} from './validations';

import { Button } from '@components/ButtonV2';
import { useSnackbar } from '@components/Snackbar/hooks';
import './styles.scss';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import { storeInfoGetRequest } from '@state/storeInfo/actions';
import {
  addNewCounterStaffRequest,
  setCounterStaffRequest,
} from '@state/task/actions';
import { emptyFunction } from '@utils/functions';

export const ClaimingModalAddStaff = ({
  onCancel,
  editMode = false,
  preloadInfo,
  afterUpdateCallback,
}: ClaimingModalAddStaffProps) => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const initialValues = {
    name: '',
    phone: '',
    email: '',
  };
  const {
    control,
    watch,
    trigger,
    formState: { errors },
    setError,
  } = useForm<AddStaffModalFormKeys>({
    resolver: yupResolver(AddStaffModalValidationSchema),
    defaultValues: preloadInfo
      ? {
          email: preloadInfo?.email ?? '',
          name: preloadInfo?.name ?? '',
          phone: preloadInfo?.phone ?? '',
        }
      : initialValues,
    mode: 'onBlur',
  });

  const nameValue = watch('name');
  const phoneValue = watch('phone');
  const emailValue = watch('email');

  const formCompleted = nameValue && phoneValue && emailValue;

  const { isLoadingAddCounterStaff, isLoadingSetCounterStaff } = useAppSelector(
    (state) => ({
      isLoadingAddCounterStaff: isRequestRunning(
        state,
        String(addNewCounterStaffRequest),
      ),
      isLoadingSetCounterStaff: isRequestRunning(
        state,
        String(setCounterStaffRequest),
      ),
    }),
  );

  const isDataValid = () => {
    return !errors.name && !errors.email && !errors.phone;
  };

  const handleSubmitValues = () => {
    if (isDataValid())
      dispatch(
        addNewCounterStaffRequest({
          email: emailValue,
          name: nameValue,
          phone: phoneValue,
          id: editMode ? preloadInfo?.id : '',
          errorCallback: (error: { email?: Array<string> }) => {
            error?.email &&
              setError('email', {
                type: 'Invalid',
                message: INVALID_EMAIL_ERROR,
              });
            showMessage('Unable to Add Counter Staff', {
              severity: 'error',
              autoHide: true,
              subMessage: 'Please try again.',
            });
          },
          successCallback: () => {
            if (!editMode) onCancel(nameValue);
            dispatch(storeInfoGetRequest());
            if (editMode && afterUpdateCallback) afterUpdateCallback();
          },
        }),
      );
  };

  return (
    <Box className='cm-add-staff-container'>
      <Box className='cm-add-staff-title-section'>
        <Typography variant='body1' className='title-text'>
          {editMode ? 'Add Phone & Email' : ' Add New Counter Staff'}
        </Typography>
      </Box>
      <Box className='cm-add-staff-inputs-section'>
        <form onSubmit={emptyFunction}>
          <Box className='cm-add-staff-input-name-section'>
            <Controller
              name='name'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextField
                  autoFocus={!preloadInfo?.name}
                  id='name'
                  variant='outlined'
                  label={
                    <>
                      Name{' '}
                      <span className='text-[1rem] text-[var(--bluon--light--red-5)]'>
                        *
                      </span>
                    </>
                  }
                  className='add-new-counter-staff-name-input'
                  name={field.name}
                  onBlur={() => {
                    field.onBlur();
                    trigger('name');
                  }}
                  color='primary'
                  onChange={field.onChange}
                  defaultValue={field.value}
                  error={!!fieldState.error}
                  fullWidth
                  helperText={formState.errors.name?.message}
                  autoComplete='off'
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box className='cm-add-staff-other-inputs-section input-container'>
            <Controller
              name='phone'
              control={control}
              render={({ field, fieldState, formState }) => (
                <NumberFormat
                  customInput={TextField}
                  autoFocus={!!(preloadInfo?.name && !preloadInfo?.phone)}
                  variant='outlined'
                  inputProps={{ maxLength: 15 }}
                  id='phone'
                  data-testid='phone'
                  label={
                    <>
                      Phone Number{' '}
                      <span className='text-[1rem] text-[var(--bluon--light--red-5)]'>
                        *
                      </span>
                    </>
                  }
                  className='add-new-counter-staff-phone-input'
                  color='primary'
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  helperText={formState.errors.phone?.message}
                  isNumericString
                  name={field.name}
                  isAllowed={({ value }) => !String(value).includes('.')}
                  onBlur={() => {
                    field.onBlur();
                    trigger('phone');
                  }}
                  defaultValue={field.value}
                  fullWidth
                  allowNegative={false}
                  autoComplete={'off'}
                />
              )}
            />
            <Controller
              name='email'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextField
                  id='email'
                  autoFocus={!!(preloadInfo?.phone && !preloadInfo.email)}
                  variant='outlined'
                  label={
                    <>
                      Email{' '}
                      <span className='text-[1rem] text-[var(--bluon--light--red-5)]'>
                        *
                      </span>
                    </>
                  }
                  className='add-new-counter-staff-email-input'
                  name={field.name}
                  onBlur={() => {
                    field.onBlur();
                    trigger('email');
                  }}
                  color='primary'
                  onChange={field.onChange}
                  defaultValue={field.value}
                  error={!!fieldState.error}
                  fullWidth
                  helperText={formState.errors.email?.message}
                  autoComplete='off'
                />
              )}
            />
          </Box>
        </form>
      </Box>
      <Box className='cm-add-staff-actions-section'>
        <Box className='cm-add-staff-action-add'>
          <Button
            variant='contained'
            className='add-counter-staff'
            type='submit'
            onClick={handleSubmitValues}
            disabled={
              !formCompleted ||
              isLoadingSetCounterStaff ||
              isLoadingAddCounterStaff ||
              !isDataValid()
            }
            loading={isLoadingSetCounterStaff || isLoadingAddCounterStaff}
          >
            {editMode ? 'Update & Select' : 'Add Counter Staff'}
          </Button>
        </Box>
        {!editMode && (
          <Box className='cm-add-staff-action-cancel'>
            <Button
              variant='text'
              className='cancel-text-btn'
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
