import WarningIcon from '@mui/icons-material/Warning';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from '@mui/material';

import { ChangeLoginCredentialModalProps } from './interface';

import { Button } from '@components/ButtonV2';
import { emptyFunction } from '@utils/functions';
import './styles.scss';

export const ChangeLoginCredentialModal = ({
  onAccept,
  onCancel,
  visible,
}: ChangeLoginCredentialModalProps): JSX.Element => (
  <Dialog
    className='bl-change-login-credential-dialog'
    maxWidth='xs'
    onClose={emptyFunction}
    open={visible}
  >
    <Stack
      border='-moz-initial'
      className='modal-content-container'
      justifyContent='center'
      spacing={1}
    >
      <WarningIcon className='warning-center-icon' />
      <DialogContent>
        <DialogContentText
          align='center'
          className='title'
          color='default'
          variant='h4'
        >
          Caution: You are changing login credentials
        </DialogContentText>
        <DialogContentText
          align='center'
          className='subtitle-one'
          color='default'
          variant='h6'
        >
          This will change the login for all USERS on this account.
        </DialogContentText>
        <DialogContentText
          align='center'
          className='subtitle'
          color='default'
          variant='h6'
        >
          If you make changes, please inform your team members so they can
          access the account
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack spacing={1} direction='row' justifyContent='center'>
          <Button
            className='cancel-btn'
            variant='contained'
            color='secondary'
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className='accept-btn'
            color='primary'
            onClick={onAccept}
            variant='contained'
          >
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Stack>
  </Dialog>
);
