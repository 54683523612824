import CallMergeIcon from '@mui/icons-material/CallMerge';
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DoneIcon from '@mui/icons-material/Done';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, RadioGroup, Skeleton } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  EOrderItemTypes,
  OrderItem,
  OrderItemDetail,
  ReplacementItem,
  UnlistedOrderItem,
} from '../../../../../../config/api/task/interface';
import FromModelButton from '../FromModelButton';
import { ItemInfoComponent } from '../ItemInfo';
import { ItemSelector } from '../ItemSelector';
import { KeyValueInterface } from '../ItemSpecs/interface';
import PartAvailability from '../PartAvailability';
import { WeCanGetItByPicker } from '../WeCanGetItByPicker';

import { CommercialModelPart } from './components/CommercialModelPart';
import { EPartType } from './constants';

import { Button } from '@components/ButtonV2';
import { CircularLoading } from '@components/CircularLoading';
import { ReplacementSettingsButton } from '@components/ReplacementSettingsButton';
import { useSnackbar } from '@components/Snackbar/hooks';
import { EAbilityActions, EAbilitySubjects } from '@config/canAbility/types';
import { partsSpecificationsString } from '@data/parts';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import useCustomAbility from '@hooks/useCustomAbility';
import useGoogleTagManager from '@hooks/useGoogleTagManager';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';
import { UseSpecBasedPartModal } from '@pages/Tasks/components/UseSpecBasedPartModal';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import {
  getItemCustomReplacementRequest,
  getItemInfoRequest,
  getItemReplacementsRequest,
  getMoreItemReplacementsRequest,
  getOemInfoRequest,
  patchCurrentItem,
  setItemCustomReplacementRequest,
  setItemCustomReplacementResponse,
} from '@state/task/actions';
import {
  getCurrentSelectedOrder,
  getItemReplacementsHasNextPage,
} from '@state/task/selectors';
import { EItemStatus } from '@utils/enums';
import { handleGetString, partImage } from '@utils/functions';
import { assembleOrderDetailGTMEvent } from '@utils/GoogleTagManager';
import { GOOGLE_TAG_MANAGER_EVENT_MESSAGES } from '@utils/GoogleTagManager/interface';

export const getPartName = (data: OrderItem | UnlistedOrderItem): string => {
  if (data?.item?.type === EOrderItemTypes.UNLISTED_ITEM)
    return (data as UnlistedOrderItem)?.item?.info?.name;

  const description = (data as OrderItem)?.item?.info?.description;
  if (description) return `${description} `;

  const strSpec = handleGetString(
    (data as OrderItem)?.item?.info?.description || data?.item.info || '',
    'type_title',
    partsSpecificationsString,
  );

  return `${strSpec} `;
};

export const getItemDescription = (
  item: OrderItem | UnlistedOrderItem,
): string => {
  if (item?.item?.type === EOrderItemTypes.UNLISTED_ITEM)
    return `${(item as UnlistedOrderItem)?.item?.info?.brand} - ${(
      item as UnlistedOrderItem
    )?.item?.info?.part_number}`;

  if (item.item.type === EPartType.GENERIC_PART)
    return item.detail?.part_number || '';

  if (item.item.type === EPartType.PART)
    if (!item.replacement)
      return `${item?.item?.info?.brand} ${(item as OrderItem)?.item?.info
        ?.number}`;
    else if (item.replacement.type === EPartType.GENERIC)
      return item.replacement.details?.description as string;
    else if (item.replacement.type === EPartType.SINGLE)
      return `${item.replacement?.details?.brand} ${item.replacement?.details?.number}`;
    else return 'Known Replacements Group';
  else if (item.item.type === EPartType.SUPPLY) return item.supply_detail;
  else return item.custom_detail;
};

interface ItemPartProps {
  item: OrderItem;
  expanded: boolean;
  changeSelectedId: (newId: string | null) => void;
  isLastItem: boolean;
  handleLastItemAction: () => void;
  selectedId: string | null;
}

export const ItemPart = ({
  item,
  expanded,
  changeSelectedId,
  handleLastItemAction,
  isLastItem,
  selectedId,
}: ItemPartProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const ability = useCustomAbility();
  const { showMessage } = useSnackbar();
  const { clearSelectedOrder } = useSetSelectedOrder();
  const { setGTMEvent } = useGoogleTagManager();

  const [selectedReplacement, setSelectedReplacement] =
    useState<ReplacementItem | null>(null);
  const [selectedOEM, setSelectedOEM] = useState<OrderItemDetail | null>(null);
  const [selectedSpecs, setSelectedSpecs] = useState<KeyValueInterface>();
  const [specsCount, setSpecsCount] = useState<number>(0);
  const [selectedNotes, setSelectedNotes] = useState<string>();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [weCanGetItByDate, setWeCanGetItByDate] = useState<string>('');
  const scrollDownRef = useRef<HTMLDivElement>(null);
  const [showUseSpecBasedPartModal, setShowUseSpecBasedPartModal] =
    useState<boolean>(false);
  const [loadingActionBtn, setLoadingActionBtn] = useState<string>('');
  const [
    commercialUnitPartNumberHasError,
    setCommercialUnitPartNumberHasError,
  ] = useState<boolean>(false);
  const [commercialUnitPartNumberValue, setCommercialUnitPartNumberValue] =
    useState<string>('');

  const handleModalClose = () => clearSelectedOrder(false);

  const {
    order,
    isLoadingReplacements,
    isLoadingCustomReplacement,
    isLoadingReplacementsNextPage,
    itemReplacementsHasNextPage,
    isLoadinSpecs,
    isLoadinOEMSpecs,
    isLoadingPatch,
    isLoadingSetCustomReplacement,
  } = useAppSelector((state) => ({
    order: getCurrentSelectedOrder(state),
    isLoadingReplacements: isRequestRunning(
      state,
      String(getItemReplacementsRequest),
    ),
    isLoadingCustomReplacement: isRequestRunning(
      state,
      String(getItemCustomReplacementRequest),
    ),
    isLoadingReplacementsNextPage: isRequestRunning(
      state,
      String(getMoreItemReplacementsRequest),
    ),
    itemReplacementsHasNextPage: getItemReplacementsHasNextPage(state, item.id),
    isLoadinSpecs: isRequestRunning(state, String(getItemInfoRequest)),
    isLoadinOEMSpecs: isRequestRunning(state, String(getOemInfoRequest)),
    isLoadingPatch: isRequestRunning(state, String(patchCurrentItem)),
    isLoadingSetCustomReplacement: isRequestRunning(
      state,
      String(setItemCustomReplacementRequest),
    ),
    replacementsRequestError: getRequestError(
      state,
      String(getItemReplacementsRequest),
    ),
  }));

  const isGenericPart = item.item.type === EPartType.GENERIC_PART;
  const gtmPartName = String(item.item?.info?.number);
  const gtmPartBrand = String(item.item?.info?.brand);

  const currentItem = item;
  const selected =
    item?.status === EItemStatus.Available
      ? item?.replacement
        ? item?.replacement?.details?.id
        : item?.item?.id
      : '';
  const [selectedPart, setSelectedPart] = useState(selected);
  const status =
    item.status === EItemStatus.Available && item.replacement
      ? 'replacement'
      : item.status;

  const changeExpanded = (flag = false) => {
    if (!flag) return changeSelectedId(null);
    changeSelectedId(item.item.id);
  };

  const handleAddCustomReplacement = useCallback(
    ({ brand, part_number }: { brand: string; part_number: string }) => {
      dispatch(
        setItemCustomReplacementRequest({
          orderId: order?.id,
          itemId: item.id,
          brand,
          part_number,
          errorCallback: () => {
            showMessage('Error updating order', {
              severity: 'error',
              autoHide: true,
            });
          },
          successCallback: (knownReplacementAdded: ReplacementItem) => {
            setShowUseSpecBasedPartModal(false);
            if (
              item.replacement &&
              item.replacement.type === EPartType.GENERIC &&
              knownReplacementAdded.id === item.replacement.id
            )
              dispatch(
                setItemCustomReplacementResponse({
                  item_id: item.id,
                  data: knownReplacementAdded,
                  update: true,
                }),
              );

            setSelectedOEM(null);
            setSelectedReplacement(knownReplacementAdded as ReplacementItem);
            setSelectedPart(knownReplacementAdded?.details.id as string);
          },
        }),
      );
    },
    [item],
  );

  const handleDispatchUpdatePart = (payload: any, actionId: string) => {
    setLoadingActionBtn(actionId);
    dispatch(
      patchCurrentItem({
        ...payload,
        errorCallback: () => {
          setLoadingActionBtn('');
          showMessage('Error updating order', {
            severity: 'error',
            autoHide: true,
          });
        },
        successCallback: () => {
          setShowUseSpecBasedPartModal(false);
          if (payload.status !== (EItemStatus.Pending as any))
            changeExpanded(false);

          if (isLastItem && handleLastItemAction) handleLastItemAction();

          setLoadingActionBtn('');
          showMessage('Order updated successfully', {
            severity: 'success',
            autoHide: true,
          });
        },
      }),
    );
  };

  const updatePart = (
    status: 'available' | 'not_available',
    available_at: string,
    actionId: string,
  ) => {
    if (!available_at) setWeCanGetItByDate('');

    const payload = {
      order: order?.id,
      available_at: available_at
        ? dayjs(available_at).local().format('YYYY-MM-DD')
        : null,
      item: item.id,
      quantity: item.quantity,
      price: item.price,
      status: status,
      replacement: selectedReplacement
        ? {
            type:
              selectedReplacement.type === EPartType.GENERIC
                ? EPartType.GENERIC
                : 'replacement',
            id: selectedReplacement.id,
          }
        : null,
    };

    isGenericPart
      ? handleDispatchUpdatePart(
          {
            ...payload,
            part_number: commercialUnitPartNumberValue,
            replacement: null,
          },
          actionId,
        )
      : handleDispatchUpdatePart(payload, actionId);
  };

  const handleScrollToFetchMore = useCallback(() => {
    if (isLoadingReplacementsNextPage || isLoadingReplacements) return;

    if (
      !isLoadingReplacementsNextPage &&
      !isLoadingReplacements &&
      itemReplacementsHasNextPage
    )
      dispatch(
        getMoreItemReplacementsRequest({ order: order?.id, item: item.id }),
      );
  }, [
    isLoadingReplacements,
    isLoadingReplacementsNextPage,
    itemReplacementsHasNextPage,
  ]);

  const createExpandedPartGTMEvent = (item: OrderItem) =>
    setGTMEvent(
      assembleOrderDetailGTMEvent(
        GOOGLE_TAG_MANAGER_EVENT_MESSAGES.PART_EXPANDED,
        {
          part: {
            brandName: gtmPartBrand,
            partName: gtmPartName,
            ...(item.knownReplacements?.data.length && {
              knownReplacements: item.knownReplacements?.data,
            }),
          },
          orderDetail: {
            orderId: order?.id,
          },
        },
      ),
    );

  useEffect(() => {
    if (expanded && !item.knownReplacements && isGenericPart)
      createExpandedPartGTMEvent(item);
    if (expanded && !item.knownReplacements && !isGenericPart)
      getItemReplacements();
  }, [expanded, item, order]);

  const getItemReplacements = () => {
    dispatch(
      getItemReplacementsRequest({
        order: order?.id,
        item: item.id,
        errorCallback: () => createExpandedPartGTMEvent(item),
        successCallback: () => {
          createExpandedPartGTMEvent(item);
          dispatch(
            getItemCustomReplacementRequest({
              orderId: order?.id,
              itemId: item.id,
            }),
          );
        },
      }),
    );
  };
  useEffect(() => {
    if (currentItem?.status !== EItemStatus.Pending && !selectedId)
      changeExpanded(false);
  }, [currentItem]);

  useEffect(() => {
    if (selectedOEM && selectedOEM.type !== EPartType.GENERIC_PART)
      if (!selectedOEM?.info.specifications)
        dispatch(
          getOemInfoRequest({
            order: order?.id,
            item: item.id,
            part: selectedPart,
          }),
        );
  }, [selectedOEM]);

  useEffect(() => {
    if (
      selectedReplacement &&
      selectedReplacement.type !== EPartType.GENERIC &&
      !isGenericPart
    )
      if (!selectedReplacement?.details.specifications)
        dispatch(
          getItemInfoRequest({
            order: order?.id,
            item: item.id,
            part: selectedPart,
          }),
        );
  }, [selectedReplacement]);

  useEffect(() => {
    if (
      (!isLoadinSpecs && selectedReplacement) ||
      (!isLoadinOEMSpecs && selectedOEM)
    ) {
      const specs = selectedOEM
        ? selectedOEM.info.specifications
        : selectedReplacement?.details.specifications;
      const notes = selectedOEM ? selectedOEM.note : selectedReplacement?.note;

      const newSpecs = { ...specs };
      Object.keys(newSpecs).forEach((key) => {
        if (isNil(newSpecs[key]) || !newSpecs[key]) delete newSpecs[key];
      });

      setSpecsCount(Object.entries(newSpecs).length);
      setSelectedSpecs(newSpecs);
      setSelectedNotes(notes);
    }
  }, [isLoadinSpecs, isLoadinOEMSpecs, selectedReplacement, selectedOEM]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (!itemReplacementsHasNextPage || !e) return;

    const target = e.target as EventTarget & HTMLDivElement;

    const bottom =
      Math.abs(
        target.scrollHeight - (target.scrollTop + target.clientHeight),
      ) <= 1;

    if (bottom) handleScrollToFetchMore();
  };

  const handleOpenDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  useEffect(() => {
    setShowDatePicker(false);
  }, [expanded, selectedPart, selectedReplacement]);

  const hasOem = !isEmpty(item.oem);

  const editSpecPart = selectedReplacement?.type === EPartType.GENERIC;

  return (
    <>
      <div className='mb-1 flex  flex-col gap-1'>
        <div className='flex w-full flex-col rounded-md border'>
          <div
            className='flex cursor-pointer justify-between p-2 pr-4'
            onClick={() => changeExpanded(!expanded)}
          >
            <div className='flex items-center gap-2 text-sm'>
              <div>
                <img
                  alt='logo'
                  className='h-10 w-10'
                  loading='lazy'
                  src={partImage(item.item.info, item.item.type)}
                />
              </div>
              <div className='font-bold'>{getPartName(item)}</div>
              <div className='opacity-60'>{getItemDescription(item)}</div>
            </div>
            <PartAvailability
              item={item}
              weCanGetItByDate={weCanGetItByDate}
              selectedReplacement={selectedReplacement}
              expanded={expanded}
            />
          </div>
          {expanded && (
            <div>
              {isGenericPart ? (
                <CommercialModelPart
                  category={item.item.info.action}
                  commercialUnitPartNumberValue={commercialUnitPartNumberValue}
                  modelNumber={
                    item?.detail?.full_model ||
                    item?.item_order_detail?.full_model ||
                    ''
                  }
                  notes={
                    item?.detail?.notes || item?.item_order_detail?.notes || ''
                  }
                  oemModel={String(item?.oem?.model) || ''}
                  oemModelId={String(item?.oem?.id) || ''}
                  partName={getPartName(item)}
                  serialNumber={
                    item?.detail?.serial_number ||
                    item?.item_order_detail?.serial_number ||
                    ''
                  }
                  setCommercialUnitPartNumberHasError={
                    setCommercialUnitPartNumberHasError
                  }
                  setCommercialUnitPartNumberValue={
                    setCommercialUnitPartNumberValue
                  }
                />
              ) : (
                <div>
                  {(isLoadingReplacements || isLoadingCustomReplacement) && (
                    <SkeletonContent />
                  )}
                  {!isLoadingReplacements && !isLoadingCustomReplacement && (
                    <RadioGroup
                      row
                      name='parts'
                      value={selectedPart}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setSelectedPart(
                          (event.target as HTMLInputElement).value,
                        );
                      }}
                    >
                      <div className='flex w-full justify-between  border-t'>
                        <div
                          className='max-h-[425px] w-full overflow-auto py-2'
                          onScroll={handleScroll}
                        >
                          <div>
                            <div
                              className={classNames(
                                'flex flex-row justify-between',
                                {
                                  'mb-2': hasOem,
                                },
                              )}
                            >
                              <div className='ml-2 text-[var(--bluon--default--blue-3)]'>
                                OEM Part
                              </div>
                              {hasOem &&
                                ability.can(
                                  EAbilityActions.MANAGE,
                                  EAbilitySubjects.SEARCH,
                                ) && (
                                  <FromModelButton
                                    onClick={handleModalClose}
                                    brand={String(item?.item?.info?.brand)}
                                    model={String(item?.oem?.model)}
                                    modelId={String(item?.oem?.id)}
                                  />
                                )}
                            </div>
                            <div>
                              <ItemSelector
                                id={item.item?.id}
                                selectedId={selectedPart as string}
                                brand={item.item?.info?.brand}
                                number={gtmPartName}
                                onSelect={() => {
                                  setGTMEvent(
                                    assembleOrderDetailGTMEvent(
                                      GOOGLE_TAG_MANAGER_EVENT_MESSAGES.OEM_PART_SELECTED,
                                      {
                                        part: {
                                          brandName: gtmPartBrand,
                                          partName: gtmPartName,
                                        },
                                        orderDetail: {
                                          orderId: order?.id,
                                        },
                                      },
                                    ),
                                  );
                                  setSelectedReplacement(null);
                                  setSelectedOEM(item.item);
                                }}
                                selectPart={setSelectedPart}
                              />
                            </div>
                          </div>
                          <div>
                            <UseSpecBasedPartModal
                              open={showUseSpecBasedPartModal}
                              editMode={editSpecPart}
                              onClose={() =>
                                setShowUseSpecBasedPartModal(false)
                              }
                              handleUseReplacement={({
                                brand,
                                part_number,
                              }) => {
                                handleAddCustomReplacement({
                                  brand,
                                  part_number,
                                });
                              }}
                              quantity={item.quantity}
                              isLoading={isLoadingSetCustomReplacement}
                              replacement={selectedReplacement}
                              partSelected={
                                <>
                                  <div className='flex items-center gap-2 text-sm'>
                                    <div>
                                      <img
                                        alt='logo'
                                        className='h-[4.063rem] w-[4.063rem]'
                                        loading='lazy'
                                        src={partImage(
                                          item.item.info,
                                          item.item.type,
                                        )}
                                      />
                                    </div>
                                    <div className='font-roboto text-[1.125rem] font-bold leading-[1.609rem] tracking-[0.011rem]'>
                                      {getPartName(item)}
                                    </div>
                                    <div className='text-[1rem] leading-[1.43rem] tracking-[0.011rem] opacity-60'>
                                      {getItemDescription(item)}
                                    </div>
                                  </div>
                                </>
                              }
                            />
                            {!!currentItem?.knownReplacements?.data.length && (
                              <>
                                <div className='mx-2 mt-2 flex h-[3rem] items-center justify-between'>
                                  <div
                                    className={classNames(
                                      'ml-2 flex items-center gap-3 text-[var(--bluon--default--blue-3)]',
                                      {
                                        'mb-2': hasOem,
                                      },
                                    )}
                                  >
                                    Replacement Parts
                                    <ReplacementSettingsButton
                                      startIcon={<SettingsOutlinedIcon />}
                                      onSuccessCallback={getItemReplacements}
                                    >
                                      Sort Brands
                                    </ReplacementSettingsButton>
                                  </div>
                                  <Button
                                    className='h-[1.875rem]'
                                    variant='outlined'
                                    onClick={() =>
                                      setShowUseSpecBasedPartModal(true)
                                    }
                                    disabled={
                                      selectedReplacement?.type !==
                                        EPartType.GENERIC &&
                                      item.knownReplacements.data.some(
                                        (replacement) =>
                                          replacement.type ===
                                          EPartType.GENERIC,
                                      )
                                    }
                                  >
                                    {editSpecPart ? 'EDIT' : 'USE'} SPEC BASED
                                    Part
                                  </Button>
                                </div>
                                <div>
                                  {currentItem?.knownReplacements?.data?.map(
                                    (replacement) => (
                                      <>
                                        <ItemSelector
                                          key={replacement.details?.id}
                                          id={replacement.details?.id}
                                          selectedId={selectedPart as string}
                                          brand={replacement.details?.brand}
                                          number={replacement.details?.number}
                                          onSelect={() => {
                                            setGTMEvent(
                                              assembleOrderDetailGTMEvent(
                                                GOOGLE_TAG_MANAGER_EVENT_MESSAGES.REPLACEMENT_PART_SELECTED,
                                                {
                                                  part: {
                                                    brandName:
                                                      replacement.details
                                                        ?.brand,
                                                    partName:
                                                      replacement.details
                                                        ?.number,
                                                  },
                                                  orderDetail: {
                                                    orderId: order?.id,
                                                  },
                                                },
                                              ),
                                            );
                                            setSelectedOEM(null);
                                            setSelectedReplacement(replacement);
                                          }}
                                          selectPart={setSelectedPart}
                                        />
                                      </>
                                    ),
                                  )}
                                </div>
                                {itemReplacementsHasNextPage && (
                                  <Box
                                    className='flex h-16 items-center justify-center overflow-hidden'
                                    ref={scrollDownRef}
                                  >
                                    {isLoadingReplacementsNextPage && (
                                      <CircularLoading />
                                    )}
                                  </Box>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {(selectedOEM || selectedReplacement) &&
                          (selectedNotes || specsCount > 0) && (
                            <div className='max-h-[500px] w-[40%] overflow-auto border-l bg-[var(--bluon--default--blue--gray-9)]'>
                              <ItemInfoComponent
                                itemSpecs={selectedSpecs}
                                specsCount={specsCount}
                                note={selectedNotes}
                                partName={gtmPartName}
                                partBrand={gtmPartBrand}
                                isSpecsLoading={
                                  isLoadinSpecs || isLoadinOEMSpecs
                                }
                                type={
                                  selectedOEM
                                    ? (selectedOEM?.info?.type as string)
                                    : (selectedReplacement?.details
                                        .type as string)
                                }
                              />
                            </div>
                          )}
                      </div>
                    </RadioGroup>
                  )}
                </div>
              )}
              <div className='flex items-center justify-center border-t p-4'>
                <div className='flex min-h-[58px] w-full flex-col justify-center gap-4 md:w-[100%] md:flex-row '>
                  <Button
                    className={classNames(
                      'w-full max-w-full !py-4 md:w-[20%]',
                      {
                        'shadow-button':
                          !isLoadingPatch &&
                          status !== EItemStatus.NotAvailable,
                      },
                    )}
                    variant='outlined'
                    color='error'
                    startIcon={<ClearIcon />}
                    onClick={() =>
                      updatePart('not_available', '', 'cant_get_it')
                    }
                    disabled={
                      isLoadingPatch || status === EItemStatus.NotAvailable
                    }
                    loading={
                      isLoadingPatch && loadingActionBtn === 'cant_get_it'
                    }
                  >
                    {"Can't Get It"}
                  </Button>
                  <div className='w-full max-w-full border-0 md:w-[40%]'>
                    <Button
                      className='h-full w-full'
                      variant='outlined'
                      startIcon={<DateRangeIcon />}
                      disabled={
                        (!isGenericPart &&
                          !selectedPart &&
                          !selectedReplacement) ||
                        isLoadingPatch ||
                        (isGenericPart &&
                          (!commercialUnitPartNumberValue ||
                            commercialUnitPartNumberHasError))
                      }
                      loading={
                        isLoadingPatch && loadingActionBtn === 'get_it_by'
                      }
                      onClick={handleOpenDatePicker}
                    >
                      We Can Get It By...
                    </Button>
                    {showDatePicker && (
                      <WeCanGetItByPicker
                        open={showDatePicker}
                        onClose={() => setShowDatePicker(false)}
                        onAccept={(ISOString) => {
                          setWeCanGetItByDate(ISOString as string);
                          setShowDatePicker(false);
                          updatePart(
                            'available',
                            ISOString as string,
                            'get_it_by',
                          );
                        }}
                        available_at={
                          item.available_at
                            ? dayjs(
                                new Date(
                                  new Date(item?.available_at).setDate(
                                    new Date(item?.available_at).getDate() + 1,
                                  ),
                                ).toISOString(),
                              )
                            : null
                        }
                      />
                    )}
                  </div>

                  {!selectedReplacement && (
                    <Button
                      className='w-full max-w-full !py-4 shadow-button md:w-[40%]'
                      variant='contained'
                      startIcon={<DoneIcon />}
                      onClick={() => updatePart('available', '', 'we_have_it')}
                      disabled={
                        (!isGenericPart &&
                          (!selectedPart ||
                            isLoadingPatch ||
                            (status === EItemStatus.Available &&
                              !item.available_at))) ||
                        (isGenericPart &&
                          (!commercialUnitPartNumberValue ||
                            commercialUnitPartNumberHasError))
                      }
                      loading={
                        isLoadingPatch && loadingActionBtn === 'we_have_it'
                      }
                    >
                      We Have It
                    </Button>
                  )}
                  {selectedReplacement && (
                    <Button
                      className='w-full !py-4 shadow-button md:w-[40%]'
                      variant='contained'
                      color='success'
                      startIcon={<CallMergeIcon />}
                      onClick={() =>
                        updatePart('available', '', 'use_this_replacement')
                      }
                      disabled={
                        !selectedReplacement ||
                        isLoadingPatch ||
                        (status === EItemStatus.Replacement &&
                          item.replacement?.details.id ===
                            selectedReplacement.details.id)
                      }
                      loading={
                        isLoadingPatch &&
                        loadingActionBtn === 'use_this_replacement'
                      }
                    >
                      Use This Replacement
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const SkeletonContent = (): JSX.Element => {
  const parts = [1, 2, 3, 4];

  const PartDetailSkeleton = (): JSX.Element => {
    return (
      <div className='flex h-10 w-full items-center justify-between border-b'>
        <div className='flex items-center'>
          <div className='ml-3 flex gap-2 '>
            <Skeleton variant='circular' width={18} height={18} />
          </div>
          <div className='ml-6'>
            <Skeleton width={150} height={30} />
          </div>
        </div>
        <div className='mr-2'>
          <Button
            variant='outlined'
            color='secondary'
            size='small'
            disabled={true}
            className='h-6 !border-[var(--bluon--light--gray-22)]'
          >
            Copy #
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className='flex w-full justify-between border-t'>
      <div className='w-full py-2'>
        <div>
          <div className='ml-2 text-[var(--bluon--default--blue-3)]'>
            OEM Part
          </div>
          <div className='ml-2'>
            <PartDetailSkeleton />
          </div>
        </div>
        <div>
          <div className='ml-2 mt-2 text-[var(--bluon--default--blue-3)]'>
            Replacement Parts
          </div>
          <div className='ml-2'>
            {parts.map((part) => (
              <PartDetailSkeleton key={part} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
