import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import UnlistedPartItemActions from '../UnlistedPartItemActions';

import {
  UnlistedItemDetailProps,
  UnlistedPartFormFieldProps,
  UnlistedPartItemFormKeys,
  UnlistedPartItemNotesProps,
} from './interface';
import { UnlistedPartItemFormValidations } from './validations';

import './index.scss';

import { useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import { patchCurrentItem } from '@state/task/actions';
import { getCurrentSelectedOrder } from '@state/task/selectors';

//  Helper component
const UnlistedPartFormField = ({
  field_id,
  label,
  control,
  trigger,
}: UnlistedPartFormFieldProps) => (
  <div className='flex w-[49%] flex-row items-center justify-center'>
    <Controller
      name={field_id}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          className='w-full'
          id={field_id}
          data-testid={field_id}
          variant='outlined'
          label={label}
          name={field.name}
          onBlur={() => {
            field.onBlur();
            trigger(field_id);
          }}
          onChange={field.onChange}
          defaultValue={field.value}
          error={fieldState?.isTouched && !!fieldState.error}
          fullWidth
          autoComplete={'off'}
          helperText={fieldState?.error?.message || 'Required'}
        />
      )}
    />
  </div>
);

//  Helper component
const UnlistedPartItemNotes = ({ item }: UnlistedPartItemNotesProps) =>
  !item?.item?.info?.notes ? null : (
    <div className='mb-12 flex w-full flex-col justify-center'>
      <div className='not-talic text-normal text-4xl text-[var(--bluon--ds--brand)]'>
        Customer Notes:
      </div>
      <div className='text-normal mt-4 text-lg not-italic text-[var(--bluon--ds--label-black)]'>
        {item?.item?.info?.notes}
      </div>
    </div>
  );

//  Main component
const UnlistedPartItemDetail = ({
  item,
  isLastItem,
  handleLastItemAction,
  closeExpanded,
}: UnlistedItemDetailProps) => {
  const { order, isUpdatingOrderItem } = useAppSelector((state) => ({
    order: getCurrentSelectedOrder(state),
    isUpdatingOrderItem: isRequestRunning(state, String(patchCurrentItem)),
  }));

  const { control, formState, trigger, watch } =
    useForm<UnlistedPartItemFormKeys>({
      defaultValues: {
        brand: item?.item?.info?.brand || '',
        part_number: item?.item?.info?.part_number || '',
      },
      mode: 'onChange',
      resolver: yupResolver(UnlistedPartItemFormValidations),
    });
  const brand = watch('brand');
  const part_number = watch('part_number');

  //  Validating form on component did mount
  useEffect(() => {
    trigger('brand');
    trigger('part_number');
  }, [trigger]);

  const validateForm = async () => {
    const brandError = await trigger('brand');
    const numberError = await trigger('part_number');

    return brandError && numberError;
  };

  //  Rendering
  return (
    <div className='flex w-full flex-col'>
      <div className='flex w-full flex-col items-center justify-center bg-[var(--bluon--ds--selected)] p-12'>
        <UnlistedPartItemNotes item={item} />
        <div className='unlisted-part-form flex w-full flex-row items-center justify-between'>
          <UnlistedPartFormField
            field_id='brand'
            label='Brand'
            control={control}
            trigger={trigger}
          />
          <UnlistedPartFormField
            field_id='part_number'
            label='Part Number'
            control={control}
            trigger={trigger}
          />
        </div>
      </div>
      <UnlistedPartItemActions
        orderId={order?.id || ''}
        formValues={{ brand, part_number }}
        item={item}
        disableActions={Object.keys(formState?.errors).length > 0}
        isLoading={isUpdatingOrderItem}
        isLastItem={isLastItem}
        handleLastItemAction={handleLastItemAction}
        closeExpanded={closeExpanded}
        triggerValidations={validateForm}
      />
    </div>
  );
};

export default UnlistedPartItemDetail;
