import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { AccountingContactInputsProps } from './interface';

import InputComponent from '@components/InputComponent';
import { FormTextInputLengths } from '@utils/FormInputLengths';

export const AccountingContactInputs = (
  props: AccountingContactInputsProps,
): JSX.Element => {
  const { control, isLoading } = props;

  return (
    <Grid
      container
      justifyContent='center'
      className='store-info-inputs-container'
    >
      <Grid item xs={12} md={9}>
        <Controller
          name='accountant_name'
          control={control}
          render={({ field, fieldState, formState }) => (
            <InputComponent
              variant='outlined'
              id='accountant_name'
              data-testid='accountingName'
              label='Name'
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              helperText={formState.errors.accountant_name?.message}
              keepHelperTextSpace
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Controller
          name='accountant_phone'
          control={control}
          render={({ field, fieldState, formState }) => (
            <InputComponent
              variant='outlined'
              id='accountant_phone'
              data-testid='accountingPhoneNumber'
              label='Phone Number'
              name={field.name}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              inputProps={{
                maxLength: FormTextInputLengths.profile.phoneFullNumber,
              }}
              helperText={formState.errors.accountant_phone?.message}
              keepHelperTextSpace
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Controller
          name='accountant_email'
          control={control}
          render={({ field, fieldState, formState }) => (
            <InputComponent
              variant='outlined'
              id='accountant_email'
              data-testid='accountingEmail'
              label='Email'
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              error={!!fieldState.error}
              inputProps={{
                maxLength: FormTextInputLengths.profile.email,
              }}
              helperText={formState.errors.accountant_email?.message}
              keepHelperTextSpace
              isLoading={isLoading}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
