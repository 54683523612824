import { useCallback } from 'react';

import useGoogleTagManager from '@hooks/useGoogleTagManager';
import {
  assembleFeedbackOpenGTMEvent,
  assembleFeedbackSubmitGTMEvent,
  assembleManualClickGTMEvent,
} from '@utils/GoogleTagManager';
import { GOOGLE_TAG_MANAGER_EVENT_MESSAGES } from '@utils/GoogleTagManager/interface';

const useTrackGTMEvents = () => {
  const { setGTMEvent } = useGoogleTagManager();

  const trackManualCardClick = useCallback(
    (title: string, url: string, modelNumber: string, modelId: string) => {
      setGTMEvent(
        assembleManualClickGTMEvent(
          `${GOOGLE_TAG_MANAGER_EVENT_MESSAGES.MANUAL_SELECTION}`,
          {
            manual: {
              title,
              url,
            },
            model: {
              modelNumber,
              id: modelId,
            },
          },
        ),
      );
    },
    [setGTMEvent],
  );

  const trackFeedbackOpen = useCallback(
    () => setGTMEvent(assembleFeedbackOpenGTMEvent()),
    [setGTMEvent],
  );

  const trackFeedbackSubmit = useCallback(
    () => setGTMEvent(assembleFeedbackSubmitGTMEvent()),
    [setGTMEvent],
  );

  return { trackManualCardClick, trackFeedbackOpen, trackFeedbackSubmit };
};

export default useTrackGTMEvents;
