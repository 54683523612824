import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import './styles.scss';
import Button from '../../../components/Button';
import TakeRate from '../../../components/TakeRate';
import { AuthHeader } from '../components/AuthHeader';
import PasswordInput from '../components/PasswordInput';
import { AcceptTermsCheckbox } from '../components/TermsCheckbox';
import { TermsOfServiceLabel } from '../components/TermsOfServiceLabel';
import { WelcomeContainer } from '../components/WelcomeContainer';

import { SetNewPasswordFormKeys } from './interfaces';
import { SetNewPasswordValidationSchema } from './validations';

import { useAppDispatch, useAppSelector } from '@hooks/state';
import useNavigateToHome from '@hooks/useNavigateToHome';
import { changePasswordRequest, logout } from '@state/auth/actions';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import { getLimitedStoreInfoRequest } from '@state/storeInfo/actions';
import { getLimitedStoreInfoData } from '@state/storeInfo/selectors';

export const SetNewPassword = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { navigateToHome } = useNavigateToHome();

  const { loading, changePasswordErrors, data } = useAppSelector((state) => ({
    data: getLimitedStoreInfoData(state),
    loading: isRequestRunning(state, String(changePasswordRequest)),
    changePasswordErrors: getRequestError(state, String(changePasswordRequest)),
    limitedStoreInfoErrors: getRequestError(
      state,
      String(getLimitedStoreInfoData),
    ),
  }));

  useEffect(() => {
    dispatch(getLimitedStoreInfoRequest());
  }, []);

  useEffect(() => {
    if (!changePasswordErrors) return;
    onHandleBack(); // TO DO: Fix error handling when service fails
  }, [changePasswordErrors]);

  useEffect(() => {
    dispatch(getLimitedStoreInfoRequest());
  }, []);

  const initialValues: SetNewPasswordFormKeys = {
    password: '',
    passwordConfirmation: '',
    acceptTerms: false,
  };

  const {
    control,
    handleSubmit,
    formState,
    watch,
    getValues,
    clearErrors,
    setError,
  } = useForm<SetNewPasswordFormKeys>({
    resolver: yupResolver(SetNewPasswordValidationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const password = watch('password');

  useEffect(() => {
    if (!password) return;
    const confirmPassword = getValues().passwordConfirmation;
    if (confirmPassword && confirmPassword === password)
      clearErrors('passwordConfirmation');
    else if (confirmPassword)
      setError('passwordConfirmation', {
        type: 'manual',
        message: 'Password doesn’t match',
      });
  }, [password]);

  const disabledSaveButton = !(
    (!formState.errors.password &&
      !formState.errors.acceptTerms &&
      !formState.errors.passwordConfirmation &&
      formState.dirtyFields.acceptTerms &&
      formState.dirtyFields.password &&
      formState.dirtyFields.passwordConfirmation) ||
    loading
  );

  const handleSubmitForm = (values: SetNewPasswordFormKeys) => {
    dispatch(
      changePasswordRequest({
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        callback: () => navigateToHome(),
      }),
    );
  };

  const onHandleBack = () => {
    dispatch(logout(() => navigate('/login')));
  };

  return (
    <WelcomeContainer>
      <Box className='bl-set-password-container'>
        <AuthHeader
          title='Create New Password'
          subTitle='This is a one time deal. Your password must be at least 8 characters long.'
        />

        <form onSubmit={handleSubmit(handleSubmitForm)} className='form'>
          <Grid container className='inputs'>
            <Grid item xs={12}>
              <PasswordInput
                variant='outlined'
                name='password'
                label='Password'
                control={control}
                id='password'
                data-testid='password'
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordInput
                variant='outlined'
                name='passwordConfirmation'
                control={control}
                id='passwordConfirmation'
                label='Confirm Password'
                data-testid='passwordConfirmation'
              />
            </Grid>
          </Grid>

          <AcceptTermsCheckbox control={control} />

          <Grid container justifyContent='center'>
            <Grid item xs>
              <TakeRate
                className='bl-take-rate-container'
                take_rate={data?.take_rate}
                take_rate_until={data?.take_rate_until}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Button
                data-testid='saveNewPasswordButton'
                variant='contained'
                color='primary'
                type='submit'
                loading={loading}
                disabled={disabledSaveButton}
              >
                Save New Password
              </Button>
            </Grid>
          </Grid>
        </form>
        <TermsOfServiceLabel />
      </Box>
    </WelcomeContainer>
  );
};
