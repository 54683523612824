import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Avatar,
  Box,
  CircularProgress,
  Fade,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { DragEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AcceptModal } from '@components/AcceptModal';
import { useSnackbar } from '@components/Snackbar/hooks';
import { useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import { deleteInvoiceRequest, setInvoiceRequest } from '@state/task/actions';
import {
  getCurrentSelectedOrderId,
  getCurrentUploadedInvoice,
} from '@state/task/selectors';
import './styles.scss';

export const MAX_INVOICE_SIZE = 3000000; // 3 MB in bytes
export const INVOICE_TYPES = ['jpg', 'jpeg', 'png', 'svg', 'pdf'];

export const UploadInvoice = ({
  disableForm = false,
  disabledSxState,
  onUploadFileEvent,
}: {
  disableForm: boolean;
  disabledSxState: object;
  onUploadFileEvent?: () => void;
}) => {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const [invoice, setInvoice] = useState<File>();
  const [modalConfig, setModalConfig] = useState<{
    open: boolean;
    type: 'delete';
  }>({ open: false, type: 'delete' });

  const { order, isUploadLoading, isDeleteLoading, uploadedInvoice } =
    useAppSelector((state) => ({
      order: getCurrentSelectedOrderId(state),
      isUploadLoading: isRequestRunning(state, String(setInvoiceRequest)),
      isDeleteLoading: isRequestRunning(state, String(deleteInvoiceRequest)),
      uploadedInvoice: getCurrentUploadedInvoice(state),
    }));

  useEffect(() => {
    saveInvoiceRequest();
  }, [invoice]);

  const invalidType = useMemo(() => {
    if (invoice)
      return !INVOICE_TYPES.some((item) =>
        invoice.type.toLowerCase().includes(item),
      );
  }, [invoice]);

  const invalidSize = invoice ? invoice?.size > MAX_INVOICE_SIZE : false;

  const invoiceType = (uploadedInvoice?.url ?? invoice?.name ?? '')
    .split('.')
    .pop()
    ?.toUpperCase();

  const invoiceSize = invoice
    ? (invoice.size / Math.pow(1024, 1)).toFixed(0)
    : '';

  const uploadedInvoiceName = decodeURI(
    uploadedInvoice?.url.split('/').pop() ?? '',
  )?.replace('-', ' ');

  const uploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file) setInvoice(file);
    }
  };

  const dropHandler = (event: DragEvent<HTMLDivElement>) => {
    if (invoice || uploadedInvoice) return;
    event.preventDefault();
    if (event.dataTransfer.items)
      for (let i = 0; i < event.dataTransfer.items.length; i++)
        if (event.dataTransfer.items[i].kind === 'file') {
          const file: File | null = event.dataTransfer.items[i].getAsFile();
          if (file) setInvoice(file);
        }
  };

  const dragOverHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const previewInvoice = () => {
    if (invoice) window.open(URL.createObjectURL(invoice), '_blank');
  };

  const previewUploadedInvoice = () => {
    if (uploadedInvoice) window.open(uploadedInvoice.url, '_blank');
  };

  const getSecondaryLabel = () => {
    if (!invalidSize && !invalidType)
      return uploadedInvoice ? '' : invoiceSize + 'kb • ';

    return invalidSize ? 'File too large • ' : 'File format not supported • ';
  };

  const modalAcceptCallback = () => {
    setInvoice(undefined);

    if (uploadedInvoice)
      dispatch(
        deleteInvoiceRequest({
          order,
          errorCallback: () =>
            showMessage('Error updating order', {
              severity: 'error',
              autoHide: true,
            }),
        }),
      );

    setModalConfig({ open: false, type: 'delete' });
  };

  const modalDenyCallback = () => {
    setModalConfig({ open: false, type: 'delete' });
  };

  const uploadFile = () => {
    const file = document.getElementById('invoice-upload');
    if (file) file.click();
  };

  const statusClass = useMemo(() => {
    if (invalidSize || invalidType) return 'error';

    if (invoice) return 'loading';

    if (uploadedInvoice) return 'success';

    return 'pending';
  }, [invoice, uploadedInvoice, invalidSize, invalidType]);

  const uploadTitle = useMemo(() => {
    if (isUploadLoading) return 'Uploading...';

    if (isDeleteLoading) return 'Deleting...';

    if (uploadedInvoice) return 'Upload Successful';

    return (
      <>
        <u onClick={uploadFile}>Click to upload</u> or drag and drop
      </>
    );
  }, [invoice, uploadedInvoice, isUploadLoading, isDeleteLoading]);

  const saveInvoiceRequest = () => {
    if (!invoice || statusClass === 'error') return;

    dispatch(
      setInvoiceRequest({
        order,
        file: invoice,
        successCallback: () => {
          setInvoice(undefined);
          if (onUploadFileEvent) onUploadFileEvent();
        },
        errorCallback: () =>
          showMessage('Error updating order', {
            severity: 'error',
            autoHide: true,
          }),
      }),
    );
  };
  const _getUploadedFileCard = () => {
    return (
      <Box className='document-tab'>
        <ListItem
          className={
            invalidSize || invalidType ? 'upload-error' : 'upload-default'
          }
          secondaryAction={
            <Box
              display='flex'
              justifyContent='center'
              gap='1.5rem'
              alignItems='center'
            >
              {!disableForm && (
                <IconButton
                  edge='end'
                  aria-label='delete'
                  color='default'
                  onClick={() => setModalConfig({ open: true, type: 'delete' })}
                >
                  <CloseIcon />
                </IconButton>
              )}

              {(isUploadLoading || isDeleteLoading) && (
                <Fade in={isUploadLoading || isDeleteLoading}>
                  <CircularProgress />
                </Fade>
              )}
              {uploadedInvoice && !isDeleteLoading && !disableForm && (
                <Fade in={!!uploadedInvoice}>
                  <CheckCircleIcon color='success' fontSize='large' />
                </Fade>
              )}
            </Box>
          }
        >
          <ListItemAvatar
            className={disableForm ? 'upload-disabled' : 'upload-icon'}
            onClick={invoice ? previewInvoice : previewUploadedInvoice}
          >
            <Avatar>
              <UploadFileIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            onClick={invoice ? previewInvoice : previewUploadedInvoice}
            primary={uploadedInvoice ? uploadedInvoiceName : invoice?.name}
            secondary={`${getSecondaryLabel()}${
              invalidSize || invalidType ? 'Failed' : invoiceType
            }`}
          />
        </ListItem>
      </Box>
    );
  };

  return (
    <>
      <Box
        className={`bl-upload-invoice-area ${statusClass}`}
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
      >
        <Box className='bl-upload-section' sx={disabledSxState}>
          <IconButton
            color='primary'
            component='label'
            className={disableForm ? 'disabled-button' : 'primary-button'}
          >
            <input
              hidden
              accept='image/jpg, image/png, application/pdf, image/svg+xml'
              multiple
              type='file'
              onChange={uploadHandler}
              id='invoice-upload'
              name='invoice-upload'
              onClick={(event) => {
                event.currentTarget.value = '';
              }}
            />
            <UploadFileIcon />
          </IconButton>

          <Typography
            variant='subtitle1'
            color='initial'
            className={`${disableForm ? 'disabled-upload-text' : ''}`}
          >
            {uploadTitle}
          </Typography>

          {!invoice && !uploadedInvoice && (
            <Typography
              variant='caption'
              color='initial'
              className={`${disableForm ? 'disabled-upload-text' : ''}`}
            >
              SVG, PNG, JPG or PDF (max. 3MB)
            </Typography>
          )}
        </Box>
        {(invoice || uploadedInvoice) && _getUploadedFileCard()}

        <a id='link' download style={{ display: 'none' }} />
        {modalConfig.open && (
          <AcceptModal
            open={modalConfig.open}
            content={
              <Typography variant='h6' color='initial'>
                Are you sure you want to {modalConfig.type}{' '}
                {invoice?.name ?? uploadedInvoiceName}
              </Typography>
            }
            acceptCallback={modalAcceptCallback}
            denyCallback={modalDenyCallback}
          />
        )}
      </Box>
    </>
  );
};
