import { Components } from '@mui/material';

import { bluonColors } from './colors';

export const MuiSwitchTheme: Components['MuiSwitch'] = {
  variants: [
    {
      props: { className: 'b4b-custom-checked', color: 'success' },
      style: {
        '.MuiSwitch-track': {
          backgroundColor: `${bluonColors.green['600']} !important`,
        },
        '.Mui-checked': {
          '.MuiSwitch-thumb': {
            backgroundColor: bluonColors.green['600'],
          },
        },

        '.Mui-disabled': {
          '.MuiSwitch-thumb': {
            opacity: '60%',
          },
        },
      },
    },
  ],
};
