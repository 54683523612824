import {
  clearOrdersLogsState,
  moreOrdersLogsResponse,
  ordersLogsResponse,
  setLogFilter,
  statusListFilterResponse,
} from './actions';
import { OrdersLogsRequestAction, OrdersLogsState } from './interface';

import { RequestResponse } from '@config/api/interface';
import { Order, StatusListFilterResponse } from '@config/api/task/interface';
import { getHasNextPageFromMeta, getNextPageFromMeta } from '@utils/apiPaging';

export interface OrdersLogsResponse extends RequestResponse {
  data: Order[];
}

const initialState: OrdersLogsState = {
  logs: [],
  statusListFilter: [],
  filter: null,
  hasNextPage: false,
  nextPage: null,
};

const ordersLogsReducer = (
  state = initialState,
  action: OrdersLogsRequestAction,
): OrdersLogsState => {
  switch (action.type) {
    case String(ordersLogsResponse): {
      const { data, meta } = action?.payload as unknown as OrdersLogsResponse;

      return {
        ...state,
        logs: [...data],
        hasNextPage: getHasNextPageFromMeta(meta),
        nextPage: getNextPageFromMeta(meta),
      };
    }
    case String(setLogFilter): {
      const { startDate, endDate, page, status } = action.payload;

      return {
        ...state,
        filter: { startDate, endDate, page, status },
      };
    }
    case String(moreOrdersLogsResponse): {
      const { data, meta } = action?.payload as unknown as OrdersLogsResponse;

      return {
        ...state,
        logs: [...(state?.logs || []), ...data],
        hasNextPage: getHasNextPageFromMeta(meta),
        nextPage: getNextPageFromMeta(meta),
      };
    }
    case String(clearOrdersLogsState): {
      return {
        ...state,
        ...initialState,
        statusListFilter: state.statusListFilter,
        filter: state.filter,
      };
    }

    case String(statusListFilterResponse): {
      const data = action?.payload as unknown as StatusListFilterResponse[];

      return {
        ...state,
        statusListFilter: [...data],
      };
    }
    default:
      return state;
  }
};

export default ordersLogsReducer;
