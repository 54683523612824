import { useCallback, useEffect } from 'react';

import { useAppSelector } from './state';

import { getStoreInfoData } from '@state/storeInfo/selectors';
import { getLoginInfo } from '@utils/Auth/AuthUtils';

const useGoogleTagManager = () => {
  const userInfo = getLoginInfo();
  const { supplierId } = useAppSelector((state) => ({
    supplierId: getStoreInfoData(state)?.id,
  }));

  useEffect(() => {
    if (window) window.dataLayer = window.dataLayer || [];
  }, [window]);

  const setGTMEvent = useCallback(
    (event: any) => {
      const newEvent = {
        ...(userInfo.id && { userId: userInfo.id }),
        ...(supplierId && { Supplier_id: supplierId }),
        timeStamp: new Date().toISOString(),
        ...event,
      };
      window.dataLayer.push(newEvent);
    },
    [supplierId],
  );

  return { setGTMEvent };
};

export default useGoogleTagManager;
