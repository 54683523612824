import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

import { WeCanGetItByPickerProps } from './interface';

import { Button } from '@components/ButtonV2';

export const WeCanGetItByPicker = ({
  onClose,
  onAccept,
  open,
  available_at,
}: WeCanGetItByPickerProps) => {
  const tomorrow = new Date().setDate(new Date().getDate() + 1);
  const maxDate = dayjs(
    new Date(tomorrow).setDate(new Date(tomorrow).getDate() + 20),
  );
  const [value, setValue] = useState<Dayjs | null>(
    available_at ? available_at : null,
  );

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  const handleClickOk = () => {
    // Setting to zero the value date hour
    const formattedValue = dayjs(value).hour(0).toISOString();
    onAccept(formattedValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat='MM/DD/YYYY'
        value={value}
        disablePast
        PopperProps={{
          placement: 'top-end',
        }}
        minDate={dayjs(new Date(tomorrow).toISOString())}
        maxDate={maxDate}
        disableHighlightToday
        components={{
          ActionBar: () => (
            <div className='flex w-full items-center justify-end p-3.5'>
              <Button
                variant='contained'
                color='secondary'
                size='small'
                className='!mr-2 h-[1.5rem] w-auto !min-w-0 !p-0'
                onClick={onClose}
              >
                <span className='px-[0.5rem] py-[0.375rem] font-[Roboto] text-[0.875rem] font-medium leading-[1.5rem] tracking-[0.025rem] text-[var(--bluon--default--blue-3)]'>
                  Cancel
                </span>
              </Button>
              <Button
                color='secondary'
                size='small'
                className='h-[1.5rem] w-auto !min-w-0 bg-orange-400 !p-0'
                onClick={handleClickOk}
                disabled={!value}
              >
                <span className='m-0 px-[0.5rem] py-[0.375rem] font-[Roboto] text-[0.875rem] font-medium leading-[1.5rem] tracking-[0.025rem] text-[var(--bluon--default--blue-3)]'>
                  Ok
                </span>
              </Button>
            </div>
          ),
        }}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            style={{
              visibility: 'hidden',
              width: 0,
              height: 0,
            }}
            {...params}
          />
        )}
        open={open}
      />
    </LocalizationProvider>
  );
};
