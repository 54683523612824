import { LoginMappedProps } from './interface';

import {
  IClaimingImageResponseData,
  IframeResponseData,
} from '@config/api/auth/interface';
import { getLoginInfo } from '@utils/Auth/AuthUtils';

// eslint-disable-next-line
export function getLoginInfoData(state: any): LoginMappedProps | undefined {
  return state.auth.loginResponse;
}
// eslint-disable-next-line
export function getLoginInfoDataInitialPass(state: any): string | null {
  return state.auth.loginResponse.initial_password_set_at;
}

// eslint-disable-next-line
export function getChangePasswordInfoData(state: any): string {
  return state.auth.changePasswordResponse;
}

// eslint-disable-next-line
export function getShowTermsInfoData(state: any): boolean {
  return state.auth.showTerms;
}

// eslint-disable-next-line
export function getReadTermsInfoData(state: any): boolean {
  return state.auth.readTerms;
}

// eslint-disable-next-line
export function getIframeData(state: any): IframeResponseData {
  return state.auth.iframeData;
}

// eslint-disable-next-line
export function getIsManager(state: any): boolean {
  return state.auth.loginResponse.distributor || getLoginInfo().distributor;
}

// eslint-disable-next-line
export function getIsImpersonatedSupplier(): boolean {
  const isImpersonatedSupplier = !!getLoginInfo().impersonatedSupplier?.id;

  return isImpersonatedSupplier;
}

export function getImpersonatedSupplier(): LoginMappedProps['impersonatedSupplier'] {
  return getLoginInfo().impersonatedSupplier;
}

// eslint-disable-next-line
export function getClaimingImage(state: any): IClaimingImageResponseData {
  return state.auth.claimingImage;
}

export function getPreventRedirectUser(state: any): boolean {
  return state.auth.preventRedirectUser;
}

export function getShouldAdjustPermissions(state: any): boolean {
  return state.auth.shouldAdjustPermissions;
}

export function getLastUpdatedPermissions(state: any): string {
  return state.auth.lastUpdatedPermissions;
}

export const safelyGetInitialPass = (state: any) => {
  const isManager = getIsManager(state);
  const isImpersonatedSupplier =
    !!state?.auth?.loginResponse?.impersonatedSupplier?.id;

  if (!isManager || !isImpersonatedSupplier)
    return (
      state?.auth?.loginResponse?.initial_password_set_at ||
      getLoginInfo()?.initial_password_set_at
    );

  return (
    state?.auth?.loginResponse?.impersonatedSupplier?.initial_password_set_at ||
    getLoginInfo()?.impersonatedSupplier?.initial_password_set_at
  );
};
