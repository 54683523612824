import {
  addCustomItemOrderResponse,
  addNewCounterStaffResponse,
  clearOrders,
  deleteCustomItemOrderResponse,
  deleteInvoiceResponse,
  deleteOrderToRemember,
  getAddedItemsResponse,
  getApprovedOrdersResponse,
  getAvailabilityOrdersResponse,
  getCustomItemsOrderResponse,
  getItemCustomReplacementResponse,
  getItemInfoResponse,
  getItemReplacementsReponse,
  getMoreApprovedOrdersResponse,
  getMoreAvailabilityOrdersResponse,
  getMoreItemReplacementsReponse,
  getMoreOrderItemsResponse,
  getOemInfoResponse,
  getOrderItemsResponse,
  getOrderItemsSupplyResponse,
  getSupplierStaffResponse,
  patchAddedItemsResponse,
  patchCurrentItemResponse,
  patchOrderResponse,
  patchSupplyItemsResponse,
  postConfirmCustomerResponse,
  postCurriInformativePopUpResponse,
  resetAddedItemsResponse,
  resetOrderItemsSupplyResponse,
  returnOrderResponse,
  sendForApprovalResponse,
  setCounterStaffResponse,
  setCurrentOrderUnconfirmedUserInfo,
  setInvoiceResponse,
  setItemCustomReplacementResponse,
  setOrderDetailsResponse,
  setOrderToRemember,
  setSelectedClaimingModal,
  setSelectedOrder,
  setShowUnconfirmedCustomerModal,
  setTotalIsExpanded,
  setTruckStockIsExpanded,
} from './actions';
import { TaskActionProps, TaskState } from './interface';

import {
  CustomItem,
  ExtraItem,
  Order,
  OrderItem,
  OrderItemsDetail,
  SupplierStaff,
  SupplierUser,
} from '@config/api/task/interface';
import { checkArraysItemsUnavailable } from '@hooks/useIsAllUnavailableItemsCheck';
import { getHasNextPageFromMeta, getNextPageFromMeta } from '@utils/apiPaging';
import { EOrderStatus } from '@utils/enums';
import { handleCustomReplacement } from '@utils/functions';

const initialState: TaskState = {
  currentSelectedOrder: null,
  isTotalExpanded: false,
  isTruckStockExpanded: false,
  showUnconfirmedCustomerModal: false,
  currentOrderUnconfirmedCustomer: undefined,
  approvedOrders: {
    data: [],
    hasNextPage: false,
    nextPage: null,
    isDirty: false,
  },
  availabilityOrders: {
    data: [],
    hasNextPage: false,
    nextPage: null,
    isDirty: false,
  },
  orderItems: {
    data: [],
    hasNextPage: false,
    nextPage: null,
    isDirty: false,
  },
  orderCustomItems: {
    data: [],
    hasNextPage: false,
    nextPage: null,
    isDirty: false,
  },
  supplierStaff: {
    data: [],
    hasNextPage: false,
    nextPage: null,
    isDirty: false,
  },
  orderSupply: {
    data: [],
    hasNextPage: false,
    nextPage: null,
    isDirty: false,
  },
  orderToRemember: {
    data: [],
  },
  addedItems: {
    data: [],
    hasNextPage: false,
    nextPage: null,
    isDirty: false,
  },
  claimingModal: null,
};

const taskReducer = (
  state = initialState,
  action: TaskActionProps,
): TaskState => {
  switch (action.type) {
    case String(setOrderDetailsResponse): {
      //  Merging former order object with order detail from response
      const orderDetail = action?.payload?.response?.data?.data || {};
      const orderToSelect = action?.payload?.customParams?.[0] || {};

      const orderWithDetails = action?.payload?.response?.data
        ? { ...orderToSelect, ...orderDetail }
        : undefined;

      return {
        ...state,
        currentSelectedOrder: orderWithDetails,
      };
    }

    case String(setCurrentOrderUnconfirmedUserInfo): {
      return {
        ...state,
        currentOrderUnconfirmedCustomer: action.payload,
        showUnconfirmedCustomerModal: true,
      };
    }

    case String(postConfirmCustomerResponse): {
      const { data } = action.payload as { data: SupplierUser };

      const selectedOrder = {
        ...state.currentOrderUnconfirmedCustomer,
        supplier_user: data,
      };

      let mappedAvailabilityOrders = state?.availabilityOrders?.data;
      let mappedApprovedOrders = state?.approvedOrders?.data;

      if (selectedOrder?.current_status?.status === EOrderStatus.Pending)
        mappedAvailabilityOrders = state.availabilityOrders.data.map(
          (order) => {
            if (order.id === selectedOrder.id)
              return { ...order, supplier_user: data };

            return order;
          },
        );
      else
        mappedApprovedOrders = state.approvedOrders.data.map((order) => {
          if (order.id === selectedOrder.id)
            return { ...order, supplier_user: data };

          return order;
        });

      return {
        ...state,
        currentSelectedOrder: selectedOrder as Order,
        availabilityOrders: {
          ...state.availabilityOrders,
          data: mappedAvailabilityOrders,
        },
        approvedOrders: {
          ...state.approvedOrders,
          data: mappedApprovedOrders,
        },
      };
    }

    case String(setSelectedOrder): {
      return {
        ...state,
        currentSelectedOrder: action?.payload,
        isTotalExpanded: false,
        isTruckStockExpanded: false,
        currentOrderUnconfirmedCustomer: undefined,
        orderItems: {
          data: [],
          hasNextPage: false,
          nextPage: null,
          isDirty: false,
        },
        orderSupply: {
          data: [],
          hasNextPage: false,
          nextPage: null,
          isDirty: false,
        },
      };
    }

    case String(patchSupplyItemsResponse): {
      const { data } = action.payload as OrderItemsDetail<ExtraItem>;

      const newArray = state.orderSupply.data.map(
        (item) => data.find((newItem) => newItem.id === item.id) || item,
      );

      return {
        ...state,
        orderSupply: { ...state.orderSupply, data: newArray },
      };
    }

    case String(patchAddedItemsResponse): {
      const { data } = action.payload as OrderItemsDetail<ExtraItem>;

      const newArray = state.addedItems.data.map(
        (item) => data.find((newItem) => newItem.id === item.id) || item,
      );

      return {
        ...state,
        addedItems: { ...state.addedItems, data: newArray },
      };
    }

    case String(postCurriInformativePopUpResponse): {
      const mappedAvailabilityOrders = state.availabilityOrders.data.map(
        (item) => {
          if (item.id === action.payload.id)
            return { ...item, curri_charge_acknowledged: true };

          return item;
        },
      );

      return {
        ...state,
        currentSelectedOrder: {
          ...(state.currentSelectedOrder as Order),
          curri_charge_acknowledged: true,
        },
        availabilityOrders: {
          ...state.availabilityOrders,
          data: mappedAvailabilityOrders,
        },
      };
    }

    case String(setShowUnconfirmedCustomerModal): {
      return { ...state, showUnconfirmedCustomerModal: action.payload };
    }

    case String(getOrderItemsSupplyResponse): {
      const { data, meta } = action.payload as OrderItemsDetail<ExtraItem>;

      return {
        ...state,
        orderSupply: {
          data,
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          isDirty: true,
        },
      };
    }

    case String(resetOrderItemsSupplyResponse): {
      return {
        ...state,
        orderSupply: initialState.orderSupply,
      };
    }

    case String(getOrderItemsResponse): {
      const { data, meta } = action.payload as OrderItemsDetail<OrderItem>;

      const newData = data.map((item) => {
        if (item.replacement && item.replacement.type === 'generic')
          return {
            ...item,
            replacement: handleCustomReplacement(item.replacement),
          };

        return item;
      });

      return {
        ...state,
        orderItems: {
          data: newData as any[],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          isDirty: true,
        },
      };
    }

    case String(getAddedItemsResponse): {
      const { data, meta } = action.payload as OrderItemsDetail<ExtraItem>;

      return {
        ...state,
        addedItems: {
          data,
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          isDirty: true,
        },
      };
    }

    case String(resetAddedItemsResponse): {
      return {
        ...state,
        addedItems: initialState.addedItems,
      };
    }

    case String(getMoreOrderItemsResponse): {
      const { data, meta } = action.payload as OrderItemsDetail<OrderItem>;

      return {
        ...state,
        orderItems: {
          data: [...state.orderItems.data, ...data],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          isDirty: true,
        },
      };
    }

    case String(getApprovedOrdersResponse): {
      const { data, meta } = action.payload as OrderItemsDetail<Order>;

      return {
        ...state,
        approvedOrders: {
          data,
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          isDirty: true,
        },
      };
    }

    case String(getMoreApprovedOrdersResponse): {
      const { data, meta } = action.payload as OrderItemsDetail<Order>;

      return {
        ...state,
        approvedOrders: {
          data: [...state.approvedOrders.data, ...data],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          isDirty: true,
        },
      };
    }

    case String(patchOrderResponse):
    case String(setInvoiceResponse): {
      const { data } = action.payload as { data: Order };

      const { mappedOrders, existOnAvailability } = mapOrderInvoices(
        data,
        state.availabilityOrders.data,
        state.approvedOrders.data,
      );

      return {
        ...state,
        currentSelectedOrder: { ...state?.currentSelectedOrder, ...data },
        availabilityOrders: {
          ...state.availabilityOrders,
          data: existOnAvailability
            ? mappedOrders
            : state.availabilityOrders.data,
        },
        approvedOrders: {
          ...state.approvedOrders,
          data: existOnAvailability ? state.approvedOrders.data : mappedOrders,
        },
      };
    }

    case String(deleteInvoiceResponse): {
      if (state.currentSelectedOrder) {
        const { mappedOrders, existOnAvailability } = mapOrderInvoices(
          state.currentSelectedOrder,
          state.availabilityOrders.data,
          state.approvedOrders.data,
          true,
        );

        return {
          ...state,
          currentSelectedOrder: {
            ...state.currentSelectedOrder,
            invoice: null,
          },
          availabilityOrders: {
            ...state.availabilityOrders,
            data: existOnAvailability
              ? mappedOrders
              : state.availabilityOrders.data,
          },
          approvedOrders: {
            ...state.approvedOrders,
            data: existOnAvailability
              ? state.approvedOrders.data
              : mappedOrders,
          },
        };
      }

      return { ...state };
    }

    case String(getAvailabilityOrdersResponse): {
      const { data, meta } = action.payload as OrderItemsDetail<Order>;

      return {
        ...state,
        availabilityOrders: {
          data,
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          isDirty: true,
        },
      };
    }

    case String(getMoreAvailabilityOrdersResponse): {
      const { data, meta } = action.payload as OrderItemsDetail<Order>;

      return {
        ...state,
        availabilityOrders: {
          data: [...state.availabilityOrders.data, ...data],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          isDirty: true,
        },
      };
    }

    case String(addCustomItemOrderResponse): {
      const { data } = action.payload as { data: CustomItem };

      return {
        ...state,
        orderCustomItems: {
          ...state.orderCustomItems,
          data: [...state.orderCustomItems.data, data],
        },
      };
    }

    case String(getItemReplacementsReponse): {
      const { item_id, data } = action.payload;

      const updatedIList = state.orderItems.data.map((item) => {
        if (item.id === item_id)
          item.knownReplacements = {
            data: data.data,
            hasNextPage: getHasNextPageFromMeta(data.meta),
            nextPage: getNextPageFromMeta(data.meta),
            total: data.total,
          };

        return item;
      });

      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          data: updatedIList,
        },
      };
    }

    case String(getMoreItemReplacementsReponse): {
      const { item_id, data } = action.payload;

      const updatedIList = state.orderItems.data.map((item) => {
        if (item.id === item_id)
          return {
            ...item,
            knownReplacements: {
              data: [...item.knownReplacements.data, ...data.data],
              hasNextPage: getHasNextPageFromMeta(data.meta),
              nextPage: getNextPageFromMeta(data.meta),
              total: data.total,
            },
          };

        return item;
      });

      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          data: updatedIList,
        },
      };
    }

    case String(deleteCustomItemOrderResponse): {
      const { itemId } = action.payload;

      return {
        ...state,
        orderCustomItems: {
          ...state.orderCustomItems,
          data: state.orderCustomItems.data.filter(
            (item) => item.item.id !== itemId,
          ),
        },
      };
    }

    case String(getItemInfoResponse): {
      const { item_id, data } = action.payload;

      const updatedIList = state.orderItems.data.map((item) => {
        if (item.id === item_id)
          item.knownReplacements.data.map((known) => {
            if (known.details.id === data.id)
              known.details.specifications = {
                ...data.specifications,
              };

            return known;
          });

        return item;
      });

      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          data: updatedIList,
        },
      };
    }

    case String(getCustomItemsOrderResponse): {
      const { data, meta } = action.payload as OrderItemsDetail<CustomItem>;

      return {
        ...state,
        orderCustomItems: {
          data,
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          isDirty: true,
        },
      };
    }

    case String(patchCurrentItemResponse): {
      const data = action.payload.data;

      const updatedIList = state.orderItems.data.map((item) => {
        if (item.id === data.id) {
          if (data.replacement?.type === 'generic') {
            const newItem = handleCustomReplacement(data?.replacement);

            data.replacement = newItem;
            data.knownReplacements = {
              ...item.knownReplacements,
              data: [
                newItem,
                ...item.knownReplacements.data.filter(
                  (item) => item.type !== 'generic',
                ),
              ],
            };

            return (item = { ...data });
          }

          const knownReplacements = item.knownReplacements;

          return (item = { ...data, knownReplacements });
        }

        return item;
      });

      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          data: updatedIList,
        },
      };
    }

    case String(getSupplierStaffResponse): {
      const { data, meta } = action.payload as OrderItemsDetail<SupplierStaff>;

      return {
        ...state,
        supplierStaff: {
          data,
          lastAssigned: meta.last_assigned as string,
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          isDirty: true,
        },
      };
    }

    case String(returnOrderResponse):
    case String(setCounterStaffResponse): {
      const data = action.payload.data;

      const updatedIList = state.availabilityOrders.data.map((order) => {
        return order.id === data.id ? { ...order, ...data } : order;
      });

      return {
        ...state,
        availabilityOrders: {
          ...state.availabilityOrders,
          data: updatedIList,
        },
        currentSelectedOrder: data,
      };
    }

    case String(sendForApprovalResponse): {
      const { data } = action.payload;

      return {
        ...state,
        availabilityOrders: {
          ...state.availabilityOrders,
          data: state.availabilityOrders.data.map((item) => {
            return item.id === data.id ? { ...item, ...data } : item;
          }),
        },
        currentSelectedOrder: { ...state?.currentSelectedOrder, ...data },
      };
    }

    case String(setTruckStockIsExpanded): {
      return {
        ...state,
        isTruckStockExpanded: action.payload,
      };
    }

    case String(setTotalIsExpanded): {
      const isAllUnavailable = checkArraysItemsUnavailable(
        state.orderSupply.data,
        state.orderItems.data,
        state.orderCustomItems.data.length,
      );

      return {
        ...state,
        isTotalExpanded: isAllUnavailable ? false : action.payload,
      };
    }

    case String(setOrderToRemember): {
      return {
        ...state,
        orderToRemember: {
          data: [...state.orderToRemember.data, action.payload],
        },
      };
    }

    case String(deleteOrderToRemember): {
      return {
        ...state,
        orderToRemember: {
          data: action.payload,
        },
      };
    }

    case String(clearOrders): {
      return {
        ...state,
        availabilityOrders: { ...initialState?.availabilityOrders },
        approvedOrders: { ...initialState?.approvedOrders },
      };
    }

    case String(getOemInfoResponse): {
      const { item_id, data } = action.payload;

      const updatedIList = state.orderItems.data.map((item) => {
        if (item.id === item_id)
          item.item.info.specifications = {
            ...data.specifications,
          };

        return item;
      });

      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          data: updatedIList,
        },
      };
    }

    case String(getItemCustomReplacementResponse): {
      const { item_id, data } = action.payload;

      const updatedIList = state.orderItems.data.map((item) => {
        if (item.id === item_id) {
          const withNoCustomReplacement = item.knownReplacements.data.filter(
            (replacement) => replacement.type !== 'generic',
          );

          return {
            ...item,
            knownReplacements: {
              ...item.knownReplacements,
              data: [handleCustomReplacement(data), ...withNoCustomReplacement],
            },
          };
        }

        return item;
      });

      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          data: updatedIList as any,
        },
      };
    }

    case String(setItemCustomReplacementResponse): {
      const { item_id, data, update } = action.payload;

      const updatedIList = state.orderItems.data.map((item) => {
        if (item.id === item_id) {
          const withNoCustomReplacement = item.knownReplacements.data.filter(
            (replacement) => replacement.type !== 'generic',
          );

          // This update is related to the edit action.
          if (update)
            return {
              ...item,
              replacement: data,
            };

          return {
            ...item,
            replacement: item.replacement,
            knownReplacements: {
              ...item.knownReplacements,
              data: [handleCustomReplacement(data), ...withNoCustomReplacement],
            },
          };
        }

        return item;
      });

      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          data: updatedIList as any,
        },
      };
    }

    case String(setSelectedClaimingModal): {
      return {
        ...state,
        claimingModal: action.payload
          ? {
              ...action.payload,
            }
          : null,
      };
    }

    case String(addNewCounterStaffResponse): {
      return {
        ...state,
        claimingModal: {
          ...state.claimingModal,
          lastAddedId: action.payload.data.id,
        } as any,
      };
    }

    default:
      return state;
  }
};

export default taskReducer;

function mapOrderInvoices(
  data: Order,
  availabilityOrders: Order[],
  approvedOrders: Order[],
  isDelete = false,
) {
  const existOnAvailability =
    availabilityOrders.findIndex((item) => item.id === data.id) !== -1;

  let mappedOrders = [];
  if (existOnAvailability)
    mappedOrders = availabilityOrders.map((item) => {
      if (item.id === data.id)
        return isDelete ? { ...item, invoice: null } : { ...item, ...data };

      return item;
    });
  else
    mappedOrders = approvedOrders.map((item) => {
      if (item.id === data.id)
        return isDelete ? { ...item, invoice: null } : { ...item, ...data };

      return item;
    });

  return { mappedOrders, existOnAvailability };
}
