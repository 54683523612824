import { ORDER_STATUS } from '@config/api/task/interface';

export const ORDER_SUPPLIER_MESSAGES: Record<
  keyof typeof ORDER_STATUS,
  string
> = {
  'pending-requested': 'Waiting to be Helped',
  'pending-assigned': '[suplier_name] is working on It',
  'pending-requested-pickup': 'Waiting to be Helped',
  'pending-assigned-pickup': '[suplier_name] is working on It',
  'pending-requested-shipment': 'Waiting to be Helped',
  'pending-assigned-shipment': '[suplier_name] is working on It',
  'pending-requested-curri': 'Waiting to be Helped',
  'pending-assigned-curri': '[suplier_name] is working on It',

  'pending-approval-fulfilled': 'Response Sent - Awaiting Customer Approval',
  'pending-approval-quote-updated': 'Updated Quote Sent - Awaiting Approval',
  'pending-approval-quote-updated-shipment':
    'Updated Quote Sent - Awaiting Approval',

  'pending-approval-quote-needed-pickup': 'Truck Stock Added - Update Quote',
  'pending-approval-quote-needed-curri': 'Truck Stock Added - Update Quote',
  'pending-approval-quote-needed-shipment':
    '[Preference] Shipment Requested - Needs Quote',

  'approved-awaiting-delivery-pickup':
    'Order approved - Store Pickup at (date time)',
  'approved-awaiting-delivery-curri':
    'Order approved - Curri Delivery at (date time)',
  'approved-awaiting-delivery-shipment':
    'Order approved - [Preference] Shipment',
  // 301/302/303
  'approved-awaiting-delivery-quote-needed-ws':
    'Curri & Truck Stock added - Update quote',
  'approved-awaiting-delivery-quote-needed': 'Curri added - Update quote',
  'approved-awaiting-delivery-quote-updated':
    'Order approved - Curri Delivery at (date time)',

  'approved-ready-for-delivery-pickup': 'Customer Pickup Soon - Be Ready',
  'approved-ready-for-delivery-curri': 'Curri Pickup Soon - Be Ready',

  // 311/312/313
  'approved-ready-for-delivery-quote-needed-ws':
    'Curri & Truck Stock added - Update quote',
  'approved-ready-for-delivery-quote-needed': 'Curri added - Update quote',
  'approved-ready-for-delivery-quote-updated': 'Curri pickup soon - Be ready!',

  'approved-delivered-pickup': 'Customer Pickup Soon - Be Ready',
  'approved-delivered-curri':
    'Curri Delivery Complete - Awaiting Customer Confirmation',

  // 321/322/323
  'approved-delivered-quote-needed-ws':
    'Curri Delivery Complete - Update quote',
  'approved-delivered-quote-needed': 'Curri Delivery Complete - Update quote',
  'approved-delivered-quote-updated':
    'Curri Delivery Complete - Awaiting Customer Confirmation',

  'third-party-handled': 'Customer Responded',
  'completed-done': 'Complete',

  'canceled-rejected': 'Order canceled by customer',
  'canceled-aborted': 'Order declined by customer',
  'canceled-rejected-by-contractor': 'Order canceled by customer',
  'canceled-blocked-user': 'Order declined by customer',
  'canceled-deleted-user': 'Order declined by customer',

  'canceled-canceled': 'Order canceled',
  'canceled-declined': 'Order declined',

  'returned-returned': 'Order returned',
};
