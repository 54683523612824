import { Box, Tabs } from '@mui/material';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { CountersI } from './interface';
import './styles.scss';

import BluonLogo from '@assets/images/bluon-logo.svg';
import BluonSearchLogo from '@assets/images/bluon-search-logo_blue.svg';
import { NavDropDown } from '@components/NavDropDown';
import NavMenuItem from '@components/NavMenuItem';
import { useSnackbar } from '@components/Snackbar/hooks';
import { EUserTypes } from '@config/api/user/interface';
import { Can } from '@config/canAbility';
import { NavRoute } from '@config/routing/interface';
import {
  BluonSearchRoute,
  ImpersonatedSupplierNavRoutes,
  ManagerNavRoutes,
  MasterMechanicRoute,
  SupplierNavRoutes,
} from '@config/routing/Routes';
import useRealTimeNotificationSound from '@hooks/fireBase/useRealTimeNotificationSound';
import useRealTimeOrderCount from '@hooks/fireBase/useRealTimeOrderCount';
import { useAppSelector } from '@hooks/state';
import useCustomAbility from '@hooks/useCustomAbility';
import useImpersonatedUser from '@hooks/useImpersonatedUser';
import useNavigateToHome from '@hooks/useNavigateToHome';
import useUserType from '@hooks/useUserType';
import ManagerBanner from '@pages/Manager/StoreOverview/components/Banner';
import { getIsImpersonatedSupplier, getIsManager } from '@state/auth/selectors';
import { getChannelUsers } from '@state/chat/actions';
import {
  getManagerProfileRequest,
  removeSupplierImpersonate,
} from '@state/manager/actions';
import { getManagerProfile } from '@state/manager/selectors';
import { cleanStoreInfo, storeInfoGetRequest } from '@state/storeInfo/actions';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import { playNotificationSound } from '@utils/functions';

const DEFAULT_COUNTERS_STATE: CountersI = {
  total_customers: 0,
  customers: 0,
  init: true,
  total_orders: 0,
};

const NavBar = () => {
  const [disabledMenus, setDisabledMenus] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [counters, setCounters] = useState<CountersI>(DEFAULT_COUNTERS_STATE);

  const { storeInfo, isManager, isImpersonatedSupplier, managerInfo } =
    useAppSelector((state) => ({
      storeInfo: getStoreInfoData(state),
      managerInfo: getManagerProfile(state),
      isManager: getIsManager(state),
      isImpersonatedSupplier: getIsImpersonatedSupplier(),
    }));
  const { user_type } = useUserType();
  const { impersonatedUserType } = useImpersonatedUser();
  const { navigateToHome } = useNavigateToHome();

  const isImpersonatedRef = useRef<boolean>(isImpersonatedSupplier);
  const isManagerRef = useRef<boolean>(isManager);

  const staff = storeInfo?.counter_staff || [];
  const logo =
    storeInfo?.logo?.url || managerInfo?.first_linked_supplier?.logo?.url || '';
  const { pathname } = useLocation();
  const ability = useCustomAbility();

  const [navbarRouteIndex, setNavbarRouteIndex] = useState(0);

  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  const filteredRoutes: NavRoute[] = useMemo(() => {
    //  Getting array to filter
    let arrayToFilter = SupplierNavRoutes;
    if (isImpersonatedSupplier) arrayToFilter = ImpersonatedSupplierNavRoutes;
    if (isManager) arrayToFilter = ManagerNavRoutes;
    if (user_type === EUserTypes.SEARCH_USER)
      arrayToFilter = [BluonSearchRoute, MasterMechanicRoute];

    if (ability?.rules?.length === 0) return arrayToFilter;

    //  Filtering array
    return arrayToFilter?.filter((tab) => {
      if (!tab?.ability) return true;
      const { action, subject, skipCanValidation } = tab.ability;
      if (skipCanValidation) return true;

      return ability?.can(action, subject);
    });
  }, [isImpersonatedSupplier, isManager, user_type, ability?.rules]);

  useEffect(() => {
    setCounters(DEFAULT_COUNTERS_STATE);
  }, [filteredRoutes]);

  const approvedOrdersSuccessCallback = useCallback(() => {
    showMessage(
      'New Tasks have been loaded. Please review in the Will Call / Approved Orders column',
      {
        severity: 'success',
      },
    );
  }, []);

  const availabilityOrdersSuccessCallback = useCallback(() => {
    showMessage(
      'New Tasks have been loaded. Please review in the Price & Availability Requests column',
      {
        severity: 'success',
      },
    );
  }, []);

  useEffect(() => {
    if (storeInfo)
      if (!storeInfo.verified_at) {
        setDisabledMenus(true);
        if (pathname !== '/account') navigate('/account');
      } else {
        setDisabledMenus(false);
      }
  }, [storeInfo]);

  const setCountersValue = (values: any) => {
    if (values)
      setCounters((prevValues) => ({ ...prevValues, ...values, init: false }));
  };

  const handleOrderNotificationReceived = (isAnApprovedOrder: boolean) => {
    const isTaskScreen =
      window && window.location.href.split('#')[1] === '/tasks';
    if (
      !isAnApprovedOrder &&
      (!isManagerRef.current || isImpersonatedRef.current)
    ) {
      dispatch(getChannelUsers());
      isTaskScreen && availabilityOrdersSuccessCallback();
    } else {
      isTaskScreen && approvedOrdersSuccessCallback();
    }

    playNotificationSound(isAnApprovedOrder);
  };

  const onLogoClick = () => {
    navigateToHome();
  };

  useRealTimeNotificationSound(storeInfo, handleOrderNotificationReceived);
  useRealTimeOrderCount(storeInfo, setCountersValue);

  const getCounter = (title: string) => {
    if (title === 'Customers') return counters.customers;

    if (title === 'Tasks') return counters.total_orders;
  };

  const handleRemoveImpersonation = (route: NavRoute) => {
    const { path } = route;
    if (path === '/store-overview') {
      dispatch(cleanStoreInfo());
      dispatch(
        removeSupplierImpersonate({
          successCallback: () => navigate('/store-overview'),
        }),
      );
    }
  };
  useEffect(() => {
    if (!isManager || isImpersonatedSupplier) dispatch(storeInfoGetRequest());

    if (isManager && !isImpersonatedSupplier && !managerInfo)
      dispatch(getManagerProfileRequest());
  }, [dispatch, isManager, isImpersonatedSupplier]);

  let logoImg = BluonLogo;
  if (
    (isImpersonatedSupplier &&
      impersonatedUserType === EUserTypes.SEARCH_USER) ||
    user_type === EUserTypes.SEARCH_USER
  )
    logoImg = BluonSearchLogo;

  const handleChange = (event: SyntheticEvent, newValue: any) => {
    console.log(newValue, event);
  };

  useEffect(() => {
    if (pathname && filteredRoutes) {
      const routeIndex = filteredRoutes.findIndex(
        (route) => pathname?.includes(route.path as string),
      );
      setNavbarRouteIndex(
        routeIndex === -1 ? filteredRoutes.length : routeIndex,
      );
    }
  }, [filteredRoutes, pathname]);

  return (
    <>
      {isImpersonatedSupplier && <ManagerBanner />}
      <Box
        position='sticky'
        className={`app-main-navbar  ${
          isImpersonatedSupplier && '!top-7 mt-7'
        }`}
        color='inherit'
      >
        <img
          className='bluon-logo'
          src={logoImg}
          alt='Bluon'
          onClick={onLogoClick}
        />

        <Tabs
          value={navbarRouteIndex}
          onChange={handleChange}
          className='nav-tabs-container'
        >
          {filteredRoutes.map((route: NavRoute, index: number) => {
            const { title, path, icon, ability } = route;

            const tabPath = path.toString();

            const Tab = (
              <NavMenuItem
                isActive={navbarRouteIndex === index}
                key={index}
                value={index}
                tabPath={tabPath}
                label={title}
                icon={icon}
                badgeValue={getCounter(title)}
                onClick={(e) => {
                  if (path === '/store-overview' || disabledMenus)
                    e.preventDefault();
                  handleRemoveImpersonation(route);
                }}
              />
            );

            return ability ? (
              <Can
                I={ability?.action}
                a={ability?.subject}
                key={title}
                passThrough={ability?.skipCanValidation}
              >
                {() => Tab}
              </Can>
            ) : (
              Tab
            );
          })}
          <NavDropDown
            logo={logo}
            staff={staff}
            storeInfoBoolean={!!storeInfo}
            isActive={navbarRouteIndex === filteredRoutes.length}
            value={filteredRoutes.length}
          />
        </Tabs>
      </Box>
    </>
  );
};

export default NavBar;
