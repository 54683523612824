import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

import DateTimeCell from '../DateTimeCell';
import LogItemSkeleton from '../LogItemSkeleton';
import LogStatusCell from '../LogStatusCell';
import PartsCell from '../PartsCell';
import UserInfoCell from '../UserInfoCell';

import { logsTableColumns } from './constants';
import { LogsTableProps } from './interface';

import { SomethingWrong } from '@components/SomethingWrong';
import { VirtualizedInfiniteList } from '@components/VirtualizedInfiniteList';
import { Order } from '@config/api/task/interface';
import { useAppDispatch } from '@hooks/state';
import useSetSelectedOrder from '@hooks/useSetSelectedOrder';
import { setSelectedClaimingModal } from '@state/task/actions';
import { EOrderSubstatus } from '@utils/enums';
import { emptyFunction } from '@utils/functions';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

const LogsTable = ({
  areLogsLoading,
  hasError,
  hasNextPage,
  logs = [],
  requestMore,
}: LogsTableProps) => {
  const dispatch = useAppDispatch();
  const { changeSelectedOrder } = useSetSelectedOrder();

  const renderHeaders = () => {
    return logsTableColumns?.map((column, index) => {
      return (
        <TableCell
          key={index}
          align={column?.align}
          className={column?.className}
        >
          <div
            className={`text-sm font-medium leading-6 tracking-wide text-black text-opacity-90 ${column?.innerClassName}`}
          >
            {column?.label}
          </div>
        </TableCell>
      );
    });
  };

  const onLogClick = (order: Order) => {
    const companyName = order?.company
      ? capitalizeFirstLetter(
          order?.user?.company?.name || order?.company.name || '',
        )
      : "For Technician's Personal Job";

    const techName = `${capitalizeFirstLetter(
      order?.user?.first_name || '',
    )} ${capitalizeFirstLetter(order?.user?.last_name || '')}`;

    if (
      order &&
      !order.working_on_it &&
      order.current_status.substatus === EOrderSubstatus.PENDING_REQUESTED
    )
      return dispatch(
        setSelectedClaimingModal({ order, techName, companyName }),
      );

    changeSelectedOrder(order?.id, order);
  };

  const renderLog = (log: Order, index: number): JSX.Element => {
    return (
      <TableRow
        className='h-18 cursor-pointer'
        key={index}
        onClick={() => onLogClick(log)}
      >
        <DateTimeCell log={log} align='left' className='w-2/12' />
        <UserInfoCell log={log} align='left' className='w-3/12' />
        <LogStatusCell log={log} align='right' className='w-4/12' />
        <PartsCell log={log} align='right' className='w-3/12' />
      </TableRow>
    );
  };

  const renderContent = () => {
    //  Initial loading state
    if (areLogsLoading && (!logs || logs.length === 0))
      return (
        <div className='align-start h-[100%] w-full flex-col justify-start overflow-y-scroll'>
          {Array.from({ length: 10 }, (_, i) => (
            <LogItemSkeleton key={i} />
          ))}
        </div>
      );
    if (!areLogsLoading && (!logs || logs.length === 0))
      return (
        <Typography
          variant='h4'
          className='empty-message absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform font-normal text-[var(--bluon--ds--darkblue)]'
        >
          No matches found!
        </Typography>
      );

    //  Error state
    if (!areLogsLoading && hasError)
      return (
        <SomethingWrong height='calc(100vh - 165px)' onReload={requestMore} />
      );

    //  Normal state
    return (
      <VirtualizedInfiniteList
        list={logs}
        isLoading={areLogsLoading}
        hasNextPage={hasNextPage}
        onLoadMore={requestMore}
        onClickItem={emptyFunction}
        rowHeight={76}
        listClassName='bg-[var(--bluon--default--white)]'
      >
        {({ index, list }: any) => (
          <Table>
            <TableBody>{renderLog(list[index], index)}</TableBody>
          </Table>
        )}
      </VirtualizedInfiniteList>
    );
  };

  return (
    <>
      <Table>
        <TableBody>{renderHeaders()}</TableBody>
      </Table>
      {renderContent()}
    </>
  );
};

export default LogsTable;
