import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import { TextField } from '@mui/material';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import FromModelButton from '../../../FromModelButton';

import { FormKeys, ICommercialModelPartProps } from './interface';
import { ValidationSchema } from './validation';
import './styles.scss';

import { Button } from '@components/ButtonV2';
import { useSnackbar } from '@components/Snackbar/hooks';
import { EAbilityActions, EAbilitySubjects } from '@config/canAbility/types';
import useCustomAbility from '@hooks/useCustomAbility';

export const CommercialModelPart = ({
  category,
  commercialUnitPartNumberValue,
  modelNumber,
  notes,
  oemModel,
  oemModelId,
  partName,
  serialNumber,
  setCommercialUnitPartNumberHasError,
  setCommercialUnitPartNumberValue,
}: ICommercialModelPartProps) => {
  const navigate = useNavigate();
  const ability = useCustomAbility();
  const { control, watch, formState } = useForm<FormKeys>({
    resolver: yupResolver(ValidationSchema),
    defaultValues: {
      partNumber: commercialUnitPartNumberValue,
    },
    mode: 'onChange',
  });

  const partNumberValue = watch('partNumber');

  const handleFindReplacements = () => {
    if (partNumberValue)
      navigate(
        `/bluon-search?tab=searchPart&search=${encodeURI(partNumberValue)}`,
      );
    else navigate('/bluon-search?tab=searchPart');
  };

  useEffect(() => {
    setCommercialUnitPartNumberHasError(!!formState.errors.partNumber);
  }, [formState.errors.partNumber]);

  const title = `Commercial Unit - Find${
    partName ? ` ${partName.substring(0, partName.length - 1)}` : ''
  }${category === 1 ? '' : ' for'}:`;

  const hasSearchPermission = ability.can(
    EAbilityActions.MANAGE,
    EAbilitySubjects.SEARCH,
  );

  return (
    <div className='commercial-model-part-container max-h-[64rem] w-full bg-[var(--bluon--ds--lightgray-4)] p-[3rem]'>
      <div className='mb-[1.5rem] flex items-center'>
        <InfoIcon className='mr-[0.5rem] text-[1.5rem] text-[var(--bluon--light--orange-1)]' />
        <div className='text-[1.5rem] font-normal leading-[2rem] text-[var(--bluon--ds--black)]'>
          {title}
        </div>
      </div>
      {(category === 3 || category === 2) && (
        <div className='mb-[1.5rem] flex min-h-[6.75rem] w-full'>
          <div className='w-3/6 pr-2'>
            <div className='mb-[1rem] flex flex-row items-center'>
              <ModelAndSeriesRow
                label='Extended Model #: '
                value={modelNumber}
                copyBtnLabel='Copy Model #'
              />
            </div>
            {category === 3 && (
              <div className='flex flex-row items-center'>
                <ModelAndSeriesRow
                  label='Serial #: '
                  value={serialNumber}
                  copyBtnLabel='Copy Serial #'
                />
              </div>
            )}
          </div>
          <div className='flex w-3/6'>
            {notes && (
              <div className='break-all pl-2 text-[1rem] font-normal leading-[1.188rem] text-[var(--bluon--ds--black)]'>
                <strong>Notes: </strong>
                {notes}
              </div>
            )}
          </div>
        </div>
      )}
      {category === 1 && hasSearchPermission && (
        <div className='mb-[1.5rem] flex w-full'>
          <FromModelButton
            buttonAdditionalStyle='bg-white'
            labelAdditionalStyle='text-[0.813rem]'
            brand=''
            model={oemModel}
            modelId={oemModelId}
          />
        </div>
      )}
      <div className='part-number-container flex  w-full flex-col items-start items-center justify-center'>
        <div className='part-number full'>
          <Controller
            name='partNumber'
            control={control}
            render={({ field, fieldState, formState }) => (
              <TextField
                sx={{
                  background: '#fff',
                  label: {
                    color: 'rgba(0, 0, 0, 0.60)',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 'normal',
                  },
                  input: {
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: '16px',
                    lineHeight: '24px',
                  },
                  '.MuiFormHelperText-root': {
                    backgroundColor: '#f0f0f0',
                    margin: 0,
                    paddingTop: '3px',
                    paddingLeft: '14px',
                  },
                }}
                id='partNumber'
                data-testid='partNumber-text-field'
                variant='outlined'
                label='Enter Part Number'
                name={field.name}
                onBlur={field.onBlur}
                onChange={(e) => {
                  field.onChange(e);
                  setCommercialUnitPartNumberValue(e.target.value);
                }}
                defaultValue={field.value}
                error={!!fieldState.error}
                fullWidth
                autoComplete='off'
                helperText={formState.errors.partNumber?.message}
              />
            )}
          />
        </div>
        {hasSearchPermission && (
          <div className='mt-2 w-[21.375rem]'>
            <Button
              className={classNames('w-full !py-2', {
                '!bg-[transparent]': !!formState.errors.partNumber,
              })}
              onClick={handleFindReplacements}
              disabled={!!formState.errors.partNumber}
            >
              <div
                className={classNames(
                  'text-[0.938rem] font-medium uppercase leading-[1.625rem] tracking-[0.029rem]',
                  {
                    'text-[var(--bluon--ds--darkblue)]':
                      !formState.errors.partNumber,
                    'text-[var(--bluon--ds--lightgray-button-text)]':
                      !!formState.errors.partNumber,
                  },
                )}
              >
                Find replacements in bluon search
              </div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const ModelAndSeriesRow = ({
  label,
  value,
  copyBtnLabel,
}: {
  label: string;
  value: string;
  copyBtnLabel: string;
}) => {
  const { showMessage } = useSnackbar();

  return (
    <>
      <div className='mr-2 text-[1rem] font-normal leading-[1.188rem] tracking-[-0.016rem] text-[var(--bluon--ds--black)]'>
        <strong>{label} </strong>
        {value}
      </div>
      <Button
        className='ml-[0.5rem] h-[1.88rem] w-[7.625rem] rounded !bg-white !p-0'
        variant='outlined'
        onClick={() => {
          navigator.clipboard.writeText(value || '');
          showMessage(`${value || ''} copied to clipboard`, {
            severity: 'info',
            autoHide: true,
          });
        }}
      >
        <div className='text-[0.813rem] font-medium uppercase leading-[1.375rem] tracking-[0.029rem] text-[var(--bluon--ds--lightblue)]'>
          {copyBtnLabel}
        </div>
      </Button>
    </>
  );
};
