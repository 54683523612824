import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useSnackbar } from '../../../../../../components/Snackbar/hooks';

import { EditModalProps } from './interface';

import { Button } from '@components/ButtonV2';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import {
  ConfirmUserPayload,
  ECustomerAccount,
  UnconfirmedCustomerModalFormKeys,
} from '@pages/Tasks/components/UnconfirmedCustomerModal/interface';
import { UnconfirmedCustomerModalValidationSchema } from '@pages/Tasks/components/UnconfirmedCustomerModal/validations/validations';
import {
  updateCustomerRequest,
  updateNationalCustomerRequest,
} from '@state/customer/actions';
import './styles.scss';
import { isRequestRunning } from '@state/requests/selectors';

export const EditModal = ({
  customer: { name, cash_buyer, account_number },
  customer_tier,
  userId,
  imgSrc,
  isNationalSupplier,
}: EditModalProps): JSX.Element => {
  const { showMessage } = useSnackbar();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [customerAccount, setCustomerAccount] = useState<ECustomerAccount>(
    ECustomerAccount.NO_SELECTION,
  );

  const { isUpdateloading, isUpdateNationalLoading } = useAppSelector(
    (state) => ({
      isUpdateloading: isRequestRunning(state, String(updateCustomerRequest)),
      isUpdateNationalLoading: isRequestRunning(
        state,
        String(updateNationalCustomerRequest),
      ),
    }),
  );

  const getCashBuyer = useCallback(() => {
    return cash_buyer
      ? ECustomerAccount.CASH_BUYER
      : ECustomerAccount.HAS_ACCOUNT;
  }, [cash_buyer]);

  useEffect(() => {
    setCustomerAccount(getCashBuyer());
  }, [open, getCashBuyer]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCustomerAccount(+e.target.value as ECustomerAccount);
  };

  const { control, watch, formState, trigger, reset } =
    useForm<UnconfirmedCustomerModalFormKeys>({
      resolver: yupResolver(UnconfirmedCustomerModalValidationSchema),
      mode: 'onChange',
      context: {
        hasAccount:
          isNationalSupplier &&
          customerAccount === ECustomerAccount.HAS_ACCOUNT,
      },
      defaultValues: {
        account_number: account_number ?? '',
        customer_tier: customer_tier ?? '',
      },
    });

  const customerTier = watch('customer_tier');
  const accountNumber = watch('account_number');

  useEffect(() => {
    if (customerAccount === ECustomerAccount.NO_SELECTION) return;
    reset({
      account_number: account_number ?? '',
      customer_tier: customer_tier ?? '',
    });
  }, [account_number, customerAccount, customer_tier, reset]);

  const handleSubmitNational = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload: ConfirmUserPayload = {
      customer_tier: customerTier,
      have_account_number: ECustomerAccount.HAS_ACCOUNT === customerAccount,
      user: +userId,
      onSuccessCallback: handleCallback(
        'success',
        'The customer has been confirmed successfully',
      ),
      onErrorCallback: handleCallback(
        'error',
        'There was an error confirming the customer',
      ),
    };

    if (customerAccount === ECustomerAccount.HAS_ACCOUNT)
      payload.account_number = accountNumber;

    dispatch(updateNationalCustomerRequest(payload));
  };

  const handleCallback = (severity: 'success' | 'error', message: string) => {
    return () => {
      showMessage(message, { severity });
      handleCloseModal();
    };
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = {
      cash_buyer: customerAccount === ECustomerAccount.CASH_BUYER,
      customer_tier: customerTier,
    };

    dispatch(
      updateCustomerRequest({
        userId,
        payload,
        errorCallback: () => {
          showMessage('There was an error confirming the customer', {
            severity: 'error',
          });
          handleCloseModal();
        },
        successCallback: () => {
          showMessage('The customer has been confirmed successfully', {
            severity: 'success',
          });
          handleCloseModal();
        },
      }),
    );
  };

  return (
    <>
      <Button
        className='bl-edit-modal-button'
        variant='outlined'
        onClick={handleClickOpen}
        size='small'
      >
        Edit
      </Button>
      <Dialog
        className='bl-edit-modal-dialog-container'
        open={open}
        onClose={handleCloseModal}
      >
        <DialogContent>
          <div className='bl-edit-modal-avatar-container'>
            <Avatar src={imgSrc} />
          </div>

          <DialogContentText
            className='bl-edit-modal-dialog-text'
            variant='subtitle1'
          >
            What kind of customer is <b>{name}</b>?
          </DialogContentText>

          <form
            onSubmit={isNationalSupplier ? handleSubmitNational : handleSubmit}
          >
            <FormControl className='bl-edit-modal-form-control-container'>
              <RadioGroup
                className='bl-edit-modal-radio-group-container'
                row
                value={customerAccount}
                onChange={handleChange}
              >
                <FormControlLabel
                  className='bl-edit-modal-form-control-label'
                  control={<Radio />}
                  label='Account Buyer'
                  labelPlacement='start'
                  value={ECustomerAccount.HAS_ACCOUNT}
                />
                <FormControlLabel
                  className='bl-edit-modal-form-control-label'
                  control={<Radio />}
                  label='Cash Buyer'
                  labelPlacement='start'
                  value={ECustomerAccount.CASH_BUYER}
                />
              </RadioGroup>
            </FormControl>

            {customerAccount !== ECustomerAccount.NO_SELECTION && (
              <Box className='modal-form'>
                {isNationalSupplier &&
                  customerAccount === ECustomerAccount.HAS_ACCOUNT && (
                    <Controller
                      name='account_number'
                      control={control}
                      render={({ field, fieldState, formState }) => (
                        <TextField
                          fullWidth
                          id='account_number'
                          variant='outlined'
                          label='Account Number (Required)'
                          value={field.value}
                          name={field.name}
                          onChange={field.onChange}
                          onBlur={() => {
                            field.onBlur();
                            trigger('account_number');
                          }}
                          defaultValue={field.value}
                          error={!!fieldState.error}
                          helperText={formState.errors.account_number?.message}
                          autoComplete='off'
                        />
                      )}
                    />
                  )}

                <Controller
                  name='customer_tier'
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <TextField
                      fullWidth
                      id='customer_tier'
                      variant='outlined'
                      label='Custom Tag (Optional)'
                      name={field.name}
                      onChange={field.onChange}
                      onBlur={() => {
                        field.onBlur();
                        trigger('customer_tier');
                      }}
                      value={field.value}
                      defaultValue={field.value}
                      error={!!fieldState.error}
                      helperText={formState.errors.customer_tier?.message}
                      autoComplete='off'
                    />
                  )}
                />
              </Box>
            )}

            <Grid container className='bl-edit-modal-buttons-container'>
              <Grid item className='bl-edit-modal-cancel-button-container'>
                <Button variant='outlined' onClick={handleCloseModal}>
                  Leave for now
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={
                    !formState.isValid ||
                    customerAccount === ECustomerAccount.NO_SELECTION ||
                    (isNationalSupplier &&
                      customerAccount === ECustomerAccount.HAS_ACCOUNT &&
                      !accountNumber) ||
                    isUpdateNationalLoading ||
                    isUpdateloading
                  }
                  loading={isUpdateNationalLoading || isUpdateloading}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
