import { Box, Link, Typography } from '@mui/material';

import { CurriMessageProps } from './interface';

import LogisticFinderIcon from '@assets/icons/logistic-finder-icon.svg';
import CurriStandardCarIcon from '@assets/images/curri-standard-car.png';
import { Button } from '@components/ButtonV2';
import { getDateTimeMessage } from '@utils/Strings/dateFormat';

export const CurriMessage = ({
  url,
  text,
  timeToken,
}: CurriMessageProps): JSX.Element => {
  const shareLink = url;

  return (
    <div className='item-chat'>
      <Box className='time-message'>
        <span className='text-xs'>Auto Generated Message</span>
        <span> {getDateTimeMessage(timeToken, 'h:mm a')}</span>
      </Box>
      <Box className='own-no-wrap relative min-w-1/8 max-w-3/5 rounded-lg bg-primary p-4'>
        <Box className='mb-2 flex items-center rounded-t bg-white px-4 py-2'>
          <img
            src={CurriStandardCarIcon}
            alt='Curri Delivery (Standard)'
            className='mr-5 h-auto max-w-[4rem]'
          />
          <Typography
            variant='caption'
            className='text-base font-medium leading-5 tracking-tight text-bluon_navy'
          >
            <a
              href={shareLink}
              target='_blank'
              rel='noopener noreferrer'
              className='text-bluon_navy no-underline'
            >
              Delivery Tracker
            </a>
          </Typography>
        </Box>
        <Typography
          variant='body1'
          className='font-normal leading-5 tracking-widest text-white'
        >
          {text}
        </Typography>
        <Box className='mb-3 mt-4'>
          <Link
            href={shareLink}
            target='_blank'
            rel='noopener noreferrer'
            sx={{ textDecoration: 'none' }}
          >
            <Button
              className='border border-black bg-gradient-to-b from-black via-gray-900 to-black text-lg font-bold leading-5 tracking-tight shadow-none'
              variant='contained'
              fullWidth
            >
              <img
                src={LogisticFinderIcon}
                className='mr-4'
                alt='Logistic Finder'
              />
              Track Delivery
            </Button>
          </Link>
        </Box>
      </Box>
    </div>
  );
};
