import { getItemDescription, getPartName } from '../../../ItemPart';

import {
  IFunctionalPartsModalSectionProps,
  IPartNameAndDescriptionProps,
} from './interface';

import { Label } from '@components/Label';
import { getLabelAvailableAtPart } from '@pages/Tasks/components/OrderDetail/utils';
import { EItemStatus } from '@utils/enums';
import { partImage } from '@utils/functions';
import { orderItemAvailableAtFormat } from 'src/constants';

import '../../styles.scss';

const PartNameAndDescription = ({
  name,
  description,
  hasTag,
}: IPartNameAndDescriptionProps) => (
  <div
    className={`flex max-h-[2.563rem] ${
      hasTag ? 'max-w-[70%]' : ''
    } flex-wrap items-center`}
  >
    <span className='ellipsis-text mr-[0.5rem] flex items-center font-[Roboto] text-[0.875rem] font-bold tracking-[0.011rem]'>
      {name}
    </span>
    <span className='ellipsis-text flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem] text-[var(--bluon--ds--lightblack)]'>
      {description}
    </span>
  </div>
);

const Image = ({ src }: { src: string }) => (
  <div className='mr-[1.313rem] max-h-[2rem] min-h-[2rem] min-w-[2rem] max-w-[2rem]'>
    <img alt='logo' className='h-[2rem] w-[2rem]' loading='lazy' src={src} />
  </div>
);

export const FunctionalPartsModalSection = ({
  items,
  item,
  idx,
  isAllUnavailable,
  hasAdditionalItem,
}: IFunctionalPartsModalSectionProps) => {
  const hasTag =
    !!isAllUnavailable ||
    !!(item.status === EItemStatus.Available && item?.available_at) ||
    !!(
      item.status === EItemStatus.Available &&
      item.replacement &&
      !item.available_at
    );

  if (isAllUnavailable || hasAdditionalItem)
    return (
      <div
        key={item.id}
        className={`flex max-h-[3.25rem] w-full flex-row items-center justify-between ${
          idx === items.length - 1 ? '' : 'border-b '
        } border-[var(--bluon--light--gray-21)]	p-4`}
      >
        <div className='flex w-full max-w-[95%] items-center'>
          <Image src={partImage(item.item.info, item.item.type)} />
          <div className='flex w-full items-center'>
            <PartNameAndDescription
              name={getPartName(item)}
              description={getItemDescription(item)}
              hasTag={hasTag}
            />

            <div className='ml-2'>
              <Label label='We can’t get it' color='light-red' />
            </div>
          </div>
        </div>
        <div>
          <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem] text-[var(--bluon--light--red-4)]'>
            0
          </div>
        </div>
      </div>
    );

  if (item.status === EItemStatus.NotAvailable) return null;

  return (
    <div
      key={item.id}
      className={`flex max-h-[3.25rem] w-full flex-row items-center justify-between ${
        idx === items.length - 1 ? '' : 'border-b '
      } border-[var(--bluon--light--gray-21)]	p-4`}
    >
      <div className='flex w-full max-w-[95%] items-center'>
        <Image src={partImage(item.item.info, item.item.type)} />
        <div className='flex w-full items-center'>
          <PartNameAndDescription
            name={getPartName(item)}
            description={getItemDescription(item)}
            hasTag={hasTag}
          />
          {item.status === EItemStatus.Available && item?.available_at && (
            <div className='ml-2'>
              <Label
                label={getLabelAvailableAtPart({
                  message: item.replacement ? '1' : '0',
                  format: orderItemAvailableAtFormat,
                  available_at: item?.available_at,
                })}
                color='brown'
              />
            </div>
          )}
          {item.status === EItemStatus.Available &&
            item.replacement &&
            !item.available_at && (
              <div className='ml-2 flex items-center justify-center rounded-full border border-[var(--bluon--default--green)] bg-[var(--bluon--light--gray-21)] px-2.5 py-1.5'>
                <div className='font-[Roboto] text-[0.75rem] font-normal leading-[0.75rem] tracking-[0.009rem] text-[var(--bluon--default--green)]'>
                  Replacement
                </div>
              </div>
            )}
        </div>
      </div>
      <div>
        <div className='flex items-center font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem]'>
          {item.quantity}
        </div>
      </div>
    </div>
  );
};
