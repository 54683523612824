import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import './styles.scss';
import { useSnackbar } from '../../../components/Snackbar/hooks';
import { AuthHeader } from '../components/AuthHeader';
import PasswordInput from '../components/PasswordInput';
import { TermsOfServiceLabel } from '../components/TermsOfServiceLabel';
import { WelcomeContainer } from '../components/WelcomeContainer';

import { ResetPasswordErrorMessage, ResetPasswordFormKeys } from './interfaces';
import { ResetPasswordValidationSchema } from './validations';

import { Button } from '@components/ButtonV2';
import { useAppSelector } from '@hooks/state';
import { resetPasswordRequest } from '@state/auth/actions';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';

export const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  const { loading, error } = useAppSelector((state) => ({
    loading: isRequestRunning(state, String(resetPasswordRequest)),
    error: getRequestError(
      state,
      String(resetPasswordRequest),
    ) as ResetPasswordErrorMessage,
  }));

  const email = searchParams.get('email');

  const initialValues: ResetPasswordFormKeys = {
    password: '',
    passwordConfirmation: '',
  };

  const { control, handleSubmit, watch, getValues, clearErrors, setError } =
    useForm<ResetPasswordFormKeys>({
      resolver: yupResolver(ResetPasswordValidationSchema),
      defaultValues: initialValues,
      mode: 'onChange',
    });

  const password = watch('password');

  useEffect(() => {
    if (!password) return;
    const confirmPassword = getValues().passwordConfirmation;
    if (confirmPassword && confirmPassword === password)
      clearErrors('passwordConfirmation');
    else if (confirmPassword)
      setError('passwordConfirmation', {
        type: 'manual',
        message: 'Password doesn’t match',
      });
  }, [password]);

  const handleSubmitForm = (formData: ResetPasswordFormKeys) => {
    const data = { ...formData, token, email };
    dispatch(
      resetPasswordRequest({
        ...data,
        callback: () => {
          showMessage('Your password has been reset successfully.', {
            severity: 'success',
          });
          navigate('/login');
        },
      }),
    );
  };

  useEffect(() => {
    if (error.email) showMessage(error.email[0], { severity: 'error' });
    if (error.password) showMessage(error.password[0], { severity: 'error' });
  }, [error]);

  return (
    <WelcomeContainer>
      <Box className='bl-reset-password-container'>
        <AuthHeader
          title='Reset Password'
          subTitle='Your password must be at least 8 characters long.'
        />

        <form onSubmit={handleSubmit(handleSubmitForm)} className='form'>
          <Grid container className='inputs'>
            <Grid item xs={12}>
              <PasswordInput
                variant='outlined'
                name='password'
                label='New Password'
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordInput
                variant='outlined'
                name='passwordConfirmation'
                label='Confirm Password'
                control={control}
                id='passwordConfirmation'
                data-testid='passwordConfirmation'
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                loading={loading}
                disabled={loading}
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </form>
        <TermsOfServiceLabel />
      </Box>
    </WelcomeContainer>
  );
};
