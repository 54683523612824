import { isEmpty } from 'lodash';
import { all, select, takeLatest } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import {
  getManagerProfileRequest,
  getManagerProfileResponse,
  getSupplierList,
  getSupplierListResponse,
  impersonateSupplierRequest,
  impersonateSupplierResponse,
  removeSupplierImpersonate,
} from './actions';
import { TaskActionProps } from './interface';

import {
  getManagerProfile,
  getSupplierList as getSupplierListApiRequest,
  impersonateSupplier,
} from '@config/api/manager';
import {
  ability,
  resetAbilities,
  updateAbilityImpersonate,
} from '@config/canAbility';
import { homeRoutes } from '@config/routing/Routes';
import makeRequest from '@state/requests/make-request';
import { getRequestError } from '@state/requests/selectors';
import { getLoginInfo, setAuthToken } from '@utils/Auth/AuthUtils';
import { getFirstAvailableHomeRoute } from '@utils/navigation';

export function* workerGetSupplierList(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;
  const { errorCallback } = action.payload;

  yield call(makeRequest, {
    endpoint: getSupplierListApiRequest,
    requestAction: action,
    receiveAction: getSupplierListResponse,
    params: action.payload,
  });

  const error = yield select((state) =>
    getRequestError(state, getSupplierList),
  );

  if (!isEmpty(error)) yield call(errorCallback);
}

export function* workerImpersonateSupplier(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;
  const { errorCallback, successCallback } = action.payload;

  yield call(makeRequest, {
    endpoint: impersonateSupplier,
    requestAction: action,
    receiveAction: impersonateSupplierResponse,
    params: action.payload,
  });

  const error = yield select((state) =>
    getRequestError(state, impersonateSupplierRequest),
  );

  if (isEmpty(error))
    yield call(
      successCallback,
      getFirstAvailableHomeRoute(homeRoutes, ability),
    );
  else yield call(errorCallback);
}

export function* workerImpersonateSupplierResponse(
  action: TaskActionProps,
): Generator<unknown> {
  const { data } = action.payload;
  const call: any = Eff.call;

  const currentAuthData = getLoginInfo();
  updateAbilityImpersonate(data.permissions);
  yield call(setAuthToken, { ...currentAuthData, impersonatedSupplier: data });
}

export function* workerRemoveSupplierImpersonate(
  action: TaskActionProps,
): Generator<unknown> {
  const { successCallback } = action.payload;
  const call: any = Eff.call;
  const currentAuthData = getLoginInfo();
  yield call(setAuthToken, {
    ...currentAuthData,
    impersonatedSupplier: undefined,
  });
  resetAbilities();
  yield call(successCallback);
}

export function* workerGetManagerProfile(
  action: TaskActionProps,
): Generator<unknown> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: getManagerProfile,
    requestAction: action,
    receiveAction: getManagerProfileResponse,
  });
}

export default function* managerSagas(): Generator<any> {
  yield all([takeLatest(getSupplierList, workerGetSupplierList)]);
  yield all([
    takeLatest(impersonateSupplierRequest, workerImpersonateSupplier),
  ]);
  yield all([
    takeLatest(impersonateSupplierResponse, workerImpersonateSupplierResponse),
  ]);
  yield all([
    takeLatest(removeSupplierImpersonate, workerRemoveSupplierImpersonate),
  ]);
  yield all([takeLatest(getManagerProfileRequest, workerGetManagerProfile)]);
}
