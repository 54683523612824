import './index.scss';
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme,
} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BluonSearchWrapper } from 'bluon-search';
import { PubNubProvider } from 'pubnub-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import packageJson from '../package.json';

import App from './App';
import { SnackbarProvider } from './components/Snackbar/hooks';
import { PubNubUtils } from './config/pubnub';
import reportWebVitals from './reportWebVitals';
import store from './state';

import client from '@config/api';
import { AbilityContext, ability } from '@config/canAbility';
import BluonTheme from '@config/styles/theme';
import useTrackGTMEvents from '@hooks/useTrackGTMEvents';

const theme = unstable_createMuiStrictModeTheme(BluonTheme);
const pubnub = PubNubUtils.getInstance().getPubNubInstance();
const environment = process ? process.env : null;

const tagManagerArgs = {
  gtmId: environment?.REACT_APP_GOOGLE_TAG_MANAGER_ID ?? 'G-2YKYTLQN96',
  auth: environment?.REACT_APP_GOOGLE_TAG_MANAGER_AUTH ?? '',
  preview: environment?.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW ?? '',
};

TagManager.initialize(tagManagerArgs);

if (environment?.REACT_APP_ENV_NAME !== 'develop')
  Sentry.init({
    dsn: environment?.REACT_APP_SENTRY_DSN,
    environment: environment?.REACT_APP_ENV_NAME,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: packageJson.version,
  });

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <HashRouter>
          <SnackbarProvider>
            <Sentry.ErrorBoundary>
              <PubNubProvider client={pubnub}>
                <AbilityContext.Provider value={ability}>
                  <BluonSearchWrapper
                    iframeUrl='search-by-part-iframe-url'
                    applyStyleOverrides={true}
                    axiosInstance={client}
                    apiVersion={environment?.REACT_APP_SEARCH_API_VERSION}
                    applyOwnSnackbar={true}
                    useTrackGTMEvents={useTrackGTMEvents}
                  >
                    <App />
                  </BluonSearchWrapper>
                </AbilityContext.Provider>
              </PubNubProvider>
            </Sentry.ErrorBoundary>
          </SnackbarProvider>
        </HashRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
