import {
  GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS,
  GOOGLE_TAG_MANAGER_CATEGORY_PAGES,
  GOOGLE_TAG_MANAGER_EVENTS,
  GOOGLE_TAG_MANAGER_EVENT_MESSAGES,
} from './interface';

import { LoginMappedProps } from '@state/auth/interface';

const normalizeString = (message: string) =>
  message.toLowerCase().split(' ').join('_');

const pageTrackingGTMEvent = (
  action: string,
  category?: string,
  specialParam?: any,
) => {
  const page = String(window.location.href).split('/#/')[1].split('?')[0];

  return assembleGTMEvent(page, action, undefined, category, specialParam);
};

export const assembleOrderBannerGTMEvent = (
  action: string,
  specialParam?: any,
) =>
  pageTrackingGTMEvent(
    action,
    GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS.ORDER_BANNER,
    specialParam,
  );

export const assembleChatGTMEvent = (action: string) =>
  pageTrackingGTMEvent(
    action,
    GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS.CUSTOMER_CHAT,
  );

export const assembleOrderDetailGTMEvent = (
  action: string,
  specialParam?: any,
) =>
  pageTrackingGTMEvent(
    action,
    GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS.ORDER_DETAILS_MODAL,
    specialParam,
  );

export const assembleFeedbackOpenGTMEvent = () =>
  pageTrackingGTMEvent(
    GOOGLE_TAG_MANAGER_EVENT_MESSAGES.FEEDBACK_OPEN,
    GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS.FEEDBACK,
  );

export const assembleLogoutGTMEvent = (userId: string) =>
  window.dataLayer.push(
    assembleGTMEvent(
      GOOGLE_TAG_MANAGER_CATEGORY_PAGES.LOGOUT,
      GOOGLE_TAG_MANAGER_EVENT_MESSAGES.LOGOUT,
      undefined,
      undefined,
      {
        userId,
        timeStamp: new Date().toISOString(),
      },
    ),
  );

export const assembleManualClickGTMEvent = (
  action: string,
  specialParam?: object,
) =>
  pageTrackingGTMEvent(
    action,
    GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS.MODEL_DETAIL,
    specialParam,
  );

export const assembleFeedbackSubmitGTMEvent = () =>
  pageTrackingGTMEvent(
    GOOGLE_TAG_MANAGER_EVENT_MESSAGES.FEEDBACK_SUBMIT,
    GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS.FEEDBACK,
  );

export const assemblePageViewGTMEvent = (action: string) =>
  assembleGTMEvent(
    GOOGLE_TAG_MANAGER_CATEGORY_PAGES.LOGIN,
    action,
    undefined,
    undefined,
    { userId: null, Supplier_id: null },
  );

export const assembleGTMEvent = (
  page: string,
  action: string,
  event?:
    | GOOGLE_TAG_MANAGER_EVENTS.PAGE_VIEW
    | GOOGLE_TAG_MANAGER_EVENTS.CUSTOM_BLUON_LIVE_EVENT,
  category?: string,
  specialParam?: any,
) => {
  return {
    event: event || GOOGLE_TAG_MANAGER_EVENTS.CUSTOM_BLUON_LIVE_EVENT,
    page,
    ...(category && { category }),
    action: normalizeString(action),
    ...(specialParam && { ...specialParam }),
  };
};

export const setNewSessionGTMEvent = (loginData: LoginMappedProps) => {
  window.dataLayer.push(
    assembleGTMEvent(
      GOOGLE_TAG_MANAGER_CATEGORY_PAGES.LOGIN,
      'New session',
      undefined,
      undefined,
      {
        timeStamp: new Date().toISOString(),
        userId: loginData.id,
      },
    ),
  );
};

export const assembleMasterMechanicGTMEvent = (specialParams?: object) =>
  assembleGTMEvent(
    GOOGLE_TAG_MANAGER_CATEGORY_PAGES.MASTER_MECHANIC,
    'master-mechanic-screen-BluonLive',
    GOOGLE_TAG_MANAGER_EVENTS.CUSTOM_BLUON_LIVE_EVENT,
    undefined,
    specialParams,
  );
