import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import './App.scss';
import Analytics from './assets/js/analytics';
import NavBar from './components/NavBar';
import { ThankYouModal } from './components/ThankYouModal';
import {
  AuthenticatedRoutes,
  ConfigRoutes,
  homeRoutes,
} from './config/routing/Routes';
import { useAppDispatch, useAppSelector } from './hooks/state';
import { loginRequest, logout } from './state/auth/actions';
import {
  getIsImpersonatedSupplier,
  getLoginInfoData,
  getPreventRedirectUser,
  safelyGetInitialPass,
} from './state/auth/selectors';
import { dashboardSetShowThankYouModal } from './state/dashboard/actions';
import { getShowThankYouModal } from './state/dashboard/selectors';
import { isRequestRunning } from './state/requests/selectors';
import { emptyFunction } from './utils/functions';

import { EAbilityActions, EAbilitySubjects } from '@config/canAbility/types';
import useCustomAbility from '@hooks/useCustomAbility';
import usePermissionsValidations from '@hooks/usePermissionsValidations';
import { getFirstAvailableHomeRoute } from '@utils/navigation';
import { renderRoutes } from '@utils/router';

function App(): JSX.Element {
  const queryClient = new QueryClient();
  const environment = process ? process.env : null;
  const ability = useCustomAbility();

  useEffect(() => {
    if (environment?.REACT_APP_ENV_NAME === 'production')
      Analytics.account.register();
  }, []);

  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  usePermissionsValidations();

  const shouldShowNavigationBar = AuthenticatedRoutes.some(({ path }) =>
    pathname.includes(path),
  );
  const {
    logged,
    loginLoading,
    showThankYouModal,
    isLogged,
    isImpersonatedSupplier,
    preventRedirectUser,
    logOutLoading,
  } = useAppSelector((state) => ({
    logged: safelyGetInitialPass(state),
    isLogged: getLoginInfoData(state),
    loginLoading: isRequestRunning(state, String(loginRequest)),
    showThankYouModal: getShowThankYouModal(state),
    isImpersonatedSupplier: getIsImpersonatedSupplier(),
    preventRedirectUser: getPreventRedirectUser(state),
    logOutLoading: isRequestRunning(state, String(logout)),
  }));

  useEffect(() => {
    if (
      pathname === '/login' &&
      (logged || isLogged?.id) &&
      !loginLoading &&
      !logOutLoading
    )
      dispatch(logout(() => emptyFunction()));
  }, [pathname]);

  //  Watching search redirections
  useEffect(() => {
    //  Checking search permissions
    if (!pathname || !pathname?.includes('/search')) return;
    if (
      ability?.rules?.length === 0 ||
      ability?.can(EAbilityActions.MANAGE, EAbilitySubjects.SEARCH)
    )
      return;

    //  Redirecting to home
    const homeRoute = getFirstAvailableHomeRoute(homeRoutes, ability);
    navigate(homeRoute || '');
  }, [pathname, ability?.rules]);

  useEffect(() => {
    if (preventRedirectUser) return navigate('/login');
  }, [preventRedirectUser]);

  const handleCloseThankYouModal = () => {
    dispatch(dashboardSetShowThankYouModal(false));
  };

  const getAppHeight = () => {
    if (isLogged?.id && logged) {
      if (isImpersonatedSupplier) return 'calc(100vh - 6rem)';

      return 'calc(100vh - 4rem)';
    }

    if (pathname === '/store-overview') return 'calc(100vh - 6rem)';

    return '100vh';
  };

  return (
    <>
      {showThankYouModal && (
        <ThankYouModal
          open={showThankYouModal}
          handleClose={handleCloseThankYouModal}
        />
      )}
      {shouldShowNavigationBar && <NavBar />}

      <div
        className='App'
        style={{ height: getAppHeight() }}
        data-testid='AppDiv'
      >
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <Routes>
            {renderRoutes(ConfigRoutes, search)}
            <Route path='/' element={<Navigate to='/login' />} />
          </Routes>
        </QueryClientProvider>
      </div>
    </>
  );
}

export default Sentry.withProfiler(App);
