import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { EMasterMechanicURLParams } from './types';

import { useAppSelector } from '@hooks/state';
import useGoogleTagManager from '@hooks/useGoogleTagManager';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import { assembleMasterMechanicGTMEvent } from '@utils/GoogleTagManager';

import './styles.scss';

const MM_BASE_URL = process.env?.REACT_APP_MASTER_MECHANIC_IFRAME_URL;
const MM_ORIGIN = 'Bluon Live Navbar';
export const MM_ROUTE = '/master-mechanic';

const MasterMechanic = () => {
  const { pathname } = useLocation();
  const { setGTMEvent } = useGoogleTagManager();
  const isCorrectRoute = pathname === MM_ROUTE;

  const { supplierId } = useAppSelector((state) => ({
    supplierId: getStoreInfoData(state)?.id,
  }));

  useEffect(() => {
    if (isCorrectRoute && supplierId) {
      const snowplowData = {
        ...(supplierId && { Supplier_id: supplierId }),
        origin: MM_ORIGIN,
      };
      setGTMEvent(assembleMasterMechanicGTMEvent(snowplowData));
    }
  }, [isCorrectRoute, supplierId]);

  const mmUrl = useMemo(() => {
    if (supplierId)
      return `${MM_BASE_URL}?${EMasterMechanicURLParams.SUPPLIER_ID}=${supplierId}`;
    else return `${MM_BASE_URL}`;
  }, [supplierId]);

  return (
    <Box
      className='mm-container'
      style={{ display: isCorrectRoute ? 'flex' : 'none' }}
    >
      <iframe
        className='mm-iframe'
        src={mmUrl}
        frameBorder='0'
        allow='microphone *'
      ></iframe>
    </Box>
  );
};

export default MasterMechanic;
