/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { OrderLinks, OrderMeta } from '../interface';
import { WorkingOnIt } from '../interfaceV2';
import { DeliveryDestinationAddress } from '../orderSummary/interface';

import { EDeliveryType, EUserStatusType } from '@utils/enums';

interface PaginatedRequest {
  page: number;
}

export interface GetOrderItems extends PaginatedRequest {
  order: string;
}

export interface GetItemReplacements {
  order: string;
  item: string;
  part: string;
  page?: number;
}

export enum ORDER_STATUS {
  // Generic Statuses
  'pending-requested' = 'pending-requested',
  'pending-assigned' = 'pending-assigned',
  'pending-requested-shipment' = 'pending-requested',
  'pending-assigned-shipment' = 'pending-assigned',
  'pending-requested-pickup' = 'pending-requested',
  'pending-assigned-pickup' = 'pending-assigned',
  'pending-requested-curri' = 'pending-requested',
  'pending-assigned-curri' = 'pending-assigned',

  'pending-approval-fulfilled' = 'pending-approval-fulfilled',

  // Delivery Type dependant Statuses
  // ? 210
  'pending-approval-quote-needed-pickup' = 'pending-approval-quote-needed-pickup',
  'pending-approval-quote-needed-curri' = 'pending-approval-quote-needed-curri',
  'pending-approval-quote-needed-shipment' = 'pending-approval-quote-needed-shipment',
  // ? 220
  'pending-approval-quote-updated' = 'pending-approval-quote-updated',
  'pending-approval-quote-updated-shipment' = 'pending-approval-quote-updated-shipment',
  // ------------------------------------------------------------------------------------
  // ? 300
  'approved-awaiting-delivery-pickup' = 'approved-awaiting-delivery-pickup',
  'approved-awaiting-delivery-curri' = 'approved-awaiting-delivery-curri',
  'approved-awaiting-delivery-shipment' = 'approved-awaiting-delivery-shipment',
  // * News States below
  // ? 301
  'approved-awaiting-delivery-quote-needed-ws' = 'approved-awaiting-delivery-quote-needed-ws',
  // ? 302
  'approved-awaiting-delivery-quote-needed' = 'approved-awaiting-delivery-quote-needed',
  // ? 303
  'approved-awaiting-delivery-quote-updated' = 'approved-awaiting-delivery-quote-updated',
  // ------------------------------------------------------------------------------------
  // ? 310
  'approved-ready-for-delivery-pickup' = 'approved-ready-for-delivery-pickup',
  'approved-ready-for-delivery-curri' = 'approved-ready-for-delivery-curri',
  // * News States below
  // ? 311
  'approved-ready-for-delivery-quote-needed-ws' = 'approved-ready-for-delivery-quote-needed-ws',
  // ? 312
  'approved-ready-for-delivery-quote-needed' = 'approved-ready-for-delivery-quote-needed',
  // ? 313
  'approved-ready-for-delivery-quote-updated' = 'approved-ready-for-delivery-quote-updated',
  // ------------------------------------------------------------------------------------
  // ? 320
  'approved-delivered-pickup' = 'approved-delivered-pickup',
  'approved-delivered-curri' = 'approved-delivered-curri',
  // * News States below
  // ? 321
  'approved-delivered-quote-needed-ws' = 'approved-delivered-quote-needed-ws',
  // ? 322
  'approved-delivered-quote-needed' = 'approved-delivered-quote-needed',
  // ? 323
  'approved-delivered-quote-updated' = 'approved-delivered-quote-updated',
  // ------------------------------------------------------------------------------------
  // ? 400
  'completed-done' = 'completed-done',

  // THIRD PARTY
  'third-party-handled' = 'third-party-handled',

  //  Canceled & Declined states
  // Canceled by Technician
  'canceled-rejected' = 'canceled-rejected',
  // Declined by Technician
  'canceled-aborted' = 'canceled-aborted',
  // Canceled by Supplier
  'canceled-canceled' = 'canceled-canceled',
  // Declined by Supplier
  'canceled-declined' = 'canceled-declined',

  'canceled-blocked-user' = 'canceled-blocked-user',
  'canceled-deleted-user' = 'canceled-deleted-user',
  'canceled-rejected-by-contractor' = 'canceled-rejected-by-contractor',
  'returned-returned' = 'returned-returned',
}

export enum ORDER_TYPE {
  AVAILABILITY_REQUESTS = 'availability-requests',
  APPROVED = 'approved',
}

export interface GetOrdersProps extends PaginatedRequest {
  type?: ORDER_TYPE.AVAILABILITY_REQUESTS | ORDER_TYPE.APPROVED;
  startDate?: string;
  endDate?: string;
  status?: string;
}

export interface PatchItemInfo {
  order: string;
  item: string;
  status: string;
  quantity: number;
  price: number;
  replacement: PatchItemReplacement[] | null;
  available_at: string | null;
  part_number: string | null;
  brand?: string;
}

export interface PayloadPatchInfo {
  status: string;
  replacement?: PatchItemReplacement[] | null;
  quantity: number;
  price: number;
  available_at?: string | null;
  part_number?: string | null;
}

export interface PatchItemReplacement {
  type: string;
  id: string;
}

export interface CounterStaffProps {
  order: string;
  staff: string;
}

export interface SetInvoiceProps {
  order: string;
  file: File;
}

export interface updateOrderProps {
  order: string;
  total: number;
  bid_number: string;
  note: string;
}
export interface OrderItemsDetail<T> {
  data: Array<T>;
  links: OrderLinks;
  meta: OrderMeta;
}

export interface Conversions {
  thumb: string;
}

export interface ImageFile {
  id: string;
  url: string;
  conversions?: Conversions[] | null;
}

export interface KeyValueInterface {
  [key: string]: string;
}

export interface OrderItemInfo {
  action: number | undefined;
  item_id: string;
  brand: string;
  description: string | null;
  image: ImageFile;
  number: string;
  subcategory: string | null;
  type: string;
  subtype: string | null;
  replacements_count: number;
  specifications: KeyValueInterface;
}

export interface OrderItemDetail {
  id: string;
  type: string;
  note: string;
  info: OrderItemInfo;
}

export interface OrderReplacementDetail {
  id: string;
  type: string;
  item_id: string;
  brand: string;
  description: string | null;
  image: ImageFile;
  number: string;
  subcategory: string | null;
  subtype: string | null;
  replacements_count: number;
  specifications: KeyValueInterface;
}

export interface ReplacementItem {
  id: string;
  type: string;
  note: string;
  brand?: string;
  description?: string | null;
  part_number?: string | null;
  details: OrderReplacementDetail;
}

export interface CustomReplacementItem {
  id: string;
  type: string;
  brand: string;
  part_number: string;
}

export interface CustomReplacementResponse {
  item_id: string;
  data: CustomReplacementItem;
}

export interface StatusListFilterResponse {
  name: string;
  slug: string;
}

export interface OrderItem {
  available_at: string | null;
  id: string;
  quantity: number;
  price: number;
  status: string;
  supply_detail: string;
  custom_detail: string;
  generic_part_description: string;
  authorized_to_delete: boolean;
  item: OrderItemDetail;
  replacement: ReplacementItem | null;
  oem?: OEMPartInformation;
  knownReplacements: {
    data: ReplacementItem[];
    hasNextPage: boolean;
    nextPage: number | null;
    total: number | null;
  };
  item_order_detail?: {
    full_model: string;
    notes: string;
    part_number: string;
    serial_number: string;
  };
  detail?: {
    full_model: string;
    notes: string;
    part_number: string;
    serial_number: string;
  };
}

export interface UnlistedOrderItem extends Omit<OrderItem, 'item'> {
  item: {
    id: string;
    type: string;
    info: {
      id: string;
      name: string;
      brand: string | null;
      part_number: string | null;
      notes: string | null;
    };
  };
}

export interface OEMPartInformation {
  id: string;
  model: string;
  functional_parts_count: number;
  manuals_count: number;
  logo: string;
  image: string;
  call_group_tags?: any;
  calling_groups?: any;
}

export interface ExtraItem {
  id: string;
  custom_detail: string;
  generic_part_description: string;
  item: InternalSupplyItem;
  price: number;
  quantity: number;
  quantity_requested: number;
  status: string;
  supply_detail: string;
}

export interface SaveTruckStockItemsProps {
  order: string;
  items: { uuid: string; quantity: number };
}

export interface InternalSupplyItemInfo {
  id: string;
  name: string;
  internal_name: string;
  sort: string;
  image: { id: string; url: string };
}

export interface InternalSupplyItem {
  id: string;
  type: string;
  info: InternalSupplyItemInfo;
}

export interface Order {
  id: string;
  name: string;
  paid_total: number;
  updated_at: string;
  working_on_it: WorkingOnIt;
  created_at: string;
  curri_charge_acknowledged: boolean;
  supplier_user: SupplierUser;
  note: string;
  third_party_url: string;
  delivery: {
    requested_date: string;
    requested_start_time: string;
    requested_end_time: string;
    date: string;
    start_time: string;
    end_time: string;
    fee: number;
    note: string;
    type: `${EDeliveryType}`;
    is_needed_now: boolean;
    info: {
      supplier_confirmed_at: string | null;
      quote_id: string | null;
      book_id: string | null;
      vehicle_type: string;
      origin_address: DeliveryDestinationAddress | string | null;
      destination_address: DeliveryDestinationAddress | string | null;
      tracking_url: string | null;
      status: string | null;
      shipment_delivery_preference: { id: string };
    };
  };
  total: number;
  had_truck_stock: boolean;
  total_line_items: number;
  bid_number: string | null;
  channel?: string;
  current_status: OrderCurrentStatus;
  user: OrderUser;
  invoice?: Invoice | null;
  items: Array<{
    id: string;
    type: string;
    info: OrderItemInfo;
  }>;
  company: SupplierCompany | null;
}
export interface OrderCurrentStatus {
  status: string;
  substatus: string;
  detail: string;
  created_at: string;
  staff?: {
    id: string;
    name: string;
  };
}
export interface OrderUser {
  id: number;
  first_name: string;
  last_name: string;
  public_name: string;
  photo: string;
  disabled: boolean;
  company: Company | null;
  push_notification_token: string | null;
  email: string;
  phone: string;
}

export interface SupplierCompany extends Company {
  account_number: string;
}
export interface Company {
  id: string;
  name: string;
  type: string;
  country: {
    code: string;
    name: string;
  };
  state: {
    code: string;
    name: string;
  };
  city: string;
  address: string;
  zip_code: string;
  verified_at: string;
  onboarded: string;
}

export interface SupplierUser {
  cash_buyer: boolean;
  customer_tier: string;
  status: EUserStatusType;
  account_number: string;
}

export interface Invoice {
  id: string;
  url: string;
}
export interface CustomItem {
  id: string;
  quantity: number;
  price: number;
  status: string;
  supply_detail: string | null;
  custom_detail: string | null;
  generic_part_description: string | null;
  item: {
    id: string;
    type: string;
    info: {
      id: string;
      name: string;
      creator: string;
    };
  };
}

export interface SupplierStaff {
  id: string;
  name: string | null;
  email: string;
  phone: string;
  type: string;
  created_at: string;
}

export interface GetReplacementsResponse {
  item_id: string;
  data: ReplacementItem[];
  links?: OrderLinks;
  meta?: OrderMeta;
}

export interface SaveNewCustomerProps {
  userId: string;
  orderId: string;
  customTag?: string;
  cashBuyer?: boolean;
}

//  TODO: add more types as needed
export enum EOrderItemTypes {
  UNLISTED_ITEM = 'unlisted_item',
}

export type OrderItemsArray = (OrderItem | UnlistedOrderItem)[];

export enum EAddedItemStatus {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'not_available',
  PENDING = 'pending',
}
