import { Components } from '@mui/material';

//  Value in REM
export const tableCellHeight = 3.87625;

export const MuiTableCellTheme: Components['MuiTableCell'] = {
  styleOverrides: {
    body: {
      padding: '0rem 1rem',
      height: `${tableCellHeight}rem`,
      borderBottom: 'none',
      verticalAlign: 'middle',
    },
    head: {
      padding: '1rem',
    },
  },
};
