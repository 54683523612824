import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, TextField } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Button } from '@components/ButtonV2';
import { ShellModal } from '@components/ShellModal';
import { ReplacementItem } from '@pages/Tasks/interfaces';

import './styles.scss';

const validationSchema = Yup.object<Record<keyof any, Yup.AnySchema>>({
  partNumber: Yup.string()
    .min(1, 'Required')
    .max(50, 'Max amount of characters allowed is 50')
    .required('Required'),
  brand: Yup.string()
    .min(1, 'Required')
    .max(50, 'Max amount of characters allowed is 50')
    .required('Required'),
});

export const UseSpecBasedPartModal = ({
  open,
  onClose,
  handleUseReplacement,
  isLoading,
  partSelected,
  quantity,
  editMode,
  replacement,
}: {
  open: boolean;
  onClose: () => void;
  handleUseReplacement: ({
    brand,
    part_number,
    type,
    description,
  }: {
    brand: string;
    part_number: string;
    type: string;
    description: string | null;
  }) => void;
  isLoading: boolean;
  partSelected: ReactElement<any>;
  quantity: number;
  editMode: boolean;
  replacement: ReplacementItem | null;
}) => {
  const initialValues = {
    brand: '',
    partNumber: '',
    qty: quantity,
  };

  const [disableUseReplacementBtn, setDisableUseReplacementBtn] =
    useState(false);
  const { control, trigger, watch, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const brandValue = watch('brand');
  const partNumberValue = watch('partNumber');

  const handleEditModeSelection = () => {
    if (editMode && replacement) {
      setValue('brand', replacement?.details?.brand);
      setValue('partNumber', replacement?.details?.number);
    }
  };

  useEffect(() => handleEditModeSelection(), [editMode]);

  useEffect(() => {
    if (!brandValue || !partNumberValue) setDisableUseReplacementBtn(true);
    if (brandValue && partNumberValue) setDisableUseReplacementBtn(false);
    if (brandValue.length > 50 || partNumberValue.length > 50)
      setDisableUseReplacementBtn(true);
  }, [brandValue, partNumberValue]);

  const handleClearAndClose = () => {
    !editMode ? reset() : handleEditModeSelection();
    onClose();
  };

  const handleSubmitVerification = () => {
    const upperBrandString = brandValue.toUpperCase();
    handleUseReplacement({
      type: 'generic',
      brand: upperBrandString,
      part_number: partNumberValue,
      description: `${upperBrandString} ${partNumberValue}`,
    });
  };

  return (
    <ShellModal
      title='Use Spec Based Part'
      subtitle='Use an item that wasn’t a part of the original request.'
      open={open}
      onClose={handleClearAndClose}
      contentAdditionalStyle='px-2'
      bottomButtons={[
        <Button
          key={1}
          onClick={handleClearAndClose}
          variant='contained'
          color='secondary'
        >
          Cancel
        </Button>,
        <Button
          key={2}
          onClick={handleSubmitVerification}
          variant='contained'
          disabled={disableUseReplacementBtn || isLoading}
        >
          <>
            Use Replacement
            {isLoading && (
              <CircularProgress
                size='small'
                className='ml-1.5 w-[1.5rem] !text-white'
              />
            )}
          </>
        </Button>,
      ]}
    >
      <div className='flex flex-col'>
        <div className='mb-12 flex items-center justify-center py-[0.313rem]'>
          {partSelected}
        </div>
        <Box className='spec-based-modal-form-container'>
          <form className='mb-10 flex h-[4.938rem] gap-4'>
            <Box className='input-container'>
              <Controller
                name='brand'
                control={control}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    id='brand'
                    label='Brand'
                    variant='outlined'
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    className='w-[15.5rem]'
                    autoComplete={'off'}
                    error={!!fieldState.error}
                    onBlur={() => {
                      fieldState.error
                        ? setDisableUseReplacementBtn(true)
                        : null;
                      field.onBlur();
                      trigger('brand');
                    }}
                    helperText={
                      fieldState.error
                        ? formState.errors.brand?.message
                        : 'Required'
                    }
                    data-testid='brand'
                    defaultValue={field.value}
                  />
                )}
              />
            </Box>
            <Box className='input-container'>
              <Controller
                name='partNumber'
                control={control}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    className='w-[15.5rem]'
                    id='partNumber'
                    label='Part Number'
                    variant='outlined'
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    data-testid='partNumber'
                    error={!!fieldState.error}
                    onBlur={() => {
                      fieldState.error
                        ? setDisableUseReplacementBtn(true)
                        : null;
                      field.onBlur();
                      trigger('partNumber');
                    }}
                    helperText={
                      fieldState.error
                        ? formState.errors.partNumber?.message
                        : 'Required'
                    }
                    autoComplete={'off'}
                  />
                )}
              />
            </Box>
            <Box className='input-container-qty'>
              <Controller
                name='qty'
                control={control}
                render={({ field }) => (
                  <TextField
                    className='w-[3.5rem]'
                    id='qty'
                    disabled
                    label='Qty'
                    variant='outlined'
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    onBlur={field.onBlur}
                  />
                )}
              />
            </Box>
          </form>
        </Box>
      </div>
    </ShellModal>
  );
};
