import { Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';

import { TabPanel } from '../../../../../../components/TabPanel';
import { ItemSpecsComponent } from '../ItemSpecs';

import { ItemInfoProps } from './interface';

import './styles.scss';
import useGoogleTagManager from '@hooks/useGoogleTagManager';
import { assembleGTMEvent } from '@utils/GoogleTagManager';
import {
  GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS,
  GOOGLE_TAG_MANAGER_CATEGORY_PAGES,
  GOOGLE_TAG_MANAGER_EVENT_MESSAGES,
} from '@utils/GoogleTagManager/interface';

export const ItemInfoComponent = (props: ItemInfoProps): JSX.Element => {
  const {
    itemSpecs,
    specsCount,
    note,
    type,
    isSpecsLoading,
    partName,
    partBrand,
  } = props;
  const { setGTMEvent } = useGoogleTagManager();

  const itemSpecsValues =
    itemSpecs &&
    Object.keys(itemSpecs).map(function (key) {
      return itemSpecs[key];
    });

  const itemSpecsHasValueItems = itemSpecsValues?.some((item) => item !== null);

  const [value, setValue] = useState(
    note ? 1 : itemSpecs || isSpecsLoading ? 2 : 0,
  );

  const handleChange = (_event: any, newValue: string) => {
    const isNotesTab = Number(newValue) === 1;
    setGTMEvent(
      assembleGTMEvent(
        GOOGLE_TAG_MANAGER_CATEGORY_PAGES.TASKS,
        `${GOOGLE_TAG_MANAGER_EVENT_MESSAGES.CLICKED_ON}${
          isNotesTab ? 'Notes' : 'Specs'
        } tab`,
        undefined,
        isNotesTab
          ? GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS.NOTES
          : GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS.SPECS,
        {
          part: {
            partName,
            brandName: partBrand,
          },
        },
      ),
    );
    setValue(+newValue);
  };

  useEffect(() => {
    setValue(note ? 1 : itemSpecs || isSpecsLoading ? 2 : 0);
  }, [note, itemSpecs]);

  return (
    <>
      <Box sx={{ borderColor: 'divider' }} className='p-4'>
        <Tabs className='item-info-tabs2' value={value} onChange={handleChange}>
          <Tab
            label='ALL SPECS'
            value={2}
            disabled={
              (!itemSpecs && !isSpecsLoading) || !itemSpecsHasValueItems
            }
          />
          <Tab label='NOTES' value={1} disabled={!note} />
        </Tabs>
      </Box>
      <Box className='px-2'>
        <TabPanel value={value} index={1} padding={1.5}>
          <Box className='notes-caption-container'>
            <Typography variant='caption' className='notes-caption'>
              {note}
            </Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2} padding={0}>
          <ItemSpecsComponent
            itemSpecs={itemSpecs}
            specsCount={specsCount}
            type={type}
            isSpecsLoading={isSpecsLoading}
            itemSpecsHasValueItems={itemSpecsHasValueItems}
          />
        </TabPanel>
      </Box>
    </>
  );
};
