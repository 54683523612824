import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { InputHTMLAttributes, useCallback, useEffect, useState } from 'react';

import { defaultFromString, defaultToString, weekDays } from './constants';
import CustomDialogTitle from './CustomDialogTitle';
import { BranchHoursModalProps } from './interface';

import { Button } from '@components/ButtonV2';
import { TimePicker } from '@components/TimePicker';
import { SelectedHoursDataModel } from '@config/api/storeInfo/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import { postBranchHours } from '@state/storeInfo/actions';
import { convertTime12to24 } from '@utils/Numbers';
import './styles.scss';
const BranchHoursModal = (props: BranchHoursModalProps): JSX.Element => {
  const { data, visible, onCancel, setFieldValue, onAccept } = props;
  const dispatch = useAppDispatch();
  const loadingPostBranchHours = useAppSelector((state) =>
    isRequestRunning(state, String(postBranchHours)),
  );

  const [inputList, setInputList] =
    useState<SelectedHoursDataModel[]>(weekDays);

  const handleInputChange = (day: string, to?: string, from?: string) => {
    const items = [...inputList];
    const currentIndex = items.findIndex((item) => item.day === day);

    if (currentIndex >= 0) {
      const item = { ...items[currentIndex] };
      if (from) item.from = from;
      if (to) item.to = to;
      items[currentIndex] = item;
    }

    setInputList(items);
    setFieldValue(items);
  };

  const handleSubmit = () => {
    const payload = inputList
      .filter((item) => item.active)
      .map((item) => ({
        day: item.day,
        from: convertTime12to24(item.from),
        to: convertTime12to24(item.to),
      }));
    dispatch(
      postBranchHours({
        hours: payload,
        callback: onAccept,
        setValueCallback: () => {
          setFieldValue(inputList);
        },
      }),
    );
  };

  const fillInputList = useCallback(() => {
    if (!data) return;
    const mappedDays = inputList.map((item) => {
      const matchedData = data.find((i) => i.day === item.day);

      return {
        day: item.day,
        active: matchedData?.active,
        to: matchedData?.to || item.to,
        from: matchedData?.from || item.from,
      };
    });
    setInputList(mappedDays);
  }, [data, inputList]);

  useEffect(() => {
    fillInputList();
  }, [data]);

  const handleCheckboxChange = (day: string) => {
    const result = inputList.map((item) =>
      item.day === day ? { ...item, active: !item.active } : item,
    );
    setInputList(result);
    setFieldValue(result);
  };

  const onCancelPress = () => {
    fillInputList();
    onCancel();
  };

  const onCloseDialog = (event: unknown, reason: string) => {
    if (reason !== 'backdropClick') onCancelPress();
  };

  return (
    <Dialog onClose={onCloseDialog} open={visible} className='bl-hours-dialog '>
      <Stack spacing={1} justifyContent='center' border='-moz-initial'>
        <DialogContent>
          <Box className='dialog-title'>
            <CustomDialogTitle onClose={onCancelPress}>
              Choose your opens days and hours
            </CustomDialogTitle>
          </Box>

          <Box className='input-container'>
            {inputList.map((item, key) => (
              <Grid key={key} container rowSpacing={1} data-testid='item'>
                <Grid item xs={3} className='check-container'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={item.day}
                        onChange={() => handleCheckboxChange(item.day)}
                        name={item.day}
                        checked={item.active}
                        inputProps={
                          {
                            'data-testid': 'itemCheckbox',
                          } as InputHTMLAttributes<HTMLInputElement> | any
                        }
                      />
                    }
                    label={_.capitalize(item.day)}
                  />
                </Grid>
                <Grid item xs={9} className='row-container'>
                  <>
                    <Typography
                      className='padding-text'
                      variant='body1'
                      color='initial'
                      display='inline'
                    >
                      from
                    </Typography>
                    <TimePicker
                      key={item.from}
                      id='fromPicker'
                      name='fromPicker'
                      data-testid='itemHourFrom'
                      startTime='00:00 AM'
                      endTime={item.to ?? '11:30 PM'}
                      interval='00:30'
                      selected={item.from || defaultFromString}
                      value={item.from || defaultFromString}
                      disabled={!item.active}
                      onChange={(event: SelectChangeEvent<any>) => {
                        handleInputChange(
                          item.day,
                          undefined,
                          event.target.value,
                        );
                      }}
                      fullWidth
                    />
                  </>
                  <>
                    <Typography
                      className='padding-text'
                      variant='body1'
                      color='initial'
                      display='inline'
                    >
                      to
                    </Typography>
                    <TimePicker
                      key={item.to}
                      id='toPicker'
                      name='toPicker'
                      data-testid='itemHourTo'
                      startTime={item.from ?? '00:00 AM'}
                      endTime='11:59 PM'
                      interval='00:30'
                      selected={item.to || defaultToString}
                      value={item.to || defaultToString}
                      disabled={!item.active}
                      onChange={(event: SelectChangeEvent<any>) => {
                        handleInputChange(item.day, event.target.value);
                      }}
                      fullWidth
                    />
                  </>
                </Grid>
              </Grid>
            ))}
          </Box>
        </DialogContent>
        <DialogActions className='modal-footer'>
          <Button
            variant='contained'
            color='secondary'
            data-testid='cancelButton'
            onClick={onCancelPress}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            data-testid='saveButton'
            startIcon={<CheckIcon />}
            onClick={handleSubmit}
            loading={loadingPostBranchHours}
            disabled={!inputList.some((input) => input.active)}
          >
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default BranchHoursModal;
