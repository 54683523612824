import {
  modelSpecificationsResponse,
  modelsResponse,
  moreModelsResponse,
  morePartReplacementResponse,
  morePartsResponse,
  moreSeriesResponse,
  partDetailResetResponse,
  partDetailResponse,
  partReplacementDetailResetResponse,
  partReplacementDetailResponse,
  partReplacementResponse,
  partsResetResponse,
  partsResponse,
  previousModelPartResponse,
  previousPartsResponse,
  previousSearchModelsResponse,
  searchModelsResponse,
  searchMoreModelsResponse,
  seriesResponse,
} from './actions';
import {
  SearchModelSpecificationsResponseProps,
  SearchModelsApiResponseProps,
  SearchModelsResponseProps,
  SearchPartDetailResponseProps,
  SearchPartReplacementResponseProps,
  SearchPartsResponseProps,
  SearchSeriesResponseProps,
  SearchStateProps,
} from './interface';

import {
  PartsDataModel,
  SearchModelsDataModel,
} from '@config/api/search/interface';
import { getHasNextPageFromMeta, getNextPageFromMeta } from '@utils/apiPaging';

const initialState: SearchStateProps = {
  series: {
    data: [],
    hasNextPage: false,
    nextPage: 1,
  },
  models: {
    data: [],
    hasNextPage: false,
    nextPage: 1,
  },
  modelSpecifications: {
    data: null,
    manualCount: 0,
  },
  parts: {
    data: [],
    hasNextPage: false,
    nextPage: 1,
    partDetails: null,
    total: null,
    replacements: {
      data: [],
      partReplacementDetails: null,
      hasNextPage: false,
      nextPage: 1,
    },
  },
  searchModel: {
    data: [],
    hasNextPage: false,
    nextPage: 1,
    total: null,
    searchTerm: null,
  },
};

const searchReducer = (
  state = initialState,
  action:
    | SearchSeriesResponseProps
    | SearchModelsResponseProps
    | SearchModelSpecificationsResponseProps
    | SearchPartsResponseProps
    | SearchModelsApiResponseProps
    | SearchPartDetailResponseProps
    | SearchPartReplacementResponseProps,
): SearchStateProps => {
  switch (action.type) {
    case String(seriesResponse): {
      const { data, meta } = (action as SearchSeriesResponseProps).payload;

      return {
        ...state,
        series: {
          ...state.series,
          data,
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
        },
      };
    }
    case String(moreSeriesResponse): {
      const { data, meta } = (action as SearchSeriesResponseProps).payload;

      return {
        ...state,
        series: {
          ...state.series,
          data: [...state.series.data, ...data],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
        },
      };
    }

    case String(modelsResponse): {
      const { data, meta } = (action as SearchModelsResponseProps).payload;
      const dataPage = data.map((model) => ({
        ...model,
        page: meta.current_page,
      }));

      return {
        ...state,
        models: {
          ...state.models,
          data: [...dataPage],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
        },
      };
    }
    case String(moreModelsResponse): {
      const { data, meta } = (action as SearchModelsResponseProps).payload;
      const dataPage = data.map((model) => ({
        ...model,
        page: meta.current_page,
      }));

      return {
        ...state,
        models: {
          ...state.models,
          data: [...state.models.data, ...dataPage],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
        },
      };
    }
    case String(modelSpecificationsResponse): {
      const { data } = (action as SearchModelSpecificationsResponseProps)
        .payload;
      const manuals = data.manuals;
      const manualCount =
        manuals.bluon_guidelines.length +
        manuals.controls_manuals.length +
        manuals.diagnostic.length +
        manuals.iom.length +
        manuals.misc.length +
        manuals.product_data.length +
        manuals.service_facts.length +
        manuals.wiring_diagram.length;

      return {
        ...state,
        modelSpecifications: {
          ...state.modelSpecifications,
          data,
          manualCount,
        },
      };
    }

    case String(partsResponse): {
      const { data, meta } = (action as SearchPartsResponseProps).payload;
      const dataPage = data.map((part) => ({
        ...part,
        page: meta.current_page,
        part_search_counter_id: meta?.part_search_counter_id,
      }));

      return {
        ...state,
        parts: {
          ...state.parts,
          data: dataPage,
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          total: meta.total === 0 ? 0 : meta.total || null,
        },
      };
    }
    case String(morePartsResponse): {
      const { data, meta } = (action as SearchPartsResponseProps).payload;
      const dataPage = data.map((part) => ({
        ...part,
        page: meta.current_page,
        part_search_counter_id: meta?.part_search_counter_id,
      }));

      return {
        ...state,
        parts: {
          ...state.parts,
          data: [...state.parts.data, ...dataPage],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
        },
      };
    }
    case String(partsResetResponse): {
      return {
        ...state,
        parts: initialState.parts,
      };
    }

    case String(partDetailResponse): {
      const { data } = (action as SearchPartDetailResponseProps).payload;

      return {
        ...state,
        parts: {
          ...state.parts,
          partDetails: data,
        },
      };
    }
    case String(partDetailResetResponse): {
      return {
        ...state,
        parts: {
          ...state.parts,
          partDetails: null,
        },
      };
    }

    case String(partReplacementDetailResponse): {
      const { data } = (action as SearchPartDetailResponseProps).payload;

      return {
        ...state,
        parts: {
          ...state.parts,
          replacements: {
            ...state.parts.replacements,
            partReplacementDetails: data,
          },
        },
      };
    }

    case String(partReplacementDetailResetResponse): {
      return {
        ...state,
        parts: {
          ...state.parts,
          replacements: {
            ...state.parts.replacements,
            partReplacementDetails: null,
          },
        },
      };
    }

    case String(partReplacementResponse): {
      const { data, meta } = (action as SearchPartReplacementResponseProps)
        .payload;

      return {
        ...state,
        parts: {
          ...state.parts,
          replacements: {
            data,
            partReplacementDetails: null,
            hasNextPage: getHasNextPageFromMeta(meta),
            nextPage: getNextPageFromMeta(meta),
          },
        },
      };
    }

    case String(morePartReplacementResponse): {
      const { data, meta } = (action as SearchPartReplacementResponseProps)
        .payload;

      return {
        ...state,
        parts: {
          ...state.parts,
          replacements: {
            data: [...state.parts.replacements.data, ...data],
            partReplacementDetails: null,
            hasNextPage: getHasNextPageFromMeta(meta),
            nextPage: getNextPageFromMeta(meta),
          },
        },
      };
    }
    case String(searchModelsResponse): {
      const { data, meta } = (action as SearchModelsApiResponseProps).payload;
      const dataPage = data.map((model) => ({
        ...model,
        page: meta.current_page,
        oem_search_counter_id: meta?.oem_search_counter_id,
      }));

      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          data: [...dataPage],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
          total: meta.total === 0 ? 0 : meta.total || null,
          searchTerm: meta.search_term || null,
        },
      };
    }
    case String(searchMoreModelsResponse): {
      const { data, meta } = (action as SearchModelsApiResponseProps).payload;
      const dataPage = data.map((model) => ({
        ...model,
        page: meta.current_page,
        oem_search_counter_id: meta?.oem_search_counter_id,
      }));

      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          data: [...state.searchModel.data, ...dataPage],
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
        },
      };
    }
    case String(previousPartsResponse): {
      const { data, meta } = (action as SearchPartsResponseProps).payload;
      const dataPage = data.map((part) => ({
        ...part,
        page: meta.current_page,
        part_search_counter_id: meta?.part_search_counter_id,
      }));
      const currentParts = [...dataPage, ...state.parts.data];

      return {
        ...state,
        parts: {
          ...state.parts,
          data: currentParts as PartsDataModel[],
        },
      };
    }

    case String(previousSearchModelsResponse): {
      const { data, meta } = (action as SearchModelsApiResponseProps).payload;
      const dataPage = data.map((model) => ({
        ...model,
        page: meta.current_page,
        oem_search_counter_id: meta?.oem_search_counter_id,
      }));
      const currentModel = [...dataPage, ...state.searchModel.data];

      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          data: currentModel as SearchModelsDataModel[],
        },
      };
    }

    case String(previousModelPartResponse): {
      const { data, meta } = (action as SearchPartsResponseProps).payload;
      const dataPage = data.map((part) => ({
        ...part,
        page: meta.current_page,
      }));
      const currentParts = [...dataPage, ...state.parts.data];

      return {
        ...state,
        parts: {
          ...state.parts,
          data: currentParts as PartsDataModel[],
        },
      };
    }

    default:
      return state;
  }
};

export default searchReducer;
