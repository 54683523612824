import { Components } from '@mui/material';

import { bluonColors, opacityColors } from './colors';

export const MuiMenuItemTheme: Components['MuiMenuItem'] = {
  variants: [
    {
      props: { className: 'menu-item' },
      style: {
        fontSize: '0.9375rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        color: bluonColors.grey['80']!,
        textTransform: 'none',
        padding: '0.75rem 1rem',
        width: '15rem',
      },
    },
    {
      props: { className: 'select-menu-item' },
      style: {
        fontFamily: 'Roboto',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '150%',
        letterSpacing: '0.009375rem',
        color: opacityColors?.black[90],
      },
    },
  ],
};
