import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { BaseCustomerBanner } from '../BaseBanner';

import { ConfirmedCustomerBannerProps } from './interface';

import { Button } from '@components/ButtonV2';
import { useSnackbar } from '@components/Snackbar/hooks';
import './styles.scss';

export const ConfirmedAccountCustomerBanner = ({
  accountNumber,
}: ConfirmedCustomerBannerProps) => {
  const { showMessage } = useSnackbar();
  const [isCopied, setIsCopied] = useState(false);

  return (
    <BaseCustomerBanner className='bl-confirmed-account-customer-banner'>
      <Typography variant='subtitle1'> Account #: {accountNumber}</Typography>
      <CopyToClipboard
        text={accountNumber}
        onCopy={() => {
          showMessage('Account number copied!', {
            severity: 'success',
            autoHide: true,
          });
          setIsCopied(true);
        }}
      >
        <Button variant='outlined' color='primary2'>
          {!isCopied ? (
            'Copy #'
          ) : (
            <div className='copied-button'>
              Copied <CheckIcon />
            </div>
          )}
        </Button>
      </CopyToClipboard>
    </BaseCustomerBanner>
  );
};
