import InfoIcon from '@mui/icons-material/Info';
import { Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import {
  acceptButtonTestId,
  cancelButtonTestId,
  cancelModalTestId,
  cancelModalTextContent,
  modalIconTestId,
  modalTextContainerTestId,
} from './constants';
import { CancelModalProps } from './interface';

import './styles.scss';
import { Button } from '@components/ButtonV2';

export const CancelFormModal = (props: CancelModalProps): JSX.Element => {
  const { visible, onAccept, onCancel } = props;

  return (
    <Dialog
      onClose={onCancel}
      open={visible}
      maxWidth={'xs'}
      className='bl-dialog'
      data-testid={cancelModalTestId}
    >
      <Stack
        spacing={1}
        justifyContent='center'
        alignItems={'center'}
        border='-moz-initial'
      >
        <InfoIcon
          color='primary'
          className='center-icon'
          data-testid={modalIconTestId}
        />
        <DialogContent>
          <DialogContentText
            id='dialog-description'
            variant='h6'
            color='default'
            align='center'
            data-testid={modalTextContainerTestId}
          >
            {cancelModalTextContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack spacing={3} direction='row' justifyContent='center'>
            <Button
              variant='outlined'
              onClick={onCancel}
              data-testid={cancelButtonTestId}
            >
              No
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={onAccept}
              data-testid={acceptButtonTestId}
            >
              Yes
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
