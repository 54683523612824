import { AddressType } from '@config/api/chat/interface';
import { DeliveryDestinationAddress } from '@config/api/orderSummary/interface';

export const getParsedAddress = (
  address: AddressType | DeliveryDestinationAddress | string | undefined,
  breakPoint: boolean,
): string => {
  if (address && typeof address !== 'string') {
    const city = breakPoint ? `<br/> ${address.city}` : address.city;
    const addressItems = [
      address.address_1,
      address.address_2,
      city,
      address.state,
      address.zip_code,
    ];

    const filteredAddress = addressItems.filter((x) => x !== null).join(', ');

    return filteredAddress;
  }

  return '';
};
