import { CircularProgress, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TermsOfService } from '../TermsOfService';

import { WelcomeContainerProps } from './interface';

import BluonLogo from '@assets/images/bluon-logo-v2-white.svg';
import GrowMap from '@assets/images/GrowMap.png';
import { useAppSelector } from '@hooks/state';
import { openTermsOfService } from '@state/auth/actions';
import { getShowTermsInfoData } from '@state/auth/selectors';
import { isRequestRunning } from '@state/requests/selectors';
import { getUsersCountRequest } from '@state/user/actions';
import './styles.scss';
import { getUsersCount } from '@state/user/selectors';

export const WelcomeContainer = ({
  children,
}: WelcomeContainerProps): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersCountRequest());
  }, [dispatch]);

  const { isLoadingUsersCount, showTerms, usersCount } = useAppSelector(
    (state) => ({
      showTerms: getShowTermsInfoData(state),
      usersCount: getUsersCount(state),
      isLoadingUsersCount: isRequestRunning(
        state,
        String(getUsersCountRequest),
      ),
    }),
  );

  const handleClose = () => {
    dispatch(openTermsOfService(!showTerms));
  };

  return (
    <>
      <TermsOfService open={showTerms} handleClose={handleClose} />
      <Grid container className='bl-login-container'>
        <Grid
          item
          container
          sm={4}
          sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
          className='login-side-container'
        >
          <Grid container className='container-center'>
            <Box className='bl-logo-title-container'>
              <Typography variant='h6' color='white'>
                Welcome to Bluon!
              </Typography>
              <img className='bluon-logo' src={BluonLogo} alt='Bluon Live' />
            </Box>
          </Grid>
          <Grid container className='container-center'>
            <img className='grow-map' src={GrowMap} alt='Grow Map' />
            {isLoadingUsersCount && !usersCount && <CircularProgress />}
            {!isLoadingUsersCount && !!usersCount && (
              <Typography variant='h6' color='white' fontWeight='400'>
                More than <b>{`${usersCount} Techs`}</b> relying on Bluon!
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item container xs={12} sm={12} md={8} className='form-container'>
          {children}
        </Grid>
      </Grid>
    </>
  );
};
