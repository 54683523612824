import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import './styles.scss';
import { ComingSoonTooltip } from '../ComingSoonTooltip/ComingSoonTooltip';
import TextInput from '../TextInput';

import { searchInputContainerTestId, searchInputTestId } from './constants';
import { SearchInputProps } from './interface';

export const SearchInput = ({
  containerClassName,
  onChange,
  placeholder,
  value,
  style,
  disabled = false,
  showComingTooltip = false,
  type = 'TextInput',
  label,
  alwaysShowBorder = false,
  searchClassName = '',
  customInputType = 'search',
  useCustomSearchAdornment = false,
}: SearchInputProps): JSX.Element => {
  const [search, setSearch] = useState('');

  const CustomSearchAdornment = (
    <IconButton aria-label='clear search' onClick={() => handleChange('')}>
      <ClearIcon className='!h-[0.875rem]' />
    </IconButton>
  );

  useEffect(() => {
    setSearch(value || '');
  }, [value]);

  const handleDebounceUpdate = useCallback(
    _.debounce(onChange ? onChange : () => null, 300),
    [],
  );

  const handleChange = (text: string) => {
    setSearch(text);
    handleDebounceUpdate(text);
  };

  return (
    <ComingSoonTooltip show={showComingTooltip}>
      <Container
        data-testid={searchInputContainerTestId}
        maxWidth='xl'
        className={`bl-search-input-container ${containerClassName}`}
      >
        {type === 'TextInput' && (
          <TextInput
            data-testid={searchInputTestId}
            type={customInputType}
            startAdornment={<SearchIcon className={searchClassName} />}
            customAdornment={
              useCustomSearchAdornment && search.length > 0
                ? CustomSearchAdornment
                : null
            }
            className={`bl-search-input ${
              alwaysShowBorder ? 'show-border' : ''
            }`}
            placeholder={placeholder}
            value={search}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event.target.value)
            }
            style={style ? style : {}}
            disabled={disabled}
          />
        )}
        {type === 'TextField' && (
          <TextField
            label={label}
            data-testid={searchInputTestId}
            type='search'
            className={'bl-search-input '}
            placeholder={placeholder}
            value={search}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event.target.value)
            }
            InputProps={{
              startAdornment: <SearchIcon />,
              fullWidth: true,
            }}
            style={style ? style : {}}
            disabled={disabled}
            fullWidth
          />
        )}
      </Container>
    </ComingSoonTooltip>
  );
};
