import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { DeclineModalForm, DeclineModalProps } from './interface';
import { DeclineModalValidationSchema } from './validations';

import { Button } from '@components/ButtonV2';
import { ShellModal } from '@components/ShellModal';
import { useSnackbar } from '@components/Snackbar/hooks';
import { useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import { declineShareableOrder } from '@state/shareableOrder/actions';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

enum RADIO_BUTTON_OPTIONS {
  CUSTOMER_REJECTED_JOB = 'Customer Rejected Job',
  UNACCEPTABLE_QUOTE = 'Unacceptable Quote',
  QUOTE_TOOK_TOO_LONG = 'Quote Took Too Long',
  OTHER = 'Other',
}

const DeclineModal = ({ isOpen, onClose, orderId }: DeclineModalProps) => {
  const initialValues: DeclineModalForm = {
    selectedOption: '',
    otherText: '',
  };

  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const { isApiSubmitRunning } = useAppSelector((state) => ({
    isApiSubmitRunning: isRequestRunning(state, String(declineShareableOrder)),
  }));

  const { control, watch, reset } = useForm<DeclineModalForm>({
    resolver: yupResolver(DeclineModalValidationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const selectedOptionValue = watch('selectedOption');
  const otherOptionTextValue = watch('otherText');

  const resetFormValues = useCallback(() => {
    reset(initialValues);
  }, []);

  const handleFormClose = useCallback(() => {
    resetFormValues();
    onClose();
  }, []);

  const declineOrderSuccessCallback = useCallback(() => {
    showMessage('Quote has been declined', {
      severity: 'info',
    });
    onClose();
  }, []);

  const declineOrderErrorCallback = useCallback(() => {
    showMessage('Oops! Something happened, please try again later', {
      severity: 'error',
    });
  }, []);

  const handleOrderDecline = () => {
    dispatch(
      declineShareableOrder({
        orderId,
        reason: capitalizeFirstLetter(selectedOptionValue.toLowerCase()),
        ...(otherOptionTextValue &&
          otherOptionTextValue.length > 0 && { detail: otherOptionTextValue }),
        successCallback: declineOrderSuccessCallback,
        errorCallback: declineOrderErrorCallback,
      }),
    );
  };

  const isOtherOptionSelected =
    selectedOptionValue === RADIO_BUTTON_OPTIONS.OTHER;

  const isButtonDisabled = useMemo(() => {
    return (
      selectedOptionValue === '' ||
      isApiSubmitRunning ||
      (isOtherOptionSelected && String(otherOptionTextValue).length < 5)
    );
  }, [
    isOtherOptionSelected,
    otherOptionTextValue,
    selectedOptionValue,
    isApiSubmitRunning,
  ]);

  return (
    <ShellModal
      open={isOpen}
      onClose={handleFormClose}
      title='Why are you declining?'
      subtitle='We share this info with your supplier.'
      bottomButtons={[
        <Button key={1} onClick={handleFormClose} variant='outlined'>
          Close
        </Button>,
        <Button
          key={2}
          onClick={handleOrderDecline}
          disabled={isButtonDisabled}
          variant='contained'
          color='error'
        >
          Decline This Request
        </Button>,
      ]}
    >
      <>
        <div className='mb-6 flex w-[11rem] md:w-[39.5rem]'>
          <Controller
            name='selectedOption'
            control={control}
            render={({ field }) => (
              <>
                <RadioGroup
                  row
                  className='max-w-lg flex-col md:flex-row'
                  defaultValue={0}
                  value={field.value}
                  name='selectedOption'
                >
                  <FormControlLabel
                    id='selectedOption'
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={RADIO_BUTTON_OPTIONS.CUSTOMER_REJECTED_JOB}
                    control={<Radio />}
                    label={RADIO_BUTTON_OPTIONS.CUSTOMER_REJECTED_JOB}
                    className='min-w-[18rem]'
                  />
                  <FormControlLabel
                    id='selectedOption'
                    value={RADIO_BUTTON_OPTIONS.UNACCEPTABLE_QUOTE}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    control={<Radio />}
                    label={RADIO_BUTTON_OPTIONS.UNACCEPTABLE_QUOTE}
                  />
                  <FormControlLabel
                    id='selectedOption'
                    value={RADIO_BUTTON_OPTIONS.QUOTE_TOOK_TOO_LONG}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    control={<Radio />}
                    label={RADIO_BUTTON_OPTIONS.QUOTE_TOOK_TOO_LONG}
                    className='min-w-[18rem]'
                  />
                  <FormControlLabel
                    id='selectedOption'
                    value={RADIO_BUTTON_OPTIONS.OTHER}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    control={<Radio />}
                    label={RADIO_BUTTON_OPTIONS.OTHER}
                  />
                </RadioGroup>
              </>
            )}
          />
        </div>
        {isOtherOptionSelected && (
          <div className='reason-wrapper mb-6'>
            <Controller
              name='otherText'
              control={control}
              render={({ field }) => (
                <TextField
                  id='otherText'
                  label='Reason'
                  variant='outlined'
                  multiline
                  inputProps={{ maxLength: 255 }}
                  maxRows={5}
                  rows={4}
                  name={field.name}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!!value && value.length > 255) return;
                    field.onChange(e);
                  }}
                  value={field.value}
                  fullWidth
                  onBlur={field.onBlur}
                  className={classNames('w-full md:w-[36.125rem]', {
                    red: Number(otherOptionTextValue?.length) === 255,
                  })}
                  color='primary'
                  helperText={
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      className='float-right'
                    >
                      <span
                        className={classNames('', {
                          'red-color': otherOptionTextValue?.length === 255,
                          'text-[var(--bluon--ds--light-black)]':
                            Number(otherOptionTextValue?.length) < 255,
                        })}
                      >{`${Number(field.value?.length)} / 255`}</span>
                    </Box>
                  }
                />
              )}
            />
          </div>
        )}
      </>
    </ShellModal>
  );
};

export default DeclineModal;
