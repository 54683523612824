import { EUserTypes } from '@config/api/user/interface';

export const formatDate = (date?: string | null, isShort = false): string => {
  if (!date) return '';

  let monthNames: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (isShort)
    monthNames = monthNames.map((month) => {
      return month.substring(0, 3);
    });

  const dateObj = new Date(date);

  return `${
    monthNames[dateObj.getUTCMonth()]
  } ${dateObj.getUTCDate()}, ${dateObj.getUTCFullYear()}`;
};

export const timeZones = [
  { label: 'Alaskan Time Zone', value: 'America/Anchorage' },
  { label: 'Pacific Time Zone', value: 'America/Los_Angeles' },
  { label: 'Mountain no DST Time Zone (Arizona)', value: 'America/Phoenix' },
  { label: 'Mountain Time Zone', value: 'America/Denver' },
  { label: 'Central Time Zone', value: 'America/Chicago' },
  { label: 'Eastern Time Zone', value: 'America/New_York' },
  { label: 'Hawaiian Time Zone', value: 'America/Adak' },
];

export const hourRangeOptions = [
  {
    value: '1',
    label: '6AM - 9AM',
  },
  {
    value: '2',
    label: '9AM - 12PM',
  },
  {
    value: '3',
    label: '12PM - 3PM',
  },
  {
    value: '4',
    label: '3PM - 6PM',
  },
];

export const orderItemAvailableAtFormat = 'MM/DD/YY';

export const supportPhoneNumbers: Record<EUserTypes, string> = {
  [EUserTypes.REGULAR_USER]: '(657) 439-7658',
  [EUserTypes.SEARCH_USER]: '(949) 340-5850',
};
