import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import './styles.scss';
import { ECustomerAccount } from '../../interface';

import { RADIO_ACTIONS } from './constants';
import { UnconfirmedCustomerFormProps } from './interface';

export const UnconfirmedCustomerForm = ({
  customerAccount,
  setCustomerAccount,
  control,
  trigger,
}: UnconfirmedCustomerFormProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerAccount(+event.target.value as ECustomerAccount);
  };

  return (
    <Box className='bl-unconfirmed-customers-actions'>
      <Box className='radio-actions'>
        <Typography variant='subtitle1'>
          Does this customer have an account? *
        </Typography>
        <RadioGroup row value={customerAccount} onChange={handleChange}>
          <FormControlLabel
            value={RADIO_ACTIONS.HAS_ACCOUNT.value}
            label={RADIO_ACTIONS.HAS_ACCOUNT.label}
            control={<Radio />}
          />
          <FormControlLabel
            value={RADIO_ACTIONS.CASH_BUYER.value}
            label={RADIO_ACTIONS.CASH_BUYER.label}
            control={<Radio />}
          />
        </RadioGroup>
      </Box>
      {customerAccount !== ECustomerAccount.NO_SELECTION && (
        <Box className='modal-form'>
          {customerAccount === ECustomerAccount.HAS_ACCOUNT && (
            <Controller
              name='account_number'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextField
                  fullWidth
                  className='account-number-field'
                  id='account_number'
                  variant='outlined'
                  label='Account Number (Required)'
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={() => {
                    field.onBlur();
                    trigger('account_number');
                  }}
                  defaultValue={field.value}
                  error={!!fieldState.error}
                  helperText={formState.errors.account_number?.message}
                  autoComplete='off'
                />
              )}
            />
          )}

          <Controller
            name='customer_tier'
            control={control}
            render={({ field, fieldState, formState }) => (
              <TextField
                fullWidth
                id='customer_tier'
                variant='outlined'
                className='custom-tag-field'
                label='Custom Tag (Optional)'
                name={field.name}
                onChange={field.onChange}
                onBlur={() => {
                  field.onBlur();
                  trigger('customer_tier');
                }}
                value={field.value}
                defaultValue={field.value}
                error={!!fieldState.error}
                helperText={formState.errors.customer_tier?.message}
                autoComplete='off'
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};
