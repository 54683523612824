import React from 'react';
import { useDispatch } from 'react-redux';

import { BrandModalActionsProps } from '../../interface';
import { BrandsActionButtons } from '../BrandsModalActionButtons';

import { useSnackbar } from '@components/Snackbar/hooks';
import {
  ESnackbarSeverity,
  SnackbarSeverity,
} from '@components/Snackbar/interface';
import { useAppSelector } from '@hooks/state';
import { isRequestRunning } from '@state/requests/selectors';
import { storeInfoPostPartBrands } from '@state/storeInfo/actions';

export const PartBrandsModalActions = ({
  brandsChecked,
  onHandleClose,
  closeModal,
  errorMessage,
  isDirty,
}: BrandModalActionsProps) => {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();

  const { isLoadingPostBrands } = useAppSelector((state) => ({
    isLoadingPostBrands: isRequestRunning(
      state,
      String(storeInfoPostPartBrands),
    ),
  }));

  const onHandleSave = () => {
    dispatch(
      storeInfoPostPartBrands({
        brands: brandsChecked,
        onSuccessCallback: handleCallback(
          ESnackbarSeverity.SUCCESS,
          'Part Brands updated successfully!',
        ),
        onErrorCallback: handleCallback(
          ESnackbarSeverity.ERROR,
          'Oops, something went wrong. Please try again.',
        ),
      }),
    );
  };

  const handleCallback = (severity: SnackbarSeverity, message: string) => {
    return () => {
      showMessage(message, { severity });
      if (severity === ESnackbarSeverity.SUCCESS) closeModal(true);
    };
  };

  return (
    <BrandsActionButtons
      errorMessage={errorMessage}
      isLoadingRequest={isLoadingPostBrands}
      onHandleClose={onHandleClose}
      onHandleSave={onHandleSave}
      isDirty={isDirty}
    />
  );
};
