import { CircularProgress, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '@components/ButtonV2';
import { ShellModal } from '@components/ShellModal';

export const AddItemModal = ({
  open,
  onClose,
  handleAddAddtionalItem,
  isLoading,
}: {
  open: boolean;
  onClose: () => void;
  handleAddAddtionalItem: (item: { name: string; qty: string }) => void;
  isLoading: boolean;
}) => {
  const { control, watch, setValue } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: {
      additionalItem: '',
      qty: '1',
    },
  });

  const additionalItemValue = watch('additionalItem');
  const qtyValue = watch('qty');

  const handleInputsDefaultValue = () => {
    setValue('additionalItem', '');
    setValue('qty', '1');
  };

  const handleClose = () => {
    handleInputsDefaultValue();
    onClose();
  };

  const handleAddItem = () => {
    handleAddAddtionalItem({ name: additionalItemValue, qty: qtyValue });
  };

  useEffect(() => handleInputsDefaultValue(), [open]);

  return (
    <ShellModal
      title='Add an Item'
      subtitle='Add an item that wasn’t a part of the original request.'
      open={open}
      onClose={handleClose}
      bottomButtons={[
        <Button
          key={1}
          onClick={handleClose}
          variant='contained'
          color='secondary'
        >
          Cancel
        </Button>,
        <Button
          key={2}
          onClick={handleAddItem}
          disabled={additionalItemValue.length < 3 || isLoading}
          variant='contained'
        >
          <>
            Add Item
            {isLoading && (
              <CircularProgress
                size='small'
                className='ml-1.5 w-[1.5rem] !text-white'
              />
            )}
          </>
        </Button>,
      ]}
    >
      <div className='mb-10 flex gap-4'>
        <Controller
          name='additionalItem'
          control={control}
          render={({ field }) => (
            <TextField
              id='additionalItem'
              label='Additional Line Item'
              variant='outlined'
              name={field.name}
              onChange={(e) => {
                const value = e.target.value;
                if (!!value && value.length > 40) return;
                field.onChange(e);
              }}
              value={field.value}
              onBlur={field.onBlur}
              className='w-[26.125rem]'
              color='primary'
            />
          )}
        />
        <Controller
          name='qty'
          control={control}
          render={({ field }) => (
            <TextField
              className='w-[9.375rem]'
              id='qty'
              label='Qty'
              variant='outlined'
              name={field.name}
              onChange={(e) => {
                const value = e.target.value;

                const absoluteValue = Math.abs(Number(value));
                if (!!value && absoluteValue > 999) return;
                const result =
                  !!value && absoluteValue > 0 ? absoluteValue : '';
                field.onChange(result);
              }}
              onKeyDown={(e) => {
                if (['-', '+', 'e'].includes(e.key)) e.preventDefault();
              }}
              value={field.value}
              onBlur={field.onBlur}
              color='primary'
            />
          )}
        />
      </div>
    </ShellModal>
  );
};
