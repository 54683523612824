import { createAction } from 'redux-actions';

const ACTION_TYPES = {
  SET_SELECTED_ORDER: 'task/set-selected-order',
  GET_ORDER_ITEMS: 'task/get-order-items',
  GET_ORDER_ITEMS_RESPONSE: 'task/get-order-items-response',
  GET_ORDER_ITEMS_SUPPLY: 'task/get-order-items-supply',
  GET_ORDER_ITEMS_SUPPLY_RESPONSE: 'task/get-order-items-supply-response',
  RESET_ORDER_ITEMS_SUPPLY_RESPONSE: 'task/reset-order-items-supply-response',
  GET_MORE_ORDER_ITEMS: 'task/get-more-order-items',
  GET_MORE_ORDER_ITEMS_RESPONSE: 'task/get-more-order-items-response',

  GET_APPROVED_ORDERS: 'task/get-approved-orders',
  REFRESH_APPROVED_ORDERS: 'task/real-time-refresh-approved-orders',
  GET_AVAILABILITY_ORDERS: 'task/get-availability-orders',
  REFRESH_AVAILABILITY_ORDERS: 'task/real-time-refresh-availability-orders',
  GET_MORE_APPROVED_ORDERS: 'task/get-more-approved-orders',
  GET_MORE_AVAILABILITY_ORDERS: 'task/get-more-availability-orders',
  GET_APPROVED_ORDERS_RESPONSE: 'task/get-approved-orders-response',
  GET_AVAILABILITY_ORDERS_RESPONSE: 'task/get-availability-orders-response',
  GET_MORE_AVAILABILITY_ORDERS_RESPONSE:
    'task/get-more-availability-orders-response',
  GET_MORE_APPROVED_ORDERS_RESPONSE: 'task/get-more-approved-orders-response',
  ADD_CUSTOM_ITEM_ORDER_REQUEST: 'task/add-custom-item-order-request',
  ADD_CUSTOM_ITEM_ORDER_RESPONSE: 'task/add-custom-item-order-response',
  DELETE_CUSTOM_ITEM_ORDER_REQUEST: 'task/delete-custom-item-order-request',
  DELETE_CUSTOM_ITEM_ORDER_RESPONSE: 'task/delete-custom-item-order-response',
  GET_CUSTOM_ITEMS_ORDER_REQUEST: 'task/get-custom-items-order-request',
  GET_CUSTOM_ITEMS_ORDER_RESPONSE: 'task/get-custom-items-order-response',
  GET_ITEM_INFO_REQUEST: 'task/get-item-info-request',
  GET_ITEM_INFO_RESPONSE: 'task/get-item-info-response',
  GET_ITEM_REPLACEMENTS_REQUEST: 'task/get-item-replacements',
  GET_ITEM_REPLACEMENTS_RESPONSE: 'task/get-item-replacements-response',
  GET_MORE_ITEM_REPLACEMENTS_REQUEST: 'task/get-more-item-replacements',
  GET_MORE_ITEM_REPLACEMENTS_RESPONSE:
    'task/get-more-item-replacements-response',
  PATCH_CURRENT_ITEM: 'task/patch-current-item',
  PATCH_CURRENT_ITEM_RESPONSE: 'task/patch-current-item-response',
  GET_SUPPLIER_STAFF: 'task/get-supplier-staff',
  GET_SUPPLIER_STAFF_RESPONSE: 'task/get-supplier-staff-response',
  PATCH_SUPPLY_ITEMS_REQUEST: 'task/patch-supply-items-request',
  PATCH_SUPPLY_ITEMS_RESPONSE: 'task/patch-supply-items-response',
  PATCH_ADDED_ITEMS_REQUEST: 'task/patch-added-items-request',
  PATCH_ADDED_ITEMS_RESPONSE: 'task/patch-added-items-response',
  GET_ADDED_ITEMS_REQUEST: 'task/get-added-items-request',
  GET_ADDED_ITEMS_RESPONSE: 'task/get-added-items-response',
  RESET_ADDED_ITEMS_RESPONSE: 'task/reset-added-items-response',
  SET_COUTER_STAFF_REQUEST: 'task/set-counter-staff-request',
  SET_COUTER_STAFF_RESPONSE: 'task/set-counter-staff-response',
  SEND_FOR_APPROVAL_REQUEST: 'task/send-for-approval-request',
  SEND_FOR_APPROVAL_RESPONSE: 'task/send-for-approval-response',
  SET_INVOICE_REQUEST: 'task/set-invoice-request',
  SET_INVOICE_RESPONSE: 'task/set-invoice-response',
  DELETE_INVOICE_REQUEST: 'task/delete-invoice-request',
  DELETE_INVOICE_RESPONSE: 'task/delete-invoice-response',
  SET_ORDER_COMPLETE_REQUEST: 'task/set-order-complete-request',
  SET_ORDER_COMPLETE_RESPONSE: 'task/set-order-complete-response',
  SET_ORDER_DETAILS_REQUEST: 'task/set-order-details-request',
  SET_ORDER_DETAILS_RESPONSE: 'task/set-order-details-response',
  SAVE_NEW_CUSTOMER_REQUEST: 'task/save-new-customer-request',
  SAVE_NEW_CUSTOMER_RESPONSE: 'task/save-new-customer-response',
  DONT_SELL_TO_REQUEST: 'task/dont-sell-to',
  DONT_SELL_TO_RESPONSE: 'task/dont-sell-to-response',
  SET_ORDER_TO_REMEMBER: 'task/set-order-to-remember',
  DELETE_ORDER_TO_REMEMBER: 'task/delete-order-to-remember',
  DECLINE_ORDER_REQUEST: 'task/decline-order-request',
  DECLINE_ORDER_RESPONSE: 'task/decline-order-response',
  CANCEL_ORDER_REQUEST: 'task/cancel-order-request',
  CANCEL_ORDER_RESPONSE: 'task/cancel-order-response',
  CLEAR_ORDERS: 'task/clear-orders',
  PATCH_ORDER_DETAILS_REQUEST: 'task/patch-order-request',
  PATCH_ORDER_DETAILS_RESPONSE: 'task/patch-order-response',
  SET_TRUCK_STOCK_EXPANDED: 'task/set-truck-stock-expanded',
  SET_TOTAL_EXPANDED: 'task/set-total-expanded',
  SET_ADDED_ITEMS_EXPANDED: 'task/set-added-items-expanded',
  GET_OEM_INFO_REQUEST: 'task/get-oem-info-request',
  GET_OEM_INFO_RESPONSE: 'task/get-oem-info-response',
  POST_CLOSE_CURRI_POP_UP: 'task/post-close-curri-pop-up',
  POST_CLOSE_CURRI_POP_UP_RESPONSE: 'task/post-close-curri-pop-up-response',
  GET_ITEM_CUSTOM_REPLACEMENT_REQUEST:
    'task/get-item-custom-replacements-request',
  GET_ITEM_CUSTOM_REPLACEMENT_RESPONSE:
    'task/get-item-custom-replacements-response',
  SET_ITEM_CUSTOM_REPLACEMENT_REQUEST:
    'task/set-item-custom-replacements-request',
  SET_ITEM_CUSTOM_REPLACEMENT_RESPONSE:
    'task/set-item-custom-replacements-response',
  SET_SELECTED_CLAIMING_MODAL: 'task/set-selected-claiming-modal',
  GET_SELECTED_CLAIMING_MODAL: 'task/get-selected-claiming-modal',
  ADD_NEW_COUNTER_STAFF_REQUEST: 'task/add-new-counter-staff-request',
  ADD_NEW_COUNTER_STAFF_RESPONSE: 'task/add-new-counter-staff-response',
  RETURN_ORDER: 'orders/return-order-response',
  UNCONFIRMED_CUSTOMER_MODAL: 'orders/show-unconfirmed-customer-modal',
  CONFIRM_CUSTOMER: 'orders/confirm-customer',
  CONFIRM_CUSTOMER_RESPONSE: 'orders/confirm-customer-response',
  CURRENT_ORDER_UNCONFIRMED_CUSTOMER_INFO:
    'orders/set-current-order-unconfirmed-customer-info',
};

export const setSelectedOrder = createAction(ACTION_TYPES.SET_SELECTED_ORDER);
export const getOrderItems = createAction(ACTION_TYPES.GET_ORDER_ITEMS);

export const getOrderItemsResponse = createAction(
  ACTION_TYPES.GET_ORDER_ITEMS_RESPONSE,
);
export const getOrderItemsSupply = createAction(
  ACTION_TYPES.GET_ORDER_ITEMS_SUPPLY,
);
export const getOrderItemsSupplyResponse = createAction(
  ACTION_TYPES.GET_ORDER_ITEMS_SUPPLY_RESPONSE,
);
export const resetOrderItemsSupplyResponse = createAction(
  ACTION_TYPES.RESET_ORDER_ITEMS_SUPPLY_RESPONSE,
);
export const getMoreOrderItems = createAction(
  ACTION_TYPES.GET_MORE_ORDER_ITEMS,
);
export const getMoreOrderItemsResponse = createAction(
  ACTION_TYPES.GET_MORE_ORDER_ITEMS_RESPONSE,
);

export const getApprovedOrders = createAction(ACTION_TYPES.GET_APPROVED_ORDERS);
export const getAvailabilityOrders = createAction(
  ACTION_TYPES.GET_AVAILABILITY_ORDERS,
);
export const getMoreApprovedOrders = createAction(
  ACTION_TYPES.GET_MORE_APPROVED_ORDERS,
);
export const getMoreAvailabilityOrders = createAction(
  ACTION_TYPES.GET_MORE_AVAILABILITY_ORDERS,
);
export const getApprovedOrdersResponse = createAction(
  ACTION_TYPES.GET_APPROVED_ORDERS_RESPONSE,
);
export const getAvailabilityOrdersResponse = createAction(
  ACTION_TYPES.GET_AVAILABILITY_ORDERS_RESPONSE,
);
export const getMoreApprovedOrdersResponse = createAction(
  ACTION_TYPES.GET_MORE_APPROVED_ORDERS_RESPONSE,
);
export const getMoreAvailabilityOrdersResponse = createAction(
  ACTION_TYPES.GET_MORE_AVAILABILITY_ORDERS_RESPONSE,
);
export const getItemInfoRequest = createAction(
  ACTION_TYPES.GET_ITEM_INFO_REQUEST,
);
export const getItemInfoResponse = createAction(
  ACTION_TYPES.GET_ITEM_INFO_RESPONSE,
);
export const getItemReplacementsRequest = createAction(
  ACTION_TYPES.GET_ITEM_REPLACEMENTS_REQUEST,
);
export const getItemReplacementsReponse = createAction(
  ACTION_TYPES.GET_ITEM_REPLACEMENTS_RESPONSE,
);
export const getMoreItemReplacementsRequest = createAction(
  ACTION_TYPES.GET_MORE_ITEM_REPLACEMENTS_REQUEST,
);
export const getMoreItemReplacementsReponse = createAction(
  ACTION_TYPES.GET_MORE_ITEM_REPLACEMENTS_RESPONSE,
);
export const patchCurrentItem = createAction(ACTION_TYPES.PATCH_CURRENT_ITEM);
export const patchCurrentItemResponse = createAction(
  ACTION_TYPES.PATCH_CURRENT_ITEM_RESPONSE,
);

export const addCustomItemOrderRequest = createAction(
  ACTION_TYPES.ADD_CUSTOM_ITEM_ORDER_REQUEST,
);

export const addCustomItemOrderResponse = createAction(
  ACTION_TYPES.ADD_CUSTOM_ITEM_ORDER_RESPONSE,
);

export const deleteCustomItemOrderRequest = createAction(
  ACTION_TYPES.DELETE_CUSTOM_ITEM_ORDER_REQUEST,
);

export const deleteCustomItemOrderResponse = createAction(
  ACTION_TYPES.DELETE_CUSTOM_ITEM_ORDER_RESPONSE,
);

export const getCustomItemsOrderRequest = createAction(
  ACTION_TYPES.GET_CUSTOM_ITEMS_ORDER_REQUEST,
);

export const getCustomItemsOrderResponse = createAction(
  ACTION_TYPES.GET_CUSTOM_ITEMS_ORDER_RESPONSE,
);

export const getSupplierStaffRequest = createAction(
  ACTION_TYPES.GET_SUPPLIER_STAFF,
);

export const getSupplierStaffResponse = createAction(
  ACTION_TYPES.GET_SUPPLIER_STAFF_RESPONSE,
);

export const patchSupplyItemsRequest = createAction(
  ACTION_TYPES.PATCH_SUPPLY_ITEMS_REQUEST,
);

export const patchSupplyItemsResponse = createAction(
  ACTION_TYPES.PATCH_SUPPLY_ITEMS_RESPONSE,
);

export const patchAddedItemsRequest = createAction(
  ACTION_TYPES.PATCH_ADDED_ITEMS_REQUEST,
);

export const patchAddedItemsResponse = createAction(
  ACTION_TYPES.PATCH_ADDED_ITEMS_RESPONSE,
);

export const getAddedItemsRequest = createAction(
  ACTION_TYPES.GET_ADDED_ITEMS_REQUEST,
);

export const getAddedItemsResponse = createAction(
  ACTION_TYPES.GET_ADDED_ITEMS_RESPONSE,
);

export const resetAddedItemsResponse = createAction(
  ACTION_TYPES.RESET_ADDED_ITEMS_RESPONSE,
);

export const setCounterStaffRequest = createAction(
  ACTION_TYPES.SET_COUTER_STAFF_REQUEST,
);

export const setCounterStaffResponse = createAction(
  ACTION_TYPES.SET_COUTER_STAFF_RESPONSE,
);

export const sendForApprovalRequest = createAction(
  ACTION_TYPES.SEND_FOR_APPROVAL_REQUEST,
);

export const sendForApprovalResponse = createAction(
  ACTION_TYPES.SEND_FOR_APPROVAL_RESPONSE,
);

export const setInvoiceRequest = createAction(ACTION_TYPES.SET_INVOICE_REQUEST);

export const setInvoiceResponse = createAction(
  ACTION_TYPES.SET_INVOICE_RESPONSE,
);

export const deleteInvoiceRequest = createAction(
  ACTION_TYPES.DELETE_INVOICE_REQUEST,
);

export const deleteInvoiceResponse = createAction(
  ACTION_TYPES.DELETE_INVOICE_RESPONSE,
);

export const saveNewCustomerRequest = createAction(
  ACTION_TYPES.SAVE_NEW_CUSTOMER_REQUEST,
);

export const dontSellToRequest = createAction(
  ACTION_TYPES.DONT_SELL_TO_REQUEST,
);

export const setOrderCompleteRequest = createAction(
  ACTION_TYPES.SET_ORDER_COMPLETE_REQUEST,
);

export const setOrderCompleteResponse = createAction(
  ACTION_TYPES.SET_ORDER_COMPLETE_RESPONSE,
);

export const setOrderDetailsRequest = createAction(
  ACTION_TYPES.SET_ORDER_DETAILS_REQUEST,
);

export const setOrderDetailsResponse = createAction(
  ACTION_TYPES.SET_ORDER_DETAILS_RESPONSE,
);

export const setOrderToRemember = createAction(
  ACTION_TYPES.SET_ORDER_TO_REMEMBER,
);

export const deleteOrderToRemember = createAction(
  ACTION_TYPES.DELETE_ORDER_TO_REMEMBER,
);

export const declineOrderRequest = createAction(
  ACTION_TYPES.DECLINE_ORDER_REQUEST,
);

export const cancelOrderRequest = createAction(
  ACTION_TYPES.CANCEL_ORDER_REQUEST,
);

export const clearOrders = createAction(ACTION_TYPES.CLEAR_ORDERS);

export const patchOrderRequest = createAction(
  ACTION_TYPES.PATCH_ORDER_DETAILS_REQUEST,
);

export const patchOrderResponse = createAction(
  ACTION_TYPES.PATCH_ORDER_DETAILS_RESPONSE,
);

export const setTotalIsExpanded = createAction(ACTION_TYPES.SET_TOTAL_EXPANDED);

export const setTruckStockIsExpanded = createAction(
  ACTION_TYPES.SET_TRUCK_STOCK_EXPANDED,
);

export const realTimeRefreshApprovedOrders = createAction(
  ACTION_TYPES.REFRESH_APPROVED_ORDERS,
);

export const realTimeRefreshAvailabilityOrders = createAction(
  ACTION_TYPES.REFRESH_AVAILABILITY_ORDERS,
);

export const getOemInfoRequest = createAction(
  ACTION_TYPES.GET_OEM_INFO_REQUEST,
);
export const getOemInfoResponse = createAction(
  ACTION_TYPES.GET_OEM_INFO_RESPONSE,
);

export const postCurriInformativePopUpRequest = createAction(
  ACTION_TYPES.POST_CLOSE_CURRI_POP_UP,
);

export const postCurriInformativePopUpResponse = createAction(
  ACTION_TYPES.POST_CLOSE_CURRI_POP_UP_RESPONSE,
);

export const getItemCustomReplacementRequest = createAction(
  ACTION_TYPES.GET_ITEM_CUSTOM_REPLACEMENT_REQUEST,
);
export const getItemCustomReplacementResponse = createAction(
  ACTION_TYPES.GET_ITEM_CUSTOM_REPLACEMENT_RESPONSE,
);

export const setItemCustomReplacementRequest = createAction(
  ACTION_TYPES.SET_ITEM_CUSTOM_REPLACEMENT_REQUEST,
);
export const setItemCustomReplacementResponse = createAction(
  ACTION_TYPES.SET_ITEM_CUSTOM_REPLACEMENT_RESPONSE,
);

export const setSelectedClaimingModal = createAction(
  ACTION_TYPES.SET_SELECTED_CLAIMING_MODAL,
);

export const getSelectedClaimingModal = createAction(
  ACTION_TYPES.GET_SELECTED_CLAIMING_MODAL,
);

export const addNewCounterStaffRequest = createAction(
  ACTION_TYPES.ADD_NEW_COUNTER_STAFF_REQUEST,
);

export const addNewCounterStaffResponse = createAction(
  ACTION_TYPES.ADD_NEW_COUNTER_STAFF_RESPONSE,
);

export const returnOrderResponse = createAction(ACTION_TYPES.RETURN_ORDER);

export const setShowUnconfirmedCustomerModal = createAction(
  ACTION_TYPES.UNCONFIRMED_CUSTOMER_MODAL,
);
export const postConfirmCustomer = createAction(ACTION_TYPES.CONFIRM_CUSTOMER);

export const postConfirmCustomerResponse = createAction(
  ACTION_TYPES.CONFIRM_CUSTOMER_RESPONSE,
);

export const setCurrentOrderUnconfirmedUserInfo = createAction(
  ACTION_TYPES.CURRENT_ORDER_UNCONFIRMED_CUSTOMER_INFO,
);
