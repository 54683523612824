import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { BRANDS_MODAL_ERRORS } from '../../constants';

import { BrandsActionButtonsProps } from './interface';

import Button from '@components/Button';

import './styles.scss';

export const BrandsActionButtons = ({
  errorMessage,
  onHandleClose,
  onHandleSave,
  isLoadingRequest,
  isDirty,
}: BrandsActionButtonsProps) => {
  const isDirtyErr = errorMessage === BRANDS_MODAL_ERRORS.DIRTY_MODAL;

  return (
    <Box
      style={{
        justifyContent: errorMessage ? 'space-between' : 'flex-end',
      }}
      className='bl-brands-actions-buttons'
    >
      {errorMessage && (
        <Typography
          variant='body1'
          className={`${!isDirtyErr && 'max-amount-error-color'}`}
        >
          {errorMessage}
        </Typography>
      )}

      <Box className='actions-container'>
        <Button
          variant='contained'
          color='secondary'
          data-testid='cancelButton'
          onClick={isDirtyErr ? onHandleSave : onHandleClose}
          disabled={isLoadingRequest}
          loading={isDirtyErr && isLoadingRequest}
          size='small'
        >
          {isDirtyErr ? 'Save Changes' : 'Cancel'}
        </Button>
        <Button
          variant='contained'
          data-testid='saveButton'
          startIcon={!isDirtyErr && <CheckIcon />}
          onClick={isDirtyErr ? onHandleClose : onHandleSave}
          disabled={isLoadingRequest || !isDirty}
          loading={!isDirtyErr && isLoadingRequest}
          size='small'
        >
          {isDirtyErr ? 'Yes, Cancel' : 'Save'}
        </Button>
      </Box>
    </Box>
  );
};
