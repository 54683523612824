import { Components } from '@mui/material';

export const MuiSelectTheme: Components['MuiSelect'] = {
  styleOverrides: {
    root: {
      outline: 'none',
      boxShadow: 'none',
      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
  },
};
