import client from '..';

import {
  GetSupplierListRequestProps,
  GetSupplierListResponse,
  ImpersonateSupplierRequestProps,
  ImpersonateSupplierResponse,
  ManagerProfileResponse,
} from './interface';

const environment = process ? process.env : null;

export async function getSupplierList(
  props: GetSupplierListRequestProps,
): Promise<GetSupplierListResponse> {
  const { searchCriteria } = props;

  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/distributors/suppliers?search_string=${searchCriteria}`,
  );

  return data;
}

export async function impersonateSupplier(
  props: ImpersonateSupplierRequestProps,
): Promise<ImpersonateSupplierResponse> {
  const { supplierId } = props;

  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/distributors/impersonate`,
    {
      supplier: supplierId,
    },
  );

  return data;
}

export async function getManagerProfile(): Promise<ManagerProfileResponse> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/distributors/account/profile`,
  );

  return data;
}
