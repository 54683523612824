import { getItemDescription, getPartName } from '../../../ItemPart';

import { UnlistedPartItemInfoProps } from './interface';

import { OrderItem } from '@config/api/task/interface';

const UnlistedPartItemInfo = ({ item }: UnlistedPartItemInfoProps) => {
  const hasBrandAndPart =
    !!item?.item?.info?.brand && !!item?.item.info.part_number;

  return (
    <div className='ml-2 flex flex-row items-center'>
      <div className='text-sm font-bold not-italic text-[var(--bluon--ds--label-black)]'>
        {hasBrandAndPart
          ? getPartName(item as unknown as OrderItem)
          : 'Unlisted Item'}
      </div>
      {hasBrandAndPart ? (
        <div className='ml-2 text-sm font-normal not-italic text-[var(--bluon--ds--label-black)] opacity-60'>
          {getItemDescription(item as unknown as OrderItem)}
        </div>
      ) : (
        <div className='ml-1 text-sm font-normal not-italic text-[var(--bluon--ds--label-black)] opacity-60'>
          {` - ${getPartName(item as unknown as OrderItem)}`}
        </div>
      )}
    </div>
  );
};

export default UnlistedPartItemInfo;
