import { Typography } from '@mui/material';
import classNames from 'classnames';
import { useCallback } from 'react';

import { bannerContainerTestId } from './constants';
import { BannerProps } from './interface';

import './styles.scss';
import { Button } from '@components/ButtonV2';

const Banner = ({ message, buttons, status, fee = 0 }: BannerProps) => {
  const renderButtons = useCallback(() => {
    return buttons?.map((button) => {
      return (
        <Button
          disabled={button.disabled}
          loading={button.loading}
          data-testid={`${button.message}`}
          key={`${button.message}`}
          variant='outlined'
          onClick={button.onPress}
          className={button.className}
        >
          {button.message}
        </Button>
      );
    });
  }, [buttons]);

  return (
    <div
      data-testid={bannerContainerTestId}
      className={classNames(
        'banner-wrapper flex max-h-16 items-center justify-between px-2 py-3',
        {
          'bg-[var(--bluon--light--yellow-3)]': status === 'warning',
          'bg-[var(--bluon--light--green-2)]': status === 'success',
        },
      )}
    >
      <Typography
        variant='body1'
        className='banner-message text-base font-normal text-[var(--bluon--ds--lightblack)]'
      >
        {message?.replace('{fee}', String(fee))}
      </Typography>
      <div className='banner-button-wrapper flex flex-row items-center justify-end gap-2'>
        {renderButtons()}
      </div>
    </div>
  );
};

export default Banner;
