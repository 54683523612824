export enum GOOGLE_TAG_MANAGER_CATEGORY_PAGES {
  LOGIN = 'Login',
  TASKS = 'Order Details',
  TASKS_PAGE = 'Tasks',
  SEARCH = 'Bluon Search',
  MASTER_MECHANIC = 'Master Mechacnic',
  LOGOUT = 'Logout',
}

export enum GOOGLE_TAG_MANAGER_CATEGORY_COMPONENTS {
  ORDER_DETAILS_MODAL = 'Order Details Modal',
  CUSTOMER_CHAT = 'Customer Chat',
  ORDER_BANNER = 'Order Banner',
  CANCEL_REQUEST_MODAL = 'Cancel Request Modal',
  SPECS = 'All Specs',
  NOTES = 'Notes',
  MODEL_DETAIL = 'Model Detail',
  SEARCH_LIST = 'Search List',
  BRAND = 'Brand',
  LINE = 'Line',
  BRAND_LINE_MODEL = 'Brand - Line - Model',
  PART_TYPE_PART_SUBCATEGORY_PART_NUMBER = 'Part Type - Part SubCategory - Part Number',
  PART_TYPE_PART_SUBCATEGORY_PART_NUMBER_REPLACEMENT = 'Part Type - Part SubCategory - Part Number - Replacement',
  MODEL_PART_TYPE_PART_SUBCATEGORY_PART_NUMBER = 'Model - Part Type - Part SubCategory - Part Number',
  USER_SEARCH_PARTS = 'User Search Parts',
  USER_SEARCH_MODELS = 'User Search Models',
  USER_SEARCH_BRANDS_LINE = 'User Search Brands/Line',
  SEARCH_TABS = 'Search Tabs',
  MANUAL_LIST = 'Manual List',
  FEEDBACK = 'Live Feedback',
}

export enum GOOGLE_TAG_MANAGER_EVENTS {
  PAGE_VIEW = 'pageView',
  CUSTOM_BLUON_LIVE_EVENT = 'customBluonLiveEvent',
}

export enum GOOGLE_TAG_MANAGER_EVENT_MESSAGES {
  OPEN_CHAT = 'open chat',
  CLICKED_ON_CHAT_CHANNEL = 'clicked on chat: ',
  LOGIN_PAGE_VIEW = 'login page view',
  LOGOUT = 'user logout',
  CLICKED_ON = 'clicked on ',
  CLICKED_ON_CASH_BUYER = 'clicked on cash buyer',
  CLICKED_ON_ACCOUNT_BUYER = 'clicked on account buyer',
  CLICKED_ON_DECLINE = 'clicked on decline',
  SHIPMENT_BANNER_NO = 'clicked no on shipment banner',
  SHIPMENT_BANNER_YES = 'clicked yes on shipment banner',
  UPDATE_QUOTE_BANNER = 'clicked on update quote',
  ORDER_DETAIL_VIEW = 'order detail view',
  CLICKED_ON_CANCEL_REQUEST = 'clicked on cancel request',
  CANCEL_REQUEST_SUCCESS = 'cancel request success',
  CLICKED_ON_DECLINE_REQUEST = 'clicked on decline request',
  CLICKED_ON_MODEL_BUTTON = 'clicked on model button',
  PART_EXPANDED = 'part expanded',
  OEM_PART_SELECTED = 'OEM part selected',
  REPLACEMENT_PART_SELECTED = 'replacement part selected',
  CLICKED_ON_UPDATE_BUTTON = 'clicked on update button',
  SEARCH_PAGE_VIEW = 'search page view',
  TASK_PAGE_VIEW = 'task page view',
  CLICKED_ON_SEARCH_PART = 'clicked on search part',
  CLICKED_ON_SEARCH_MODEL = 'clicked on search model',
  CLICKED_ON_SEARCH_MODEL_REPLACEMENT = 'clicked on search model replacement',
  CLICKED_ON_SEARCH_LINE = 'clicked on search line',
  CLICKED_ON_SEARCH_BRAND = 'clicked on search brand',
  CLICKED_ON_KNOWN_REPLACEMENT = 'clicked on known replacement',
  USER_SEARCH = 'user search',
  MANUAL_SELECTION = 'manual selection',
  FEEDBACK_SUBMIT = 'feedback submit',
  FEEDBACK_OPEN = 'feedback open',
}
