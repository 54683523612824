import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { TotalsFormKeys, TotalsFormProps } from './interface';
import { TotalsValidationSchema } from './validations';

import './styles.scss';
import { useAppSelector } from '@hooks/state';
import useAddedItemsValidations from '@hooks/useAddedItemsValidations';
import { getOrderAddedItems } from '@state/task/selectors';
import { autoFocusInput } from '@utils/Elements';

export const TotalsForm = ({
  isTotalExpanded,
  isBidNumberRequired,
  handleValues,
  disableForm = false,
  initialValues,
  isNationalSupplier,
}: TotalsFormProps) => {
  const { addedItems } = useAppSelector((state) => ({
    addedItems: getOrderAddedItems(state),
  }));
  const { areAllItemsConfirmed, haveChangedItems } = useAddedItemsValidations();

  const { control, handleSubmit, watch, formState, trigger, clearErrors } =
    useForm<TotalsFormKeys>({
      resolver: yupResolver(TotalsValidationSchema),
      defaultValues: initialValues,
      mode: 'onChange',
      context: {
        isBidNumberRequired,
        addedItemsConfirmed: areAllItemsConfirmed,
        originalTotal: initialValues?.total,
        isNationalSupplier,
        haveAddedItemsChanged: haveChangedItems,
      },
    });

  useEffect(() => {
    if (!disableForm && haveChangedItems) trigger('total');
  }, [addedItems, disableForm, haveChangedItems, trigger]);

  const totalInputRef = React.useRef<HTMLInputElement>();
  const totalValue = watch('total');
  const bidNumberValue = watch('bidNumber');
  const notesValue = watch('notes');
  const orderLinkValue = watch('orderLink');

  const handleSubmitForm = (values: TotalsFormKeys) => {
    handleValues(values);
  };

  useEffect(() => {
    if (isTotalExpanded) autoFocusInput(totalInputRef);
    else clearErrors();
  }, [addedItems, isTotalExpanded]);

  useEffect(() => {
    handleValues(totalValue, bidNumberValue, notesValue, false, orderLinkValue);
  }, [totalValue, bidNumberValue, notesValue, orderLinkValue]);

  useEffect(() => {
    const initialTotal = initialValues?.total ?? '0';
    const isThereAnyValueChange =
      Number(totalValue?.replace(/[^0-9.-]+/g, '')) !==
        Number(initialTotal.replace(/[^0-9.-]+/g, '')) ||
      notesValue !== initialValues?.notes ||
      bidNumberValue !== initialValues?.bidNumber;

    if (isThereAnyValueChange) {
      const hasErrors = !isEmpty(formState.errors);
      handleValues(
        hasErrors ? undefined : totalValue,
        bidNumberValue,
        notesValue,
        isThereAnyValueChange,
        orderLinkValue,
      );
    }
  }, [totalValue, bidNumberValue, notesValue, formState, orderLinkValue]);

  return (
    <Box className='totals-form-container'>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Box className='input-container'>
          <Controller
            name='total'
            control={control}
            render={({ field, fieldState, formState }) => (
              <NumberFormat
                customInput={TextField}
                disabled={disableForm}
                variant='outlined'
                id='total'
                data-testid='total'
                label='Enter Total *'
                thousandSeparator
                value={field.value?.replace('$', '')}
                onChange={field.onChange}
                error={!!fieldState.error}
                decimalScale={2}
                prefix='$ '
                inputRef={totalInputRef}
                helperText={
                  fieldState.error
                    ? formState.errors.total?.message
                    : '* Required'
                }
                isNumericString
                name={field.name}
                onBlur={(e: any) => {
                  const plainValue = e.target.value.replace('$', '');
                  field.onBlur();
                  if (plainValue)
                    field.onChange(
                      plainValue.includes('.')
                        ? plainValue
                        : plainValue + '.00',
                    );
                  trigger('total');
                }}
                defaultValue={field.value}
                fullWidth
                allowNegative={false}
                autoComplete={'off'}
              />
            )}
          />
        </Box>

        <Box className='input-container'>
          <Controller
            name='bidNumber'
            control={control}
            render={({ field, fieldState, formState }) => (
              <TextField
                id='bidNumber'
                data-testid='bidNumber'
                variant='outlined'
                label={`Bid Number ${isBidNumberRequired ? '*' : ''}`}
                name={field.name}
                onBlur={() => {
                  field.onBlur();
                  trigger('bidNumber');
                }}
                onChange={field.onChange}
                defaultValue={field.value}
                error={!!fieldState.error}
                disabled={disableForm}
                fullWidth
                inputProps={{ maxLength: 24 }}
                helperText={`${
                  isBidNumberRequired
                    ? formState.errors.bidNumber?.message ??
                      'Required - Up to 24 characters'
                    : 'Optional - Up to 24 characters'
                }`}
                autoComplete={'off'}
              />
            )}
          />
        </Box>

        {isNationalSupplier && (
          <Box className='input-container'>
            <Controller
              name='orderLink'
              control={control}
              render={({ field, fieldState, formState }) => (
                <TextField
                  id='orderLink'
                  data-testid='orderLink'
                  variant='outlined'
                  label='Link to Complete Order *'
                  name={field.name}
                  onBlur={() => {
                    field.onBlur();
                    trigger('orderLink');
                  }}
                  onChange={field.onChange}
                  defaultValue={field.value}
                  error={!!fieldState.error}
                  disabled={disableForm}
                  fullWidth
                  helperText={
                    formState.errors.orderLink?.message ?? '* Required'
                  }
                  autoComplete={'off'}
                />
              )}
            />
          </Box>
        )}

        <Box className='input-container'>
          <Controller
            name='notes'
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                id='notes'
                data-testid='notes'
                variant='outlined'
                label='Order Notes'
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
                defaultValue={field.value}
                error={!!fieldState.error}
                inputProps={{ maxLength: 240 }}
                disabled={disableForm}
                multiline
                maxRows={5}
                rows={4}
                fullWidth
                helperText={
                  <Box display='flex' justifyContent='space-between'>
                    <span>Optional - Up to 240 characters</span>
                    <span
                      className={field.value.length === 240 ? 'red-color' : ''}
                    >{`${field.value.length} / 240`}</span>
                  </Box>
                }
              />
            )}
          />
        </Box>
      </form>
    </Box>
  );
};
