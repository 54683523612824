import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import './styles.scss';
import { AuthHeader } from '../components/AuthHeader';
import PasswordInput from '../components/PasswordInput';
import { TermsOfServiceLabel } from '../components/TermsOfServiceLabel';
import { WelcomeContainer } from '../components/WelcomeContainer';

import { LoginFormKeys } from './interfaces';
import { LoginValidationSchema } from './validations';

import { Button } from '@components/ButtonV2';
import InputComponent from '@components/InputComponent';
import { useAppSelector } from '@hooks/state';
import useGoogleTagManager from '@hooks/useGoogleTagManager';
import { loginRequest } from '@state/auth/actions';
import { getRequestError, isRequestRunning } from '@state/requests/selectors';
import { FormTextInputLengths } from '@utils/FormInputLengths';
import { assemblePageViewGTMEvent } from '@utils/GoogleTagManager';
import { GOOGLE_TAG_MANAGER_EVENT_MESSAGES } from '@utils/GoogleTagManager/interface';

export const LoginComponent = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setGTMEvent } = useGoogleTagManager();

  const { loginResponseErrors, loading } = useAppSelector((state) => ({
    loading: isRequestRunning(state, String(loginRequest)),
    loginResponseErrors: getRequestError(state, String(loginRequest)),
  }));

  //  TODO: [SEARCH] capture error from backend and setting it
  useEffect(() => {
    if (loginResponseErrors.password)
      setError('password', {
        type: 'Invalid',
        message: loginResponseErrors.password[0],
      });

    if (loginResponseErrors.email)
      setError('email', {
        type: 'Invalid',
        message: loginResponseErrors.email[0],
      });
  }, [loginResponseErrors]);

  useEffect(() => {
    setGTMEvent(
      assemblePageViewGTMEvent(
        GOOGLE_TAG_MANAGER_EVENT_MESSAGES.LOGIN_PAGE_VIEW,
      ),
    );
  }, [setGTMEvent]);

  const initialValues: LoginFormKeys = {
    email: '',
    password: '',
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirect = params.get('redirect');

  const handleLoginNavigation = ({
    route,
    isManager,
  }: {
    route: string;
    isManager: boolean;
  }) => {
    if (redirect && !isManager) route = redirect;
    navigate(route);
  };

  const handleForgotPasswordClick = () => navigate('/login/forgot-password');

  const { control, setError, handleSubmit } = useForm<LoginFormKeys>({
    resolver: yupResolver(LoginValidationSchema),
    defaultValues: initialValues,
  });

  const handleSubmitForm = (values: LoginFormKeys) => {
    dispatch(
      loginRequest({
        email: values.email,
        password: values.password,
        callback: handleLoginNavigation,
      }),
    );
  };

  return (
    <WelcomeContainer>
      <Box className='b4b-login-container'>
        <AuthHeader
          title='Log in to Bluon'
          subTitle='Please enter your username and password.'
        />
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container className='form'>
            <Grid item xs={12}>
              <Controller
                name='email'
                control={control}
                render={({ field, fieldState, formState }) => (
                  <InputComponent
                    variant='outlined'
                    id='email'
                    label='Email address'
                    data-testid='email'
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    defaultValue={field.value}
                    placeholder='Enter email address'
                    error={!!fieldState.error}
                    inputProps={{
                      maxLength: FormTextInputLengths.profile.email,
                    }}
                    helperText={formState.errors.email?.message}
                    keepHelperTextSpace
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordInput
                variant='outlined'
                name='password'
                control={control}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} className='footer-buttons'>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                loading={loading}
              >
                Log in
              </Button>

              <Button
                variant='text'
                color='primary'
                onClick={handleForgotPasswordClick}
              >
                Forgot password?
              </Button>
            </Grid>
          </Grid>
        </form>
        <TermsOfServiceLabel />
      </Box>
    </WelcomeContainer>
  );
};
