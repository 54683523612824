import { useEffect, useState } from 'react';

import useCustomAbility from './useCustomAbility';

import { EAbilitySubjects } from '@config/canAbility/types';

const useUserPermissions = () => {
  const ability = useCustomAbility();
  const [isSupplier, setIsSupplier] = useState(true);

  useEffect(() => {
    if (!ability.rules?.length) return setIsSupplier(true);

    const hasTasksAndCustomersPermissions = ability.rules?.some(
      (rule) => rule.subject === EAbilitySubjects.TASKS_AND_CUSTOMERS,
    );
    const hasSearchPermissions = ability?.rules?.some(
      (rule) => rule.subject === EAbilitySubjects.SEARCH,
    );

    if (!hasTasksAndCustomersPermissions && hasSearchPermissions)
      return setIsSupplier(false);

    //  As default value, user should be considered supplier
    setIsSupplier(true);
  }, [ability.rules]);

  return {
    isSupplier,
  };
};

export default useUserPermissions;
