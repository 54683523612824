import * as Yup from 'yup';

import { UnlistedPartItemFormKeys } from './interface';

export const UnlistedPartItemFormValidations = Yup.object<
  Record<keyof UnlistedPartItemFormKeys, Yup.AnySchema>
>({
  brand: Yup.string()
    .required('Required')
    .max(50, 'Max amount of characters allowed is 50.'),
  part_number: Yup.string()
    .required('Required')
    .max(50, 'Max amount of characters allowed is 50.'),
});
