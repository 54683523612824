/* eslint-disable @typescript-eslint/no-explicit-any */
import client from '..';

import {
  CustomerDataProps,
  CustomerListDataResponse,
  CustomerParams,
} from './interface';

const environment = process ? process.env : null;

export async function getCustomerList(): Promise<CustomerListDataResponse> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/users`,
  );

  return data;
}

export async function confirmCustomer({
  userId,
  payload,
}: CustomerParams): Promise<CustomerDataProps> {
  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/confirmed-users/${userId}/confirm`,
    payload,
  );

  return data;
}

export async function unconfirmCustomer({
  userId,
}: CustomerParams): Promise<CustomerDataProps> {
  const { data } = await client.delete(
    `/${environment?.REACT_APP_API_VERSION}/users/${userId}/confirm`,
  );

  return data;
}

export async function updateCustomer({
  userId,
  payload,
}: CustomerParams): Promise<CustomerDataProps> {
  const { data } = await client.patch(
    `/${environment?.REACT_APP_API_VERSION}/confirmed-users/${userId}`,
    payload,
  );

  return { data: { ...data.data, id: +userId } };
}

export async function removeCustomer({
  userId,
}: CustomerParams): Promise<CustomerDataProps> {
  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/removed-users/${userId}`,
  );

  return data;
}

export async function unremoveCustomer({
  userId,
}: CustomerParams): Promise<CustomerDataProps> {
  const { data } = await client.delete(
    `/${environment?.REACT_APP_API_VERSION}/removed-users/${userId}`,
  );

  return data;
}

export async function getRemovedCustomerList(): Promise<CustomerDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/removed-users`,
  );

  return data;
}
