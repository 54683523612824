import { createAction } from 'redux-actions';

export const loginRequest = createAction('auth/login-request');

export const loginResponse = createAction('auth/login-response');

export const changePasswordRequest = createAction(
  'auth/login-change-password-request',
);

export const logout = createAction('auth/logout');

export const resetUser = createAction('auth/reset-user');

export const forgotPasswordRequest = createAction('auth/forgot-password');

export const resetPasswordRequest = createAction('auth/reset-password');

export const openTermsOfService = createAction('auth/open-terms');

export const readTermsOfService = createAction('auth/read-terms');

export const sendSMSRequest = createAction('auth/send-sms-request');

export const sendSMSResponse = createAction('auth/send-sms-response');

export const getBluonIframeUrl = createAction('auth/get-bluon-iframe-url');

export const getBluonIframeUrlResponse = createAction(
  'auth/get-bluon-iframe-url-response',
);

export const getClaimingImageUrlRequest = createAction(
  'auth/get-claiming-image-url-request',
);

export const getClaimingImageUrlResponse = createAction(
  'auth/get-claiming-image-url-response',
);

export const logoutPreventRedirectResponse = createAction(
  'auth/logout-prevent-redirect-response',
);
export const setShouldAdjustPermissions = createAction(
  'auth/set-should-adjust-permissions',
);
export const clearImpersonatedSupplier = createAction(
  'auth/clear-impersonated-supplier',
);
