import { Avatar, TableCell } from '@mui/material';

import { CellProps } from '../LogsTable/interface';

import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

const UserInfoCell = ({ log, ...restOfProps }: CellProps) => {
  const { user, company } = log;

  const getUserInfo = () => {
    const userName = `${capitalizeFirstLetter(
      user?.first_name || '',
    )} ${capitalizeFirstLetter(user?.last_name || '')}`;
    //  TODO: verified logic

    return userName;
  };

  return (
    <TableCell {...restOfProps}>
      <div className='w-1/1 h-1/1 flex flex-row items-center justify-start'>
        <Avatar sx={{ width: 40, height: 40 }} src={user?.photo} />
        <div className='h-1/1 ml-1.5 flex flex-col items-start justify-center'>
          <div className='text-base font-normal text-[var(--bluon--ds--brand)]'>
            {user?.company?.name ||
              company?.name ||
              "Technician's Personal Use"}
          </div>
          <div className='text-sm font-normal text-black text-opacity-60'>
            {getUserInfo()}
          </div>
        </div>
      </div>
    </TableCell>
  );
};

export default UserInfoCell;
