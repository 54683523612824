import client from '..';

import { NotificationSettingsDataProps } from './interface';

const environment = process ? process.env : null;

export async function getNotificationsSettings(): Promise<NotificationSettingsDataProps> {
  const { data } = await client.get(
    `/${environment?.REACT_APP_API_VERSION}/settings`,
    {
      params: {
        group: 'notification',
      },
    },
  );

  return data;
}

export async function updateNotificationSettings(params: any): Promise<any> {
  const { data } = await client.post(
    `/${environment?.REACT_APP_API_VERSION}/settings/bulk-notification`,
    {
      settings: params,
    },
  );

  return data;
}
