import { Box, Typography } from '@mui/material';
import React from 'react';

import { BrandsModalEmptySearchProps } from './interface';
import './styles.scss';

export const BrandsModalEmptySearch = ({
  search,
}: BrandsModalEmptySearchProps) => {
  return (
    <Box className='bl-brands-modal-empty-search'>
      <Typography variant='h5'>No results matching</Typography>
      <Typography variant='body1'>{search}</Typography>
      <Typography variant='caption'>
        Clear the search field and try again.
      </Typography>
    </Box>
  );
};
