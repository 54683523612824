import { PureAbility } from '@casl/ability';

import { IHomeRoute } from '@config/routing/interface';

export const getFirstAvailableHomeRoute = (
  routes: IHomeRoute[],
  ability: PureAbility,
) => {
  if (!ability?.rules?.length) return null;

  return (
    routes.find(
      (route) => ability?.can(route?.ability?.action, route?.ability?.subject),
    )?.path || null
  );
};
