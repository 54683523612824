import { Notification } from './subpages/Notification';
import { StoreInfo } from './subpages/StoreInfo';

import { EAbilityActions, EAbilitySubjects } from '@config/canAbility/types';

export const accountTabs = [
  {
    id: 0,
    label: 'Store',
    dataTestId: 'accountStoreTab',
    abilityConfig: null,
    component: <StoreInfo />,
  },
  {
    id: 1,
    label: 'Notifications',
    dataTestId: 'accountNotificationsTab',
    abilityConfig: {
      action: EAbilityActions.MANAGE,
      subject: EAbilitySubjects.NOTIFICATIONS,
    },
    component: <Notification />,
  },
];
